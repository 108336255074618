export interface ITypeSummaryColumn {
  field: string;
  header: string;
  hdrClass?: string;
  fldClass?: string;
  show: boolean;
  disabled?: boolean;
  editable?: boolean;
}

export class AuditTypeTableHelper {
  public typeSummaryColumns: ITypeSummaryColumn[];
  public typeSummaryFooterColumns: ITypeSummaryColumn[];

  constructor() {
    this.generateTypeSummaryColumns();
    this.generateFooterColumns();
  }

  private generateTypeSummaryColumns(): void {
    this.typeSummaryColumns = [
        { field: 'AttributeName', header: 'Audit Type Name',
          show: true, disabled: false},
        { field: 'AuditCount', header: 'Audit Count',
          show: true, disabled: false},
        { field: 'ClaimCount', header: 'Claim Count',
          show: true, disabled: false},
        { field: 'InitialRiskAmount', header: 'Initial Risk Amt',
          hdrClass: 'money', fldClass: 'money', show: true, disabled: false},
        { field: 'ApprovedRecoupmentAmount', header: 'Approved Recoupment Amt',
          hdrClass: 'money', fldClass: 'money', show: true, disabled: false},
    ];
  }

  private generateFooterColumns(): void {
    this.typeSummaryFooterColumns = [
      { field: 'AuditCountFtr', header: 'Audit Count',
          show: true, disabled: false},
      { field: 'ClaimCountFtr', header: 'Claim Count',
          show: true, disabled: false},
      { field: 'InitialRiskAmountFtr', header: 'Initial Risk Amount',
          hdrClass: 'money', fldClass: 'money', show: true, disabled: false},
      { field: 'ApprovedRecoupmentAmountFtr', header: 'Approved Recoupment Amount',
          hdrClass: 'money', fldClass: 'money', show: true, disabled: false},
    ];
  }
}
