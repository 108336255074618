import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import {
  AuditSpecialistService,
  AuditStatusService,
  AuthorizationService, DeliveryMethodService,
  GlobalFilterService,
  UiHelperService,
  AuditService
} from 'src/app/services';
import { forkJoin, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuditSearchRequest,
  AuditSearchResult,
  StatusModel,
  PrimeNgListItem,
  Pagination,
  ILimitedResult
} from 'src/app/models';
import { ValidateDates } from 'src/app/validators/validate-dates';
import { AuditByStatusesFilter } from 'src/app/models/workflow/audit-by-statuses-filter';
import { AuditByStatusesResult } from 'src/app/models/workflow/audit-by-statuses-results';
import { ITableColumn } from '../../admin/audit-vendor/helpers/vendor-table-helper';
import { AuditWorkflowSearchFilter } from 'src/app/models/workflow/audit-workflow-search-filter';
import * as _ from 'lodash';
import { AuditWorkflowSearchResult } from 'src/app/models/workflow/audit-workflow-search-result';
import { WorkFlowCache } from 'src/app/models/workflow/workflow-cache';

@Component({
  selector: 'app-workflow-summary',
  templateUrl: './workflow-summary.component.html',
  styleUrls: ['./workflow-summary.component.css']
})
export class WorkflowSummaryComponent implements OnInit, OnDestroy {
  public readonly STORAGE_ITEM = 'workflowCache';
  readonly featureAuditEdit = 'UI::AuditEdit';
  public readonly pageSizeOptions = [{label: 30, value: 30},
                                    {label: 10, value: 10}];
  public auditStatusListItems: PrimeNgListItem[];
  public auditSpecialistListItems: PrimeNgListItem[];
  public loadingMasterData: boolean;
  public clientChangedSubscription: any;
  public workflowFormGroup: FormGroup;
  public workflowStatusResults: AuditByStatusesResult[];
  public workflowStatusFilter: AuditByStatusesFilter;
  public showStatuses: boolean;
  public autoShowTable: boolean;
  public hasEdit: boolean;
  public loadingStatus: boolean;
  public readonly dateRangeError = 'The date range is invalid';
  public datesInvalid: boolean;
  public pageSize = 10;
  public cache: WorkFlowCache;
  private routeSub:any;

  constructor(private fb: FormBuilder,
    private router: Router,
    private authorizationService: AuthorizationService,
    private auditStatusService: AuditStatusService,
    private globalFilterService: GlobalFilterService,
    private auditSpecialistService: AuditSpecialistService,
    private uiHelperService: UiHelperService,
    private route: ActivatedRoute,
    private auditService: AuditService) {
      this.workflowStatusFilter = new AuditWorkflowSearchFilter(new AuditByStatusesFilter(), 0);
      this.clientChangedSubscription = this.globalFilterService.ClientChanged.subscribe(() => {
        sessionStorage.removeItem(this.STORAGE_ITEM);
        this.resetPageSetup();
        this.loadDropDowns();
        this.showStatuses = false;
      });

      this.hasEdit = this.hasEditFeature();
    }

  ngOnInit() {
    this.initialPageSetup();
    const sessionItem = sessionStorage.getItem(this.STORAGE_ITEM);
    if(sessionItem) {
      this.restoreFromCache(sessionItem);
    }
    this.routeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const currentCache = this.buildCachedObject();
        sessionStorage.setItem(this.STORAGE_ITEM, JSON.stringify(currentCache));
      }
    });
  }
    // clean up to prevent memory leaks, by unsubscribing to subscribed events
  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  // check if user has edit feature
  hasEditFeature(): boolean {
    return this.authorizationService.hasFeature(this.featureAuditEdit);
  }

  initialPageSetup() {
    this.initFormGroup();
    this.loadDropDowns();
  }

  buildCachedObject(): WorkFlowCache {
    const cache = new WorkFlowCache();
    cache.Filter = this.workflowStatusFilter;
    cache.Results = this.workflowStatusResults;
    cache.FilterStatusId = this.workflowFormGroup.controls['auditStatusId'].value;
    cache.ShowStatus = this.showStatuses;
    return cache;
  }

  restoreFromCache(sessionItem: string) {
    this.workflowStatusFilter = null;
    this.cache = sessionItem ? JSON.parse(sessionItem) : null;
    if(this.cache) {
      this.showStatuses = this.cache.ShowStatus;
      this.workflowStatusFilter = this.cache.Filter;
      // update filter
      this.workflowFormGroup.reset({
        auditStatusId: this.cache.FilterStatusId,
        specialistId: this.cache.Filter.AuditSpecialistId,
        dueStartDate: this.cache.Filter.StartAuditDueDate? new Date(this.cache.Filter.StartAuditDueDate) : null,
        dueEndDate: this.cache.Filter.EndAuditDueDate? new Date(this.cache.Filter.EndAuditDueDate): null,
        statusStartDate: this.cache.Filter.StartStatusDueDate ? new Date(this.cache.Filter.StartStatusDueDate): null,
        statusEndDate: this.cache.Filter.EndStatusDueDate ? new Date(this.cache.Filter.EndStatusDueDate): null
      })
      //update store for status
      this.workflowStatusResults = this.cache.Results;
      this.showStatuses = this.cache.ShowStatus;
    }
  }

  getInitialSummary(): void {
    this.auditService.getWorkflowSummary(new AuditByStatusesFilter())
        .subscribe( (response) => {
          this.workflowStatusResults = response;
          this.showStatuses = true;
          this.loadingStatus = false;
      });
  }
  initFormGroup() {
    this.workflowFormGroup = this.fb.group(
      {
        auditStatusId: '',
        specialistId: '',
        dueStartDate: '',
        dueEndDate: '',
        statusStartDate: '',
        statusEndDate: ''
      }, {
        validator: [
          ValidateDates.StartDateNotGreaterThanEndDate('dueStartDate', 'dueEndDate'),
          ValidateDates.StartDateNotGreaterThanEndDate('statusStartDate', 'statusEndDate'),
        ]
      });
    }
  loadDropDowns() {
    // set flag to disable ui controls
    this.loadingMasterData = true;
    // very important to deal to understand the data comes out
    // in the same order it was put in.
    forkJoin(
      this.auditStatusService.getAuditStatusListItems(true),
      this.auditSpecialistService.getAuditSpecialistListItems(),
    )
      .subscribe(([
        respAuditStatuses,
        respAuditSpecialists,
      ]) => {
        this.auditStatusListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditStatuses);
        this.auditSpecialistListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditSpecialists);
               // set flag to enable ui controls
        this.loadingMasterData = false; // we can enable ui components when false
      });
  }

  searchByStatus() {
    this.loadingStatus = !this.loadingStatus;
    const statusId = this.workflowFormGroup.controls['auditStatusId'].value;

    this.workflowStatusFilter = new AuditByStatusesFilter(
      this.workflowFormGroup.controls['specialistId'].value,
      this.workflowFormGroup.controls['statusStartDate'].value,
      this.workflowFormGroup.controls['statusEndDate'].value,
      this.workflowFormGroup.controls['dueStartDate'].value,
      this.workflowFormGroup.controls['dueEndDate'].value,
    );

    this.auditService.getWorkflowSummary(this.workflowStatusFilter)
      .subscribe( (response) => {
        if (statusId) {
          const index = _.filter(response, {'AuditStatusId': Number(statusId)});
          this.workflowStatusResults = index;
        } else {
          this.workflowStatusResults = response;
        }

        this.showStatuses = this.workflowStatusResults.length > 0 ? true : false;
        if (this.workflowStatusResults.length === 1 ) {
          this.autoShowTable = true;
        } else {
          this.autoShowTable = false;
        }

        this.loadingStatus = !this.loadingStatus;
    });
  }

  invalidDueDates() {
    if (this.workflowFormGroup) {
      return  this.workflowFormGroup.controls['dueEndDate'].hasError('invalidDateOrder');
    }
  }

  invalidStatusDates() {
    if (this.workflowFormGroup) {
      return  this.workflowFormGroup.controls['statusEndDate'].hasError('invalidDateOrder');
    }
  }

  resetPageSetup() {
    sessionStorage.removeItem(this.STORAGE_ITEM);
    this.initFormGroup();
    this.workflowFormGroup.reset();
    this.showStatuses = false;
  }
}
