import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { AdminModalAction } from '../../models/adminModalAction';

@Component({
  selector: 'app-admin-add-edit-dialog',
  templateUrl: './admin-add-edit-dialog.component.html',
  styleUrls: ['./admin-add-edit-dialog.component.css']
})
export class AdminAddEditDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('name', { static: true }) name: ElementRef;
  @ViewChild('code', { static: true }) code: ElementRef;
  @ViewChild('save', { static: true }) save: ElementRef;
  @Input() show: boolean;
  @Input() id: number;
  @Input() titleText: string;
  @Input() nameValue: string;
  @Input() codeValue: string;
  @Input() editCode: boolean;
  @Input() showCode: boolean;
  @Input() displayOnWorkflowValue: boolean;
  @Input() showDisplayOnWorkflow: boolean;
  @Output() modalAction: EventEmitter<AdminModalAction> = new EventEmitter<AdminModalAction>();

  submitDisabled: boolean;
  nameValueInternal: string;
  codeValueInternal: string;
  displayOnWorkflowValueInternal: boolean;
  displayOnWorkflowCurrentValue: boolean;

  constructor(
  ) { }

  ngOnInit() {
    this.checkSubmitEnabled();
  }

  ngAfterViewInit() {
    this.save.nativeElement.blur();
  }

  onHide() {
    this.name.nativeElement.blur();
  }

  onShow(): void {
    this.nameValueInternal = this.nameValue;
    this.codeValueInternal = this.codeValue;
    this.submitDisabled = true;
    this.name.nativeElement.focus();
    this.displayOnWorkflowValueInternal = this.displayOnWorkflowValue ? this.displayOnWorkflowValue : false;
    this.displayOnWorkflowCurrentValue = this.displayOnWorkflowValue;
  }

  limit(controlName: string) {
    switch (controlName) {
      case 'nameChange': {
        if (this.nameValueInternal && this.nameValueInternal.length > 50) {
          this.nameValueInternal = this.nameValueInternal.substring(0, 50);
          this.name.nativeElement.value = this.name.nativeElement.value.substring(0, 50);
        }
        break;
      }
      case 'codeChange': {
        if (this.codeValueInternal && this.codeValueInternal.length > 5) {
          this.codeValueInternal = this.codeValueInternal.substring(0, 5);
          this.code.nativeElement.value = this.code.nativeElement.value.substring(0, 5);
        }
        break;
      }
        break;
    }
    this.checkSubmitEnabled();
  }

  submitClick(action: string): void {
    if (action === 'cancel') {
      this.modalAction.emit({
        action: action,
        id: null,
        nameValue: null,
        codeValue: null,
        workflowValue: null
      });
      this.nameValueInternal = null;
      this.codeValueInternal = null;
    } else {
      if (this.id) {
        this.modalAction.emit({
          action: 'update',
          id: this.id,
          nameValue: this.nameValueInternal,
          codeValue: this.codeValueInternal,
          workflowValue: this.displayOnWorkflowValueInternal
        });
      } else {
        this.modalAction.emit({
          action: 'add',
          id: this.id,
          nameValue: this.nameValueInternal,
          codeValue: this.codeValueInternal,
          workflowValue: this.displayOnWorkflowValueInternal
        });
      }
    }
    this.name.nativeElement.focus();
  }

  checkSubmitEnabled(): void {
    if (!this.showCode) {
      this.submitDisabled = this.nameValueInternal == null || this.nameValueInternal.length < 1;
    } else if (!this.onShow) {
      this.submitDisabled = this.nameValueInternal == null || this.nameValueInternal.length < 1 ;
    } else if (this.showDisplayOnWorkflow) {
      this.submitDisabled = this.displayOnWorkflowValueInternal === this.displayOnWorkflowCurrentValue ||
                            this.nameValueInternal == null || this.nameValueInternal.length < 1;
    } else {
      this.submitDisabled = this.nameValueInternal == null || this.nameValueInternal.length < 1 ||
                            this.codeValueInternal == null || this.codeValueInternal.length < 1;
    }
  }
}
