import { ITableColumn } from 'src/app/models/tColumn';

export class AuditStatusHistoryTableHelper {
  public auditStatusHistoryColumns: ITableColumn[];
  constructor() {
    this.buildColumns();
  }

  buildColumns(): void {
    this.auditStatusHistoryColumns = [
      { field: 'Name', header: 'Audit Status' },
      { field: 'DueDate', header: 'Due Date' },
      { field: 'CreatedBy', header: 'Author' },
      { field: 'CreateDate', header: 'Date Modified' }
    ]; 
  }
}
