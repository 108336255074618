export class AuditCarrier {
    AuditCarrierId: number;
    // [Required]
    // StringLength(50)]
    Name: string;
    InUse: boolean;
    ModifiedBy: string;
    ModifiedDate: Date;
    CreatedBy: string;
    CreateDate: Date;
}
