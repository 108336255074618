import { Table } from 'primeng/table';
import { findIndex } from 'lodash';

export interface IAssociatedClaimsColumn {
  field: string;
  header: string;
  hdrClass?: string;
  fldClass?: string;
  show: boolean;
  disabled?: boolean;
  editable?: boolean;
  checked?: boolean;
  sortable?: boolean;
}

export class AuditFormTableHelper {
  public associatedClaimsColumns: IAssociatedClaimsColumn[];

  constructor() {
    this.generateAssociatedClaimsColumns();
  }

  private generateAssociatedClaimsColumns(): void {
    this.associatedClaimsColumns = [
        { field: 'Selected', header: '',
          hdrClass: 'grid-checkbox', fldClass: 'grid-checkbox', show: true, disabled: false, sortable: false},
        { field: 'Prescription_PrescriptionNumber', header: 'Rx Number',
          show: true, disabled: false, sortable: true},
        { field: 'Prescription_SplitBillCode', header: 'COB #',
          show: true, disabled: false, sortable: true},
        { field: 'Prescription_FillDate', header: 'Fill Date',
          show: true, disabled: false, sortable: true},
        { field: 'Payer_ProcessorIdentifier', header: 'BIN',
          show: true, disabled: false, sortable: true},
        { field: 'Payer_ShardPayerName', header: 'Carrier Name',
          show: true, disabled: false, sortable: true},
        { field: 'Location_CorporateLocationNumber', header: 'Store #',
          show: true, disabled: false, sortable: true},
        { field: 'Drug_NationalDrugCode', header: 'NDC',
          show: true, disabled: false, sortable: true},
        { field: 'Drug_Label', header: 'Drug Name', // , fldClass: 'overflow'
          show: true, disabled: false, sortable: true},
        { field: 'DiscrepancyCode', header: 'Discrepancy Code(s)',
          show: true, disabled: false, sortable: true},
        { field: 'TagCode', header: 'Tag(s)',
          show: true, hdrClass: 'no-pointer', disabled: false, sortable: false},
        { field: 'InitialRisk', header: 'Initial Risk',
          hdrClass: 'money', fldClass: 'money', show: true, disabled: false, sortable: true},
        { field: 'ApprovedRecoupmentAmount', header: 'Approved Recoupment Amt',
          hdrClass: 'money', fldClass: 'money', show: true, disabled: false, sortable: true},
    ];
  }
}
