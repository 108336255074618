import { Component, OnInit, Input } from '@angular/core';
import { Section } from '../../models';
import { AuthorizationService, GlobalFilterService} from '../../services';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import Theme from 'b2b-theme';
import 'b2b-theme/dist/b2b-theme.css';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { SelectItem } from 'primeng/api/selectitem';
import { FileStatus } from 'src/app/models/file-status';
import { browser } from 'protractor';
import { PhiService} from '../../services/phi.service';
import { delay } from 'rxjs/operators';

declare function intializeHealthcareTopBar(parentContainer: JQuery, authzApiUrl: string, PHIToggleCallback: (toggleStatus: string) => void): void;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  private topBarLoaded = false;
  public sectionsObj: Section;
  public usersClients: SelectItem[] = [];
  public clientsLoaded = false;
  public statusRollup: string;
  public readonly apptitle = environment.applicationDisplayName;
  @Input() sections: string;
private _phiService: PhiService;

  constructor(
    private globalFilterService: GlobalFilterService,
    private authorizationService: AuthorizationService,
    private _cookieService: CookieService,
    private phiService: PhiService
  ) {
    if (this.topBarLoaded) {
      return;
    }
    this._phiService = phiService;
    this.injectHealthcareTopBar()
      .then(() => {
        const topBar = intializeHealthcareTopBar($('#healthcareTopBar'), environment.authZApiRoot, this.PHIToggleCallback.bind(this)  );
        // console.log(`PHIToggleCallBack Subscribed`);
        window['healthcareTopBar'] = topBar;
        this.topBarLoaded = true;
        this.authorizationService.setHealthcareTopBar(topBar);
        Theme.init();
      });

      this.globalFilterService.ClientsLoaded.subscribe(
        (response) => {
          _.forEach(this.globalFilterService.clients, (client) => {
            this.usersClients.push({ label: `${client.ClientId} - ${client.ClientName}`, value: client.ClientId });
          });
          this.clientsLoaded = true;
        }
      );
      this.globalFilterService.loadClients();
  }

  ngOnInit(): void {
    this.sectionsObj = JSON.parse(this.sections);
  }
  clientSelected(event: any) {
    this.globalFilterService.selectedClientId = event.value;
  }
  private injectHealthcareTopBar(): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = environment.healthcareTopBar;
      script.addEventListener('load', resolve);
      script.addEventListener('error', () => reject('Error loading script.'));
      script.addEventListener('abort', () => reject('Script loading aborted.'));
      document.body.appendChild(script);
    });
  }
  public get showHeader(): boolean {
    return this._cookieService.get('id_token') !== '';
  }
  checkValidLink(feature: string): boolean {
    if (!feature) {
        return false;
    }
    if (!this.authorizationService.hasFeature(feature)) {
        return false;
    }
    return true;
  }

  PHIToggleCallback(phiValue?: string) {
    // console.log(`PHIToggleCallBack - ${phiValue}`)
    if (phiValue) {
      this.phiService.PhiToggle = true;
    } else {
      this.phiService.PhiToggle = false;
    }
  }
}
