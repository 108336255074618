import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { environment } from '../../environments/environment';
import { ILimitedResult, LimitedResult, Pagination } from '../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImageSearchResult } from '../models/image-search-result';
import { Guid } from 'guid-typescript';

const BASE_URL_AUDIT = `${environment.auditApiRoot}/clients`;
const BASE_URL_IMAGE = `${environment.imageApiRoot}/clients`;

@Injectable({
  providedIn: 'root'
})

export class ImageRepoService {

  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) { }

  public downloadImage(guid: string): Observable<Blob> {
    return this.http.get(`${BASE_URL_IMAGE}/${this.globalFilterService.selectedClientId}/ImageRepository/Image/${guid}/PhiToggle/true`,
                          { responseType: 'blob' })
  }

  public downloadCompressedImages(guids: string[]): Observable<Blob> {
    return this.http.post(`${BASE_URL_IMAGE}/${this.globalFilterService.selectedClientId}/ImageRepository/DownloadCompressedImages/PhiToggle/true`,
    guids,
    { headers: this.httpUtility.getHeaders(), responseType: 'blob' })
  }

  public getImageMetadata(guid: string): Observable<ImageSearchResult> {
    return this.http.get<ImageSearchResult>(`${BASE_URL_IMAGE}/${this.globalFilterService.selectedClientId}/ImageRepository/ImageMetadata/${guid}`)
  }
  
}
