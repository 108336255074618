import { HttpParams } from '@angular/common/http';

export class Pagination {

  constructor(public limit: number,
              public offset: number,
              public sort = '',
              public sortOrder?: number) {}

  public buildSearchParams(): HttpParams {
    let params = new HttpParams();
        params = params.append('limit', this.limit.toString());
        params = params.append('offset', this.offset.toString());
        if (this.sort) {
            params = params.append('sort', `${this.getSortOrder(this.sortOrder)}${this.sort}`);

        }
    return params;
  }

  getSortOrder(order: number): string {
    return order === -1 ? '-' : '';
  }
}

