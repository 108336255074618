import { Component, EventEmitter, OnInit, ViewChild, Input, Output, OnChanges } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import * as toastr from 'toastr';
import { ClaimService, AuditService } from 'src/app/services';
import { BusinessResponse } from 'src/app/models/businessResponse';
import { BusinessResponseService } from 'src/app/services/business-response.service';
import { environment } from 'src/environments/environment';

const TOASTR_OPTIONS: any = {
  closeButton: true,
  timeOut: '0',
  extendedTimeOut: '0'
};


@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.css']
})

export class FileUploadDialogComponent implements OnInit {
  @ViewChild('fileUpload', { static: true }) fileUploadElem: FileUpload;
  @Input() show: boolean;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() key: any;
  @Output() uploadComplete: EventEmitter<any> = new EventEmitter<any>();

  public uploading: boolean;
  public badFile: boolean;
  public readonly helpFilePath = '../assets/file-templates/' + environment.helpDocName;
  constructor(private service: AuditService,
    private busRespService: BusinessResponseService) { }

  ngOnInit() {
  }

  uploadFile(event: any, key: any): void {
    this.uploading = true;
    for (const file of event.files) {
      this.service.postFile(key, file).subscribe(
        (response: BusinessResponse) => {
          if (this.busRespService.handleBusinessResponse('', response, TOASTR_OPTIONS)) {
          } else {
            this.uploadComplete.emit(true);
          }
        }, (err) => {
          toastr.error('There was an issue submitting the file for upload.',
            'File Upload Status', );
          this.uploadComplete.emit(false);
        });
    }

    this.show = false;
    this.fileUploadElem.clear();
  }

  handleFile(event): void {
    if (event.files && !event.files[0].name.includes('.csv')) {
      this.badFile = true;
    } else {
      this.badFile = false;
    }
  }

  onShow(event): void {
    this.fileUploadElem.files = [];
  }

  hide(): void {
    this.showChange.emit(false);
    this.uploadComplete.emit(false);
  }
}
