import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { LookerAdminService } from './looker-admin.service';

@Injectable()
export class LookerAnalyticsService {
    origin = environment.lookerAnalytics.url;

    constructor(private router: Router, private location: Location, private activatedRoute: ActivatedRoute,
      private lookerAdminService: LookerAdminService) { }

    public handleEvents(event: any, iframe) {
        const data = JSON.parse(event.data);
        if (data['type'] === 'drillmenu:click' && data['link_type'] === 'dashboard' && data['modal'] === false) {
            // This event is triggered When user do cross dashboard navigation
            var componentPath = this.router.url.split('/')[1];
            var absUrl = this.origin + data['url'];
            this.lookerAdminService.storeDashboards.items.forEach( x => {
              if (absUrl.includes(x.slugId)) {

                var path = componentPath + '/analytics/' + x.slugId;
                this.router.navigate([path], {queryParams: { absoluteUrl: absUrl}});
              }
            });
        } else if (data['type'] === 'dashboard:filters:changed') {
            // This event is triggered When user selects the filter in dashboard
            const filters = data['dashboard']['dashboard_filters'];
            const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: filters }).toString();
            this.location.go(url);
        } else if (data['type'] === 'dashboard:loaded') {
            // This event is triggered When dashboard loads
            let locationParams = {};
            if (this.location.path().split('?').length > 1) {
                locationParams = this.convertQueryStringtoJson(this.location.path().split('?')[1]);
            }
            // isEqual condition is required to avoid infinity loop
            if (!_.isEmpty(locationParams) && !_.isEqual(locationParams, data['dashboard']['dashboard_filters'])) {
                const dashboardFilterRequest = this.getDashboardFilterUpdateRequest(locationParams);
                iframe.postMessage(dashboardFilterRequest, this.origin);
                const dashboardRunRequest = this.getDashboardRunRequest();
                iframe.postMessage(dashboardRunRequest, this.origin);
            }
        } else { }
    }

    private getFiltersQueryStrings(filters) {
        let queryString = '';
        for (const key in filters) {
            if (filters[key] !== '') {
                queryString = (queryString === '') ? '?' + key + '=' + filters[key] : queryString + '&' + key + '=' + filters[key];
            }
        }
        return queryString;
    }

    public convertQueryStringtoJson(queryString) {
        let obj = {};
        if (queryString) {
            // Before decoding the query string, all '+' signs needs to be replaced with spaces
            queryString.replace(/\+/g, ' ').split('&').map((item) => {
                const [k, v] = item.split('=').map((val: string) => decodeURIComponent(val));
                obj[k] = v;
            });
        }
        return obj;
    }

    public getDashboardFilterUpdateRequest(filtersObject) {
        return JSON.stringify(
            {
                type: 'dashboard:filters:update',
                filters: filtersObject
            }
        );
    }

    public getDashboardRunRequest() {
        return JSON.stringify(
            {
                type: 'dashboard:run'
            }
        );
    }

    // This method is required to update the url with forwarded filters while doing cross dashboard navigation so that user can bookmark it
    public processForwardedParams(absoluteUrl: string) {
        let forwardedParams = {};
        if (absoluteUrl.split('?').length > 1) {
            forwardedParams = this.convertQueryStringtoJson(absoluteUrl.split('?')[1]);
        }
        const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: forwardedParams }).toString();
        this.location.go(url);
    }
}
