<h2 style="margin-bottom: 1em;">Audit Statuses</h2>
<p style="font-style: italic;color:orangered;padding:2px; ;">
  <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
  In Use column reflects whether a Status has ever been associated to an audit. 
</p>
<app-audit-toolbar [toolbarItemList]="toolbarHelper.currentToolbar" (OnClick)="toolbarBtnClick($event)"></app-audit-toolbar>
<p-table #dt [columns]="auditStatusTableHelper.statusColumns" [value]="auditStatusItems" (onLazyLoad)="loadDataLazy($event)"
  [loading]="gridLoading" loading="fa-spinner" [scrollable]="true" [lazy]="true" [paginator]="true" [responsive]="true"
  [rows]="30" [totalRecords]="totalItemCount">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [ngClass]="col.hdrClass ? col.hdrClass : 'default'" [ngStyle]="!col.sortable? {'cursor': 'default'} : ''"
        [style.cursor]="col.disableSort === true ? 'default' : 'pointer'" [pSortableColumn]="col.disableSort === true ? '' : col.field">
        {{col.header}}
        <p-sortIcon *ngIf="!col.disableSort" [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" [ngClass]="col.fldClass? col.fldClass : 'default'">
        <div *ngIf="col.field === 'Name'">
          <a *ngIf="canEdit" [routerLink]="" (click)="openEditDialog(rowData)" style="cursor:pointer;">
            {{rowData[col.field]}}
          </a>
          <span *ngIf="!canEdit">{{rowData[col.field]}}</span>
        </div>
        <div *ngIf="col.field === 'InUse'">
          {{rowData[col.field] === true ? "Yes" : "No"}}
        </div>
        <div *ngIf="col.field === 'DisplayOnWorkflow'">
          {{rowData[col.field] === true ? "Yes" : "No"}}
        </div>
        <div *ngIf="col.field === 'ModifiedBy'">
          {{rowData[col.field]}}
        </div>
        <div *ngIf="col.field === 'ModifiedDate'">
          {{rowData[col.field] | date: 'MM/dd/yyyy'}}
        </div>
        <div *ngIf="col.field === 'Delete' && !col.disabled"  [ngStyle]="!rowData[col.InUse] ? {'cursor': 'pointer'} : ''">
          <a *ngIf="rowData.InUse !== true" (click)="confirmDelete(rowData)" style="cursor: pointer; width: 30px;">
            <svg class="inm-icon" aria-label="Delete">
              <use xlink:href="#inm-icon__trash" class="danger" /></svg>
          </a>
          <a *ngIf="rowData.InUse === true" style="cursor: default;width: 30px;">
            <svg class="inm-icon" aria-label="Delete">
              <use xlink:href="#inm-icon__trash" class="disabled" /></svg>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    Total Record Count: {{totalItemCount}}
  </ng-template>
</p-table>

<app-admin-add-edit-dialog 
  [titleText]="addEditTitle" 
  [(show)]="showAuditStatusDialog" 
  [nameValue]="statusName" 
  [displayOnWorkflowValue]="displayOnWorkflow"
  [id]="statusId"
  [showDisplayOnWorkflow]="true"
  (modalAction)="modalAction($event)">
</app-admin-add-edit-dialog>
<app-delete-dialog titleText="Delete Confirmation" [(show)]="showDeleteDialog" [id]="statusId"
  [message]="deleteMessage" (modalAction)="deleteModalAction($event)"></app-delete-dialog>
  <p-dialog 
    header="Apply Workflow Order" 
    [(visible)]="displayOrderDialog"
    [minWidth]="500" 
    [minHeight]="500"
    modal="true">
      <ngx-sortable #sortable
      [listStyle]="{width: 'auto', height: '400px'}"
        [items]="newStatusOrder" >
        <ng-template let-item>
          <div>
            <p class="list-style" style="margin-left: 10px">{{item.Name}}</p>
          </div>
        </ng-template>
      </ngx-sortable>
      <div class="btn-container-dialog" style="padding-top: 14px">
        <button #save type="button" class="inm-button btn-padding" (click)="orderDialogClick('save')">Save</button>
        <button type="button" class="inm-button btn-padding" (click)="orderDialogClick('cancel')">Cancel</button>
      </div>
  </p-dialog>