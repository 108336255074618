import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { Pagination, ILimitedResult} from '../models';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { DiscrepancyCode } from 'src/app/models/discrepancyCode';
import { BusinessResponse } from '../models/businessResponse';

@Injectable({
  providedIn: 'root'
})
export class AuditDiscrepancyCodeService {
  private readonly base_url = `${environment.auditApiRoot}/clients`;

  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) { }

  public getAuditDiscrepancyCodesLimitedResult(pagination: Pagination): Observable<ILimitedResult<DiscrepancyCode>> {
    const params = pagination.buildSearchParams();
    return this.http.get<ILimitedResult<DiscrepancyCode>>(
      `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditDiscrepancyCodes`, {params});
  }

  public addAuditDiscrepancyCode(name: string, code: string): Observable<BusinessResponse> {
    const url = `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditDiscrepancyCodes`;
    return this.http.post<BusinessResponse>(url, { Name: name, Code: code });
  }

  public updateAuditDiscrepancyCode(discrepancyCodeId: number, name: string, code: string): Observable<BusinessResponse> {
    const url = `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditDiscrepancyCodes`;
    return this.http.put<BusinessResponse>(url, {DiscrepancyCodeId: discrepancyCodeId, Name: name, Code: code});
  }

  public deleteDiscrepancyCode(discrepancyCodeId: number): Observable<BusinessResponse> {
    const url = `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditDiscrepancyCodes/${discrepancyCodeId}`;
    return this.http.delete<BusinessResponse>(url);
  }
}
