import { EventEmitter, Injectable, Output } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { Client } from '../models';
import * as _ from 'lodash';
import { ClientTenant } from '../models/client-tenant';

@Injectable()
export class GlobalFilterService {
  public clientsLoaded = false;
  public clients: Client[] = [];
  public clientTenants: ClientTenant[] = [];
  public readonly STORAGE_ITEM = 'currentSearchCriteria';
  constructor(private authorizationService: AuthorizationService) {
  }

  @Output() ClientsLoaded: EventEmitter<boolean> = new EventEmitter();
  @Output() ClientChanged: EventEmitter<boolean> = new EventEmitter();

  private _selectedClientId: string;
  private _selectedClientTenantName: string;

  public get selectedClientId(): string {
    return this._selectedClientId;
  }
  public set selectedClientId(clientId: string) {
    this._selectedClientId = clientId;
    //this method is called in the header component on the client dropdown.
    //read the notes about this method and adjust when power client tenant users become a thing
    if(this.clientTenants) {
      this.getUserClientTenant();
    }
    sessionStorage.removeItem(this.STORAGE_ITEM);
    this.ClientChanged.emit(true);
  }

  public get selectedClientTenantName(): string {
    return this._selectedClientTenantName;
  }

  public set selectedClientTenantName(clientTenantName: string) {
    this._selectedClientTenantName = clientTenantName;
    sessionStorage.removeItem(this.STORAGE_ITEM);
    this.ClientChanged.emit(true);
  }
//This method will not be necessary when a drop down is created for client tenant. 
 private getUserClientTenant() {
    this._selectedClientTenantName = ''; 
    var tempTenants = this.clientTenants;
    tempTenants = tempTenants.filter(clientTenant => clientTenant.ClientId === parseInt(this._selectedClientId));
    //For now a user can only be set up for one client tenant per client. Remove this when we do the drop down
    if(tempTenants.length === 1) {
      this._selectedClientTenantName = tempTenants[0].TenantName;
    }
  }

  async loadClients() {

    if (this.clients.length !== 0) {
      this.ClientsLoaded.emit(true);
      return;
    }
    await this.authorizationService.CurrentUser().then(
      currentUser => {

        // store this in the class as a Client[]
        this.clients = currentUser.Clients;
        this.clientTenants = currentUser.ClientTenants;
        // In the header component you can read the GFS.Clients whatever and transform it to the dropdown stuff.
        this._selectedClientId = this.clients[0].ClientId.toString();
        if(this.clientTenants) {
          this.getUserClientTenant();
        }
        this.clientsLoaded = true;

        this.ClientsLoaded.emit(true);
      }
    );
  }
}
