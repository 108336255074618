import { Component, AfterViewInit, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthorizationService } from './services/authorization.service';
import * as toastr from 'toastr';
import { AppInsightsService } from './services';
import { PhiService } from './services/phi.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [PhiService]
})
export class AppComponent implements OnInit {
  title = environment.applicationDisplayName;
  sections: string = JSON.stringify([{
    name: 'AUDIT',
    path: 'audit',
    feature: 'UI::MenuItemAudit',
    subSection: null,
  },
  {
    name: 'ADMIN',
    path: 'admin',
    feature: 'UI::MenuItemAdmin',
    subSection: null,
  },
  {
    name: 'METRICS',
    path: 'metrics/',
    url: '/analytics',
    feature: 'UI::MenuItemMetrics',
    subSection: null,
  }]);

  apiRoot: string;
  ipAddress: string;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private http: HttpClient,
    private authorizationService: AuthorizationService) {
    toastr.options = {
      showDuration: '1000',
      hideDuration: '1000',
      timeOut: '3000',
      extendedTimeOut: '2000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        googleAnalyticsService.logPageView(event);
      }
    });
  }

  ngOnInit(): void {
    this.apiRoot = environment.auditApiRoot;
  }

}
