import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ViewChildren,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import {
  AddressBookTableHelper
} from './helpers/address-book-table.helper';
import {
  AuditAddressBookService,
  GlobalFilterService,
  AuthorizationService,
  UiHelperService
} from 'src/app/services';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors,
  FormControl,
  AbstractControl,
  ValidatorFn
} from '@angular/forms';
import {
  Table
} from 'primeng/table';
import {
  dropRight
} from 'lodash';
import {
  Pagination
} from 'src/app/models';
import {
  LazyLoadEvent
} from 'primeng/api';
import {
  AddressBookToolbarHelper
} from './helpers/address-book-toolbar.helper';
import {
  ToolbarAction
} from 'src/app/shared/models/toolbarAction';
import {
  ModalAction
} from 'src/app/shared/models/modalAction';
import * as toastr from 'toastr';
import {
  AuditAddressBook
} from 'src/app/models/audit-address-book';
import {
  CustomPhonePipe
} from 'src/app/pipes/custom-phone-pipe';
import {
  DatePipe
} from '@angular/common';

@Component({
  selector: 'app-audit-address-book',
  templateUrl: './audit-address-book.component.html',
  styleUrls: ['./audit-address-book.component.css']
})
export class AuditAddressBookComponent implements OnInit, AfterViewInit {
  @ViewChild('contactName', { static: true }) contactNameControl: ElementRef;
  @ViewChild('save', { static: true }) save: ElementRef;
  public readonly tableHelper: AddressBookTableHelper;
  public readonly toolbarHelper: AddressBookToolbarHelper;
  public readonly max_rows = 30;
  public readonly viewFeatures = [{
      feature: 'UI::AddressBookDelete',
      name: 'delete',
      accessable: false
    },
    {
      feature: 'UI::AddressBookEdit',
      name: 'edit',
      accessable: false
    }
  ];

  public deleteText = '';
  public readonly deleteTitle = 'Delete Confirmation';
  public tableLoading: boolean;
  public confirmDelete: boolean;
  public addressBooks: AuditAddressBook[];
  public auditAddressBook: AuditAddressBook;
  public totalItemCount: number;
  public deleteAllowed: boolean;
  public selectedRows: AuditAddressBook[];
  public editDialogTitle: string;
  public addEditAddressBookVisible: boolean;
  public addressBookId = 0;
  public contactName: string;
  public companyName: string;
  public faxNumber: string;
  public phoneNumber: string;
  public notes: string;
  public addressBookFormGroup: FormGroup;
  public submitDisabled: boolean;
  // error messages
  readonly faxInvalidErrorMessage = 'Fax Number should be 10 digit number.';
  readonly faxRequiredErrorMessage = 'Fax Number is Required.';
  readonly phoneInvalidErrorMessage = 'Phone Number should 10 digit number.';
  readonly validPhoneRegexPattern = '^\\(?[0-9]{3}\\)?[\\s]?[0-9]{3}[-]?[0-9]{4}$';
  readonly contactNameRequiredMessage = 'Contact Name is Required.';

  constructor(private auditAddressBookService: AuditAddressBookService,
    private authService: AuthorizationService,
    private uiHelperService: UiHelperService,
    private globalFilterService: GlobalFilterService,
    private customPhonePipe: CustomPhonePipe,
    private fb: FormBuilder,
    private datePipe: DatePipe) {
    this.tableHelper = new AddressBookTableHelper();
    this.toolbarHelper = new AddressBookToolbarHelper(this.authService);
    this.globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
    this.initFormGroup();
  }

  @ViewChild('dt', { static: true }) dTable: Table;

  ngOnInit() {
    this.tableLoading = true;
    this.checkFeatures();
    this.initFormGroup();
    this.checkSubmitEnabled();
  }

  ngAfterViewInit() {
    this.save.nativeElement.blur();
  }

  hideAddEditAddressBook() {
    this.addEditAddressBookVisible = false;
    this.addressBookFormGroup.reset();
    this.save.nativeElement.blur();
  }

  showAddEditAddressBook() {
    this.submitDisabled = true;
    this.contactNameControl.nativeElement.focus();
  }

  private checkFeatures(): void {
    for (let i = 0; i < this.viewFeatures.length; i++) {
      if (this.authService.hasFeature(this.viewFeatures[i].feature)) {
        this.viewFeatures[i].accessable = true;
      }
    }
    if (!this.viewFeatures[0].accessable) {
      this.tableHelper.tableColumns = dropRight(this.tableHelper.tableColumns);
    }
  }

  initFormGroup() {
    this.addressBookFormGroup = this.fb.group({
      contactName: ['', Validators.compose([Validators.required])],
      companyName: [''],
      // tslint:disable-next-line: max-line-length
      faxNumber: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.pattern(this.validPhoneRegexPattern)])],
      // tslint:disable-next-line: max-line-length
      phoneNumber: ['', Validators.compose([Validators.pattern(this.validPhoneRegexPattern)])],
      notes: ['']
    });
  }

  loadDataLazy(event: LazyLoadEvent): void {
    if (!this.tableLoading) {
      this.tableLoading = true;
    }

    this.auditAddressBookService.getAuditAddressBookLimitedResult(new Pagination(this.max_rows, event.first, event.sortField, event.sortOrder))
      .subscribe((data) => {
        this.addressBooks = data.Items;
        this.totalItemCount = data.TotalItemCount;
        this.tableLoading = false;
      }, (error) => {
        this.tableLoading = false;
      });
  }

  onClientsChanged(): void {
    if (this.dTable) {
      this.dTable.reset();
      this.addressBookId = 0;
      this.tableLoading = true;
    }
  }

  toolbarBtnClick(event: ToolbarAction) {
    switch (event.index) {
      case 0: {
        this.dTable.reset();
        this.hideAddEditAddressBook();
        break;
      }
      case 1: {
        this.handleDialogs('add');
        this.addEditAddressBookVisible = true;
        this.addressBookFormGroup.reset();
        this.editDialogTitle = 'Add New Address Book Contact';
        this.submitDisabled = true;
        break;
      }
    }
  }

  clickName(rowData: AuditAddressBook) {
    this.addressBookId = rowData.AuditAddressBookId;
    this.addressBookFormGroup.controls['contactName'].setValue(rowData.ContactName);
    this.addressBookFormGroup.controls['companyName'].setValue(rowData.CompanyName);
    this.addressBookFormGroup.controls['faxNumber'].setValue(rowData.FaxNumber);
    this.addressBookFormGroup.controls['phoneNumber'].setValue(rowData.PhoneNumber);
    this.addressBookFormGroup.controls['notes'].setValue(rowData.Notes);
    this.addEditAddressBookVisible = true;
    this.editDialogTitle = 'Edit Address Book Contact';
  }

  deleteClicked(rowData: AuditAddressBook): void {
    this.addressBookId = rowData.AuditAddressBookId;
    this.deleteText = `Are you sure that you want to delete <strong>${rowData.ContactName}</strong>?`;
    this.handleDialogs('delete');
  }

  submitClick(action: string) {
    if (action === 'cancel') {
      this.hideAddEditAddressBook();
    } else {
      if (action === 'save') {
        this.auditAddressBook = new AuditAddressBook;
        this.auditAddressBook.AuditAddressBookId = this.addressBookId;
        this.auditAddressBook.CompanyName = this.addressBookFormGroup.controls['companyName'].value;
        this.auditAddressBook.ContactName = this.addressBookFormGroup.controls['contactName'].value;
        if (this.addressBookFormGroup.controls['faxNumber'].value) {
          this.auditAddressBook.FaxNumber = this.unmaskPhone(this.addressBookFormGroup.controls['faxNumber'].value);
        }
        this.auditAddressBook.Notes = this.addressBookFormGroup.controls['notes'].value;
        if (this.addressBookFormGroup.controls['phoneNumber'].value) {
          this.auditAddressBook.PhoneNumber = this.unmaskPhone(this.addressBookFormGroup.controls['phoneNumber'].value);
        }
        if (this.addressBookId === 0) {
          this.auditAddressBookService.addAddressBook(this.auditAddressBook)
            .subscribe((response) => {
              toastr.success('Contact was added successfully!');
              this.dTable.reset();
              this.confirmDelete = false;
              this.hideAddEditAddressBook();
            }, () => {
              toastr.error('We are not able to add the contact at this time.');
            });
        } else {
          this.auditAddressBookService.updateAddressBook(this.auditAddressBook)
            .subscribe((response) => {
              toastr.success('Contact was updated successfully!');
              this.dTable.reset();
              this.confirmDelete = false;
              this.hideAddEditAddressBook();
            }, () => {
              toastr.error('We are not able to update the contact at this time.');
            });
        }
        this.addressBookId = 0;
      }
    }
    this.contactNameControl.nativeElement.focus();
  }

  handleDialogs(name: string) {
    if (name === 'add') {
      this.addressBookFormGroup.reset();
      this.editDialogTitle = 'Add New Address Book Contact';
      this.addEditAddressBookVisible = true;
    }
    if (name === 'delete') {
      this.confirmDelete = true;
    }
  }

  deleteCallback(event: ModalAction): void {
    if (event.action === 'cancel') {
      this.confirmDelete = false;
      return;
    }
    this.auditAddressBookService.deleteAddressBook(this.addressBookId)
      .subscribe((response) => {
        toastr.success('Contact was deleted successfully!');
        this.dTable.reset();
        this.confirmDelete = false;
      }, () => {
        toastr.error('We are not able to delete the contact at this time.');
      });
    this.addressBookId = 0;
  }

  faxIsInvalid() {
    return (!this.addressBookFormGroup.controls['faxNumber'].valid &&
      this.addressBookFormGroup.controls['faxNumber'].touched &&
      this.addressBookFormGroup.controls['faxNumber'].errors['pattern']);
  }

  faxIsEmpty() {
    return (!this.addressBookFormGroup.controls['faxNumber'].valid &&
      this.addressBookFormGroup.controls['faxNumber'].touched &&
      this.addressBookFormGroup.controls['faxNumber'].value === '');
  }
  phoneIsInvalid() {
    return (!this.addressBookFormGroup.controls['phoneNumber'].valid &&
      this.addressBookFormGroup.controls['phoneNumber'].touched &&
      this.addressBookFormGroup.controls['phoneNumber'].errors['pattern']);
  }

  contactNameValid() {
    return (!this.addressBookFormGroup.controls['contactName'].valid &&
      this.addressBookFormGroup.controls['contactName'].touched);
  }

  unmaskPhone(tel: string) {
    return tel.replace(/\D+/g, '');
  }

  getFormValidationErrors() {
    Object.keys(this.addressBookFormGroup.controls).forEach(key => {
      console.log(this.addressBookFormGroup.get(key).value);
      const controlErrors: ValidationErrors = this.addressBookFormGroup.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  checkSubmitEnabled(): void {
    this.submitDisabled = this.addressBookFormGroup.pristine ||
    this.addressBookFormGroup.invalid;
  }

}
