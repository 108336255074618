  <p-blockUI [blocked]="disableUI" [target]="content"></p-blockUI>
  <p-panel #content [showHeader]="false" styleClass="panel-fix">
    <p-progressBar [ngStyle]="{ 'height': '1px' }" mode="indeterminate" *ngIf="disableUI || loadingGrid"></p-progressBar>
    <div id="main" >
      <div style="flex-grow: 1;padding-right: 10px;">
        <div>
          <p-radioButton class="block" (click)="setRadio($event)" [label]="'Single'" [value]="0" [(ngModel)]="multiselect"></p-radioButton>
          <div style="padding-bottom: 10px;"></div>
          <p-radioButton class="block" (click)="setRadio($event)" [label]="'Multiple'" [value]="1" [(ngModel)]="multiselect"></p-radioButton>
        </div>
      </div>
      <div style="flex-grow: 5;justify-content: start;align-content: flex-start;">
        <form class="container" [formGroup]="formGroup">
          <div *ngIf="multiselect === 1" style="flex-grow: 3;">
            <div class="inm-label--required space-it">Service Event Ids (SEIDs):</div>
            <textarea #area [(ngModel)]="seids" class="text-area inm-input" autoResize="true" formControlName="sIds"
              (input)="seidsChanged($event)" spellcheck="false" placeholder="{{placeholderText}}">                                                               
            </textarea>
          </div>
          <div class="space-it" *ngIf="multiselect === 0" style="flex-grow: 1">
            <div class="inm-label--required">NCPDP:</div>
            <label [ngClass]="{'inm-input--required' : !invalidNcpdp(), 'inm-input--invalid': invalidNcpdp() } "
              attr.data-message="{{invalidNcpdpMessage()}}">
              <input #ncpdp required class="inm-input__field" formControlName="ncpdp" (input)="ncpdpChanged()">
            </label>
          </div>
          <div class="space-it" *ngIf="multiselect === 0" style="flex-grow: 1">
            <div class="inm-label--required">Rx Number:</div>
            <label [ngClass]="{'inm-input--required' : !invalidRxNumber(), 'inm-input--invalid': invalidRxNumber() }"
              attr.data-message="{{invalidRxNumberMessage()}}">
              <input required class="inm-input__field" formControlName="rxNumber" (input)="rxNumberChanged($event)">
            </label>
          </div>
          <div class="space-it" *ngIf="multiselect === 0" style="flex-grow: 1">
            <div class="inm-label--required">Fill Date:</div>
            <p-calendar required [yearRange]="'2000:2040'" [yearNavigator]="true" [monthNavigator]="true" [showIcon]="true"
              formControlName="fillDate" placeholder="MM/DD/YYYY">
            </p-calendar>
          </div>
          <div class="space-it"></div>
          <div>
            <section class="inm-actions--plain" style="padding-top:23px;flex-grow: 2;margin-left:10px;">
              <button class="inm-button" (click)="search()" [disabled]="searchDisabled()">Search</button>
              <div class="space-it"></div>
              <button class="inm-button" (click)="resetServiceEventId()">Reset</button>
            </section>
            <section class="inm-actions--plain" *ngIf="multiselect === 1" style="flex-grow: 2;margin-left:10px;">
            <div class="check-box">
              <p-checkbox [(ngModel)]="getMissingSeids"  label=" Show SEIDs Not Found" 
              binary="true" formControlName="seidCheckbox">
              </p-checkbox>
            </div>
          </section>
          </div>
        </form>
      </div>
    </div>
    <div class="hspacer"></div>
    <span *ngIf="hasAssociations">
      <p style="font-style: italic;color:orangered;padding:2px; ;">
        <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
        One or more of the returned claims are associated with an existing audit.
      </p>
    </span>
    <span style="padding-bottom: 6px;" *ngIf="showMaxSEIDsError">
      <p style="font-style: italic;color:orangered;padding:2px;display:inline-block;">
        <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
        Exceeded maximum of 500.
      </p>
    </span>
    <!-- <span style="padding-bottom: 6px;" *ngIf="showInvalidSeidCount">
      <p style="font-style: italic;color:orangered;padding:2px;display:inline-block;">
        <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
        One or more Service Event IDs entered are in an invalid format. 
      </p>
      <a class="ellipse" (click)="showMoreDialog()"><span style="cursor: pointer;font-size: 30px;" pTooltip="More Info" tooltipZIndex="100000">&#8230;</span></a>
    </span> -->
    <p-dialog 
      header="Invalid Service Event Ids"
      [(visible)]="showInvalidSeidCount" 
      [minWidth]="465">
      <textarea [ngModel]="badSeidString" class="text-area inm-input scroll-area" readonly>                                                               
      </textarea>
    </p-dialog>
    <p-dialog 
      header="Service Event Ids Not Found"
      [(visible)]="showMissingSeids" 
      [minWidth]="465">
      <textarea [ngModel]="missingSeidString" class="text-area inm-input scroll-area" readonly>                                                               
      </textarea>
    </p-dialog>
    <div *ngIf="showGrid" class='inm-spaced' style="z-index: 10;">
      <p-table #dClaimsTable
        [columns]="tableHelper.assocClaimColumns"
        [value]="claimSearchResults" 
        [loading]="loadingGrid" 
        [lazy]="true" 
        (onLazyLoad)="onLazyLoad($event)"
        [responsive]="true" 
        [rows]="rows" 
        [paginator]="showPagination" 
        [totalRecords]="totalItemCount" 
        (onPage)="undoSelect($event)"
        [scrollable]="true" scrollHeight="350px" 
        [(selection)]="selectedClaims">
        <ng-template pTemplate="header" let-columns>
          <tr class="claimSearchCols">
            <th *ngFor="let col of columns" 
              [ngClass]="col.hdrClass ? col.hdrClass : 'default'" 
              [pSortableColumn]="col.sort === false ? false : col.field">
              {{col.header}}
              <p-sortIcon *ngIf="col.sort !== false" [field]="col.field"></p-sortIcon>
              <p-tableHeaderCheckbox #chkBoxAll *ngIf="col.field === 'Selected'"></p-tableHeaderCheckbox>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-audit let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns" [ngClass]="col.fldClass ? col.fldClass : 'default'">
              <div *ngIf="col.field === 'Selected'">
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </div>
              <div *ngIf="col.field === 'Prescription_PrescriptionNumber'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field == 'Prescription_SplitBillCode'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'Prescription_FillDate'">
                {{rowData[col.field] | date:'MM/dd/yyyy'}}
              </div>
              <div *ngIf="col.field === 'Payer_ProcessorIdentifier'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'Payer_ShardPayerName'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'Location_CorporateLocationNumber'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'Drug_NationalDrugCode'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'Drug_Label'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'Money_PayerReceivableAmountClaim'">
                {{rowData[col.field] | currency:'USD':'symbol':'1.2-2'| customCurrencyPipe}}
              </div>
              <div *ngIf="col.field === 'Source'">
                {{rowData[col.field] == 'SYSTEM' ? 'Inmar' : 'Upload'}}
              </div>
              <div *ngIf="col.field === 'InUse'" [ngStyle]="rowData[col.field] ? {'color': 'red'} : ''">
                {{rowData[col.field] ? 'Yes' : 'No'}}
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          Total Record Count: {{ totalItemCount }}
        </ng-template>
      </p-table>
    </div>
    <div *ngIf="claimSearchResults.length > 0" class='inm-spaced'>
      <div class="btn-container-dialog">
        <button class="inm-button" (click)="submitClaimsToAdd()" [disabled]="!(selectedClaims.length > 0)">Save</button>
      </div>
    </div>
  </p-panel>
