import { NgModule } from '@angular/core';
import { ConfirmClientTenantComponent } from 'src/app/components/confirm-client-tenant/confirm-client-tenant.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { DialogModule } from 'primeng/dialog';
@NgModule({
    imports: [DialogModule, ModalModule.forRoot()],
    declarations: [ConfirmClientTenantComponent],
    exports: [ConfirmClientTenantComponent],
    // entryComponents: [ConfirmClientTenantComponent],
    providers: [BsModalService],
    })
export class SharedClientTenantGuardModule {}
