import { Component,
         OnInit,
         Input,
         Output,
         EventEmitter } from '@angular/core';
import { IToolbarItem } from 'src/app/components/audit-form/helpers/toolbar.helper';
import { ToolbarAction } from '../../models/toolbarAction';

export interface IToolbarItems {
  label?: string;
  name: string;
  tip: string;
  icon: string;
  disabled?: boolean;
  show: boolean;
}

@Component({
  selector: 'app-audit-toolbar',
  templateUrl: './audit-toolbar.component.html',
  styleUrls: ['./audit-toolbar.component.css']
})
export class AuditToolbarComponent implements OnInit {
  @Input() toolbarItemList: IToolbarItem[];
  @Output() OnClick: EventEmitter<ToolbarAction> = new EventEmitter<ToolbarAction>();
  constructor() {
  }

  ngOnInit() {
  }

  toolbarBtnClick(name: string, index: number): void {
    this.OnClick.emit({name: name, index: index});
  }
}
