import * as _ from 'lodash';

import {ActivatedRoute, Router} from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthorizationService } from '../../services';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-audit-section',
  templateUrl: './audit-section.component.html'
})
export class AuditSectionComponent implements OnInit {
  sectionRoot = '/audit/';

  sections = [  {
    name: 'ADD AUDIT',
    path: 'audit-form',
    feature: 'UI::AuditAdd',
    subSection: null,
  }, {
    name: 'AUDIT SEARCH',
    path: 'auditsearch',
    feature: 'UI::AuditSearch',
    subSection: null,
  }, {
    name: 'DOCUMENT SEARCH',
    path: 'auditcarrierdocumentsearch',
    feature: 'UI::AuditCarrierDocumentSearch',
    subSection: null,
  }, {
    name: 'FAX STATUS',
    path: 'faxstatus',
    feature: 'UI::FaxStatus',
    subSection: null,
  }, {
    name: 'SUMMARY - AUDIT CARRIER',
    path: 'auditcarriersummary',
    feature: 'UI::AuditCarrierSummary',
    subSection: null,
  }, {
    name: 'SUMMARY - AUDIT TYPE',
    path: 'audittypesummary',
    feature: 'UI::AuditTypeSummary',
    subSection: null,
  }, {
    name: 'UPLOAD CLAIMS',
    path: 'uploadclaims',
    feature: 'UI::UploadClaims',
    subSection: null,
  }, {
    name: 'WORKFLOW',
    path: 'workflow',
    feature: 'UI::Workflow',
    subSection: null,
  },
  ];

  constructor(private router: Router,
    private authorizationService: AuthorizationService) {
  }

  ngOnInit() {
   }

  routeChanged() {
    if (this.authorizationService.HealthcareTopBar) {
      this.authorizationService.HealthcareTopBar.notifyActivity();
    }
  }
}
