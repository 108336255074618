import { AuthorizationService } from '../../../services/authorization.service';
import { ClaimService } from '../../../services/claim.service';
import { ModalAction } from 'src/app/shared/models/modalAction';
import { AssociatedClaim } from 'src/app/models/associated-claim';
import * as toastr from 'toastr';
import { Tag } from '../../../models/tag';
import { flatMap } from 'lodash';
import { DiscrepancyCode } from 'src/app/models/discrepancyCode';
import { map } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { resolve } from 'url';
import { ClaimWithSource } from 'src/app/models/claimWithSource';

export interface IToolbarItem {
  label?: string;
  name: string;
  tip: string;
  icon: string;
  disabled?: boolean;
  show: boolean;
  exclude?: boolean;
  toggle?: boolean;
}

export class ToolbarHelper {
  currentToolbar: IToolbarItem[] = [];
  public showAction = '';
  public actionText = '';
  public labelText = '';
  public isRecoupment = false;
  public isReturnTags = false;
  public isDisable = false;
  public readonly deleteMsg: string;
  public optionLabel: string;
  public tags: Tag[];
  public discrepancyCodes: DiscrepancyCode[];
  
  private readonly TOOLBAR_ITEMS = [
    { feature: '',
      item: {label: 'Refresh', name: 'refresh', tip: 'Refresh Grid', icon: '#inm-icon__refresh',
      disabled: false, show: true, exclude: true }},
    { feature: '',
      item: {label: 'Show Tags', name: 'showTags', tip: 'Show Tags', icon: '#inm-icon__eye',
      disabled: false, show: !this.isReturnTags, toggle: true }},
    { feature: '',
      item: {label: 'Hide Tags', name: 'hideTags', tip: 'Hide Tags', icon: '#inm-icon__eye-slash',
      disabled: false, show: this.isReturnTags, toggle: true }},
    { feature: 'UI::AddDiscrepancyCodeIcon',
      item: {label: 'Discrepency Code', name: 'applyDiscrepancy', tip: 'Apply Discrepancy Code(s)',
      icon: '#inm-icon__adjust', disabled: false, show: true }},
    { feature: 'UI::AddNoteIcon',
      item: {label: 'Note', name: 'applyNote', tip: 'Apply Note', icon: '#inm-icon__page',
      disabled: false, show: true }},
    { feature: 'UI::AddTagIcon',
      item: {label: 'Tag', name: 'applyTag', tip: 'Apply Tag', icon: '#inm-icon__tag',
      disabled: false, show: true }},
    { feature: 'UI::DeleteAssociatedClaimIcon',
      item: {label: 'Delete Claim', name: 'deleteClaim', tip: 'Delete Selected Claims', icon: '#inm-icon__trash',
      disabled: false, show: true }},
    { feature: 'UI::ApproveRecoupmentAmountIcon',
      item: {label: 'Recoupment Amount', name: 'approveRecoupment', tip: 'Approve For Recoupment',
      icon: '#inm-icon__save', disabled: false, show: true }},
    { feature: 'UI::ApproveRecoupmentAmountIcon',
      item: {name: 'saveRecoupment', tip: 'Save Recoupment', icon: '#inm-icon__check-square',
      disabled: false, show: false }},
    { feature: 'UI::ApproveRecoupmentAmountIcon',
      item: {name: 'cancelRecoupment', tip: 'Cancel Recoupment', icon: '#inm-icon__x-square',
      disabled: false, show: false }},
  ];

  constructor(private authorizationService: AuthorizationService,
              private claimService: ClaimService, private auditId: number) {
    if (auditId) {
      this.loadTagsandCodes();
    }
    this.generateToolbarData();
    this.deleteMsg =
      'Are you sure you want to delete the selected associated claims?';
  }

  generateToolbarData(): void {
    for (let i = 0; i < this.TOOLBAR_ITEMS.length; i++) {
      if (this.authorizationService.hasFeature(this.TOOLBAR_ITEMS[i].feature) || this.TOOLBAR_ITEMS[i].feature === '') {
          this.currentToolbar.push(this.TOOLBAR_ITEMS[i].item);
      }
    }
  }

  loadTagsandCodes(): void {
    let tagsReturned: boolean;
    let descCodeReturned: boolean;
    this.claimService.getTags()
        .subscribe( (data: Tag[]) => {
          tagsReturned = true;
          this.tags = data;
        });
      this.claimService.getDiscrepencyCode()
      .subscribe( (data) => {
        descCodeReturned = true;
        this.discrepancyCodes = data.Items;
      });
  }

  toolbarBtnClick(name: string): void {
    this.showAction = name;
    switch (name) {
      case 'refresh': {
        if (this.isRecoupment) {
          this.updateBtnsForRecoupment();
        }
        break;
      } case 'showTags': {
        this.updateBtnsForTags();
        break;
      } case 'hideTags': {
        this.updateBtnsForTags();
        break;
      } case 'applyTag': {
        this.actionText = 'Apply Tag';
        this.labelText =  'Choose Tag:';
        this.optionLabel = 'TagCodeDesc';
        break;
      } case 'applyNote': {
        this.actionText = 'Apply Note';
        this.labelText =  'Add Note:';
        break;
      } case 'applyDiscrepancy': {
        this.actionText = 'Apply Discrepancy Code(s)';
        this.labelText = 'Choose Discrepancy Code(s):';
        this.optionLabel = 'CodeName';
        break;
      } case 'deleteClaim': {
        this.actionText = 'Delete Selected Claims';
        break;
      } case 'approveRecoupment': {
        this.updateBtnsForRecoupment();
        break;
      } case 'cancelRecoupment': {
        this.updateBtnsForRecoupment();
        break;
      } case 'saveRecoupment': {
        break;
      }
    }
  }

  toolbarDialogModalAction(modalAction: ModalAction, selectedRows: AssociatedClaim[], callback?: () => void): void {
    if (modalAction.action === 'cancel') {
      this.showAction = '';
      return;
    }

    switch (modalAction.id) {
      case 'applyTag': {
        this.claimService.applyTagToClaim(this.buildClaimWithSource(selectedRows),
          modalAction.value.TagID,
          )
          .subscribe( () => {
              toastr.success('Tag applied to claim(s)!');
          }, () => {
            toastr.error('There was an issue applying tag to claim(s)');
          });
        break;
      } case 'applyNote': {
        this.claimService.applyNoteToClaim(modalAction.value,
          this.buildClaimWithSource(selectedRows))
          .subscribe( () => {
              toastr.success('Note applied to claim(s)!');
          }, () => {
            toastr.error('There was an issue applying note to claim(s)!');
          });
        break;
      } case 'applyDiscrepancy': {
          this.claimService.applyDescrepencyCode(this.auditId, this.getDiscrepancyCodesAsArray(modalAction.value),  this.getClaimIdAsArray(selectedRows))
          .subscribe( () => {
            toastr.success('Discrepancy codes applied!');
          }, () => {
            toastr.error('There was an issue applying discrepancy code(s) to the selected claim(s)');
          });
        break;
      } case 'deleteClaim': {
        break;
      }
    }
    this.showAction = '';
  }

  // private update toolbar visability
  public updateBtnsForRecoupment(): void {
    if (!this.isRecoupment) {
      for (const item of this.currentToolbar) {
        item.disabled = item.show;
        if (!item.show) {
          item.show = true;
        }
      }
    }

    // recoupment undo
    if (this.isRecoupment) {
      for (const item of this.currentToolbar) {
        if (item.show && !item.disabled) {
          item.show = false;
        }
        item.disabled = false;
      }
    }

    this.isRecoupment = !this.isRecoupment;
  }

  // private update toolbar visability
  public updateBtnsForTags(): void {
    if (!this.isReturnTags) {
      for (const item of this.currentToolbar) {
        if (item.name === 'hideTags') {
          item.show = true;
        }
        if (item.name === 'showTags') {
          item.show = false;
        }
      }
    }

    // Tags undo
    if (this.isReturnTags) {
      for (const item of this.currentToolbar) {
        if (item.name === 'hideTags') {
          item.show = false;
        }
        if (item.name === 'showTags') {
          item.show = true;
        }
      }
    }

    this.isReturnTags = !this.isReturnTags;
  }

  public disableBtnTags(): void {
    if (!this.isDisable) {
      for (const item of this.currentToolbar) {
        if (item.name === 'hideTags') {
          item.disabled = true;
        }
        if (item.name === 'showTags') {
          item.disabled = true;
        }
    }
    }
    if (this.isDisable) {
      for (const item of this.currentToolbar) {
        if (item.name === 'hideTags') {
          item.disabled = false;
        }
        if (item.name === 'showTags') {
          item.disabled = false;
        }
    }
    }
    this.isDisable = !this.isDisable;
  }

  // transform for api call
  public getClaimIdAsArray(selectedClaims: AssociatedClaim[]): number[] {
    return flatMap(selectedClaims, (o) => o.RawDataImport_ShardClaimIdentifier);
  }

  public getDiscrepancyCodesAsArray(selectedDiscrepancyCodes: DiscrepancyCode[]): number[] {
    return flatMap(selectedDiscrepancyCodes, (o) => o.DiscrepancyCodeId);
  }

  public buildClaimWithSource(selectedClaims: AssociatedClaim[]): ClaimWithSource[] {
    const claimWithSources: ClaimWithSource[] = [];
    selectedClaims.forEach((o) => {
      claimWithSources.push(
        new ClaimWithSource(
          o.RawDataImport_ShardClaimIdentifier,
          o.Source));
    });

    return claimWithSources;
  }
}
