<div style="margin: 10px; width: auto;">
  <p-accordion [activeIndex]="index" (onOpen)="loadTable(paginationDefault)" (onClose)="close($event)" >
    <p-accordionTab #accordianTab  [disabled]="status.TotalCount === 0">
      <p-header style="display: flex;flex-direction: row;justify-content: flex-start;flex-wrap: wrap">
          <span class="metric-column" style="margin-left: 5px;width: 350px">
            <h4 style="font-weight: bolder">
              {{status.AuditStatus}}
            </h4>
          </span>
          <span class="metric-column" style="width: 200px">
            <h4>
              Audit Count:
            </h4>
            <span class="metric metric-count" >{{status.TotalCount}}</span>
          </span>
          <span class="metric-column" style="width: 400px">
            <h4>
              Status Due:
            </h4>
            <span class="metric" style="width: 300px;display: block;">
              <span class="highlight" style="width: 120px" [ngClass]="status.StatusPastDueCount == 0? '' : 'metric-red'">Past Due:
                <span class="metric-count">{{status.StatusPastDueCount}}</span>
              </span>
              <span class="highlight"  style="width: 120px"  [ngClass]="status.StatusDueTodayCount == 0? '' : 'metric-yellow'">Due Today:
                <span  class="metric-count">{{status.StatusDueTodayCount}}</span>
              </span>
            </span>
          </span>
          <span class="metric-column" style="width: 400px">
            <h4>
              Audit Due:
            </h4>
            <span class="metric" style="width: 300px;display: block;" >
              <span class="highlight"  style="width: 120px"  [ngClass]="status.AuditPastDueCount == 0? '' : 'metric-red'">Past Due:
                <span class="metric-count">{{status.AuditPastDueCount}}</span>
              </span>
              <span class="highlight"  style="width: 120px"  [ngClass]="status.AuditDueTodayCount == 0? '' : 'metric-yellow'">Due Today:
                <span class="metric-count">{{status.AuditDueTodayCount}}</span>
              </span>
            </span>
          </span>
          <span class="metric-column" style="width: 100px">
            <h4>
              Pending:
            </h4>
            <span class="metric metric-count" >{{status.IsPendingCount}}</span>
          </span>
      </p-header>
      <p-table #dt
      [columns]="tableColumns"
      [value]="auditSearchResults"
      [loading]="tableLoading"
      loading="fa-spinner"
      [responsive]="true"
      [lazy]="true"
      (onLazyLoad)="onLazyLoad($event)"
      [paginator]="true"
      [rows]="10"
      [totalRecords]="totalItemCount">
      <ng-template pTemplate="header" let-columns>
          <tr class="cols">
            <th *ngFor="let col of columns"
              [ngClass]="col.hdrClass ? col.hdrClass : 'default'"
              [pSortableColumn]="col.field !== 'Selected' ? col.field : false">
              {{col.header}}
              <p-sortIcon *ngIf="col.field !== 'Selected'" [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-audit let-columns="columns">
          <tr>
            <td *ngFor="let col of columns"
              [ngClass]="col.fldClass ? col.fldClass : 'default'">
              <div *ngIf="col.field === 'AuditNumber'">
                <a *ngIf="canEdit" style="color: blue;" [routerLink]="['/audit/audit-form', {'id': audit['AuditId']}]">
                  {{audit[col.field]}}
                </a>
                <span *ngIf="!canEdit">{{audit[col.field]}}</span>
              </div>
              <div *ngIf="col.field === 'AuditReferenceNumber'">
                {{audit[col.field]}}
              </div>
              <div *ngIf="col.field === 'Carrier'">
                  {{audit[col.field]}}
                </div>
              <div *ngIf="col.field === 'AuditType'">
                {{audit[col.field]}}
              </div>
              <div *ngIf="col.field === 'ClaimCount'">
                {{audit[col.field]}}
              </div>
              <div *ngIf="col.field === 'TotalInitialRiskAmount'" style="text-align: right;">
                <span>{{audit[col.field] | currency:'USD':'symbol':'1.2-2'| customCurrencyPipe  }}</span>
              </div>
              <div *ngIf="col.field === 'TotalApprovedRecoupmentAmount'" style="text-align: right;">
                <span>{{audit[col.field] | currency:'USD':'symbol':'1.2-2'| customCurrencyPipe  }}</span>
              </div>
              <div *ngIf="col.field === 'StatusDueDate'" [ngClass]="checkDateForCss(audit[col.field])">
                {{audit[col.field] | date: 'MM/dd/yyyy'}}
              </div>
              <div *ngIf="col.field === 'AuditDueDate'" [ngClass]="checkDateForCss(audit[col.field], false)">
                {{audit[col.field] | date: 'MM/dd/yyyy'}}
              </div>
              <div *ngIf="col.field === 'IsPending'">
                {{audit[col.field] === true ? 'Y' : 'N'}}
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan ="7" style=" text-align: center">
              No records found
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          Total count: {{totalItemCount}} audits
        </ng-template>
      </p-table>
    </p-accordionTab>
  </p-accordion>
</div>
