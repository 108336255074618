import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { LimitedResult, PrimeNgListItem, StatusModel, Pagination, ILimitedResult, AuditSummary} from '../models';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuditType } from 'src/app/models/audit-type';
import { BusinessResponse } from 'src/app/models/businessResponse';

@Injectable({
  providedIn: 'root'
})
export class AuditTypeService {
  private readonly apiRoot = `${environment.auditApiRoot}`;

  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) { }

  public getAuditTypeListItems(): Observable<PrimeNgListItem[]> {
    return this.http.get(
      `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditTypes/?limit=${environment.defaultListLimit}`,
      { headers: this.httpUtility.getHeaders() }
    )
    .pipe(
      map(res => res['Items'].map(item => new PrimeNgListItem(item.Name, item.AuditTypeId))));
  }

  public getAuditTypesLimitedResult(pagination: Pagination): Observable<ILimitedResult<AuditType>> {
    const params = pagination.buildSearchParams();
    return this.http.get<ILimitedResult<AuditType>>(
      `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditTypes`, {params});
  }

  public addAuditType(name: string): Observable<BusinessResponse> {
    const url = `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditTypes`;
    return this.http.post<BusinessResponse>(url, { Name: name });
  }

  public updateAuditType(auditTypeId: number, name: string): Observable<BusinessResponse> {
    const url = `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditTypes`;
    return this.http.put<BusinessResponse>(url, {AuditTypeId: auditTypeId, Name: name});
  }

  public deleteAuditType(auditTypeId: number): Observable<Response> {
    const url = `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditTypes/${auditTypeId}`;
    return this.http.delete<Response>(url);
  }
}
