export class AuditClaimDetail {
  ClientId: number;
  RawDataImport_ShardClaimIdentifier: number;
  Prescription_PrescriptionNumber: string;
  Prescription_FillDate: Date;
  Location_CorporateLocationNumber: string;
  Location_NCPDPProviderIdentifier: string;
  Location_NationalProviderIdentifier: number;
  InitialRisk: number;
  Money_CopayAmountClaim: number;
  ApprovedRecoupmentAmount: number;
  Payer_ProcessorIdentifier: number;
  VendorName: string;
  Payer_ShardPayerName: string;
  Patient_FullName: string;
  Patient_DateOfBirth: Date;
  Drug_NationalDrugCode: string;
  IsPHILogged: boolean;
  Prescription_SplitBillCode: number;
  Drug_HCPCSCode1: string;
  Patient_ClaimControlNumber: string;
  Source: string;
  Patient_MedicareBeneficiaryIdentifierNumber: string;
  Prescription_ToFillDate: Date;
  ServiceProvider_PrescriberFirstName: string;
  ServiceProvider_PrescriberLastName: string;
  ServiceProvider_PrescriberFaxNumber: string;
  Prescription_DaysSupply: number;
  Prescription_DispensedQuantity: number;
  Payer_ProcessorControlNumber: string;
}
