import { Component, OnInit } from '@angular/core';
import { GlobalFilterService, AuthorizationService } from 'src/app/services';
import { ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { Table } from 'primeng/table';
import * as toastr from 'toastr';
import { FaxStatusTableHelper } from './helpers/fax-status-table-helper';
import { FaxStatusToolbarHelper } from './helpers/fax-status-toolbar-helper';
import { ToolbarAction } from 'src/app/shared/models/toolbarAction';
import { FaxService } from 'src/app/services/fax-service';
import { Pagination } from 'src/app/models';
import { Fax } from 'src/app/models/fax';
const LIMIT = 100;

@Component({
  selector: 'app-fax-status',
  templateUrl: './fax-status.component.html',
  styleUrls: ['./fax-status.component.css']
})
export class FaxStatusComponent implements OnInit {

  @ViewChild('dt', { static: true }) dTable: Table;

  readonly faxStatusTableHelper: FaxStatusTableHelper;
   readonly toolbarHelper: FaxStatusToolbarHelper;
   faxes: Fax[] = [];
   typeDataLoading: boolean;
   nextButtonDisabled = true;
   previousButtonDisabled = true;
   pageNumber = 1;
   offSet: number;
  constructor(private faxService: FaxService,
    private authorizationService: AuthorizationService,
    globalFilterService: GlobalFilterService) {
    this.faxStatusTableHelper = new FaxStatusTableHelper();
    this.toolbarHelper = new FaxStatusToolbarHelper(this.authorizationService);
    globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
  }
  onClientsChanged(): void {
    if (this.dTable) {
      this.dTable.reset();
      this.pageNumber = 1;
      this.nextButtonDisabled = true;
      this.previousButtonDisabled = true;
      this.calculateOffset();
      this.getFaxStatus(new Pagination(LIMIT, this.offSet, ''));
    }
  }

  ngOnInit() {
    this.typeDataLoading = true;
  }

  getFaxStatus(pagination: Pagination) {
    this.disablePreviousButtonCheck();
    this.typeDataLoading = true;
    this.faxService.getFaxStatus(pagination)
      .subscribe(
        (data) => {
          this.faxes = data.Faxes;
          this.nextButtonDisabled = !data.NextPageExist;
          this.typeDataLoading = false;
        },
        // handle error state
        () => {
          this.typeDataLoading = false;
          toastr.error('Error loading data from server.');
        });

  }

  loadDataLazy(event: LazyLoadEvent) {
    if (!this.typeDataLoading) {
      this.typeDataLoading = true;
    }
    this.getFaxStatus(new Pagination(LIMIT, event.first, event.sortField, event.sortOrder));
  }

  previousButtonClicked() {
    this.pageNumber--;
    this.calculateOffset();
    this.getFaxStatus(new Pagination(LIMIT, this.offSet, ''));
  }

  nextButtonClicked() {
    this.pageNumber++;
    this.calculateOffset();
    this.getFaxStatus(new Pagination(LIMIT, this.offSet, ''));
  }

  calculateOffset() {
    this.offSet = (this.pageNumber - 1) * LIMIT;
  }

  disablePreviousButtonCheck() {
    if (this.pageNumber !== 1) {
      this.previousButtonDisabled = false;
    } else {
      this.previousButtonDisabled = true;
    }
  }

  toolbarBtnClick(event: ToolbarAction) {
    switch (event.name) {
      case 'refresh': {
        this.refresh();
        break;
      }
    }
  }
  getNextButtonClass() {
    let classType = 'ui-paginator-next ui-paginator-element ui-state-default ui-corner-all ';
    if (this.nextButtonDisabled) {
       classType += 'ui-state-disabled';
    }
    return classType;
  }

  getPrevButtonClass() {
    let classType = 'ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all ';
    if (this.previousButtonDisabled) {
      classType += 'ui-state-disabled';
    }
    return classType;
  }
  refresh() {
    this.calculateOffset();
    this.getFaxStatus(new Pagination(LIMIT, this.offSet, ''));
  }
}
