<p-dialog id="uploadImageDocuments" header="Upload Documents"
  appendTo="body"
  [modal]="true"
  [(visible)]="show" 
  [closable]="true" 
  [responsive]="true" 
  [width]="520"
  (onHide)="hide()" 
  [closable]="true" 
  (onShow)="onShow($event)">
    <p-fileUpload
      #imageUpload
      mode="advanced"
      [customUpload]="true"
      (uploadHandler)="uploadImages($event, key)"
      [multiple]="true" 
      maxFileSize="1500000000"
      [style]="{'height': '200px'}"
      [showCancelButton]="false"
      (onSelect)="filesSelected($event)">
      <ng-template let-file pTemplate="file">
          <div class="col-row">
            <div style="margin-top: 12px">
              <svg viewBox="0 0 20 20" class="img-icon" aria-label="file">
                <use xlink:href="#inm-icon__page"></use>
              </svg>
            </div>
            <div class="img-name">
              <p style="text-align: left;overflow-wrap: break-word;width: 300px;margin-top:-3px">
              {{file.name}}
              </p>
            </div>
            <div style="align-content: center;margin-top: 10px">
              <button pButton icon="pi pi-times" class="ui-button ui-button-icon-only" (click)="removeImage(file)"></button>
            </div>
          </div>
      </ng-template>
    </p-fileUpload>
    <div>
      <p class="overlay-text"><strong>Drag and drop one file here. <br>
        Choosing larger file(s) may take several seconds to display.</strong>
      </p>
    </div>
    <div class="overlay-error" *ngIf="invalidFileCount"> 
      <p class="inm-message--danger">{{fileErrorMessage}}</p>
    </div>
    <p style="font-style: italic;color:orangered;padding:2px; ;">
      <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
      Up to 5 files may be uploaded at a time (max size 15MB each).
    </p> 
  </p-dialog>

