<p-dialog id="uploadDocumentsDialog" header="Upload Claims" [(visible)]="show" 
    [closable]="true" [modal]="true" [responsive]="true" [width]="520"
    [baseZIndex]="10000" (onHide)="hide()" [closable]="true" (onShow)="onShow($event)">
      <span class="template-align" style="top: 6%">
        <a [attr.href]="helpFilePath" download>
          Help <svg class="inm-icon" aria-label="Help">
                <use style="fill: #00afdb" [attr.xlink:href]="'#inm-icon__question-circle-filled'"></use>
              </svg>
        </a>
      </span>
      <span class="template-align" style="top: 14%">
        <a href="../assets/file-templates/ManualClaimsTemplate.csv" download="ManualClaimsTemplate.csv">
          Download Template
        </a>
      </span>
      <p-fileUpload
        #fileUpload
        mode="advanced"
        [customUpload]="true"
        (uploadHandler)="uploadFile($event)"
        accept=".csv" 
        [multiple]="false" 
        maxFileSize="1000000"
        [style]="{'height': '200px'}"
        [styleClass]="badFile ? 'bad-file' : ''"
        [showCancelButton]="false"
        (onSelect)="handleFile($event)">
      </p-fileUpload>
    <div>
      <p class="overlay-text"><strong>Drag and drop one file here.</strong></p>
    </div>
    <p style="font-style: italic;color:orangered;padding:2px; ;">
      <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
       Only one templated CSV file is allowed with a maximum of 5000 claims.
    </p>
  </p-dialog>

