import { Component, OnInit, ViewChild } from '@angular/core';
import { DiscrepancyCodeTableHelper } from './helpers/discrepancy-code-table.helper';
import { AuditDiscrepancyCodeService,
         GlobalFilterService,
         AuthorizationService } from 'src/app/services';
import { Table } from 'primeng/table';
import { dropRight } from 'lodash';
import { AuditSpecialist, Pagination } from 'src/app/models';
import { LazyLoadEvent } from 'primeng/api';
import { DiscrepancyCodeToolbarHelper } from './helpers/discrepancy-code-toolbar.helper';
import { ToolbarAction } from 'src/app/shared/models/toolbarAction';
import { ModalAction } from 'src/app/shared/models/modalAction';
import * as toastr from 'toastr';
import { AdminModalAction } from 'src/app/components/admin/shared/models/adminModalAction';
import { DiscrepancyCode } from 'src/app/models/discrepancyCode';
import { BusinessResponseService } from 'src/app/services/business-response.service';

@Component({
  selector: 'app-audit-discrepancy-code',
  templateUrl: './audit-discrepancy-code.component.html',
  styleUrls: ['./audit-discrepancy-code.component.css']
})
export class AuditDiscrepancyCodeComponent implements OnInit {
  public readonly tableHelper: DiscrepancyCodeTableHelper;
  public readonly toolbarHelper: DiscrepancyCodeToolbarHelper;
  public readonly max_rows = 30;
  public readonly viewFeatures = [{ feature: 'UI::DiscrepancyCodeDelete', name: 'delete', accessable: false},
                                  { feature: 'UI::DiscrepancyCodeEdit', name: 'edit', accessable: false }];

  public deleteText = '';
  public readonly deleteTitle = 'Delete Confirmation';
  public tableLoading: boolean;
  public confirmDelete: boolean;
  public dCodes: DiscrepancyCode[];
  public totalItemCount: number;
  public deleteAllowed: boolean;
  public selectedRows: DiscrepancyCode[];
  public showAddEditDialog: boolean;
  public editDialogTitle: string;
  public editId: number;
  public dCodeName: string;
  public dCodeCode = '';
  public dCodeId: number;
  public codeInUse: boolean;

  constructor(private discrepanceCodeService: AuditDiscrepancyCodeService,
              private authService: AuthorizationService,
              private globalFilterService: GlobalFilterService,
              private busResService: BusinessResponseService) {
    this.tableHelper = new DiscrepancyCodeTableHelper();
    this.toolbarHelper = new DiscrepancyCodeToolbarHelper(this.authService);
    this.globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
  }

  @ViewChild('dt', { static: true }) dTable: Table;

  ngOnInit() {
    this.tableLoading = true;
    this.checkFeatures();
  }

  private checkFeatures(): void {
    for ( let i = 0; i < this.viewFeatures.length; i++) {
      if (this.authService.hasFeature(this.viewFeatures[i].feature)) {
        this.viewFeatures[i].accessable = true;
      }
    }
    if ( !this.viewFeatures[0].accessable) {
      this.tableHelper.tableColumns = dropRight(this.tableHelper.tableColumns);
    }
  }

  loadDataLazy(event: LazyLoadEvent): void {
    if (!this.tableLoading) {
      this.tableLoading = true;
    }

    this.discrepanceCodeService.getAuditDiscrepancyCodesLimitedResult
      (new Pagination(this.max_rows, event.first, event.sortField, event.sortOrder))
      .subscribe( (data) => {
        this.dCodes = data.Items;
        this.totalItemCount = data.TotalItemCount;
        this.tableLoading = false;
      }, (error) => {
        this.tableLoading = false;
      });
  }

  onClientsChanged(): void {
    if (this.dTable) {
      this.dTable.reset();
      this.tableLoading = true;
    }
  }

  toolbarBtnClick(event: ToolbarAction) {
    switch (event.index) {
      case 0: {
        this.dTable.reset();
        break;
      } case 1: {
        this.handleDialogs('add');
      }
    }
  }

  clickName(rowData: DiscrepancyCode) {
    this.dCodeName = rowData.Name;
    this.dCodeCode = rowData.Code;
    this.dCodeId = rowData.DiscrepancyCodeId;
    this.editId = this.dCodeId;
    this.codeInUse = rowData.InUse;
    this.handleDialogs('edit');
  }

  deleteClicked(rowData: DiscrepancyCode): void {
    this.dCodeId = rowData.DiscrepancyCodeId;
    this.deleteText = `Are you sure that you want to delete <strong>${rowData.Name}</strong>?`;
    this.handleDialogs('delete');
  }

  handleDialogs(name: string) {
    if (name === 'edit') {
      this.editDialogTitle = 'Edit Discrepancy Code';
      this.showAddEditDialog = true;
    }

    if (name === 'add') {
      this.dCodeName = null;
      this.dCodeCode = null;
      this.editId = null;
      this.codeInUse = false;
      this.editDialogTitle = 'Add New Discrepancy Code';
      this.showAddEditDialog = true;
    }

    if ( name === 'delete') {
      this.confirmDelete = true;
    }
  }

  deleteCallback(event: ModalAction): void {
    if (event.action === 'cancel') {
      this.confirmDelete = false;
      return;
    }
    this.discrepanceCodeService.deleteDiscrepancyCode(this.dCodeId)
      .subscribe( (response) => {
        toastr.success('Discrepancy code was deleted successfully!');
        this.resetSpecialist();
        this.dTable.reset();
        this.confirmDelete = false;
      }, () => {
        toastr.error('We are not able to delete the discrepancy code at this time.');
      });
  }

  editCallback(event: AdminModalAction): void {
    if (event.action === 'cancel') {
      this.showAddEditDialog = false;
      return;
    }
    if (event.action === 'update') {
      this.discrepanceCodeService.updateAuditDiscrepancyCode(this.dCodeId, event.nameValue, event.codeValue)
        .subscribe( (response) => {
         if (this.busResService.handleBusinessResponse('Update discrepancy code name successful!', response)) {
            this.resetSpecialist();
            this.dTable.reset();
            this.showAddEditDialog = false;
          }
        }, () => {
          toastr.error('We are not able to update the discrepancy code name at this time.');
        });
    }

    if (event.action === 'add') {
      this.discrepanceCodeService.addAuditDiscrepancyCode(event.nameValue, event.codeValue)
        .subscribe( (response) => {
          if (this.busResService.handleBusinessResponse('Add discrepancy code successful!', response)) {
            this.resetSpecialist();
            this.dTable.reset();
            this.showAddEditDialog = false;
          }
        }, () => {
          toastr.error('We are not able to add the discrepancy code at this time.');
        });
    }
  }

  resetSpecialist(): void {
    this.dCodeId = null;
    this.dCodeName = '';
  }
}
