import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { UiHelperService, ClaimService } from 'src/app/services';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AssociateClaimToAudit, SelectableClaimSearchResult, ClaimSearchResult, Pagination, ILimitedResult } from '../../models';
import { AuditService } from '../../services';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import * as toastr from 'toastr';
import { AssociateClaimsTableHelper } from './helpers/associate-claims-table.helper';
import { Table } from 'primeng/table';
import * as detectie from 'detectie';
import { LazyLoadEvent } from 'primeng/api';
import { SEIDValidator } from '../../validators/seid-validator'
import { SeidSearchResult } from 'src/app/models/seid-search-result';
import { CookieService } from 'ngx-cookie-service';

const MAX_ROWS = 30;
const MAX_SEIDS = 500;

enum ErrorCondition {
  SEIDValidation,
  MaxSEIDS
}
@Component({
  selector: 'app-audit-claims',
  templateUrl: './audit-claims.component.html',
  styleUrls: ['./audit-claims.component.css']
})

export class AuditClaimsComponent implements OnInit, OnChanges {

  @Input() auditId: number;
  @Input() showDialog: boolean;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @ViewChild('dClaimsTable', { static: false }) dTable: Table;
  @ViewChild('area', { static: false }) textArea: ElementRef;
  @ViewChild('ncpdp', { static: false }) inputText: ElementRef;

  public ncpdp: string;
  public seids: string;
  public seidsLength: number;
  public rxNumber: string;
  public fillDate: Date;
  public badSeids: string[];
  public badSeidString: string;
  public missingSeidString: string;
  public loadingGrid = false; // shows, hides grid spinner
  public claimSearchResults: SelectableClaimSearchResult[] = [];
  public formGroup: FormGroup;
  public selectAllChecked = false;
  public readonly tableHelper: AssociateClaimsTableHelper;
  public readonly isIE: any;
  public multiselect = 1;
  public selectedClaims: ClaimSearchResult[] = [];
  public disableUI: boolean;
  public showPagination: boolean;
  public readonly rowsByBrowser: number;
  public totalItemCount: number;
  public rows: number;
  private prevSort: string;
  private prevSortOrder: number;
  public showGrid: boolean;
  public hasAssociations: boolean;
  public cookiePresent: boolean = false;
  public previousCookieValue: boolean = false;
  public showInvalidSeidCount: boolean;
  public showMaxSEIDsError: boolean = false;
  public showMissingSeids: boolean = false;
  public getMissingSeids: boolean = false;
  public showMissingPopup: boolean = true;
  public checkboxCookieKey: string = 'missing_seid_checkbox';
  public placeholderText: string = ['Enter or paste one or more (max 500) comma-separated values.\n \n',
                                'Format: NCPDP #-Rx #-Fill Date (YYYYMMDD). \n',
                                'Example: 5555555-1234567-20180131, 5555555-2234567-20180131, \u2026'].join('');

  constructor(private uiHelperService: UiHelperService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private auditService: AuditService,
    private claimService: ClaimService,
    private seidValidator: SEIDValidator,
     private cookieService: CookieService) {
      this.isIE = detectie();
      if (this.isIE) {
        this.showPagination = true;
        this.rowsByBrowser = MAX_ROWS;
        this.rows = MAX_ROWS;
      } else {
        this.rowsByBrowser = 9999;
      }
      this.tableHelper = new AssociateClaimsTableHelper();
    }

  ngOnInit() {
    this.initFormGroup();
  }

  ngOnChanges(changes): void {
    if (changes.showDialog &&
        changes.showDialog.currentValue === false) {
      this.selectedClaims = [];
      this.close();
    }else{
      if(this.cookieService.check(this.checkboxCookieKey)){
        if(this.getCookie(this.checkboxCookieKey) == 'true'){
          this.getMissingSeids = true;
        }
        this.cookiePresent = true;
      }
    }
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      sIds: new FormControl(null, []),
      seidCheckbox: false,
      ncpdp: [this.ncpdp, Validators.compose([Validators.minLength(7), Validators.maxLength(7)])],
      rxNumber: [this.rxNumber, Validators.compose([Validators.pattern('[a-zA-Z0-9]{1,14}'),Validators.minLength(1), Validators.maxLength(14)])],
      fillDate: [(this.fillDate != null) ? this.datePipe.transform(this.fillDate, 'MM/dd/yyyy') : ''
        , Validators.compose([])],
    });
  }

  rxNumberChanged() {
    const value = this.formGroup.controls['rxNumber'].value;
    if (!value) {
      return;
    }

    //  Remove any whitespace from raw value.
    const trimmedValue = value.toString().trim();

    if (!/^[a-zA-Z0-9]{1,14}$/.test(trimmedValue.trim())) {
      // remove characters
      var updatedValue = trimmedValue.replace(/[^a-zA-Z0-9]/g, '');
      if(updatedValue.length > 14) {
        updatedValue = updatedValue.slice(0,13);
      }
      this.formGroup.controls['rxNumber'].setValue(updatedValue); // remove non-numeric with;
    }
    this.uiHelperService.limitToMaxLength(this.formGroup, 'ncpdp');
    return;
  }

  ncpdpChanged() {
    const value = this.formGroup.controls['ncpdp'].value;
    if (!value) {
      return;
    }

    //  Remove any whitespace from raw value.
    const trimmedValue = value.toString().trim();

    //  If the pasted format doesn't match 3464029-6043503-20161121, return
    if (!/^[0-9]{7}-[a-zA-Z0-9]{1,14}-[0-9]{8}$/.test(trimmedValue.trim())) {
      if (!/^[0-9]{1,7}$/.test(trimmedValue.trim())) {
        // remove characters
        this.formGroup.controls['ncpdp'].setValue(trimmedValue.replace(/\D/g, '')); // remove non-numeric with;
      }
      this.uiHelperService.limitToMaxLength(this.formGroup, 'ncpdp');
      return;
    }

    //  Split out the values from the pasted value into its parts.
    const set = trimmedValue.split(/-/);

    //  If we don't have 3 fields, return
    if (set.length !== 3) {
      return;
    }

    //  Create the proper formatted date from the date value.
    const parsedDate = moment(set[2], 'YYYYMMDD');

    if (!parsedDate.isValid) {
      return;
    }

    const formattedDate = parsedDate.format('MM/DD/YYYY');

    //  Assign split values to fields.
    this.formGroup.controls['ncpdp'].setValue(set[0]);
    this.formGroup.controls['rxNumber'].setValue(set[1]);
    this.formGroup.controls['fillDate'].setValue(formattedDate);

    // Adding the call to Limit the length after all the above has been done
    this.uiHelperService.limitToMaxLength(this.formGroup, 'ncpdp');
  }

  seidsChanged(event: any) {
    this.seidsLength = 0;
    const MAX_SEIDS = 500;
    if (this.seids) {
      let dataIn: string[] = [];
      if (this.seids.length > 0) {
        dataIn = this.seids.split(/\r|\n/);
        if (dataIn && dataIn.length <= 0) {
          dataIn = this.seids.split(',');
        }
      }

      if (dataIn.length >= 0 && dataIn.length < MAX_SEIDS + 1) {
        this.seids = dataIn.join(', ');
        this.seidsLength = dataIn.length;
      } else {
        this.seids = '';
        for (let i = 0; i < 500; i++) {
          if (i !== 499) {
            this.seids += dataIn[i] + ', ';
          }

          if (i === 499) {
            this.seids += dataIn[i];
          }
        }
        this.seidsLength = this.seids.split(',').length;
      }
    }
    /* if (this.seids.length > 0 &&
      this.seidsLength <= 500 &&
      (this.seids[this.seids.length - 1 ] !== ',' &&
       this.seids[this.seids.length - 2 ] !== ',')) {
      this.seids += ', ';
    } */
  }

  undoSelect(event): void {
    if (this.selectedClaims.length > 0) {
      this.selectedClaims = [];
    }
  }

  setFocus() {
    if (this.multiselect === 1) {
      this.textArea.nativeElement.focus();
    } else {
      this.inputText.nativeElement.focus();
    }
  }

  setRadio(event) {
    this.setFocus();
    this.resetServiceEventId();
  }

  limit(event) {
    const controlName = event.target.attributes['formcontrolname'].value;
    this.uiHelperService.limitToMaxLength(this.formGroup, controlName);
  }

  searchDisabled() {
    const rollup = this.multiselect === 0 ?
                   this.formGroup.invalid :
                   !(this.seidsLength > 0);
    return rollup;
  }

  onLazyLoad(event: LazyLoadEvent) {
    event = this.setSort(event);
    if (!event.sortField) {
      event.sortField = '-InUse';
    }
    if (!this.loadingGrid && event.sortField !== 'Selected') {
      this.loadClaimsGrid(
        new Pagination(this.rowsByBrowser, event.first, event.sortField, event.sortOrder));
    }
  }

  setSort(event: LazyLoadEvent) {
    if (typeof event.sortField === 'boolean') {
      event.sortField = this.prevSort;
      event.sortOrder = this.prevSortOrder;
    } else {
      this.prevSort = event.sortField;
      this.prevSortOrder = event.sortOrder;
    }
    return event;
  }

  search() {
    this.selectedClaims = [];

    if ((this.claimSearchResults.length > 0) && this.dTable) {
      this.dTable.reset();
    } else {
    this.loadClaimsGrid(
      new Pagination(this.rowsByBrowser, 0, '-InUse'));
    }

  }

  loadClaimsGrid(pagination: Pagination) {
    this.badSeids = [];
    let serviceEventId: string[] = [];

    if (this.multiselect === 0) {
      const dateString = moment(this.formGroup.controls['fillDate'].value).format('YYYYMMDD');
      serviceEventId.push(this.formGroup.controls['ncpdp'].value + '-' + this.formGroup.controls['rxNumber'].value + '-' + dateString);
    } else {
      const items = this.seids.split(',');
      serviceEventId = items.map( (item) => item.trim());
      serviceEventId = serviceEventId.filter(Boolean); //to remove trailing commas
      if(items && items.length > MAX_SEIDS) {
        this.showMaxSEIDsError = true;
        this.showGrid = false;
        this.hasAssociations = false;
        return;
      } else {
        this.showMaxSEIDsError = false;
      }
      // Validate SEIDS against the regex rules
      this.badSeids = this.seidValidator.validateSEIDArray(this.seids.trim().split(','))
      this.showInvalidSeidCount = this.badSeids.length > 0;
      if(this.showInvalidSeidCount) {
        this.badSeidString = this.badSeids.join(', ');
        this.showGrid = false;
        this.hasAssociations = false;
        return;
      }
    }

    // Checks passed. Show grid and loading
    //this.displayMoreDialog = false;
    this.loadingGrid = true;
    this.showGrid = true;

    this.claimService.getUnassociatedClaimsForAudit(this.auditId, serviceEventId, pagination)
      .subscribe( (result: ILimitedResult<SelectableClaimSearchResult>) => {
        this.claimSearchResults = result.Items;
        this.totalItemCount = result.TotalItemCount;
        this.checkHasAssociations(result.Items);
        if (!this.isIE) {
          this.rows = result.TotalItemCount;
        }
        //Check if this is not the first search of user
        if(this.cookiePresent){
          //if the checkbox was changed by user, then update it
          this.previousCookieValue = JSON.parse(this.getCookie(this.checkboxCookieKey));
          if((this.previousCookieValue == true && this.getMissingSeids == false) || (this.previousCookieValue == false && this.getMissingSeids == true)){
            this.setCookie(this.checkboxCookieKey, this.getMissingSeids, false);
          }
        }else{
          //set cookie for the first time
          this.setCookie(this.checkboxCookieKey, this.getMissingSeids, true);
        }
        
        if(this.getMissingSeids && this.showMissingPopup){
          //call api to get missing seids
          this.claimService.getMissingSeids(serviceEventId,pagination).subscribe((data:ILimitedResult<SeidSearchResult>)=>{
            this.showMissingSeids = data.TotalItemCount > 0;
            this.loadingGrid = false;
            if(this.showMissingSeids){
              this.missingSeidString = data.Items.map(a => a.ServiceEventId).join(', ');
            }
          },err => {
            this.loadingGrid = false;
            toastr.error('Error occurred with finding missing seids, please try again.');
          })
        }else{
          if(!this.showMissingPopup){
            this.showMissingPopup = true;
          }
          this.loadingGrid = false;
        }
      }, err => {
        this.loadingGrid = false;
        toastr.error('Error occurred with the association of claims, please try again.');
      });    

    this.claimSearchResults = [];
    this.missingSeidString = '';
    
  }

  resetServiceEventId() {
    this.selectAllChecked = false;
    this.claimSearchResults = []; // clear the grid and will also hide it
    this.ncpdp = '';
    this.rxNumber = '';
    this.fillDate = null;
    this.seids = '';
    this.seidsLength = 0;
    this.hasAssociations = false;
    this.showGrid = false;
    this.showInvalidSeidCount = false;
    this.showMaxSEIDsError = false;
    this.showMissingSeids = false;
    if (this.formGroup) {
      this.formGroup.reset();
    }
    if(this.cookieService.check(this.checkboxCookieKey)){
      var boolValue = JSON.parse(this.getCookie(this.checkboxCookieKey));
      this.formGroup.controls['seidCheckbox'].setValue(boolValue);
    }
  }

  setCookie(key: string, checked: boolean, insert: boolean){
    let today = new Date();
    const exp = new Date(today.getFullYear()+1, today.getMonth(), today.getDate());
    let value: string = String(checked);
    console.log(value);
    if(!insert){
      this.cookieService.delete(key);
    }
      this.cookieService.set(key, value, exp);
  }

  getCookie(key: string) {
    return this.cookieService.get(key);
  }

  selectAllRecords(event) {
    const checked = event.target.checked;
    this.setGridSelections(checked);
  }

  rowChecked(claimSearchResult: SelectableClaimSearchResult, event: any) {
    const checked = event.target.checked;
    claimSearchResult.Selected = checked;
  }

  close() {
    this.multiselect = 1;
    this.resetServiceEventId();
    this.closed.emit();
  }

  // checks all or unchecks all
  setGridSelections(checked: boolean) {
    this.selectAllChecked = checked;
    this.claimSearchResults.forEach(item =>  {
      item.Selected = checked;
    });
  }

  // Submit function
  submitClaimsToAdd() {
    this.disableUI = true;
    const claimsToSave = this.selectedClaims;

    // convert to AssociateClaimToAudit items
    const claimsToAssociate: AssociateClaimToAudit[] = claimsToSave
      .map(myclaim => ({ RawDataImport_ShardClaimIdentifier: myclaim.RawDataImport_ShardClaimIdentifier,
                         Source: myclaim.Source }));

    this.auditService.addClaimsToAudit(this.auditId, claimsToAssociate)
      .subscribe(data => {
        this.selectAllChecked = false;
        this.selectedClaims = [];
        toastr.success(`Associated ${claimsToSave.length} claims.`);
        this.showMissingPopup = false;
        this.search();
        this.disableUI = false;
      }, error => {
        toastr.error('Error saving claims to audit. Try again later.');
        this.disableUI = false;
      });
  }

  invalidNcpdp() {
    return this.formGroup.controls['ncpdp'].invalid &&
      (this.formGroup.controls['ncpdp'].dirty || this.formGroup.controls['ncpdp'].touched);
  }

  invalidRxNumber() {
    return this.formGroup.controls['rxNumber'].invalid &&
      (this.formGroup.controls['rxNumber'].dirty || this.formGroup.controls['rxNumber'].touched);
  }

  invalidFillDate() {
    return this.formGroup.controls['fillDate'].invalid &&
      (this.formGroup.controls['fillDate'].dirty || this.formGroup.controls['fillDate'].touched);
  }

  invalidNcpdpMessage() {
    return this.invalidNcpdp() ? 'Should be 7 digits.' : '';
  }

  invalidRxNumberMessage() {
    return this.invalidRxNumber() ? 'Should be 1-14 characters.' : '';
  }

  private checkHasAssociations(claims: ClaimSearchResult[]): void {
    if ((!claims || claims.length <= 0)) {
      this.hasAssociations = false;
    }

    let cont = true;
    this.hasAssociations = false;

    claims.forEach( (val) => {
      if (cont && val.InUse) {
        cont = false;
        this.hasAssociations = true;
      }
    });
  }
}
