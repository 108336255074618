import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AdminSectionComponent,
  AuditCarriersComponent,
  AuditDiscrepancyCodeComponent,
  AuditVendorComponent,
  AuditSpecialistComponent,
  AuditStatusComponent,
  AuditTypeComponent,
  AuditAddressBookComponent
} from '../../components';
import { AuthGuard, FeatureGuard } from '../../guards';
import * as _ from 'lodash';
import { AuditClassComponent } from 'src/app/components/admin/audit-class/audit-class.component';
import { ClientTenantGuard } from 'src/app/guards/clientTenant.guard';
import { DialogModule } from 'primeng/dialog';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';

/*********************************************/
/*
The AdminModule defines routes for the Admin related pages of the site (see routes below).

The default route will load the AdminSectionCompents.  Child routes load components within
the AuditSectionComponent's router-outlet.

AdminSectionComponent (components/admin-section) is responsible for determining which child to
load if you navigate to the default path of the module.
*/
/********************************************/
const routes: Routes = [
  {
    path: 'admin',
    component: AdminSectionComponent,
    canActivate: [AuthGuard],
    canActivateChild: [ClientTenantGuard],
    children: [
      {
        path: 'auditaddressbook', component: AuditAddressBookComponent, canActivate: [FeatureGuard],
        data: { feature: 'UI::AuditAddressBook' }
      },
      {
        path: 'auditcarriers', component: AuditCarriersComponent, canActivate: [FeatureGuard],
        data: { feature: 'UI::AuditCarriers' }
      },
      {
        path: 'auditclass', component: AuditClassComponent, canActivate: [FeatureGuard],
        data: { feature: 'UI::AuditClass' }
      },
      {
        path: 'auditdiscrepancycodes', component: AuditDiscrepancyCodeComponent, canActivate: [FeatureGuard],
        data: { feature: 'UI::AuditDiscrepancyCodes' }
      },
      {
        path: 'auditvendor', component: AuditVendorComponent, canActivate: [FeatureGuard],
        data: { feature: 'UI::AuditVendor' }
      },
      {
        path: 'auditstatus', component: AuditStatusComponent, canActivate: [FeatureGuard],
        data: { feature: 'UI::AuditStatus' }
      },
      {
        path: 'auditspecialist', component: AuditSpecialistComponent, canActivate: [FeatureGuard],
        data: { feature: 'UI::AuditSpecialist' }
      },
      {
        path: 'audittype', component: AuditTypeComponent, canActivate: [FeatureGuard],
        data: { feature: 'UI::AuditType' }
      },
      { path: '', redirectTo: 'auditaddressbook', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), DialogModule, ModalModule.forRoot()],
  exports: [RouterModule],
})
export class AdminRoutingModule { }
