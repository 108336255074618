import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { ModalAction } from '../../models/modalAction';
import { ContextMenuItem } from '../../models/contextMenuItem';
import { delay } from 'lodash';


@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css']
})
export class ContextMenuComponent implements OnInit {
  @Input() buttonText: string;
  @Input() menuItems: ContextMenuItem[];
  @Output() modalAction: EventEmitter<ModalAction> = new EventEmitter<ModalAction>();
  @HostListener('document:click', ['$event']) clickedOutside($event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      this.show = !this.show;
    } else {
      this.show = false;
    }
  }
  public show: boolean = false;
  constructor(private eRef: ElementRef) { }

  ngOnInit() {
  }

  itemClicked(name: string) {
    this.modalAction.emit({id: name, title: this.buttonText, action: null});
  }

}
