import { FaxParty } from "./fax-party";
import { ImageSearchResult } from "./image-search-result";

export class FaxSend {
    public Recipients: FaxParty[];
    public Sender: FaxParty;
    public SendDate: Date;
    public IncludeVendorCoverPage: boolean;
    public Subject: string;
    public Comments: string;
    public SelectedDocumentMetadata: ImageSearchResult[];

}
