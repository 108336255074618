import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Pagination,} from '../models';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GlobalFilterService } from './global-filter.service';
import { FaxStatus } from '../models/fax-status';
import { Fax } from '../models/fax';
import { FaxSend } from '../models/fax-send';

const BASE_URL = `${environment.faxApiRoot}/clients`;
const APP_NAME = `${environment.applicationName}`;

@Injectable({
  providedIn: 'root'
})

export class FaxService {
  constructor(
    private http: HttpClient,
    private globalFilterService: GlobalFilterService,
  ) {
  }

  public getFaxStatus(pagination: Pagination): Observable<FaxStatus> {
    const params = pagination.buildSearchParams();
    if(!this.globalFilterService.selectedClientTenantName)
    {
      return this.http.get<FaxStatus>(
        `${BASE_URL}/${this.globalFilterService.selectedClientId}/applicationNames/${APP_NAME}/Faxes/FaxesSent`,
        { params }
      );
    } 
    return this.http.get<FaxStatus>(
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/ClientTenants/${this.globalFilterService.selectedClientTenantName}/applicationNames/${APP_NAME}/Faxes/FaxesSent`,
      { params }
    );

  }

  public sendFax(fax: FaxSend): Observable<Response> {
    if(!this.globalFilterService.selectedClientTenantName)
    {
      return this.http.post<Response>(
        `${BASE_URL}/${this.globalFilterService.selectedClientId}/applicationNames/${APP_NAME}/Faxes/`,
        fax
      );
    }
    return this.http.post<Response>(
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/ClientTenants/${this.globalFilterService.selectedClientTenantName}/applicationNames/${APP_NAME}/Faxes/`,
      fax
    );
  }

}