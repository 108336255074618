
<portal-navbar id="portal-navbar" style="background-color:#50aed7;height:70px;">
  <nav class="portalnav">
  <div class="portalnav__title"></div>
  <div class="portalnav__content"></div>
  <span class='rebrand-logo'></span>
  <span class="inm-logo"></span>
  <div id="menuBar"> 
    <span id="inmar-logo-text-container" routerLink="" style="margin-bottom: 7px">
      <div class="inmar-logo-text"></div>
      <div class="inmar-logo-text inmar-logo-text-thin">{{apptitle}}</div>
    </span> 
    <ul id="menuItems">
      <li *ngFor="let section of sectionsObj">
        <a *ngIf="!section.subSection && checkValidLink(section.feature)" routerLinkActive="active" [routerLink]="section.path" [routerLinkActiveOptions]="{ exact: false }">{{ section.name }}</a>
      </li>
    </ul>  
  </div> 
  <div *ngIf="clientsLoaded && usersClients.length > 1">
      <p-dropdown [options]="usersClients" (onChange)="clientSelected($event)" class="client-droplist"></p-dropdown>
  </div> 
  <div *ngIf="clientsLoaded && usersClients.length === 1">
    <div><h4 class="client-label">{{usersClients[0]['label']}}</h4></div>
  </div> 
</nav>
</portal-navbar>
<div name="healthcareTopBar" id="healthcareTopBar" style="margin-bottom: 5px;"></div>