import { Component, EventEmitter, OnInit, ViewChild, Input, Output, OnChanges } from '@angular/core';

@Component({
  selector: 'app-upload-claims',
  templateUrl: './upload-claims.component.html'
})

export class UploadClaimsComponent {
    public showFileDialog = false;

    constructor() { }

    showDialog() {
      this.showFileDialog = true;
    }

    uploadComplete(event: boolean) {
      this.showFileDialog = false;
    }
}
