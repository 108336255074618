import { ITableColumn } from 'src/app/models/tColumn';

export class AuditImageTableHelper {
  public auditImageColumns: ITableColumn[];
  constructor() {
    this.buildColumns();
  }

  buildColumns(): void {
    this.auditImageColumns = [
      { field: 'Name', header: 'File Name', fldClass: 'col-link',},
      { field: 'Delete', header: '', hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn', sort: false}
    ];
  }
}
