export interface IAuditCarrierColumn {
    field: string;
    header: string;
    hdrClass?: string;
    fldClass?: string;
    show: boolean;
    disabled?: boolean;
    editable?: boolean;
    width?: string;
    disableSort?: boolean;
}

export class AuditCarrierTableHelper {
    readonly featureAuditEdit = 'UI::AuditCarrierEdit';       // feature user must have to enable Edit links in grid
    readonly featureAuditDelete = 'UI::AuditCarrierDelete';  // feature user must have to enable Delete links in grid
    public carrierColumns: IAuditCarrierColumn[];

    constructor() {
        this.generateCarrierSummaryColumns();
    }

    private generateCarrierSummaryColumns(): void {
        this.carrierColumns = [
            {
                field: 'Name', header: 'Name',
                show: true, disabled: false
            },
            {
                field: 'InUse', header: 'In Use',
                hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn',
                show: true, disabled: false, disableSort: true
            },
            {
                field: 'ModifiedBy', header: 'Author',
                show: true, disabled: false
            },
            {
                field: 'ModifiedDate', header: 'Date Modified',
                show: true, disabled: false
            },
            {
                field: 'Delete', header: '',
                hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn',
                show: true, disabled: false,
                width: '50px', disableSort: true
            },
        ];
    }
}


