export class Audit {
    AuditId: number;
    ClientId: number;
    AuditReferenceNumber: string;
    readonly AuditNumber?: number;
    StatusId: number;
    SpecialistId: number;
    AuditCarrierId: number;
    DeliveryMethod: string;
    AuditClassId: string;
    VendorId: number;
    AuditTypeId: number;
    NotificationDate: Date;
    ReceivedDate: Date;
    AuditDueDate: Date;
    StatusDueDate: Date;
    NoteText: string;
    IsHideFromWorkflow: boolean;
    IsPending: boolean;
    /* Keep for update story
    // delete if not needed by Update Audit story
    IsActive: boolean;
    CreateDate: Date;
    CreatedByUserId: string;
    LastModifiedDate: Date;
    LastModifiedByUserId: string;
    */
}

