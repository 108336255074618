import { AuditWorkflowSearchFilter } from "./audit-workflow-search-filter";
import { AuditWorkflowSearchResult } from "./audit-workflow-search-result";
import { AuditByStatusesResult } from "./audit-by-statuses-results";
import { AuditByStatusesFilter } from "./audit-by-statuses-filter";

export class WorkFlowCache {
  public Filter: AuditByStatusesFilter;
  public Results: AuditByStatusesResult[];
  public FilterStatusId?: number;
  public ShowStatus: boolean;
  constructor(){
    
  }
}