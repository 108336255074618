import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Table, TableCheckbox, TableHeaderCheckbox } from 'primeng/table';
import { ILimitedResult, LimitedResult, Pagination, PrimeNgListItem } from 'src/app/models';
import { AuditCarrierDocumentSearch } from 'src/app/models/audit-carrier-document-search';
import { AuditCarrierService, AuthorizationService, GlobalFilterService, ImageRepoService, UiHelperService } from 'src/app/services';
import { AuditCarrierDocumentSearchTableHelper } from './helpers/audit-carrier-document-search-table-helper';
import * as toastr from 'toastr';
import { PhiService } from 'src/app/services/phi.service';
import { LazyLoadEvent } from 'primeng/api';
import { AuditCarrierDocumentSearchToolbarHelper } from './helpers/audit-carrier-document-search-toolbar.helper';
import { ToolbarAction } from 'src/app/shared/models/toolbarAction';

const MAX_ROWS = 30;

@Component({
  selector: 'app-audit-carrier-document-search',
  templateUrl: './audit-carrier-document-search.component.html',
  styleUrls: ['./audit-carrier-document-search.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class AuditCarrierDocumentSearchComponent implements OnInit, OnDestroy {
  @ViewChild('dTable', { static: false }) resultTable: Table;
  @ViewChild('chkBoxAll', { static: false }) selectAll: TableHeaderCheckbox;
  public readonly STORAGE_ITEM = 'currentAuditCarrierDocumentSearch';
  public readonly default_page: Pagination;
  public readonly tableHelper: AuditCarrierDocumentSearchTableHelper;
  public readonly toolbarHelper: AuditCarrierDocumentSearchToolbarHelper;
  public readonly rowsByBrowser: number;
  public phiToggleEnabled: boolean;
  public selectedDocuments: AuditCarrierDocumentSearch[] = [];
  loadingSearchResults: boolean;
  loadingMasterData: boolean;
  carrierIdForSearch: string;
  auditCarrierDocumentSearchFormGroup: FormGroup;
  auditCarrierDocumentSearchResults: AuditCarrierDocumentSearch[] = [];
  clientChangedSubscription: any;
  
  auditCarrierListItems: PrimeNgListItem[];
  totalItemCount = MAX_ROWS;

  showSearchResults: boolean; // reset to false after the page is constructed b/c angular doesn't like when you set to false originally
  public totalRecordCount: number;

  public maxFileSizeLimitReached = false;
  public selectedDocumentsFileSize = 0;
  public documentResultsFileSize = 0;
  public totalFileSizeAllowed = 100;

  constructor(private fb: FormBuilder,
    private auditCarrierService: AuditCarrierService,
    private globalFilterService: GlobalFilterService,
    private authService: AuthorizationService,
    private uiHelperService: UiHelperService,
    private phiService: PhiService,
    private imageRepoService: ImageRepoService,
    private route: ActivatedRoute,
    private router: Router) { 
      this.phiService.PhiToggleChanged.subscribe(() => this.onPhiToggleChange());
       // subscribe to global so we can reset form if global client is changed
      this.clientChangedSubscription = this.globalFilterService.ClientChanged.subscribe(() => {
        sessionStorage.removeItem(this.STORAGE_ITEM);
        if (this.route.snapshot.params.carrierName){
          this.router.navigate(['audit/auditcarrierdocumentsearch'],{ queryParams: {},  queryParamsHandling: "merge" });
        }
        this.initialPageSetup();
      });
      this.default_page = new Pagination(MAX_ROWS, 0, '');
      this.tableHelper = new AuditCarrierDocumentSearchTableHelper();
      this.toolbarHelper = new AuditCarrierDocumentSearchToolbarHelper(authService);
    } 

    ngOnInit() {
      this.onPhiToggleChange();
      this.initialPageSetup();
    }

    // clean up to prevent memory leaks, by unsubscribing to subscribed events
    ngOnDestroy(): void {
      if (this.clientChangedSubscription) {
        this.clientChangedSubscription.unsubscribe();
      }
    }

    // ngOnChanges(changes): void {
    //   this.selectedDocuments = [];
    //   this.selectedDocumentsFileSize = 0;
    //   this.documentResultsFileSize = 0;
    //   this.onPhiToggleChange();
    // }

    
    onPhiToggleChange(): void {
      this.phiToggleEnabled = this.phiService.PhiToggle;
      this.checkBulkdDownloadButton();
    }

    toolbarBtnClick(event: ToolbarAction) {
      switch (event.name) {
        case 'refresh': {
          this.selectedDocuments = [];
          this.selectedDocumentsFileSize = 0;
          this.checkBulkdDownloadButton();
          this.resultTable.reset();;
          break;
        }
        case 'bulkDownloadDocuments': {
          this.downloadSelectedDocuments();
          break;
        }
      }
    }
  

    resetTable(): void {
      if (this.resultTable) {
        this.resultTable.reset();
      }
    }

    resetPageSetup() {
      sessionStorage.removeItem(this.STORAGE_ITEM);
      this.initFormGroup();
  
      // empty grid and hide it
      this.auditCarrierDocumentSearchResults = [];
      this.showSearchResults = false;
      this.selectedDocuments = [];
      this.maxFileSizeLimitReached = false;
      this.selectedDocumentsFileSize = 0;
    }

    initialPageSetup() {
      if (this.route.snapshot.params.carrierName) {
        sessionStorage.removeItem(this.STORAGE_ITEM);
      }
      
      this.initFormGroup();
      this.loadDropDown();
      // empty grid and hide it
      this.auditCarrierDocumentSearchResults = [];
      const sessionItem = sessionStorage.getItem(this.STORAGE_ITEM);
      const currentAuditCarrierDocumentSearch: number = sessionItem ? JSON.parse(sessionItem) : null;
      this.showSearchResults = false;
      if (currentAuditCarrierDocumentSearch) {
        this.loadFormGroupAfterSearch(currentAuditCarrierDocumentSearch);
        this.search(new Pagination(MAX_ROWS, 0, ''), true);
        this.calculateSelectedDocumentsFileSize();
        this.isMaxFileLimitReached();
        this.checkBulkdDownloadButton();
      }
    }

  initFormGroup() {
      this.auditCarrierDocumentSearchFormGroup = this.fb.group(
        {
          auditCarrierId: ['',Validators.compose([Validators.required])],
        });
    }

 loadDropDown() {
  this.loadingMasterData = true;
   this.auditCarrierService.getAuditCarrierListItems()
   .subscribe((
     respAuditCarriers: PrimeNgListItem[],
     ) => {
          this.auditCarrierListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditCarriers);
          // set flag to enable ui controls
          this.loadingMasterData = false; // we can enable ui components when false
        }, err => {
          this.loadingMasterData = false;
        });
    }
    
  loadFormGroupAfterSearch(auditCarrierId: number) {
    this.auditCarrierDocumentSearchFormGroup.reset({
      auditCarrierId: auditCarrierId,
    });
  }

  loadAuditCarrierDocumentSearchLazy(event: LazyLoadEvent): void {
    if (!this.loadingSearchResults) {
      this.search(
        new Pagination(MAX_ROWS, event.first, event.sortField, event.sortOrder), false);
        this.selectedDocuments = [];
        this.selectedDocumentsFileSize = 0; 
        this.isMaxFileLimitReached();
        this.checkBulkdDownloadButton();
    }
  }

  formIsInvalid(): boolean {
    return this.auditCarrierDocumentSearchFormGroup.invalid;
  }

  search(pagination: Pagination, resetTable: boolean) {
    this.auditCarrierDocumentSearchResults = [];
    const controls = this.auditCarrierDocumentSearchFormGroup.controls;
    const auditCarrierId = controls['auditCarrierId'].value;
    
    this.showSearchResults = true;
    this.loadingSearchResults = true;

    // store search in session storage for reuse when returning to search screen.
    sessionStorage.setItem(this.STORAGE_ITEM, JSON.stringify(auditCarrierId));

    this.loadFormGroupAfterSearch(auditCarrierId); // rebind data for display 

    if (resetTable) {
      this.resetTable();
    }

    this.auditCarrierService.performAuditCarrierDocumentSearch(auditCarrierId, pagination)
      .subscribe(
        (data: LimitedResult<AuditCarrierDocumentSearch>) => {
          this.auditCarrierDocumentSearchResults = data.Items;
          this.loadingSearchResults = false;
          this.totalItemCount = data.TotalItemCount;
          if(this.totalItemCount > 0)
          {
            this.auditCarrierDocumentSearchResults = this.auditCarrierDocumentSearchResults.map(this.convertImageSizeToMB);
            var tempDocumentResults = this.auditCarrierDocumentSearchResults.map(item => item.ImageSize);
            this.documentResultsFileSize = tempDocumentResults.map(Number).reduce((a,b) => a+b, 0);
          }
        },
        // handle error state
        () => {
          this.loadingSearchResults = false;
          toastr.error('Error loading data from server.');
          this.totalItemCount = 0;
      });
  } 

  undoSelect(event): void {
    if (this.selectedDocuments.length > 0) {
      this.selectedDocuments = [];
      this.selectedDocumentsFileSize = 0;
      this.checkBulkdDownloadButton();
    }
  }

  onDocumentSelect(event) {
    this.calculateSelectedDocumentsFileSize()
    this.isMaxFileLimitReached();
    this.checkBulkdDownloadButton();
  }
  
  onDocumentUnselect(event) {
    this.calculateSelectedDocumentsFileSize();
    if(this.selectedDocumentsFileSize <= this.totalFileSizeAllowed) {
      this.maxFileSizeLimitReached = false;
      this.checkBulkdDownloadButton();
    }
    
  }

  checkAllClick(event): void {
    if (!this.selectAll.checked) { 
      this.selectedDocuments = [];
    }
    this.calculateSelectedDocumentsFileSize()
    this.isMaxFileLimitReached();
    this.checkBulkdDownloadButton();

  }

  downloadDocument(rowData: AuditCarrierDocumentSearch) {
    if(rowData.AuditImageId.toString())
    {
        this.imageRepoService.getImageMetadata(rowData.AuditImageId.toString()).subscribe((imageSearchResult) => {

          let mimeType = imageSearchResult.MimeType;
          this.imageRepoService.downloadImage(rowData.AuditImageId.toString()).subscribe((data) => {

            const blob = new Blob([data], {type: mimeType});
          
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = rowData.ImageName;
            
            // this is a get around to download a blob. 
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, rowData.ImageName);
            } else {
              link.click();
              link = null;
            }
          }, err => {
            toastr.error('There was an issue getting the image from the server.');
          });;
        });
    }
  }
  
  convertImageSizeToMB(image): AuditCarrierDocumentSearch {
    image.ImageSize = parseFloat((image.ImageSize/Math.pow(10,6)).toFixed(2)); 
    if(image.ImageSize === 0) {
      image.ImageSize = 0.01
    }
    return image; 
  }

  downloadSelectedDocuments() {
  if(this.selectedDocuments.length > 0)
  {
      let mimeType = "application/zip";
      let fileName = "CompressedFiles.zip"; //this is the name Image Repo supplies.
      var selectedDocumentIds = this.selectedDocuments.map(Image => Image.AuditImageId.toString());
      toastr.info("File download queued.");
      this.imageRepoService.downloadCompressedImages(selectedDocumentIds).subscribe((data) => {

        const blob = new Blob([data], {type: mimeType});
      
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = fileName;
        
        // this is a get around to download a blob. 
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          link.click();
          link = null;
        }
        this.selectedDocuments = [];
        this.maxFileSizeLimitReached = false;
        this.selectedDocumentsFileSize = 0;
        this.checkBulkdDownloadButton();
      }, err => {
        toastr.error('There was an issue getting the image from the server.');
      });;
    }

  }

  isMaxFileLimitReached() {
     if (this.selectedDocumentsFileSize > this.totalFileSizeAllowed) {
      this.maxFileSizeLimitReached = true;
      toastr.error('Exceeded max file limitation of ' + this.totalFileSizeAllowed + ' MB.');
      this.checkBulkdDownloadButton();
    }
  }

  calculateSelectedDocumentsFileSize() {
    var tempSelectedDocuments = this.selectedDocuments.map(item => item.ImageSize);
    this.selectedDocumentsFileSize = tempSelectedDocuments.map(Number).reduce((a,b) => a+b, 0);
  }

 checkBulkdDownloadButton() {
    this.toolbarHelper.updateBulkDownloadDocumentsButton((this.phiToggleEnabled && this.maxFileSizeLimitReached) || 
    (this.phiToggleEnabled && this.selectedDocumentsFileSize === 0) || (!this.phiToggleEnabled));
  }

}
