import { Input } from '@angular/core';
import { AuthorizationService } from 'src/app/services';

export interface IToolbarItem {
  label?: string;
  name: string;
  tip: string;
  icon: string;
  disabled?: boolean;
  show: boolean;
  exclude?: boolean;
}

export class AuditImageToolbarHelper {
  public showAction: string;
  public actionText: string;
  public currentToolbar = [];
  private readonly TOOLBAR_ITEMS = [
    { feature: '',
      item: {label: 'Refresh', name: 'refresh', tip: 'Refresh Grid', icon: '#inm-icon__refresh',
      disabled: false, show: true, exclude: true }},
    { feature: 'UI::AuditImagesUpload',
      item: {label: 'Upload Document', name: 'uploadImage', tip: 'Upload Documents',
      icon: '#inm-icon__upload', disabled: false, show: true }},
    { feature: 'UI::GenerateAuditClaimsFile',
      item: {label: 'Generate an Associated Claims File', name: 'generateClaimsTextFile', tip: 'Generate an Associated Claims File (Top 1,000).', icon: '#inm-icon__export',
      disabled: false, show: true}}];
  constructor(private authService: AuthorizationService) {
    this.generateToolbarData();
  }

  generateToolbarData(): void {
    for (let i = 0; i < this.TOOLBAR_ITEMS.length; i++) {
      if (!this.TOOLBAR_ITEMS[i].feature || this.authService.hasFeature(this.TOOLBAR_ITEMS[i].feature)) {
        this.currentToolbar.push(this.TOOLBAR_ITEMS[i].item);
        continue;
      }
    }
  }
  
  //Enable/Disable Generate Claim File Button 
   public updateGenerateClaimFileButton(isEnabled: boolean): void {
    for (const item of this.currentToolbar) {
      if(item.name === 'generateClaimsTextFile') {
          item.disabled = isEnabled;
      }
    }
  }
}
