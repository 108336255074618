<h2 style="margin-bottom: 1em;">Audit Carriers</h2>
<app-audit-toolbar [toolbarItemList]="toolbarHelper.currentToolbar" (OnClick)="toolbarBtnClick($event)"></app-audit-toolbar>
<p-table #dt [columns]="auditCarrierTableHelper.carrierColumns" [value]="auditCarrierItems" (onLazyLoad)="loadDataLazy($event)"
  [loading]="gridLoading" loading="fa-spinner" [scrollable]="true" [lazy]="true" [paginator]="true" [responsive]="true"
  [rows]="30" [totalRecords]="totalItemCount">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [ngClass]="col.hdrClass? col.hdrClass : 'default'"
        [style.cursor]="col.disableSort === true ? 'default' : 'pointer'" [pSortableColumn]="col.disableSort === true ? '' : col.field">
        {{col.header}}
        <p-sortIcon *ngIf="!col.disableSort" [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" [ngClass]="col.fldClass? col.fldClass : 'default'">
        <div *ngIf="col.field === 'Name'">
          <a *ngIf="canEdit" [routerLink]="" (click)="openEditDialog(rowData)" style="cursor:pointer;">
            {{rowData[col.field]}}
          </a>
          <span *ngIf="!canEdit">{{rowData[col.field]}}</span>
        </div>
        <div *ngIf="col.field === 'InUse'">
          {{rowData[col.field] === true ? "Yes" : "No"}}
        </div>
        <div *ngIf="col.field === 'ModifiedBy'">
          {{rowData[col.field]}}
        </div>
        <div *ngIf="col.field === 'ModifiedDate'">
          {{rowData[col.field] | date: 'MM/dd/yyyy'}}
        </div>
        <div *ngIf="col.field === 'Delete' && !col.disabled">
          <a *ngIf="rowData.InUse !== true" (click)="confirmDelete(rowData)" style="cursor: pointer; width: 30px;">
            <svg class="inm-icon" aria-label="Delete">
              <use xlink:href="#inm-icon__trash" class="danger" /></svg>
          </a>
          <a *ngIf="rowData.InUse === true" style="cursor: default;width: 30px;">
            <svg class="inm-icon" aria-label="Delete">
              <use xlink:href="#inm-icon__trash" class="disabled" /></svg>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    Total Record Count: {{totalItemCount}}
  </ng-template>
</p-table>

<app-admin-add-edit-dialog [titleText]="addEditTitle" [(show)]="showAuditCarrierDialog" [id]="carrierId"
  [nameValue]="carrierName" (modalAction)="modalAction($event)"></app-admin-add-edit-dialog>

<app-delete-dialog titleText="Delete Confirmation" [(show)]="showDeleteDialog" [id]="carrierId"
  [message]="deleteMessage" (modalAction)="deleteModalAction($event)"></app-delete-dialog>