import { Component,OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './confirm-client-tenant.component.html',
  styleUrls: ['./confirm-client-tenant.component.css']
})
export class ConfirmClientTenantComponent implements OnInit {

  message: string;
  titleText: string;
  show: boolean;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.message = 'Client environment cannot be determined.  If this problem continues, please contact someone from your account team';
    this.titleText = 'Client Configuration Error';
    this.show = true;
  }

  actionCall(): void {
    this.bsModalRef.hide();
  }

}
