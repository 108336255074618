<p-dialog header="{{titleText}}" [(visible)]="show"
  [modal]="true" [closable]="false" (onShow)="onShow()" 
  [blockScroll]="true" [style]="{minWidth:'450px'}" [contentStyle]="{height: '350px'}">
  <form [formGroup]="form" *ngIf="show" style="height: 200px">
    <div class="inm-label--required">{{displayTextBoxLabel}}</div>
    <p-autoComplete #autoCompSelector [suggestions]="filteredResults"
      formControlName="selection"
      [minlength]="1"
      [(ngModel)]="selectedValue" size="40" (onKeyUp)="validateInput()" (onSelect)="validateInput()"
      (completeMethod)="search($event)"  dataKey="{{displayLabel}}" (onDropdownClick)="getListData()">
    </p-autoComplete>
  </form>
  <div style="margin-top: 35px" class="btn-container-dialog">
    <button type="button" class="inm-button btn-padding" [disabled]="disableSaveBtn" (click)="submitClick('save')">Save</button>
    <button type="button" class="inm-button btn-padding" (click)="submitClick('cancel')">Cancel</button>
  </div>
</p-dialog>
