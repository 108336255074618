import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})

export class FooterComponent {
  private year: number;
  public copyright: string;
  constructor() {
      this.year = (new Date()).getFullYear();
      this.copyright = `© ${this.year} Inmar Inc.` ;
  }

}
