<h2 style="margin-bottom: 1em;">Audit Carrier Summary</h2>
<p-table #dt [columns]="auditCarrierTableHelper.carrierSummaryColumns" 
  [value]="carrierSummary" (onLazyLoad)="loadDataLazy($event)"
  [loading]="carrierDataLoading" loading="fa-spinner" [scrollable]="true" 
  [lazy]="true" [paginator]="true" [responsive]="true" [rows]="30"
  [totalRecords]="totalItemCount">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns"
      [ngClass]="col.hdrClass? col.hdrClass : 'default'"
      [pSortableColumn]="col.field">
        {{col.header}}
      <p-sortIcon [field]="col.field"></p-sortIcon>
    </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
        <td *ngFor="let col of columns" 
        [ngClass]="col.fldClass? col.fldClass : 'default'">
            <div *ngIf="col.field === 'AttributeName'">
              <a [routerLink]="['/audit/auditsearch', {'carrierName': rowData['AttributeName']}]">
                {{rowData[col.field]}}
              </a>
            </div>
            <div *ngIf="col.field === 'AuditCount'">
              {{rowData[col.field]}}
            </div>
            <div *ngIf="col.field === 'ClaimCount'">
              {{rowData[col.field]}}
            </div>
            <div *ngIf="col.field === 'InitialRiskAmount'">
              {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' | customCurrencyPipe }}
            </div>
            <div *ngIf="col.field === 'ApprovedRecoupmentAmount'">
              {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' | customCurrencyPipe }}
            </div>
        </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary"> 
    Total Record Count: {{totalItemCount}}
  </ng-template>
</p-table>


