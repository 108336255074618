import * as _ from 'lodash';
import * as moment from 'moment';
import * as toastr from 'toastr';

import { ActivatedRoute, Router } from '@angular/router';
import {
  AuditCarrierService,
  AuditService,
  AuditSpecialistService,
  AuditStatusService,
  AuditTypeService,
  AuditVendorService,
  AuthorizationService,
  DeliveryMethodService,
  GlobalFilterService,
  UiHelperService
} from '../../services';
import { AuditSearchRequest, AuditSearchResult, ILimitedResult, Pagination, PrimeNgListItem, StatusModel } from '../../models';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuditClassService } from 'src/app/services/audit-class.service';
import { AuditSearchTableHelper } from './helpers/audit-search-table.helper';
import { ModalAction } from 'src/app/shared/models/modalAction';
import { Table } from 'primeng/table';
import { ValidateDates } from 'src/app/validators/validate-dates';
import { dropRight } from 'lodash';
import { forkJoin } from 'rxjs';

const MAX_ROWS = 30;
@Component({
  selector: 'app-audit-search',
  templateUrl: './audit-search.component.html',
  styleUrls: ['./audit-search.component.css'], encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})

export class AuditSearchComponent implements OnInit, OnDestroy {
  @ViewChild('dTable', { static: false }) resultTable: Table;
  public readonly STORAGE_ITEM = 'currentSearchCriteria';
  public readonly default_page: Pagination;
  public fillDatesInvalid: boolean;
  public readonly tableHelper: AuditSearchTableHelper;
  loadingSearchResults: boolean;
  loadingMasterData: boolean;
  carrierIdForSearch: string;
  typeIdForSearch: string;
  auditSearchFormGroup: FormGroup;
  auditSearchModel: AuditSearchRequest;
  auditSearchResults: AuditSearchResult[];
  clientChangedSubscription: any;
  notificationStartDateSubscription: any;
  notificationEndDateSubscription: any;
  receivedStartDateSubscription: any;
  receivedEndDateSubscription: any;
  dueStartDateSubscription: any;
  dueEndDateSubscription: any;
  auditTypesListItems: PrimeNgListItem[];
  auditStatusList: StatusModel[];
  auditStatusListItems: PrimeNgListItem[];
  auditSpecialistListItems: PrimeNgListItem[];
  auditCarrierListItems: PrimeNgListItem[];
  auditVendorListItems: PrimeNgListItem[];
  deliveryMethodListItems: PrimeNgListItem[];
  auditClassListItems: PrimeNgListItem[];
  totalItemCount = MAX_ROWS;

  readonly featureAuditEdit = 'UI::AuditEdit';       // feature user must have to enable Edit links in grid
  readonly featureAuditDelete = 'UI::AuditDelete';  // feature user must have to enable Delete links in grid

  // errorMessages and ToastrMessages
  readonly notificationDateErrorMessage = 'End date must be greater than or equal to start date.';
  readonly receivedDateErrorMessage = 'End date must be greater than or equal to start date.';
  readonly dueDateErrorMessage = 'End date must be greater than or equal to start date.';
  readonly deleteToastrMessage = 'There was an issue with deleting the audit you selected. The item was not deleted.';
  readonly tagInvalidErrorMessage = 'Must be less than 9 characters.';
  readonly claimControlNumberInvalidErrorMessage = 'Must be less than or equal to 50 characters.';
  readonly fillDateErrorMessage = 'End date must be greater than or equal to start date.';

  notificatonDatesInvalid = true;
  receivedDatesInvalid = true;
  dueDatesInvalid = true;

  showSearchResults: boolean; // reset to false after the page is constructed b/c angular doesn't like when you set to false originally
  public totalRecordCount: number;
  public deleteConfirmationMessage: string;
  public refId: number;
  public showDeleteDialog: boolean;


  constructor(private fb: FormBuilder,
    private auditCarrierService: AuditCarrierService,
    private auditStatusService: AuditStatusService,
    private deliveryMethodService: DeliveryMethodService,
    private globalFilterService: GlobalFilterService,
    private auditSpecialistService: AuditSpecialistService,
    private auditVendorService: AuditVendorService,
    private confirmationService: ConfirmationService,
    private authorizationService: AuthorizationService,
    private auditTypeService: AuditTypeService,
    private auditClassService: AuditClassService,
    private auditService: AuditService,
    private uiHelperService: UiHelperService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // subscribe to global so we can reset form if global client is changed
    this.clientChangedSubscription = this.globalFilterService.ClientChanged.subscribe(() => {
      sessionStorage.removeItem(this.STORAGE_ITEM);
      if (this.route.snapshot.params.carrierName || this.route.snapshot.params.typeName){
        this.router.navigate(['audit/auditsearch'],{ queryParams: {},  queryParamsHandling: "merge" });
      }
      this.initialPageSetup();
    });
    this.default_page = new Pagination(MAX_ROWS, 0, '');
    this.tableHelper = new AuditSearchTableHelper();
  }

  ngOnInit() {
    this.initialPageSetup();
    this.checkFeatures();
  }

  resetTable(): void {
    if (this.resultTable) {
      this.resultTable.reset();
    }
  }

  initialPageSetup() {
    if (this.route.snapshot.params.carrierName) {
      sessionStorage.removeItem(this.STORAGE_ITEM);
    }
    if (this.route.snapshot.params.typeName) {
      sessionStorage.removeItem(this.STORAGE_ITEM);
    }
    this.initFormGroup();
    this.loadDropDowns();
    // empty grid and hide it
    this.auditSearchResults = [];
    const sessionItem = sessionStorage.getItem(this.STORAGE_ITEM);
    const currentSearchCriteria: AuditSearchRequest = sessionItem ? JSON.parse(sessionItem) : null;
    this.showSearchResults = false;
    if (currentSearchCriteria) {
      this.loadFormGroupAfterSearch(currentSearchCriteria);
      this.search(new Pagination(MAX_ROWS, 0, ''), true);
    }
  }

  // resets when client changes or if you click reset button.
  resetPageSetup() {
    sessionStorage.removeItem(this.STORAGE_ITEM);
    this.initFormGroup();

    // empty grid and hide it
    this.auditSearchResults = [];
    this.showSearchResults = false;
  }

  // check if user has edit feature
  hasEditFeature(): boolean {
    return this.authorizationService.hasFeature(this.featureAuditEdit);
  }

  // clean up to prevent memory leaks, by unsubscribing to subscribed events
  ngOnDestroy(): void {
    if (this.clientChangedSubscription) {
      this.clientChangedSubscription.unsubscribe();
    }
  }

  /************ View initialization methods *********** */
  initFormGroup() {

    this.auditSearchFormGroup = this.fb.group(
      {
        auditReferenceNumber: '',
        auditNumber: '',
        auditTypeId: '',
        auditStatusId: '',
        notificationStartDate: '',
        receivedStartDate: '',
        dueStartDate: '',
        notificationEndDate: '',
        receivedEndDate: '',
        dueEndDate: '',
        bin: '',
        store: '',
        drug: '',
        tag: ['', Validators.compose([Validators.maxLength(8)])],
        rxNumber: '',
        rxFromFillDate: '',
        rxToFillDate: '',
        specialistId: '',
        auditCarrierId: '',
        vendorId: '',
        deliveryMethod: '',
        auditClassId: '',
        claimControlNumber: ['', Validators.compose([Validators.maxLength(50)])],
      }, {
        validator: [
          ValidateDates.StartDateNotGreaterThanEndDate('notificationStartDate', 'notificationEndDate'),
          ValidateDates.StartDateNotGreaterThanEndDate('receivedStartDate', 'receivedEndDate'),
          ValidateDates.StartDateNotGreaterThanEndDate('dueStartDate', 'dueEndDate'),
          ValidateDates.StartDateNotGreaterThanEndDate('rxFromFillDate', 'rxToFillDate'),
        ]
      });
  }

  checkFeatures() {
    if (!this.authorizationService.hasFeature(this.featureAuditDelete)) {
      this.tableHelper.auditSearchColumns = dropRight(this.tableHelper.auditSearchColumns);
    }
  }

  loadDropDowns() {
    // set flag to disable ui controls
    this.loadingMasterData = true;
    // very important to deal to understand the data comes out
    // in the same order it was put in.
    forkJoin(
      this.auditTypeService.getAuditTypeListItems(),
      this.auditStatusService.getAuditStatusListItems(),
      this.auditSpecialistService.getAuditSpecialistListItems(),
      this.auditCarrierService.getAuditCarrierListItems(),
      this.auditVendorService.getAuditVendorListItems(),
      this.deliveryMethodService.getDeliverMethods(),
      this.auditClassService.getAuditClassListItems()
    )
      .subscribe(([
        respAuditTypes,
        respAuditStatuses,
        respAuditSpecialists,
        respAuditCarriers,
        respAuditVendors,
        respDeliveryMethods,
        resAuditClasses,
      ]) => {
        this.auditTypesListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditTypes);
        this.auditStatusListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditStatuses);
        this.auditSpecialistListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditSpecialists);
        this.auditCarrierListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditCarriers);
        this.auditVendorListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditVendors);
        this.deliveryMethodListItems = this.uiHelperService.addEmptyFirstRowToListItems(respDeliveryMethods);
        this.auditClassListItems = this.uiHelperService.addEmptyFirstRowToListItems(resAuditClasses);
        // set flag to enable ui controls
        this.loadingMasterData = false; // we can enable ui components when false
        this.loadSearchFromUrl();
      });
  }

  loadSearchFromUrl() {
    const carrierNameForSearch = this.route.snapshot.params.carrierName;
    const typeNameForSearch = this.route.snapshot.params.typeName;
    if (carrierNameForSearch) {
      // find model in list
      this.auditCarrierListItems.forEach(value => {
        if (value.label === carrierNameForSearch) {
          this.carrierIdForSearch = value.value;
        }
      });
      // load the carrier name into the carrier dropdown
      this.auditSearchFormGroup.controls['auditCarrierId'].setValue(this.carrierIdForSearch);
      // initiate search
      this.search(this.default_page, false);
    }
    if (typeNameForSearch) {
      // find model in list
      this.auditTypesListItems.forEach(value => {
        if (value.label === typeNameForSearch) {
          this.typeIdForSearch = value.value;
        }
      });
      // load the carrier name into the carrier dropdown
      this.auditSearchFormGroup.controls['auditTypeId'].setValue(this.typeIdForSearch);
      // initiate search
      this.search(this.default_page, false);
    }
  }

  /************** Validation **************************/
  invalidNotificationDates() {
    this.notificatonDatesInvalid = ((this.auditSearchFormGroup.controls['notificationStartDate'].touched
      || this.auditSearchFormGroup.controls['notificationEndDate'].touched)
      && (
        this.auditSearchFormGroup.controls['notificationStartDate'].hasError('invalidDateOrder')
        || this.auditSearchFormGroup.controls['notificationEndDate'].hasError('invalidDateOrder')));

    return this.notificatonDatesInvalid;
  }
  invalidReceivedDates() {
    this.receivedDatesInvalid = ((this.auditSearchFormGroup.controls['receivedStartDate'].touched
      || this.auditSearchFormGroup.controls['receivedEndDate'].touched)
      && (
        this.auditSearchFormGroup.controls['receivedStartDate'].hasError('invalidDateOrder')
        || this.auditSearchFormGroup.controls['receivedEndDate'].hasError('invalidDateOrder')));

    return this.receivedDatesInvalid;
  }
  invalidDueDates() {
    this.dueDatesInvalid = ((this.auditSearchFormGroup.controls['dueStartDate'].touched
      || this.auditSearchFormGroup.controls['dueEndDate'].touched)
      && (
        this.auditSearchFormGroup.controls['dueStartDate'].hasError('invalidDateOrder')
        || this.auditSearchFormGroup.controls['dueEndDate'].hasError('invalidDateOrder')));
    return this.dueDatesInvalid;
  }
  invalidFillDates() {
    const fillDatesTouched = (this.auditSearchFormGroup.controls['rxFromFillDate'].touched
    || this.auditSearchFormGroup.controls['rxToFillDate'].touched);
    const fillDatesErrors = (this.auditSearchFormGroup.controls['rxFromFillDate'].hasError('invalidDateOrder')
      || this.auditSearchFormGroup.controls['rxToFillDate'].hasError('invalidDateOrder'));
    const rollup = fillDatesTouched && fillDatesErrors;
    this.fillDatesInvalid = rollup;
    return rollup;
  }
  datesPairsAreValid() {
    return (!this.notificatonDatesInvalid &&
            !this.receivedDatesInvalid &&
            !this.dueDatesInvalid &&
            !this.fillDatesInvalid);
  }

  // search button helper to enable / disable the search button
  formIsInvalid(): boolean {
    return (!this.datesPairsAreValid() || this.auditSearchFormGroup.invalid);
  }

  tagLengthInvalid() {
    return (
      !this.auditSearchFormGroup.controls['tag'].valid
      && this.auditSearchFormGroup.controls['tag'].dirty
      && this.auditSearchFormGroup.controls['tag'].errors['maxlength']);
  }

  claimControlNumberLengthInvalid() {
    return (
      !this.auditSearchFormGroup.controls['claimControlNumber'].valid
      && this.auditSearchFormGroup.controls['claimControlNumber'].dirty
      && this.auditSearchFormGroup.controls['claimControlNumber'].errors['maxlength']);
  }
  /************** End Validation *************/

  // table functions start
  onLazyLoad(event: LazyLoadEvent): void {
    if (!this.loadingSearchResults) {
      this.search(
        new Pagination(MAX_ROWS, event.first, event.sortField, event.sortOrder), false);
    }
  }

  search(pagination: Pagination, resetTable: boolean) {
    const resultCountLimit = 99999;
    this.auditSearchResults = [];
    const mySearchRequest = this.getAuditSearchModel();

    this.showSearchResults = true;
    this.loadingSearchResults = true;

    // store search in session storage for reuse when returning to search screen.
    sessionStorage.setItem(this.STORAGE_ITEM, JSON.stringify(mySearchRequest));

    this.fillEmptyDates(mySearchRequest); // sets empty date values
    this.loadFormGroupAfterSearch(mySearchRequest); // rebind data for display (dates get updated in view)

    if (resetTable) {
      this.resetTable();
    }

    this.auditService.performAuditSearch(mySearchRequest, pagination)
      .subscribe(
        (data: ILimitedResult<AuditSearchResult>) => {
          this.auditSearchResults = data.Items;
          this.loadingSearchResults = false;
          this.totalItemCount = data.TotalItemCount;
        },
        // handle error state
        () => {
          this.loadingSearchResults = false;
          toastr.error('Error loading data from server.');
      });
  }

  // rebind data for display (so dates get updated in view)
  loadFormGroupAfterSearch(mySearchRequest: AuditSearchRequest) {
    this.auditSearchFormGroup.reset({
      auditReferenceNumber: mySearchRequest.AuditReferenceNumber,
      auditNumber: mySearchRequest.AuditNumber,
      auditTypeId: mySearchRequest.AuditTypeId,
      auditStatusId: mySearchRequest.AuditStatusId,
      notificationStartDate: mySearchRequest.StartNotificationDate ? new Date(mySearchRequest.StartNotificationDate) : '',
      receivedStartDate: mySearchRequest.StartReceiveDate ? new Date(mySearchRequest.StartReceiveDate) : '',
      dueStartDate: mySearchRequest.StartAuditDueDate ? new Date(mySearchRequest.StartAuditDueDate) : '',
      notificationEndDate: mySearchRequest.EndNotificationDate ? new Date(mySearchRequest.EndNotificationDate) : '',
      receivedEndDate: mySearchRequest.EndReceiveDate ? new Date(mySearchRequest.EndReceiveDate) : '',
      dueEndDate: mySearchRequest.EndAuditDueDate ? new Date(mySearchRequest.EndAuditDueDate) : '',
      bin: mySearchRequest.Payer_ProcessorIdentifier,
      // this is a hack to get around the store getter, which returns undefined for some reason
      store: mySearchRequest.Location_NCPDPProviderIdentifier,
      // this is a hack to get around the drug getter, which returns undefined for some reason
      drug: mySearchRequest.Drug_NationalDrugCode,
      tag: mySearchRequest.Tag,
      rxNumber: mySearchRequest.Prescription_PrescriptionNumber,
      rxFromFillDate: mySearchRequest.Prescription_FromFillDate ? new Date(mySearchRequest.Prescription_FromFillDate) : '',
      rxToFillDate: mySearchRequest.Prescription_ToFillDate ? new Date(mySearchRequest.Prescription_ToFillDate) : '',
      specialistId: mySearchRequest.SpecialistId,
      auditCarrierId: mySearchRequest.AuditCarrierId,
      vendorId: mySearchRequest.VendorId,
      deliveryMethod: mySearchRequest.DeliveryMethod,
      auditClassId: mySearchRequest.AuditClassId,
      claimControlNumber: mySearchRequest.Patient_ClaimControlNumber
    });
  }

  fillEmptyDates(request: AuditSearchRequest) {
    const startNotificationDate = request.StartNotificationDate;
    const endNotificationDate = request.EndNotificationDate;

    const startReceiveDate = request.StartReceiveDate;
    const endReceiveDate = request.EndReceiveDate;

    const startAuditDueDate = request.StartAuditDueDate;
    const endAuditDueDate = request.EndAuditDueDate;

    // set default dates if dates left blank
    // Blank End dates will be 10 years after start date
    // Blank Start dates will be 10 years before end date
    if (!startNotificationDate && endNotificationDate) {
      request.StartNotificationDate = moment(endNotificationDate).add(-10, 'years').toDate();
    }
    if (startNotificationDate && !endNotificationDate) {
      request.EndNotificationDate = moment(startNotificationDate).add(10, 'years').toDate();
    }

    if (!startReceiveDate && endReceiveDate) {
      request.StartReceiveDate = moment(endReceiveDate).add(-10, 'years').toDate();
    }
    if (startReceiveDate && !endReceiveDate) {
      request.EndReceiveDate = moment(startReceiveDate).add(10, 'years').toDate();
    }

    if (!startAuditDueDate && endAuditDueDate) {
      request.StartAuditDueDate = moment(endAuditDueDate).add(-10, 'years').toDate();
    }
    if (startAuditDueDate && !endAuditDueDate) {
      request.EndAuditDueDate = moment(startAuditDueDate).add(10, 'years').toDate();
    }
  }

  getAuditSearchModel(): AuditSearchRequest {
    const mySearch = new AuditSearchRequest();
    const controls = this.auditSearchFormGroup.controls;
    mySearch.AuditReferenceNumber = controls['auditReferenceNumber'].value;
    mySearch.AuditNumber = controls['auditNumber'].value;
    mySearch.AuditTypeId = controls['auditTypeId'].value;
    mySearch.StartNotificationDate = controls['notificationStartDate'].value;
    mySearch.StartReceiveDate = controls['receivedStartDate'].value;
    mySearch.StartAuditDueDate = controls['dueStartDate'].value;
    mySearch.EndNotificationDate = controls['notificationEndDate'].value;
    mySearch.EndReceiveDate = controls['receivedEndDate'].value;
    mySearch.EndAuditDueDate = controls['dueEndDate'].value;
    mySearch.AuditStatusId = controls['auditStatusId'].value;
    mySearch.Payer_ProcessorIdentifier = controls['bin'].value;
    mySearch.Store = controls['store'].value;
    mySearch.Drug = controls['drug'].value;
    mySearch.Tag = controls['tag'].value;
    mySearch.Prescription_PrescriptionNumber = controls['rxNumber'].value;
    mySearch.Prescription_FromFillDate = controls['rxFromFillDate'].value;
    mySearch.Prescription_ToFillDate = controls['rxToFillDate'].value;
    mySearch.SpecialistId = controls['specialistId'].value;
    mySearch.AuditCarrierId = controls['auditCarrierId'].value;
    mySearch.VendorId = controls['vendorId'].value;
    mySearch.DeliveryMethod = controls['deliveryMethod'].value;
    mySearch.AuditClassId = controls['auditClassId'].value;
    mySearch.Patient_ClaimControlNumber = controls['claimControlNumber'].value;
    return mySearch;
  }

  // used by grid to load name from id
  getAuditTypeName(id: string) {
    const result = _.find(this.auditTypesListItems, (auditType) => {
      return auditType.value === id;
    });
    if (result) {
      return result.label;
    } else {
      return 'Invalid Type';
    }
  }

  // subscribe to delete so we can show primeNg confirmation dialog
  confirmDelete(field) {
    this.showDeleteDialog = true;
    this.refId = field.AuditId;
    this.deleteConfirmationMessage =
      [`Are you sure that you want to delete <strong>${field.AuditReferenceNumber}</strong>`,
        ` and all of its associated Audit Claims?`].join('');
  }

  delete(event: ModalAction) {
    if (event.action === 'save') {
      let success: boolean;
      this.showDeleteDialog = false;
      this.auditService.deleteAudit(this.refId)
        .subscribe((value) => {
          success = value;
          !success ? toastr.error(this.deleteToastrMessage) : toastr.success('Delete successful!');
          this.resetTable();
        });
    }
    this.showDeleteDialog = false;
  }

}


