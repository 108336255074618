<p-dialog 
  header="{{titleText}}" 
  [(visible)]="show"
  [modal]="true"
  [style]="{'margin': '5px'}"
  [closable]="false" 
  [minHeight]="200" 
  (onShow)="onShow()" 
  (onHide)="onHide()">
  <div>
    <label>
      <div class="inm-label--required">Name:</div>
      <input #name class="inm-input" style="width: 420px" [(ngModel)]="nameValueInternal" (input)="limit('nameChange')" (keydown.enter)="submitClick('save')">
    </label>
    <div [hidden]='!showCode'>
      <div class="inm-label--required">Code:</div>
      <input #code [disabled]="editCode" class="inm-input" style="width: 75px" [(ngModel)]="codeValueInternal" (input)="limit('codeChange')" (keydown.enter)="submitClick('save')">
    </div>
    <div [hidden]='!showDisplayOnWorkflow'>
      <div class="inm-label">Display On Workflow:</div>
      <p-checkbox [(ngModel)]="displayOnWorkflowValueInternal" binary="true" (onChange)="checkSubmitEnabled()"></p-checkbox>
    </div>
  </div>
  <div class="btn-container-dialog" style="padding-top: 14px">
    <button #save type="button" class="inm-button btn-padding" [disabled]="submitDisabled" (click)="submitClick('save')">Save</button>
    <button type="button" class="inm-button btn-padding" (click)="submitClick('cancel')">Cancel</button>
  </div>
</p-dialog>