import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AssociateClaimToAudit,
  AuditSearchRequest,
  AuditSearchResult,
  LimitedResult,
  ILimitedResult,
  Audit,
  AuditClaim,
  ClaimSearchRequest,
  ClaimSearchResult,
  Pagination,
  AuditClaimDetail,
  AuditSummary
} from '../models';

import { Observable } from 'rxjs';
import * as toastr from 'toastr';
import { environment } from '../../environments/environment';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { AuditByStatusesFilter } from '../models/workflow/audit-by-statuses-filter';
import { AuditByStatusesResult } from '../models/workflow/audit-by-statuses-results';
import { AuditWorkflowSearchFilter } from '../models/workflow/audit-workflow-search-filter';
import { AuditWorkflowSearchResult } from '../models/workflow/audit-workflow-search-result';
import { AuditStatusHistoryResult } from '../models/audit-status-history-result';

const BASE_URL = `${environment.auditApiRoot}/clients`;

@Injectable({
  providedIn: 'root'
})

export class AuditService {
  private readonly Timeout: number = 30000;
  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) {
  }

  public getAudit(id: number): Observable<Audit> {
    return this.http.get<Audit>(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/Audits/${id}`,
      { headers: this.httpUtility.getHeaders() }
    );
  }

  public getWorkflowSummary(auditByStatusesFilter: AuditByStatusesFilter): Observable<AuditByStatusesResult[]> {
    const headers = this.httpUtility.getHeaders();
    return this.http.post<AuditByStatusesResult[]>(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/WorkflowSummary/`,
      auditByStatusesFilter,
      { headers }
    );
  }

  public getAuditSummary(pagination: Pagination, attributeName: string): Observable<ILimitedResult<AuditSummary>> {
    const params = pagination.buildSearchParams();
    const headers = this.httpUtility.getHeaders();
    return this.http.get<ILimitedResult<AuditSummary>>(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditSummary?byAttribute=${attributeName}`,
      { headers, params }
    );
  }

  public SearchAuditWorkflowByStatuses(
        filter: AuditWorkflowSearchFilter,
        pagination: Pagination): Observable<ILimitedResult<AuditWorkflowSearchResult>> {
    const headers = this.httpUtility.getHeaders();
    const params = pagination.buildSearchParams();
    return this.http.post<ILimitedResult<AuditWorkflowSearchResult>>(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/SearchAuditWorkflowByStatuses/`,
      filter,
      { headers, params }
    );
  }

  // todo: refactor to generic base impl
  public performAuditSearch(auditSearchModel: AuditSearchRequest, pagination: Pagination):
    Observable<ILimitedResult<AuditSearchResult>> {
    const params = pagination.buildSearchParams();
    const headers = this.httpUtility.getHeaders();
    return this.http.post<LimitedResult<AuditSearchResult>>(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/SearchAudits/`,
      auditSearchModel,
      { headers, params }
    );
  }

  public addAudit(audit: Audit): Observable<Audit> {
    const url = `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/Audits`;
    return this.http.post<Audit>(
      url, audit);
  }

  public updateAudit(audit: Audit): Observable<Audit> {
    const url = `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/Audits`;
    return this.http.put<Audit>(
      url, audit);
  }

  public async performUpdateAudit(id: number, audit: Audit): Promise<any> {
    const token = this.httpUtility.getHeaders();
    return await this.http.post(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/Audit/UpdateAudit/${id}`,
      audit,
      { headers: this.httpUtility.getHeaders() }
    ).toPromise()
      .catch((error) => {
        toastr.error('Unable to Update Audit. Please check fields and try again');
      });
  }

  public performAuditClaimSearch(auditId: number): Observable<LimitedResult<AuditClaim>> {
    const url = `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/Audits/${auditId}/Claims`;
    return this.http.get<LimitedResult<AuditClaim>>(url);
  }

  public async performClaimSearch(claimSearchModel: ClaimSearchRequest): Promise<LimitedResult<ClaimSearchResult>> {
    return await this.http.post<LimitedResult<ClaimSearchResult>>(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/Claim/Search`,
      claimSearchModel,
      { headers: this.httpUtility.getHeaders() }
    ).toPromise();
  }

  public async deleteClaim(auditClaimId: number): Promise<any> {
    return await this.http.post(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/Audit/DeleteClaim/${auditClaimId}`,
      auditClaimId,
      { headers: this.httpUtility.getHeaders() }
    ).toPromise();
  }

  public addClaimsToAudit(auditId: number, claimsToAssociate: AssociateClaimToAudit[]): Observable<boolean> {
    const url = `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/Audits/${auditId}/Claims`;
    return this.http.put<boolean>(url, claimsToAssociate);
  }

  // public addClaimsToAudit(auditId: number, claimsToAdd: ClaimSearchResult[]): Observable<boolean> {
  //   const url =  `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/Audits/${auditId}/Claims`;
  //   return this.http.put<boolean>(url, claimsToAdd);
  // }
  public deleteAudit(auditId: number): Observable<boolean> {
    const url =
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/Audits/${auditId}`;
    return this.http.delete<boolean>(url);
  }

  public generateClaimsTextFile(auditId: number): Observable<Response> {
    const url = `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/Audits/${auditId}/GenerateAuditClaimsFiles`;
    const headers = new HttpHeaders();
    return this.http.post<Response>(url, {headers});

  }

  postFile(key: any, file: File): Observable<Response> {
    const url = `${BASE_URL}/${this.globalFilterService.selectedClientId}/Audits/${key.auditId}/UploadClaims`;
    const formData: FormData = new FormData();
    formData.append('claimFile', file, file.name);
    return this.http.post<Response>(url, formData);
  }
    
  public getAuditStatusHistory(auditId: number, pagination: Pagination): Observable<ILimitedResult<AuditStatusHistoryResult>> {
    const params = pagination.buildSearchParams();
    const headers = this.httpUtility.getHeaders();
    return this.http.get<ILimitedResult<AuditStatusHistoryResult>>(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/Audits/${auditId}/StatusHistory`,
      { headers, params }
    );
  }


  postManualClaimFile(file: File): Observable<Response> {
    const url = `${BASE_URL}/${this.globalFilterService.selectedClientId}/UploadManualClaims`;
    const formData: FormData = new FormData();
    formData.append('claimsFile', file, file.name);
    return this.http.post<Response>(url, formData);
  }

}
