export class AuditClass {
    AuditClassId: number;
    Name: string;
    ImageId: string;
    TemplateName: string;
    InUse: boolean;
    ModifiedBy: string;
    ModifiedDate: Date;
    CreatedBy: string;
    CreateDate: Date;
}
