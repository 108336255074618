<h2>Fax Status</h2>
<p style="font-style: italic;color:orangered;padding:2px; ;">
  <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
  Page count will be displayed when status is Complete.
</p>
<app-audit-toolbar [toolbarItemList]="toolbarHelper.currentToolbar" (OnClick)="toolbarBtnClick($event)"></app-audit-toolbar>
<p-table #dt [columns]="faxStatusTableHelper.faxStatusColumns" [value]="faxes" 
(onLazyLoad)="loadDataLazy($event)"
[loading]="typeDataLoading" loading="fa-spinner" [scrollable]="true" 
[lazy]="true" [responsive]="true" [rows]="100"
>  
<ng-template pTemplate="header" let-columns>
  <tr>
    <th *ngFor="let col of columns"
    [ngClass]="col.hdrClass? col.hdrClass : 'default'"
    >
      {{col.header}}
  </th>
  </tr>
</ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns"> 
    <tr [ngClass]="{'newStatus': rowData.TransmissionStatus.toLowerCase() === 'new', 'errorStatus': rowData.TransmissionStatus.toLowerCase() === 'error', 
        'inProgressStatus': rowData.TransmissionStatus.toLowerCase() === 'in progress'}">
      <td *ngFor="let col of columns" [ngClass]="col.fldClass? col.fldClass : 'default'">
        <div *ngIf="col.field === 'ReceiverName'">
          {{rowData[col.field]}}
        </div>
        <div *ngIf="col.field === 'DestinationFaxNumber'">
          {{rowData[col.field] | customPhonePipe}}
        </div>
        <div *ngIf="col.field === 'Pages'">
          {{rowData[col.field]}}
        </div>
        <div *ngIf="col.field === 'TransmissionStatus'">
          {{rowData[col.field] | titlecase}} 
        </div>
        <div *ngIf="col.field === 'CompletedTimestamp'" style=" text-align: center">
          {{rowData[col.field] | date: 'MM/dd/yyyy hh:mm aa'}}
        </div> 
      </td> 
    </tr>

  </ng-template>
  <ng-template pTemplate="summary" let-columns>
    <tr style="display: flex; align-items: center; justify-content: center">
      <td colspan ="5">
        <div class="ui-paginator-bottom ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix ng-star-inserted" ng-reflect-klass="ui-paginator-bottom" ng-reflect-ng-class="ui-paginator ui-widget ui-widg">
     
        <button id="previousButton"  [ngClass]="getPrevButtonClass()" 
                                     ng-reflect-klass="ui-paginator-prev ui-paginator" 
                                     (click)="previousButtonClicked()" 
                                     [disabled]="previousButtonDisabled">
            <span class="ui-paginator-icon pi pi-caret-left"></span> 
        </button> 
        <span class="ui-paginator-pages">
          <button class="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active ng-star-inserted" (click)="refresh()" ng-reflect-klass="ui-paginator-page ui-paginator">{{pageNumber}}</button> 
        </span>
        <button id="nextButton" [ngClass]="getNextButtonClass()" 
                                ng-reflect-klass="ui-paginator-next ui-paginator"  
                                (click)="nextButtonClicked()" 
                                [disabled]="nextButtonDisabled"> 
          <span class="ui-paginator-icon pi pi-caret-right"></span>  
        </button>

        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td colspan ="5" style=" text-align: center">
        No records found
      </td>
    </tr>
  </ng-template>
</p-table>