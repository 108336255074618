<p-panel #content [showHeader]="false" styleClass="panel-fix">
  <p-progressBar mode="indeterminate" *ngIf="loadingClaimDetails"></p-progressBar>
  <div id="main">
    <div class='inm-wrapper'>
      <div class="single-line">
        <div class="left-side">
          <div class="inm-label">RX Number: </div>
          <label class="inm-input" id="rxNumber">{{claimDetail.Prescription_PrescriptionNumber}}</label>
        </div>
        <div class="right-side">
          <div class="inm-label">Initial Risk: </div>
          <label class="inm-input">{{claimDetail.InitialRisk | currency:'USD':'symbol':'1.2-2' | customCurrencyPipe}}</label>
        </div>
      </div>
      <div class="single-line">
        <div class="left-side">
          <div class="inm-label">Fill Date: </div>
          <label class="inm-input">{{claimDetail.Prescription_FillDate | date:'MM/dd/yyyy'}}</label>
        </div>
        <div class="right-side">
          <div class="inm-label">Approved Recoupment: </div>
          <label class="inm-input">{{claimDetail.ApprovedRecoupmentAmount | currency:'USD':'symbol':'1.2-2' | customCurrencyPipe}}</label>
        </div>
      </div>
      <div class="single-line">
        <div class="left-side">
          <div class="inm-label">To Fill Date: </div>
          <label class="inm-input">{{claimDetail.Prescription_ToFillDate | date:'MM/dd/yyyy'}}</label>
        </div>
        <div class="right-side">
          <div class="inm-label">Copay: </div>
          <label class="inm-input">{{claimDetail.Money_CopayAmountClaim | currency:'USD':'symbol':'1.2-2' | customCurrencyPipe}}</label>
        </div>
      </div>
      <div class="single-line">
        <div class="left-side">
          <div class="inm-label">COB #: </div>
          <label class="inm-input">{{claimDetail.Prescription_SplitBillCode}}</label>
        </div>
        <div class="right-side">
          <div class="inm-label">Patient Name: </div>
          <label class="inm-input">{{claimDetail.Patient_FullName}}</label>
        </div>
      </div>
      <div class="single-line">
        <div class="left-side">
          <div class="inm-label">Store #: </div>
          <label class="inm-input">{{claimDetail.Location_CorporateLocationNumber}}</label>
        </div>
        <div class="right-side">
          <div class="inm-label">Patient DOB: </div>
          <label class="inm-input">{{claimDetail.Patient_DateOfBirth | date:'MM/dd/yyyy'}}</label>
        </div>
      </div>
      <div class="single-line">
        <div class="left-side">
          <div class="inm-label">NCPDP: </div>
          <label class="inm-input">{{claimDetail.Location_NCPDPProviderIdentifier}}</label>
        </div>
        <div class="right-side">
          <div class="inm-label">Claim Control Number: </div>
          <label class="inm-input">{{claimDetail.Patient_ClaimControlNumber}}</label>
        </div>
      </div>
      <div class="single-line">
        <div class="left-side">
          <div class="inm-label">NPI: </div>
          <label class="inm-input">{{claimDetail.Location_NationalProviderIdentifier}}</label>
        </div>
        <div class="right-side">
          <div class="inm-label">Medicare Beneficiary ID: </div>
          <label class="inm-input">{{claimDetail.Patient_MedicareBeneficiaryIdentifierNumber}}</label>
        </div>
      </div>
      <div class="single-line">
        <div class="left-side">
          <div class="inm-label">Audit Vendor: </div>
          <label class="inm-input">{{claimDetail.VendorName}}</label>
        </div>
        <div class="right-side">
          <div class="inm-label">HCPCS: </div>
          <label class="inm-input">{{claimDetail.Drug_HCPCSCode1}}</label>
        </div>
      </div>
      <div class="single-line">
        <div class="left-side">
          <div class="inm-label">Bin/Carrier Name: </div>
          <label class="inm-input">{{claimDetail.Payer_ProcessorIdentifier}}/{{claimDetail.Payer_ShardPayerName}}</label>
        </div>
        <div class="right-side">
          <div class="inm-label">Processor Control #: </div>
          <label class="inm-input">{{claimDetail.Payer_ProcessorControlNumber}}</label>
        </div>
      </div>
      <div class="single-line">
        <div class="left-side">
          <div class="inm-label">Days Supply: </div>
          <label class="inm-input">{{claimDetail.Prescription_DaysSupply}}</label>
        </div>
        <div class="right-side">
          <div class="inm-label">Prescriber Name: </div>
          <label *ngIf="claimDetail.ServiceProvider_PrescriberFirstName || claimDetail.ServiceProvider_PrescriberLastName" class="inm-input">{{claimDetail.ServiceProvider_PrescriberFirstName}}, {{claimDetail.ServiceProvider_PrescriberLastName}}</label>
          <label *ngIf="!claimDetail.ServiceProvider_PrescriberFirstName && !claimDetail.ServiceProvider_PrescriberLastName" class="inm-input"></label>
        </div>
      </div>
      <div class="single-line">
        <div class="left-side">
          <div class="inm-label">Quantity Dispensed: </div>
          <label class="inm-input">{{claimDetail.Prescription_DispensedQuantity}}</label>
        </div>
        <div class="right-side">
          <div class="inm-label">Prescriber Fax Number: </div>
          <label class="inm-input">{{claimDetail.ServiceProvider_PrescriberFaxNumber}}</label>
        </div>
      </div>
    </div>
    <div class="table" *ngIf="hasShowDiscrepancyCodesFeature()">
      <p-table #discrepancyCodeTable
      id="discrepancyCodeTable"
      [columns]="tableHelper.discrepancyCodeColumns"
      [value]="auditClaimDiscrepancyCodes"
      [loading]="loadingDiscrepancyCodeGrid"
      [lazy]="true"
      [responsive]="true"
      [scrollable]="true"
      scrollHeight="80px"
      (onLazyLoad)="loadDiscrepancyCodesLazy($event)">
      <!-- colgroup template is added to fix the bug of scroolable p-table columns misalignment with header -->
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width">
        </colgroup>
      </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr class="discrepancyCodeCols">
            <th *ngFor="let col of columns" 
              [ngClass]="col.hdrClass ? col.hdrClass : 'default'" 
              [pSortableColumn]="col.sort === false ? false : col.field">
              {{col.header}} 
              <p-sortIcon *ngIf="col.sort !== false" [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns" [ngClass]="col.fldClass ? col.fldClass : 'default'">
              <div *ngIf="col.field === 'Code'">
                  {{rowData[col.field]}}
              </div> 
              <div *ngIf="col.field === 'Name'">
                  {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'CreatedBy'">
                  {{rowData[col.field]}}
              </div> 
              <div *ngIf="col.field === 'CreateDate'">
                  {{ rowData[col.field] | date:'MM/dd/yyyy' }}
              </div> 
              <div *ngIf="col.field === 'Delete'">
                <a (click)="confirmDiscrepancyCodeDelete(rowData)" style="cursor: pointer; width: 20px;">
                    <svg class="inm-icon" aria-label="Delete">
                      <use xlink:href="#inm-icon__trash" class="danger" /></svg>
                </a>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan ="7" style=" text-align: center">
              No records found
            </td>
          </tr>
        </ng-template>        
      </p-table>
    </div>
    <div class="table" *ngIf="hasShowTagsFeature()">
      <p-table #tagTable
      id="tagTable"
      [columns]="tableHelper.tagColumns"
      [value]="auditClaimTags"
      [loading]="loadingTagsGrid"
      [lazy]="true"
      [responsive]="true"
      [scrollable]="true"
      scrollHeight="80px"
      (onLazyLoad)="loadTagsLazy($event)">
      <!-- colgroup template is added to fix the bug of scrollable p-table columns misalignment with header -->
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width">
        </colgroup>
      </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr class="tagCols">
            <th *ngFor="let col of columns" 
              [ngClass]="col.hdrClass ? col.hdrClass : 'default'" 
              [pSortableColumn]="col.sort === false ? false : col.field">
              {{col.header}} 
              <p-sortIcon *ngIf="col.sort !== false" [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns" 
              [ngClass]="col.fldClass ? col.fldClass : 'default'">
              <div *ngIf="col.field === 'TagCode'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'TagDesc'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'UserID'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'CreateDate'">
                {{ rowData[col.field] | date:'MM/dd/yyyy' }}
              </div>
              <div *ngIf="col.field === 'Delete'">
                <a (click)="confirmTagDelete(rowData)" style="cursor: pointer; width: 20px;">
                  <svg class="inm-icon" aria-label="Delete">
                    <use xlink:href="#inm-icon__trash" class="danger" />
                  </svg>
                </a>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan="7" style="text-align: center;">
              No records found
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="table" *ngIf="hasShowNotesFeature()">
      <p-table #noteTable
      id="noteTable"
      [columns]="tableHelper.noteColumns"
      [value]="auditClaimNotes"
      [loading]="loadingNotesGrid"
      [lazy]="true"
      [scrollable]="true"
      scrollHeight="80px"
      (onLazyLoad)="loadNotesLazy($event)">
      <!-- colgroup template is added to fix the bug of scrollable p-table columns misalignment with header -->
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width">
        </colgroup>
      </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr class="noteCols">
            <th *ngFor="let col of columns"
            [ngClass]="col.hdrClass ? col.hdrClass : 'default'"
            [pSortableColumn]="col.sort === false ? false : col.field">
              {{col.header}}
              <p-sortIcon *ngIf="col.sort !== false" [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns" [ngClass]="col.fldClass ? col.fldClass : 'default'">
              <div *ngIf="col.field === 'NoteText'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'UserID'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'Tmstmp'">
                {{ rowData[col.field] | date:'MM/dd/yyyy' }}
              </div>
              <div *ngIf="col.field === 'Delete'">
                <a (click)="confirmNoteDelete(rowData)" style="cursor: pointer; width: 20px;">
                  <svg class="inm-icon" aria-label="Delete">
                    <use xlink:href="#inm-icon__trash" class="danger" />
                  </svg>
                </a>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan="7" style="text-align: center;">
              No records found
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-panel>
<app-delete-dialog  
[show]="showDeleteDialog" 
[titleText]="deleteTitle"
[message]="deleteMessage"
(modalAction)="delete($event)">
</app-delete-dialog>