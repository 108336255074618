import { Injectable } from "@angular/core";
import * as detectie from 'detectie';
import * as moment from 'moment';


const PATTERN = '^[0-9]{7}[-][a-zA-Z0-9]{1,14}[-](?=[0-9]{8})(?<!\\d)(?:(?:(?:1[6-9]|[2-9]\\d)?\\d{2})(?:(?:(?:0[13578]|1[02])31)|(?:(?:0[1,3-9]|1[0-2])(?:29|30)))|(?:(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))0229)|(?:(?:1[6-9]|[2-9]\\d)?\\d{2})(?:(?:0?[1-9])|(?:1[0-2]))(?:0?[1-9]|1\\d|2[0-8]))(?!\\d)$'
const IE_PATTERN = /^[0-9]{7}[-][a-zA-Z0-9]{1,14}[-][0-9]{8}$/
@Injectable()
export class SEIDValidator {

  public readonly isIE: any;
  constructor(){
    this.isIE = detectie();
  }

  validateSEIDArray(seids: string[]): string[] {
    var badIds: string[] = [];
    var regexPattern: any;

    // to support IE
    if(this.isIE){
      regexPattern = IE_PATTERN;
    } else {
      regexPattern = PATTERN;
    }
     seids.forEach( (id: string) => {
       if(!id && !(id.length > 0 )){
         return;
       }
      let cleanId = id.replace(',', '').trim();
      let valid: boolean;
      if(this.isIE){
        valid = (regexPattern.test(cleanId) && this.ValidateDate(cleanId));
      } else {
        valid = RegExp(regexPattern).test(cleanId);
      }
      
      if(valid === false){
        badIds.push(cleanId);
      }
    });

    return badIds;
  }

  ValidateDate(seid: string) {
    let arrayParts = seid.split('-');
    if(arrayParts.length = 3) {
      return moment(arrayParts[2]).isValid();
    }
  }
}