import { ITableColumn } from 'src/app/models/tColumn';

export class ClaimDetailTableHelper {
  public discrepancyCodeColumns: ITableColumn[];
  public tagColumns: ITableColumn[];
  public noteColumns: ITableColumn[];
  constructor() {
    this.buildColumns();
  }

  buildColumns(): void {
    this.discrepancyCodeColumns = [
      { field: 'Code', header: 'Discrepancy Code', sort: true },
      { field: 'Name', header: 'Description', sort: true },
      { field: 'CreatedBy', header: 'User', sort: true },
      { field: 'CreateDate', header: 'Last Modified', sort: true },
      { field: 'Delete', header: '', hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn', sort: false }
    ];

    this.noteColumns = [
      { field: 'NoteText', header: 'Note', sort: true },
      { field: 'UserID', header: 'User', sort: true },
      { field: 'Tmstmp', header: 'Last Modified', sort: true },
      { field: 'Delete', header: '', hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn', sort: false }
    ];

    this.tagColumns = [
      { field: 'TagCode', header: 'Tag Code', sort: true },
      { field: 'TagDesc', header: 'Description', sort: true },
      { field: 'UserID', header: 'User', sort: true },
      { field: 'CreateDate', header: 'Last Modified', sort: true },
      { field: 'Delete', header: '', hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn', sort: false }
    ];
  }
}
