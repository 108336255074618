import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { AuditVendor, LimitedResult, PrimeNgListItem, ILimitedResult, Pagination } from '../models';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessResponse } from 'src/app/models/businessResponse';
@Injectable({
  providedIn: 'root'
})
export class AuditVendorService {
  private readonly base_url = `${environment.auditApiRoot}/clients`;
  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) { }
  public async getAuditVendors(): Promise<LimitedResult<AuditVendor>> {
    return await this.http.get<LimitedResult<AuditVendor>>(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditVendors`,
      { headers: this.httpUtility.getHeaders() }
    ).toPromise();
  }

  public getAuditVendorsLimitedResult(pagination: Pagination): Observable<ILimitedResult<AuditVendor>> {
    const params = pagination.buildSearchParams();
    return this.http.get<ILimitedResult<AuditVendor>>(
      `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditVendors`, {params});
  }

  public getAuditVendorListItems(): Observable<PrimeNgListItem[]> {
    return this.http.get(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditVendors/?limit=${environment.defaultListLimit}`,
      { headers: this.httpUtility.getHeaders() }
    )
    .pipe(
      map(res => res['Items'].map(item => new PrimeNgListItem(item.Name, item.VendorId))));
  }

  public addAuditVendor(name: string): Observable<BusinessResponse> {
    const url = `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditVendors`;
    return this.http.post<BusinessResponse>(url, { Name: name });
  }

  public updateAuditVendor(vendorId: number, name: string): Observable<BusinessResponse> {
    const url = `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditVendors`;
    return this.http.put<BusinessResponse>(url, {VendorId: vendorId, Name: name});
  }

  public deleteAuditVendor(specialistId: number): Observable<Response> {
    const url = `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditVendors/${specialistId}`;
    return this.http.delete<Response>(url);
  }
}
