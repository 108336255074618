import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { LimitedResult, PrimeNgListItem, StatusModel, Pagination, ILimitedResult, AuditSummary} from '../models';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuditType } from 'src/app/models/audit-type';
import { BusinessResponse } from 'src/app/models/businessResponse';
import { AuditAddressBook } from '../models/audit-address-book';

@Injectable({
  providedIn: 'root'
})
export class AuditAddressBookService {
  private readonly apiRoot = `${environment.auditApiRoot}`;

  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) { }

  public getAuditAddressBookLimitedResult(pagination: Pagination): Observable<ILimitedResult<AuditAddressBook>> {
    const params = pagination.buildSearchParams();
    return this.http.get<ILimitedResult<AuditAddressBook>>(
      `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditAddressBooks`, {params});
  }

  public addAddressBook(auditAddressBook: AuditAddressBook): Observable<BusinessResponse> {
    const headers = this.httpUtility.getHeaders();
    const url = `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditAddressBooks`;
    return this.http.post<BusinessResponse>(url,  auditAddressBook, {headers} );
  }

  public updateAddressBook(auditAddressBook: AuditAddressBook): Observable<BusinessResponse> {
    const headers = this.httpUtility.getHeaders();
    const url = `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditAddressBooks`;
    return this.http.put<BusinessResponse>(url, auditAddressBook, {headers} );
  }

  public deleteAddressBook(addressBookId: number): Observable<BusinessResponse> {
    const url = `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditAddressBooks/${addressBookId}`;
    return this.http.delete<BusinessResponse>(url);
  }

}
