import { Component,
    EventEmitter,
    OnInit,
    Output,
    Input} from '@angular/core';
import { ModalAction } from '../../models/modalAction';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { find, map } from 'lodash';

@Component({
selector: 'app-checkbox-dialog',
templateUrl: './checkbox-dialog.component.html',
styleUrls: ['./checkbox-dialog.component.css']
})

export class CheckboxDialogComponent implements OnInit {
@Input() listItems: any[];
@Input() show: boolean;
@Input() id: string;
@Input() displayTextBoxLabel: string;
@Input() displayLabel: string;
@Input() titleText: string;
@Input() filterProperty: string;
@Output() modalAction: EventEmitter<ModalAction> = new EventEmitter<ModalAction>();

public selectedValue: any;
public filteredResults: any;
public form: FormGroup;
public disableSaveBtn: boolean;
public unfilteredResults: any;

constructor() {
  this.disableSaveBtn = true;
}

ngOnInit() {
this.form = new FormGroup({
 selection: new FormControl(null, [
 ])
});
}

onShow() {
}

validateInput(): void {
const exists = this.selectedValue.length;

this.disableSaveBtn = exists > 0 ? false : true;
}

search(event) {
if (event.query) {
 const results = this.unfilteredResults.filter(s => s
   .toLowerCase().startsWith(event.query.toLowerCase()));
     this.filteredResults = results;
}
}

getListData(): void {
this.filteredResults = [];
this.filteredResults = this.unfilteredResults;
}

reset(): void {
this.form.reset();
this.filteredResults = [];
}

submitClick(action: string): void {
const value = this.selectedValue;
this.filteredResults = [];
this.modalAction.emit({
 id: this.id,
 title: this.titleText,
 action: action,
 value: value });
 this.selectedValue = '';
 this.disableSaveBtn = true;
}

}
