import { Injectable, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as _ from 'lodash';
import { SelectMultipleControlValueAccessor } from '@angular/forms';
import { stringify } from 'querystring';

@Injectable()

  export class PhiService {
    phiChangedSubscription: any;
    PhiToggleChanged: EventEmitter<boolean> = new EventEmitter();

    private _phiToggle: boolean = false;
    
    constructor(private cookieService: CookieService) {
    }
    
    public get PhiToggle(): boolean {
      this._phiToggle = this.getPhiCookie();
      return this._phiToggle;
    }

    public set PhiToggle(phiToggle:boolean) {
      this._phiToggle = this.getPhiCookie();
      //console.log(`PHIToggle() - ${phiToggle}`);
      this.PhiToggleChanged.emit(this._phiToggle);
    } 

    getPhiCookie():boolean{
      var phiCookie = this.cookieService.get('phi_toggle');
      if(phiCookie === ""){
        return false;
      }else{
        if(phiCookie.toUpperCase() === "TRUE"){
          return true;
        }else{
          return false;
        }
      }
    }
  }