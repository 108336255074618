import { Component, EventEmitter, OnInit, ViewChild, Input, Output, OnChanges } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { cloneDeep, remove } from 'lodash';
import * as toastr from 'toastr';
import { AuditService } from 'src/app/services';
import { BusinessResponse } from 'src/app/models/businessResponse';
import { BusinessResponseService } from 'src/app/services/business-response.service';
import { environment } from 'src/environments/environment';
import { AuditImageService } from '../../services/audit-image.service';

const TOASTR_OPTIONS: any = {
  closeButton: true,
  timeOut: '0',
  extendedTimeOut: '0'
};


@Component({
  selector: 'app-image-upload-component',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})

export class ImageUploadComponent implements OnInit {
  @ViewChild('imageUpload', { static: true }) fileUploadElem: FileUpload;
  @Input() show: boolean;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() key: any;
  @Output() uploadImageComplete: EventEmitter<any> = new EventEmitter<any>();

  public uploading: boolean;
  public readonly fileLimitMsg = 'Up to 5 files may be uploaded at a time.';
  public invalidFileCount = false;
  public readonly fileErrorMessage = 'Only 5 files can be uploaded at a time';
  private filesCache: File[] = [];
  constructor(private service: AuditImageService,
    private busRespService: BusinessResponseService) { }

  ngOnInit() {
  }

  filesSelected(event: any) {
    const maxFiles = 5;
    if (this.fileUploadElem.files) {
      const filesCount = this.fileUploadElem.files.length;
      if (filesCount > 0 && filesCount <= 5) {
        this.filesCache = cloneDeep(this.fileUploadElem.files);
      }
      if (filesCount > maxFiles) {
        this.invalidFileCount = true;
        if (this.filesCache.length > 0) {
          this.fileUploadElem.files = this.filesCache;
        } else {
          this.fileUploadElem.clear();
        }
        setInterval(() => {
          this.invalidFileCount = false;
        }, 6000);
      }
    }
  }

  uploadImages(event: any, key: any): void {
    this.uploading = true;
    if (event.files.length) {
      toastr.info(`${event.files.length} files have been queued.`);
    }
    this.service.uploadImages(key, event.files).subscribe(
      (response: BusinessResponse) => {
        this.uploadImageComplete.emit(true);
      }, (err) => {
        toastr.error('There was a problem uploading the file. Please make sure the file size is less than 15MB and the sum total file size is less than 75MB.');
        this.uploadImageComplete.emit(false);
      });
    this.show = false;
    this.fileUploadElem.clear();
  }

  removeImage(file: any) {
    remove(this.fileUploadElem.files, (f) => {
      return f === file;
    });
  }

  onShow(event): void {
    this.fileUploadElem.files = [];
    this.filesCache = [];
    this.invalidFileCount = false;
  }

  hide(): void {
    this.showChange.emit(false);
    this.uploadImageComplete.emit(false);
  }
}
