export interface IFaxStatusColumn {
    field: string;
    header: string;
    hdrClass?: string;
    fldClass?: string;
    show: boolean;
    disabled?: boolean;
    editable?: boolean;
  }
  
  export class FaxStatusTableHelper {
    public faxStatusColumns: IFaxStatusColumn[];
    public faxStatusFooterColumns: IFaxStatusColumn[];
  
    constructor() {
      this.generateFaxStatusColumns();
      this.generateFooterColumns();
    }
  
    private generateFaxStatusColumns(): void {
      this.faxStatusColumns = [
          { field: 'ReceiverName', header: 'Name',
            show: true, disabled: false},
          { field: 'DestinationFaxNumber', header: 'Fax Number',
            show: true, disabled: false},
          { field: 'Pages', header: 'Pages',
            show: true, disabled: false},
            { field: 'TransmissionStatus', header: 'Status',
            show: true, disabled: false},
          { field: 'CompletedTimestamp', header: 'Timestamp',
            hdrClass: 'date', fldClass: 'date', show: true, disabled: false},
         
      ];
    }
  
    private generateFooterColumns(): void {
      this.faxStatusFooterColumns = [
        { field: 'ReceiverNameFtr', header: 'Name',
            show: true, disabled: false},
          { field: 'DestinationFaxNumberFtr', header: 'Fax Number',
            show: true, disabled: false},
          { field: 'PagesFtr', header: 'Pages',
            show: true, disabled: false},
          { field: 'CompletedTimestampFtr', header: 'Timestamp',
            hdrClass: 'date', fldClass: 'date', show: true, disabled: false},
          { field: 'TransmissionStatusFtr', header: 'Status',
            show: true, disabled: false},
      ];
    }
  }
  