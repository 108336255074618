import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuditRoutingModule } from './audit-routing.module';
import {
  AuditCarrierSummaryComponent,
  FooterComponent,
  SideNavComponent } from '../../components';
import { AuditCarrierService } from 'src/app/services';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { SharedClientTenantGuardModule } from '../SharedModules/SharedClientTenantGuardModule';

@NgModule({
  imports: [
    CommonModule,
    AuditRoutingModule,
    SharedClientTenantGuardModule
  ],
  declarations: [
  ],
  providers: [
  ]
})
export class AuditModule { }
