import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { LimitedResult, PrimeNgListItem, StatusModel, Pagination, ILimitedResult} from '../models';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessResponse } from 'src/app/models/businessResponse';
import { AuditClass } from '../models/audit-class';
import { PrimeNgAuditClassListItem } from '../models/prime-ng-auditclass-list-item';
import { AuditTemplateDetail } from '../models/audit-template-details';

@Injectable({
  providedIn: 'root'
})

export class AuditClassService {
  private readonly base_url = `${environment.auditApiRoot}/clients`;

  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) { }

  public getAuditClassListItems(): Observable<PrimeNgListItem[]> {
    return this.http.get(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditClasses/?limit=${environment.defaultListLimit}`,
      { headers: this.httpUtility.getHeaders() }
    )
    .pipe(
      map(res => res['Items'].map(item => new PrimeNgListItem(item.Name, item.AuditClassId))));
  }

  public getAuditClassPrimeNgListItems(): Observable<PrimeNgAuditClassListItem[]> {
    return this.http.get(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditClasses/?limit=${environment.defaultListLimit}`,
      { headers: this.httpUtility.getHeaders() }
    )
    .pipe(
      map(res => res['Items'].map(item => new PrimeNgAuditClassListItem(item.AuditClassId, new AuditTemplateDetail(item.ImageId, item.TemplateName)))));
  }

  public getAuditClassesLimitedResult(pagination: Pagination): Observable<ILimitedResult<AuditClass>> {
    const params = pagination.buildSearchParams();
    return this.http.get<ILimitedResult<AuditClass>>(
      `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditClasses`, {params});
  }

  public addAuditClass(name: string, formFile: File): Observable<BusinessResponse> {
    const url = `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditClasses`;
    const formData: FormData = new FormData();
    formData.append('Name',name);
    if(formFile){
        formData.append('FormFiles', formFile, formFile.name);
      }
    return this.http.post<BusinessResponse>(url, formData);
  }

  public getAuditClass(auditClassId: number): Observable<AuditClass> {
    const url = `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditClasses/${auditClassId}`;
    return this.http.get<AuditClass>(url,{ headers: this.httpUtility.getHeaders()});
  }
  public updateAuditClass(auditClassId: number, name: string, imageId: string, formFile: File): Observable<BusinessResponse> {
    const url = `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditClasses`;

    const formData: FormData = new FormData();
    formData.append('AuditClassId', String(auditClassId));
    formData.append('Name', name);
    if(imageId){
      formData.append('ImageId', imageId);
    }
    if(formFile){
      formData.append('FormFiles', formFile, formFile.name);
    }
    return this.http.put<BusinessResponse>(url, formData);
  }

  public deleteAuditClass(auditClassId: number): Observable<Response> {
    const url = `${this.base_url}/${this.globalFilterService.selectedClientId}/AuditClasses/${auditClassId}`;
    return this.http.delete<Response>(url);
  }
}
