import { Injectable } from '@angular/core';
import { PrimeNgListItem} from '../models';
import { FormControl, FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class UiHelperService {

  constructor() { }

  addEmptyFirstRowToListItems(list: PrimeNgListItem[]) {
    list.unshift( new PrimeNgListItem('', ''));
    return list;
  }

  // Uses the MaxLength validator of a formGroup control and clips the 
  // value if it longer than the max value. For example if the max value 
  // 4, and you type in 'abcde', this function will trim it to 'abcd'.
  // By calling this on an input's (input) event, you can prevent users 
  // from typing in text that is too long.
  limitToMaxLength(formGroup: FormGroup, controlName: string) {
      if (formGroup.controls[controlName].errors && formGroup.controls[controlName].errors['maxlength']) {
        const requiredLength = formGroup.controls[controlName].errors['maxlength'].requiredLength;
        const newString = formGroup.controls[controlName].value.substr(0, requiredLength);
         formGroup.controls[controlName].setValue(newString);
      }
  }
}
