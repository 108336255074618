import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { environment } from '../../environments/environment';
import { ClaimSearchRequest, SelectableClaimSearchResult, ILimitedResult, LimitedResult, Pagination } from '../models';
import { Observable } from 'rxjs';
import { AssociatedClaim } from '../models/associated-claim';
import { Tag } from '../models/tag';
import { DiscrepancyCode } from '../models/discrepancyCode';
import { map } from 'rxjs/operators';
import { ClaimWithSource } from '../models/claimWithSource';
import { ILimitedResultAuditClaim } from '../models/limited-result-auditclaim';
import { SeidSearchRequest } from '../models/seid-search-request';
import { SeidSearchResult } from '../models/seid-search-result';
import { ClaimSearchFilter } from '../models/claim-search-filter';
const BASE_URL = `${environment.auditApiRoot}/clients`;
const BASE_CLAIM_URL = `${environment.claimApiRoot}/clients`;

enum TagStatus {
  Active,
  Inactive,
  All
}

enum FindMissingSeidStatus {
  All,
  Found,
  NotFound
}

interface IUpdateRecoupment {
  RawDataImport_ShardClaimIdentifier: number;
  RecoupmentAmount: number;
}

@Injectable({
  providedIn: 'root'
})

export class ClaimService {

  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) { }

  public getUnassociatedClaimsForAudit(
      auditId: number, serviceEventIds: string[], pagination: Pagination): Observable<LimitedResult<SelectableClaimSearchResult>> {

    const claimSearchRequest = new ClaimSearchRequest();
    claimSearchRequest.IncludePHI = false;
    claimSearchRequest.ServiceEventIds = serviceEventIds;
    const params = pagination.buildSearchParams();

    // Need to Add 9999 ???
    const url =
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/SearchUnassociatedClaims/Audits/${auditId}/`;
    return this.http.post<LimitedResult<SelectableClaimSearchResult>>(url, claimSearchRequest, { headers: {}, params: params });
  }

  public getAssociatedClaims(auditId: number, showTags: boolean, rxNumbers: string[],
    pagination: Pagination): Observable<ILimitedResultAuditClaim<AssociatedClaim>> {
    const url =
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/Audits/${auditId}/Claims`;
    let params = pagination.buildSearchParams();
    params = params.append('isReturnTags', showTags.toString());
    const headers = this.httpUtility.getHeaders();
    const claimSearchFilter = new ClaimSearchFilter();
    claimSearchFilter.PrescriptionNumber = rxNumbers;
    return this.http.post<ILimitedResultAuditClaim<AssociatedClaim>>(url, claimSearchFilter, { headers: headers, params: params });
  }

  public getMissingSeids( serviceEventIds: string[], pagination: Pagination, findStatus: FindMissingSeidStatus = FindMissingSeidStatus.NotFound): Observable<ILimitedResult<SeidSearchResult>> {
    const url =
      `${BASE_CLAIM_URL}/${this.globalFilterService.selectedClientId}/Claims/SEIDSearch`;

      const seidSearchRequest = new SeidSearchRequest();
      seidSearchRequest.ServiceEventIds = serviceEventIds;
      seidSearchRequest.limit = pagination.limit;
      seidSearchRequest.offset =  pagination.offset;
      seidSearchRequest.sort = '';

    const headers = this.httpUtility.getHeaders();
    const params = new HttpParams().append('findStatus', FindMissingSeidStatus[findStatus]);
    return this.http.post<ILimitedResult<SeidSearchResult>>(url, seidSearchRequest, { headers, params });
  }

  public getTags(active: TagStatus = TagStatus.Active): Observable<Tag[]> {
    const url =
      `${BASE_CLAIM_URL}/${this.globalFilterService.selectedClientId}/Tags`;
    const headers = this.httpUtility.getHeaders();
    const params = new HttpParams().append('tagStatus', TagStatus[active]);
    return this.http.get<Tag[]>(url, { headers, params });
  }

  public getAllTags(): Observable<ILimitedResult<Tag>> {
    const url =
      `${BASE_CLAIM_URL}/${this.globalFilterService.selectedClientId}/Tags`;
    const headers = this.httpUtility.getHeaders();
    const params = new HttpParams().append('tagStatus', 'All');
    return this.http.get<ILimitedResult<Tag>>(url, { headers, params });
  }

  public getDiscrepencyCode(): Observable<ILimitedResult<DiscrepancyCode>> {
    const url =
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/AuditDiscrepancyCodes`;
    let params = new HttpParams().append('limit', '9999');
    params = params.append('offset', '0');
    return this.http.get<ILimitedResult<DiscrepancyCode>>(url, {params});
  }

  public applyTagToClaim(claimsWithSource: ClaimWithSource[], tagId: string): Observable<boolean> {
    const url =
      `${BASE_CLAIM_URL}/${this.globalFilterService.selectedClientId}/Claims/AddTags`;
    const headers = this.httpUtility.getHeaders();
    const body = {TagId: tagId, ClaimIds: claimsWithSource};
    return this.http.post<boolean>(url, body, { headers });
  }

  public applyNoteToClaim(noteText: string, claimsWithSource: ClaimWithSource[]): Observable<boolean> {
    const url =
      `${BASE_CLAIM_URL}/${this.globalFilterService.selectedClientId}/Claims/AddNotes`;
    const headers = this.httpUtility.getHeaders();
    const body = {NoteText: noteText, ClaimIds: claimsWithSource};
    return this.http.post<boolean>(url, body, { headers });
  }

  public applyDescrepencyCode(auditId: number, discrepancyCodeId: number[], claimId: number[]): Observable<boolean> {
    const url =
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/Audits/${auditId}/Claims/ApplyDiscrepancyCodes`;
    const headers = this.httpUtility.getHeaders();
    const body = {DiscrepancyCodeId: discrepancyCodeId, RawDataImport_ShardClaimIdentifier: claimId};
    return this.http.put<boolean>(url, body, { headers });
  }

  public deleteClaim(auditId: number, claimIds: ClaimWithSource[]): Observable<boolean> {
    const url =
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/Audits/${auditId}/Claims`;
    const headers = { 'ContentType': 'application/json'};
    const httpOptions = {headers: headers, body: claimIds};
    return this.http.delete<boolean>(url, httpOptions);
  }

  public updateRecoupments(auditId: number, tagId: number, recoupments: IUpdateRecoupment[]): Observable<boolean> {
    const url =
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/Audits/${auditId}/Claims/ApproveRecoupments/${tagId}`;
    const body = {Recoupments: recoupments};
    const headers = this.httpUtility.getHeaders();
    const httpOptions = {headers: headers, body: body};
    return this.http.put<boolean>(url, body);
  }
}
