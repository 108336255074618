import { SelectDialogComponent } from './components/select-dialog/select-dialog.component';
import { CheckboxDialogComponent } from './components/checkbox-dialog/checkbox-dialog.component';
import { NgModule } from '@angular/core';
import { AddNotesDialogComponent } from './components/add-notes-dialog/add-notes-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoComplete } from 'primeng/autocomplete';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { BlockUIModule } from 'primeng/blockui';
import { PanelModule } from 'primeng/panel';
import { ListboxModule } from 'primeng/listbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { AuditToolbarComponent } from './components/audit-toolbar/audit-toolbar.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { NgxSortableComponent } from './components/ngx-sortable/ngx-sortable.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ButtonModule,
    DialogModule,
    DropdownModule,
    ToolbarModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextareaModule,
    AutoCompleteModule,
    TooltipModule,
    FileUploadModule,
    BlockUIModule,
    PanelModule,
    ListboxModule
  ],
  declarations: [
    SelectDialogComponent,
    AddNotesDialogComponent,
    DeleteDialogComponent,
    AuditToolbarComponent,
    FileUploadDialogComponent,
    NgxSortableComponent,
    ContextMenuComponent,
    CheckboxDialogComponent
  ],
  exports: [
    SelectDialogComponent,
    AddNotesDialogComponent,
    DeleteDialogComponent,
    AuditToolbarComponent,
    FileUploadDialogComponent,
    NgxSortableComponent,
    ContextMenuComponent,
    CheckboxDialogComponent,
    AutoCompleteModule,
  ]
})

export class SharedModule {}
