export class AuditClaim {
    AuditClaimId: number;
    ClaimId: number;
    PrescriptionNumber: string;
    FillDate: Date;
    CarrierId: number;
    StoreId: number;
    CarrierName: string;
    StoreName: string;
    PrimaryNdc: string;
    ClaimReceivableAmount: number;
    FinalReceivableAmount: number;
    SoldDate: Date;
    Source: string;
}
