import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { environment } from '../../environments/environment';
import { ILimitedResult, LimitedResult, Pagination } from '../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImageSearchResult } from '../models/image-search-result';
import { Guid } from 'guid-typescript';

const BASE_URL = `${environment.auditApiRoot}/clients`;

@Injectable({
  providedIn: 'root'
})

export class AuditImageService {

  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) { }


  public getAssociatedImages(auditId: number, pagination: Pagination): Observable<ILimitedResult<ImageSearchResult>> {
    const url =
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/Audits/${auditId}/SearchImageMetadatas`;
    const params = pagination.buildSearchParams();
    const headers = this.httpUtility.getHeaders();
    return this.http.get<ILimitedResult<ImageSearchResult>>(url, { headers, params });
  }

  public deleteImages(auditId: number, imageId: Guid): Observable<boolean> {
    const url =
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/Audits/${auditId}/Images/${imageId}`;
      return this.http.delete<boolean>(url);
  }

  public uploadImages(auditId: number, formFile: File[]): Observable<Response> {
    const url =
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/Audits/${auditId}/Images`;
    const headers = new HttpHeaders();
    let FormFiles = new FormData();

    formFile.forEach(file => {
      FormFiles.append('formFiles', file, file.name);
    });

    return this.http.post<Response>(url, FormFiles, {headers});
  }

  public downloadImage(auditId: number, guid: string): Observable<Blob> {
    return this.http.get(`${BASE_URL}/${this.globalFilterService.selectedClientId}/Audits/${auditId}/Images/${guid}`,
                          { responseType: 'blob' })
  }
}
