import { Component, EventEmitter, AfterViewInit, OnDestroy, ViewChild, Input, Output, OnChanges } from '@angular/core';
import * as toastr from 'toastr';
import { AuditClaimService, AuthorizationService } from 'src/app/services';
import {
  AuditClaimDetail,
  Pagination,
  ILimitedResult,
  AuditClaimNote,
  AuditClaimTag,
  ClaimWithSourceAndNoteIds,
  ClaimWithSourceAndTagIds
} from 'src/app/models';
import { ClaimDetailTableHelper } from './helpers/claim-detail-table.helper';
import { LazyLoadEvent } from 'primeng/api';
import { ModalAction } from 'src/app/shared/models/modalAction';
import { Table } from 'primeng/table';
import { dropRight } from 'lodash';
import { AuditClaimDiscrepancyCode } from 'src/app/models/audit-claim-discrepancy-code';
import { AuditClaimNoteService } from 'src/app/services/audit-claim-note.service';
import { AuditClaimTagService } from 'src/app/services/audit-claim-tag.service';
import { PhiService } from '../../services/phi.service';
import { isUndefined } from 'util';

@Component({
  selector: 'app-claim-detail',
  templateUrl: './claim-detail.component.html',
  styleUrls: ['./claim-detail.component.css']
})

export class ClaimDetailComponent implements OnChanges, OnDestroy {
  @Input() auditClaimId: number;
  @Input() source: string;
  @Input() showPhi: boolean;
  @Input() showDialog: boolean;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @ViewChild('discrepancyCodeTable', { static: false }) discrepancyCodeTable: Table;
  @ViewChild('tagTable', { static: false }) tagTable: Table;
  @ViewChild('noteTable', { static: false }) noteTable: Table;

  public claimDetail: AuditClaimDetail;
  public auditClaimDiscrepancyCodes: AuditClaimDiscrepancyCode[];
  public auditClaimNotes: AuditClaimNote[];
  public auditClaimTags: AuditClaimTag[];
  public loadingClaimDetails = true;
  public loadingDiscrepancyCodeGrid = true;
  public loadingNotesGrid = true;
  public loadingTagsGrid = true;
  public deleteTitle: string;
  public deleteMessage: string;
  public showDeleteDialog = false;

  // keys used to delete
  private rawDataImport_ShardClaimIdentifier: number;
  private discrepancyCodeId: number;
  private noteId: number;
  private tagId: number;
  private phiToggleChangedSubscription: any;

  public readonly tableHelper: ClaimDetailTableHelper;
  public readonly rowsByBrowser: number;

  readonly viewDiscrepancyCodeFeature = 'UI::ClaimDetailDiscrepancyCodes';
  readonly viewNotesFeature = 'UI::ClaimDetailNotes';
  readonly viewTagsFeature = 'UI::ClaimDetailTags';
  readonly deleteDiscrepancyCodeFeature = 'UI::DiscrepancyCodeDeleteAssociation';
  readonly deleteTagFeature = 'UI::TagDelete';
  readonly deleteNoteFeature = 'UI::NoteDelete';
  readonly deleteDiscrepancyCodeToastrMessage = 'There was an issue with removing the Discrepancy Code you selected from the claim. ' +
                                                'The item was not removed.';
  readonly deleteTagToastrMessage = 'There was an issue with deleting the tag you selected. The item was not deleted.';
  readonly deleteNoteToastrMessage = 'There was an issue with deleting the note you selected. The item was not deleted.';

  constructor(private auditClaimService: AuditClaimService,
    private authService: AuthorizationService,
    private claimNoteService: AuditClaimNoteService,
    private claimTagService: AuditClaimTagService,
    private phiService: PhiService) {
      this.rowsByBrowser = 9999;
      this.claimDetail = new AuditClaimDetail();
      this.tableHelper = new ClaimDetailTableHelper();
      this.checkFeatures();
      this.phiToggleChangedSubscription = this.phiService.PhiToggleChanged.subscribe(() => {
        if(!isUndefined(this.auditClaimId)){
          this.loadClaimDetail();
        }
      });
  }

  // ngAfterViewInit() {
  //   if (this.auditClaimId) {
  //     this.loadDiscrepancyCodesGrid(new Pagination(this.rowsByBrowser, 0, '-CreatedBy'));
  //   }
  //   this.checkFeatures();
  // }

  ngOnDestroy() {
    this.auditClaimId = undefined;
    this.source = undefined;
      if (this.phiToggleChangedSubscription) {
        this.phiToggleChangedSubscription.unsubscribe();
      }
  }

  ngOnChanges(changes): void {
    if (changes.showDialog && changes.showDialog.currentValue === false) {
      this.claimDetail = new AuditClaimDetail();
      this.auditClaimDiscrepancyCodes = [];
      this.auditClaimTags = [];
      this.auditClaimNotes = [];
      this.closed.emit();
    }

    if (changes.showDialog && changes.showDialog.currentValue === true && this.auditClaimId) {
      this.loadClaimDetail();
    }
  }

  loadDiscrepancyCodesLazy(event: LazyLoadEvent) {
    if (this.auditClaimId) {
      this.loadDiscrepancyCodesGrid(new Pagination(this.rowsByBrowser, 0, event.sortField, event.sortOrder));
    }
  }

  loadNotesLazy(event: LazyLoadEvent) {
    if (this.rawDataImport_ShardClaimIdentifier) {
      this.loadNotesGrid(new Pagination(this.rowsByBrowser, 0, event.sortField, event.sortOrder));
    }
  }

  loadTagsLazy(event: LazyLoadEvent) {
    if (this.rawDataImport_ShardClaimIdentifier) {
      this.loadTagsGrid(new Pagination(this.rowsByBrowser, 0, event.sortField, event.sortOrder));
    }
  }

  loadClaimDetail() {
    this.loadingClaimDetails = true;
    this.showPhi = this.phiService.PhiToggle;
    this.auditClaimService.getAuditClaimDetailAsync(this.auditClaimId, this.source, this.showPhi)
      .subscribe( (result: AuditClaimDetail) => {
        this.claimDetail = result;
        if (!this.claimDetail.IsPHILogged && this.showPhi) {
          toastr.error('Unable to retrieve PHI data at this time, please retry later.');
        }
        this.rawDataImport_ShardClaimIdentifier = this.claimDetail.RawDataImport_ShardClaimIdentifier;
        this.loadingClaimDetails = false;
        if (this.hasShowDiscrepancyCodesFeature()) {
          this.loadDiscrepancyCodesGrid(new Pagination(this.rowsByBrowser, 0, '-CreateDate'));
        }
        if (this.hasShowNotesFeature()) {
          this.loadNotesGrid(new Pagination(this.rowsByBrowser, 0, '-Tmstmp'));
        }
        if (this.hasShowTagsFeature()) {
          this.loadTagsGrid(new Pagination(this.rowsByBrowser, 0, '-CreateDate'));
        }
        if (!this.claimDetail.IsPHILogged && this.showPhi) {
          toastr.error('Unable to retrieve PHI data at this time. Please try again later.');
        }
      }, err => {
        toastr.error('Unable to display claim detail information.');
      });
  }

  loadDiscrepancyCodesGrid(pagination: Pagination) {
    this.loadingDiscrepancyCodeGrid = true;
    this.auditClaimDiscrepancyCodes = [];

    this.auditClaimService.getAuditClaimDiscrepancyCodes(this.auditClaimId, pagination)
      .subscribe( (result: ILimitedResult<AuditClaimDiscrepancyCode>) => {
        this.auditClaimDiscrepancyCodes = result.Items;
        this.loadingDiscrepancyCodeGrid = false;
      }, err => {
        toastr.error('There was an issue getting the audit claim discrepancy codes from the server.');
        this.auditClaimDiscrepancyCodes = [];
        this.loadingDiscrepancyCodeGrid = false;
      });
  }

  loadNotesGrid(pagination: Pagination) {
    this.loadingNotesGrid = true;
    this.auditClaimNotes = [];

    this.claimNoteService.getAuditClaimNotes(this.rawDataImport_ShardClaimIdentifier, this.source, pagination)
      .subscribe( (result: ILimitedResult<AuditClaimNote>) => {
        this.auditClaimNotes = result.Items;
        this.loadingNotesGrid = false;
      }, err => {
        toastr.error('There was an issue getting the audit claim notes from the server.');
        this.auditClaimNotes = [];
        this.loadingNotesGrid = false;
      });
  }

  loadTagsGrid(pagination: Pagination) {
    this.loadingTagsGrid = true;
    this.auditClaimTags = [];

    this.claimTagService.getAuditClaimTags(this.rawDataImport_ShardClaimIdentifier, this.source, pagination)
      .subscribe( (result: ILimitedResult<AuditClaimTag>) => {
        this.auditClaimTags = result.Items;
        this.loadingTagsGrid = false;
      }, err => {
        toastr.error('There was an issue getting the audit claim tags from the server.');
        this.auditClaimTags = [];
        this.loadingTagsGrid = false;
      });
  }

  hasShowTagsFeature(): boolean {
    return this.authService.hasFeature(this.viewTagsFeature);
  }

  hasShowNotesFeature(): boolean {
    return this.authService.hasFeature(this.viewNotesFeature);
  }

  hasShowDiscrepancyCodesFeature(): boolean {
    return this.authService.hasFeature(this.viewNotesFeature);
  }

  checkFeatures() {
    if (!this.authService.hasFeature(this.deleteDiscrepancyCodeFeature)) {
      this.tableHelper.discrepancyCodeColumns = dropRight(this.tableHelper.discrepancyCodeColumns);
    }
    if (!this.authService.hasFeature(this.deleteTagFeature)) {
      this.tableHelper.tagColumns = dropRight(this.tableHelper.tagColumns);
    }
    if (!this.authService.hasFeature(this.deleteNoteFeature)) {
      this.tableHelper.noteColumns = dropRight(this.tableHelper.noteColumns);
    }
  }

  confirmDiscrepancyCodeDelete(rowData) {
    this.deleteTitle = `Confirm Delete Discrepancy Code`;
    this.deleteMessage =
      `Are you sure that you want to delete <strong>${rowData.Code} - ${rowData.Name}</strong>?`;
    this.discrepancyCodeId = rowData.DiscrepancyCodeId;
    this.showDeleteDialog = true;
  }

  confirmNoteDelete(rowData) {
    this.deleteTitle = `Confirm Delete Note`;
    this.deleteMessage =
      `Are you sure that you want to delete <strong>${rowData.NoteText}</strong>?`;
    this.noteId = rowData.NoteID;
    this.showDeleteDialog = true;
  }

  confirmTagDelete(rowData) {
    this.deleteTitle = `Confirm Delete Tag`;
    this.deleteMessage =
      `Are you sure that you want to delete <strong>${rowData.TagCode} - ${rowData.TagDesc}</strong>?`;
    this.tagId = rowData.TagID;
    this.showDeleteDialog = true;
  }

  delete(event: ModalAction) {
    if (event.action === 'save') {
      if (this.discrepancyCodeId) {
        this.deleteDiscrepancyCode();
      }
      if (this.noteId) {
        this.deleteNote();
      }
      if (this.tagId) {
        this.deleteTag();
      }
    }
    this.showDeleteDialog = false;
  }

  private deleteDiscrepancyCode() {
    this.auditClaimService.deleteAuditClaimDiscrepancyCode(this.auditClaimId, this.discrepancyCodeId)
      .subscribe((value) => {
        const success = value;
        !success ? toastr.error(this.deleteDiscrepancyCodeToastrMessage) : toastr.success('Delete successful!');
        this.discrepancyCodeId = null;
        this.discrepancyCodeTable.reset();
      }, err => {
        toastr.error(this.deleteDiscrepancyCodeToastrMessage);
        this.discrepancyCodeId = null;
        this.discrepancyCodeTable.reset();
      });
  }

  private deleteNote() {
    const idsForDelete = new ClaimWithSourceAndNoteIds();
    idsForDelete.NoteIds = [this.noteId];
    idsForDelete.RawDataImport_ShardClaimIdentifier = this.rawDataImport_ShardClaimIdentifier;
    idsForDelete.Source = this.source;
    this.claimNoteService.deleteAuditClaimNotes(idsForDelete).subscribe((value) => {
      const success = value;
      !success ? toastr.error(this.deleteNoteToastrMessage) : toastr.success('Delete successful!');
      this.noteId = null;
      this.noteTable.reset();
    }, err => {
      toastr.error(this.deleteNoteToastrMessage);
      this.noteId = null;
      this.noteTable.reset();
    });
  }

  private deleteTag() {
    const idsForDelete = new ClaimWithSourceAndTagIds();
    idsForDelete.TagIds = [this.tagId];
    idsForDelete.RawDataImport_ShardClaimIdentifier = this.rawDataImport_ShardClaimIdentifier;
    idsForDelete.Source = this.source;
    this.claimTagService.deleteAuditClaimTags(idsForDelete).subscribe((value) => {
      const success = value;
      !success ? toastr.error(this.deleteTagToastrMessage) : toastr.success('Delete successful!');
      this.tagId = null;
      this.tagTable.reset();
    }, err => {
      toastr.error(this.deleteTagToastrMessage);
      this.tagId = null;
      this.tagTable.reset();
    });
  }

  hide(): void {
    this.closed.emit(false);
  }
}
