import { AuditSearchResult, Pagination } from 'src/app/models';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';

import { AccordionTab } from 'primeng/accordion';
import { AuditByStatusesResult } from 'src/app/models/workflow/audit-by-statuses-results';
import { AuditService } from 'src/app/services';
import { AuditWorkflowSearchFilter } from 'src/app/models/workflow/audit-workflow-search-filter';
import { ITableColumn } from '../../admin/audit-vendor/helpers/vendor-table-helper';
import { Status } from 'cucumber';

const COLUMNS: ITableColumn[] = [
  { field: 'AuditNumber', header: 'Audit Number', show: true, fldClass: 'default' },
  { field: 'AuditReferenceNumber', header: 'Ref Number', show: true },
  { field: 'Carrier', header: 'Audit Carrier', show: true },
  { field: 'AuditType', header: 'Type', show: true },
  { field: 'ClaimCount', header: 'Claim Count', show: true },
  { field: 'TotalInitialRiskAmount', header: 'Initial Risk Amt', hdrClass: 'money', fldClass: 'money', show: true },
  { field: 'TotalApprovedRecoupmentAmount', header: 'Approved Recoupment Amt', hdrClass: 'money', fldClass: 'money', show: true },
  { field: 'StatusDueDate', header: 'Status Due Date', show: true },
  { field: 'AuditDueDate', header: 'Audit Due Date', show: true },
  { field: 'IsPending', header: 'Pending', show: true },
];
@Component({
  selector: 'app-workflow-status',
  templateUrl: './workflow-status.component.html',
  styleUrls: ['./workflow-status.component.css']
})
export class WorkflowStatusComponent implements OnInit, OnChanges {
  @ViewChild('accordianTab', { static: true }) accordianTab: AccordionTab;
  @Input() status: AuditByStatusesResult;
  @Input() autoShowTable: boolean;
  @Input() expanded: boolean;
  @Input() canEdit: boolean;
  @Input() tablePageSize = 10;
  @Input() tableData: AuditSearchResult[];
  @Input() searchFilter: AuditWorkflowSearchFilter;
  @Output() expandedChange: EventEmitter<boolean>;
  @Output() metricClick: EventEmitter<number>;
  public readonly tableColumns: ITableColumn[];
  public readonly today = new Date(new Date().setHours(0, 0, 0, 0));
  public showStatus: boolean;
  public auditSearchResults: AuditSearchResult[];
  public tableLoading: boolean;
  public index: number;
  public totalItemCount: number;
  public paginationDefault: Pagination = new Pagination(this.tablePageSize, 0, null);

  constructor(private auditService: AuditService) {
    this.tableColumns = COLUMNS;
    this.metricClick = new EventEmitter<number>();
    this.expandedChange = new EventEmitter<boolean>();
  }

  ngOnChanges(changes): void {
    if ( changes.autoShowTable &&
      changes.autoShowTable.currentValue) {
      this.index = 0;
      this.loadTable(this.paginationDefault);
    }
  }

  ngOnInit() {
      this.showStatus =  Boolean(this.status);
  }

  close(event: any) {
    this.expandedChange.emit(false);
  }

  checkDateForCss(dateIn, isStatus: boolean = true): string {
    let myClass = '';
      if ( (new Date(dateIn) < this.today)) {
        myClass = 'metric-red';
        return myClass;
      }
      const myDate = new Date(new Date(dateIn).setHours(0, 0, 0, 0));
      if ( myDate.getTime() === this.today.getTime()) {
        myClass = 'metric-yellow';
      }
      return myClass;
  }

  onLazyLoad(event): void {
    if (this.accordianTab.selected) {
      this.loadTable(
        new Pagination(
          10, event.first, event.sortField, event.sortOrder));
    }
  }

  loadTable(pagination: Pagination) {
    this.metricClick.emit(this.status.AuditStatusId);
      this.tableLoading = true;
      const filter = this.searchFilter;
      filter.AuditStatusId = this.status.AuditStatusId;
      this.auditService.SearchAuditWorkflowByStatuses(filter, pagination)
        .subscribe( (data) => {
          this.auditSearchResults = data.Items;
          this.tableLoading = false;
          this.totalItemCount = data.TotalItemCount;
      });
      this.expandedChange.emit(true);
  }
}
