export class AuditByStatusesResult {
  AuditStatusId: number;
  AuditStatus: string;
  TotalCount: number;
  StatusDueTodayCount: number;
  StatusPastDueCount: number;
  AuditDueTodayCount: number;
  AuditPastDueCount: number;
  IsPendingCount: number;
  Expanded: boolean;
}
