export interface ICarrierSummaryColumn {
  field: string;
  header: string;
  hdrClass?: string;
  fldClass?: string;
  show: boolean;
  disabled?: boolean;
  editable?: boolean;
}

export class AuditCarrierTableHelper {
  public carrierSummaryColumns: ICarrierSummaryColumn[];
  public carrierSummaryFooterColumns: ICarrierSummaryColumn[];

  constructor() {
    this.generateCarrierSummaryColumns();
    this.generateFooterColumns();
  }

  private generateCarrierSummaryColumns(): void {
    this.carrierSummaryColumns = [
        { field: 'AttributeName', header: 'Audit Carrier Name',
          show: true, disabled: false},
        { field: 'AuditCount', header: 'Audit Count',
          show: true, disabled: false},
        { field: 'ClaimCount', header: 'Claim Count',
          show: true, disabled: false},
        { field: 'InitialRiskAmount', header: 'Initial Risk Amt',
          hdrClass: 'money', fldClass: 'money', show: true, disabled: false},
        { field: 'ApprovedRecoupmentAmount', header: 'Approved Recoupment Amt',
          hdrClass: 'money', fldClass: 'money', show: true, disabled: false},
    ];
  }

  private generateFooterColumns(): void {
    this.carrierSummaryFooterColumns = [
      { field: 'AuditCountFtr', header: 'Audit Count',
          show: true, disabled: false},
      { field: 'ClaimCountFtr', header: 'Claim Count',
          show: true, disabled: false},
      { field: 'InitialRiskAmountFtr', header: 'Initial Risk Amount',
          hdrClass: 'money', fldClass: 'money', show: true, disabled: false},
      { field: 'ApprovedRecoupmentAmountFtr', header: 'Approved Recoupment Amount',
          hdrClass: 'money', fldClass: 'money', show: true, disabled: false},
    ];
  }
}
