import * as toastr from 'toastr';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Audit, ClaimSearchResult, ILimitedResult, Pagination, PrimeNgListItem } from '../../models';
import {
  AuditCarrierService,
  AuditService,
  AuditSpecialistService,
  AuditStatusService,
  AuditTypeService,
  AuditVendorService,
  AuthorizationService,
  ClaimService,
  DeliveryMethodService,
  GlobalFilterService,
  ImageRepoService,
  UiHelperService
} from '../../services';
import { Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgControlStatus, Validators } from '@angular/forms';
import { IToolbarItem, ToolbarHelper } from './helpers/toolbar.helper';
import { LazyLoadEvent, SortEvent } from 'primeng/api';
import { Observable, forkJoin, fromEventPattern } from 'rxjs';
import { Table, TableHeaderCheckbox } from 'primeng/table';
import { find, findIndex, map } from 'lodash';

import { AssociatedClaim } from 'src/app/models/associated-claim';
import { AuditClassService } from 'src/app/services/audit-class.service';
import { AuditFormTableHelper } from './helpers/audit-form-table.helper';
import { AuditImage } from 'src/app/models/audit-image';
import { AuditImageService } from '../../services/audit-image.service';
import { DatePipe } from '@angular/common';
import { ILimitedResultAuditClaim } from 'src/app/models/limited-result-auditclaim';
import { ImageSearchResult } from 'src/app/models/image-search-result';
import { MenuHelper } from './helpers/menu.helper';
import { ModalAction } from 'src/app/shared/models/modalAction';
import {PhiService} from '../../services/phi.service';
import { PrimeNgAuditClassListItem } from 'src/app/models/prime-ng-auditclass-list-item';
import {SelectItem} from 'primeng/api';

const MAX_ROWS = 60;
@Component({
  selector: 'app-audit-form',
  templateUrl: './audit-form.component.html',
  styleUrls: ['./audit-form.component.css']
})

export class AuditFormComponent implements OnInit, OnDestroy {
  loadingMasterData: boolean;
  auditFormGroup: FormGroup;
  public initialStateAuditModel: Audit;
  showAuditClaims = false;
  editAuditId: number;
  navigationSubscription: any;
  auditTypesListItems: PrimeNgListItem[];
  auditStatusListItems: PrimeNgListItem[];
  auditSpecialistListItems: PrimeNgListItem[];
  auditCarrierListItems: PrimeNgListItem[];
  auditVendorListItems: PrimeNgListItem[];
  deliveryMethodListItems: PrimeNgListItem[];
  auditClassListItems: PrimeNgListItem[];
  auditClassTemplateListItems: PrimeNgAuditClassListItem[];
  rxNumbersFilter: string[] = [];
  rxFilterString: string;
  showAddClaims = false;
  showAddImages = false;
  showTemplate = false;
  showSendFax = false;
  showStatusHistory = false;
  showSearchAuditClaimsHistory = false;
  isTemplateAvailable = false;
  showClaimDetail = false;
  prevSort = '';
  prevSortOrder?: number ;
  isRemovedChecked = false;
  claimDetailAuditClaimId: number;
  claimDetailSource: string;
  templateName: string;
  imageId: string;
  totalClaimCount = 0;
  totalApprovedRecoupmentAmount = 0;

  // Table impl
  public associatedClaimsDataLoading: boolean;
  public totalItemCount = 0;
  public readonly auditFormTableHelper: AuditFormTableHelper;
  private readonly menuHelper: MenuHelper;
  public toolbarHelper: ToolbarHelper;
  public associatedClaims: AssociatedClaim[];
  public associatedImages: ImageSearchResult[];
  public allChecked: boolean;
  public toolbar: IToolbarItem[];
  public selectedClaims: AssociatedClaim[] = [];
  private oldClaimValues: AssociatedClaim[];
  public showFileDialog: boolean;
  public disableSave = false;
  public phiToggleEnabled: boolean;

  public clientChangedSubscription: any;
  public uploadDisabled: boolean;
  public auditNumberText = '';
  public enableOrderButtons: boolean;
  public hasAssociatedDocs: boolean;
  public hasLinkToClaimDetail: boolean;
  public maxRowsBinding: number = MAX_ROWS;

  constructor(
    private auditTypeService: AuditTypeService,
    private auditStatusService: AuditStatusService,
    private auditSpecialistService: AuditSpecialistService,
    private auditCarrierService: AuditCarrierService,
    private auditVendorService: AuditVendorService,
    private auditClassService: AuditClassService,
    private authorizationService: AuthorizationService,
    private deliveryMethodService: DeliveryMethodService,
    private claimService: ClaimService,
    private auditImageService: AuditImageService,
    private imageRepoService: ImageRepoService,
    private uiHelperService: UiHelperService,
    private fb: FormBuilder,
    private auditService: AuditService,
    private globalFilterService: GlobalFilterService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private phiService: PhiService) {

    this.subscribeToClientChange();
    this.menuHelper = new MenuHelper(this.authorizationService);
    globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
    this.phiService.PhiToggleChanged.subscribe(() => this.onPhiToggleChange());
    this.subscribeToNavigationChange();
    this.auditFormTableHelper = new AuditFormTableHelper();
    this.toolbarHelper = new ToolbarHelper(this.authorizationService,
                                            this.claimService,
                                            this.route.snapshot.params.id);
  }

  @ViewChild('dt', { static: false }) dTable: Table;
  @ViewChild('chkBoxAll', { static: false }) selectAll: TableHeaderCheckbox;

  ngOnInit() {
    this.associatedClaimsDataLoading = true;
    this.onPhiToggleChange();
    this.hasAssociatedDocs = Boolean(this.authorizationService.hasFeature('UI::AuditImagesView'));
    this.hasLinkToClaimDetail = Boolean(this.authorizationService.hasFeature('UI::AuditClaimDetailView'));
  }

  toolbarBtnClick(name: string): void {
    if (name === 'saveRecoupment') {
      this.updateRecoupments();
    }
    if (name === 'cancelRecoupment') {
      this.setAllEditRecoupmentDefaults(this.selectedClaims.length, false);
    }
    if (name === 'approveRecoupment') {
      this.setAllEditRecoupmentDefaults(this.selectedClaims.length, true);
    }
    if (name === 'refresh') {
      this.refreshGrid();
    }
    this.toolbarHelper.toolbarBtnClick(name);
    if (name === 'showTags' || name === 'hideTags') {
      if (!this.associatedClaimsDataLoading) {
        this.associatedClaimsDataLoading = true;
      }
      this.getAssociatedClaims(new Pagination(MAX_ROWS, this.dTable.first, this.dTable.sortField, this.dTable.sortOrder));
    }
  }

  updateRecoupments(): void {
    const updatedRecoupments: any[] = [];
    let recoupUpdates = 0;
    this.selectedClaims.forEach(claim => {
      if (claim.EditApprovedRecoupment !== null && claim.ApprovedRecoupmentAmount !== claim.EditApprovedRecoupment) {
        recoupUpdates++;
        updatedRecoupments.push({ RawDataImport_ShardClaimIdentifier: claim.RawDataImport_ShardClaimIdentifier,
               RecoupmentAmount: claim.EditApprovedRecoupment });
      }
    });

    if (updatedRecoupments.length) {
      const tagId = (this.toolbarHelper.tags.filter(x => x.TagCode === 'RECOUP'))[0].TagID;
      this.claimService.updateRecoupments(this.editAuditId, Number(tagId), updatedRecoupments)
      .subscribe( (data) => {
        // update actual with edit on success.
        this.selectedClaims.forEach( claim => {
          const claimIndex = this.findRowIndex(claim.RawDataImport_ShardClaimIdentifier);
          if (claim.EditApprovedRecoupment !== null) {
            this.associatedClaims[claimIndex].ApprovedRecoupmentAmount =
            claim.EditApprovedRecoupment;
          }
        });
        this.toolbarHelper.updateBtnsForRecoupment();
        toastr.success(`We updated ${recoupUpdates} recoupment amounts successfully!`);
      }, () => {
        toastr.error('There was an issue updating your recoupment amounts.');
      });
    }
  }

  refreshGrid(): void {
    if (this.dTable) {
      this.dTable.reset();
    }
  }
  // Start Data table viewmodel impl
  onClientsChanged(): void {
    if (this.editAuditId && this.dTable) {
      this.refreshGrid();
      this.associatedClaimsDataLoading = true;
    }
  }

  getAssociatedClaims(pagination: Pagination) {
    this.toolbarHelper.disableBtnTags();
    this.claimService.getAssociatedClaims(this.editAuditId, this.toolbarHelper.isReturnTags, this.rxNumbersFilter, pagination)
      .subscribe(
        (data: ILimitedResultAuditClaim<AssociatedClaim>) => {
          this.associatedClaims = data.Items;
          this.associatedClaimsDataLoading = false;
          this.totalItemCount = data.TotalItemCount;
          this.totalClaimCount = data.TotalClaimCount;
          this.totalApprovedRecoupmentAmount = data.TotalApprovedRecoupmentAmount;
          this.toolbarHelper.disableBtnTags();
          this.setAllEditRecoupmentDefaults(this.associatedClaims.length, false);
        }
    );
  }

  setAllEditRecoupmentDefaults(totalItems: number, useMoney: boolean): void {
    // default edit to approve for recoupment.
    for (let i = 0; i < totalItems; i++) {
      if (this.selectedClaims[i]) {
        const rowIndex = this.findRowIndex(this.selectedClaims[i].RawDataImport_ShardClaimIdentifier);
        this.setApprovedRecoupmentRowDefault(rowIndex, this.associatedClaims, useMoney);
      } else {
        this.setApprovedRecoupmentRowDefault(i, this.associatedClaims, useMoney);
      }
    }
  }

  // if the Approved recoupment is 0 and useMoney is true set actual to money field.
  setApprovedRecoupmentRowDefault(index: number, associatedClaims: AssociatedClaim[], useMoney: boolean): void {
    if (!associatedClaims) {
      return;
    }

    if ( associatedClaims[index].ApprovedRecoupmentAmount === 0 && useMoney) {
      associatedClaims[index].EditApprovedRecoupment = associatedClaims[index].Money_PayerReceivableAmountClaim;
    } else {
      associatedClaims[index].EditApprovedRecoupment = associatedClaims[index].ApprovedRecoupmentAmount;
    }
  }

  checkAllClick(event): void {
    if (!this.selectAll.checked) {
      this.setAllEditRecoupmentDefaults(this.associatedClaims.length, false);

      if (this.toolbarHelper.isRecoupment) {
        this.toolbarHelper.updateBtnsForRecoupment();
      }
    } else {
      for (let i = 0; i < this.selectedClaims.length; i++) {
        this.setApprovedRecoupmentRowDefault(i, this.associatedClaims, true);
      }
    }
  }

  updateRowEdit(event): void {
    const rowIndex = this.findRowIndex(event.data.RawDataImport_ShardClaimIdentifier);
    this.setApprovedRecoupmentRowDefault(rowIndex, this.associatedClaims, true);
  }

  findRowIndex(claimIdentifier: number): number {
    return findIndex(this.associatedClaims, (o: AssociatedClaim) => {
      return o.RawDataImport_ShardClaimIdentifier === claimIdentifier;
    });
  }

  checkRowSelected(claimId: number): boolean {
    const row = find(this.selectedClaims, { RawDataImport_ShardClaimIdentifier: claimId });
    return row ? true : false;
  }

  undoRowEdit(event): void {
    if ( event.data ) {
      const relatedRowIndex = this.findRowIndex(event.data.RawDataImport_ShardClaimIdentifier);

      this.setApprovedRecoupmentRowDefault(relatedRowIndex, this.associatedClaims, false);
    }

    if (this.toolbarHelper.isRecoupment && this.selectedClaims.length === 0) {
      this.toolbarHelper.updateBtnsForRecoupment();
    }
  }

  loadDataLazy(event: LazyLoadEvent) {
    this.selectedClaims = [];
    event = this.setSort(event);
    if (this.editAuditId && event.sortField !== 'Selected') {
      if (!this.associatedClaimsDataLoading) {
        this.associatedClaimsDataLoading = true;
      }
      this.getAssociatedClaims(
        new Pagination(MAX_ROWS, event.first, event.sortField, event.sortOrder));
    }
  }

  // End Data table viewmodel impl

  deleteCallback(event: ModalAction) {
    if ( event.action === 'save') {
      this.claimService.deleteClaim(this.editAuditId, this.toolbarHelper.buildClaimWithSource(this.selectedClaims))
      .subscribe( () => {
        toastr.success('Claim(s) deleted!');
        this.refreshGrid();
      }, () => {
        toastr.error('There was an issue deleting the selected claim(s)');
      });
    }
    this.toolbarHelper.showAction = '';
  }

  initializeComponent() {
    this.editAuditId = this.route.snapshot.params.id;
    this.initAuditLoadForm();
    this.loadDropDowns();
    if (this.editAuditId) {
      this.associatedClaimsDataLoading = true;
    }

    this.toolbarHelper = new ToolbarHelper(this.authorizationService,
      this.claimService,
      this.route.snapshot.params.id);

  }

  ngOnDestroy(): void {
    if (this.clientChangedSubscription) {
      this.clientChangedSubscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
      this.initialStateAuditModel = null;
    }
  }

  subscribeToClientChange() {
    this.clientChangedSubscription = this.globalFilterService.ClientChanged.subscribe(() => {
      if (this.editAuditId) {
        this.router.navigate(['audit/auditsearch']);
      } else {
        this.loadDropDowns();
        this.setAuditClassDropDown();
        this.resetTemplateField();
        toastr.info('Client changed. Reloaded client master data.');
      }
    });
  }

  undoSelect(event): void {
    this.selectedClaims = [];
  }

  subscribeToNavigationChange() {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.initializeComponent();
      }
    });
  }

  initFormGroup(audit: Audit) {
    this.auditFormGroup = this.fb.group({
      auditId: audit.AuditId,
      auditReferenceNumber: [audit.AuditReferenceNumber, Validators.compose([Validators.required, Validators.maxLength(50)])],
      statusId: [audit.StatusId, Validators.compose([Validators.required])],
      specialistId: audit.SpecialistId,
      auditCarrierId: [audit.AuditCarrierId, Validators.compose([Validators.required])],
      deliveryMethod: [audit.DeliveryMethod],
      vendorId: audit.VendorId,
      auditClassId: audit.AuditClassId,
      auditTypeId: [audit.AuditTypeId, Validators.compose([Validators.required])],
      notificationDate: [(audit.NotificationDate != null) ? this.datePipe.transform(audit.NotificationDate, 'MM/dd/yyyy') : '',
      Validators.compose([Validators.required])],
      receivedDate: [(audit.ReceivedDate != null) ? this.datePipe.transform(audit.ReceivedDate, 'MM/dd/yyyy') : ''
        , Validators.compose([Validators.required])],
      auditDueDate: [(audit.AuditDueDate != null) ? this.datePipe.transform(audit.AuditDueDate, 'MM/dd/yyyy') : ''
        , Validators.compose([Validators.required])],
      statusDueDate: [(audit.StatusDueDate != null) ? this.datePipe.transform(audit.StatusDueDate, 'MM/dd/yyyy') : ''
      , Validators.compose([Validators.required])],
      noteText: [audit.NoteText, Validators.maxLength(2000)],
      isHideFromWorkflow: (audit.IsHideFromWorkflow),
      isPending: (audit.IsPending)
    });
    this.auditFormGroup.controls['isHideFromWorkflow'].setValue(audit.IsHideFromWorkflow);
    this.auditFormGroup.controls['isPending'].setValue(audit.IsPending);
  }
  loadDropDowns() {
    // set flag to disable ui controls
    this.loadingMasterData = true;

    // data comes out
    // in the same order it was requested.
    forkJoin([
      this.auditTypeService.getAuditTypeListItems(),
      this.auditStatusService.getAuditStatusListItems(),
      this.auditSpecialistService.getAuditSpecialistListItems(),
      this.auditCarrierService.getAuditCarrierListItems(),
      this.auditVendorService.getAuditVendorListItems(),
      this.deliveryMethodService.getDeliverMethods()
      // Call to AuditClassService has been moved to setInitialAuditStateAndForm() method to make sure
      // the getAuditClassListItems, getAuditClassPrimeNgListItems and initialStateAuditModel loads at the
      // same time, so that template for auditclass can be populated.
    ])
      .subscribe(([
        respAuditTypes,
        respAuditStatuses,
        respAuditSpecialists,
        respAuditCarriers,
        respAuditVendors,
        respDeliveryMethods
      ]) => {
        this.auditTypesListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditTypes);
        this.auditStatusListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditStatuses);
        this.auditSpecialistListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditSpecialists);
        this.auditCarrierListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditCarriers);
        this.auditVendorListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditVendors);
        this.deliveryMethodListItems = this.uiHelperService.addEmptyFirstRowToListItems(respDeliveryMethods);
        // set flag to enable ui controls
        this.loadingMasterData = false; // we can enable ui components when false
      });
  }

  // clear the form back to original values if Reset button clicked
  reset() {
    this.initFormGroup(this.initialStateAuditModel);
    this.resetTemplateField();
  }

  removeFilter() {
    this.rxNumbersFilter = [];
    if (!this.associatedClaimsDataLoading) {
      this.associatedClaimsDataLoading = true;
    }
    this.refreshGrid();
  }

  // click Event handler
  saveAudit() {
    this.disableSave = true;
    const auditModel = this.getAuditModelFromForm();

    if (!this.editAuditId) {
      this.auditService.addAudit(auditModel)
        .subscribe(result => {
          toastr.success('Successfully created.');
          this.setInitialAuditStateAndForm(auditModel);
          this.disableSave = false;
          this.associatedClaims = [];
          this.totalItemCount = 0;
          this.router.navigate(['audit/audit-form', { 'id': result.AuditId }]);
        }, err => {
          toastr.error(err);
          this.disableSave = false;
        });
    } else {
      this.auditService.updateAudit(auditModel)
        .subscribe(result => {
          toastr.success('Successfully updated.');
          this.initFormGroup(auditModel);
          // this.initialStateAuditModel = auditModel;
          this.disableSave = false;
        }, err => {
          toastr.error(err);
          this.disableSave = false;
        });
    }
  }

  setInitialAuditStateAndForm(audit: Audit) {
    this.initFormGroup(audit);
    this.initialStateAuditModel = audit;
    this.setAuditClassDropDown();
    this.auditNumberText = `Update Audit: # ${this.initialStateAuditModel.AuditNumber}`;
  }

  setAuditClassDropDown() {
    this.auditClassService.getAuditClassListItems().subscribe((respAuditClasses) => {
      this.auditClassListItems = this.uiHelperService.addEmptyFirstRowToListItems(respAuditClasses);
      this.auditClassService.getAuditClassPrimeNgListItems().subscribe((resAuditTemplate) => {
        this.auditClassTemplateListItems = resAuditTemplate;
        this.getTemplate(this.initialStateAuditModel.AuditClassId);
      });
    });
  }

  getAuditModelFromForm(): Audit {
    const myAudit = this.initialStateAuditModel;
    myAudit.AuditId = this.editAuditId; // will be null when adding
    const controls = this.auditFormGroup.controls;
    myAudit.AuditReferenceNumber = controls['auditReferenceNumber'].value;
    myAudit.StatusId = controls['statusId'].value;
    myAudit.SpecialistId = controls['specialistId'].value;
    myAudit.AuditCarrierId = controls['auditCarrierId'].value;
    myAudit.DeliveryMethod = controls['deliveryMethod'].value;
    myAudit.VendorId = controls['vendorId'].value;
    myAudit.AuditTypeId = controls['auditTypeId'].value;
    myAudit.NotificationDate = controls['notificationDate'].value;
    myAudit.ReceivedDate = controls['receivedDate'].value;
    myAudit.AuditDueDate = controls['auditDueDate'].value;
    myAudit.StatusDueDate = controls['statusDueDate'].value;
    myAudit.NoteText = controls['noteText'].value;
    myAudit.AuditClassId = controls['auditClassId'].value;
    myAudit.IsHideFromWorkflow = this.setIsHideFromWorkflow(controls['isHideFromWorkflow'].value);
    myAudit.IsPending = this.setIsPending(controls['isPending'].value);
    return myAudit;
  }

  limit(event) {
    const controlName = event.target.attributes['formcontrolname'].value;
    this.uiHelperService.limitToMaxLength(this.auditFormGroup, controlName);
  }

  // will need to uncomment when doing Update Audit story
  initAuditLoadForm() {
    if (this.editAuditId) {
      this.auditService.getAudit(this.editAuditId)
        .subscribe(
          (result) => {
            this.setInitialAuditStateAndForm(result);
          },
          err => {
            toastr.error('Error loading audit');
          });
    } else {
      this.setInitialAuditStateAndForm(new Audit());
    }
  }

  showDialog() {
    if (this.editAuditId) {
      this.showAddClaims = true;
    } else {
      toastr.error('No audit selected.');
    }
  }

  showFaxDialog() {
    if (this.editAuditId) {
      this.showSendFax = true;
    } else {
      toastr.error('No audit selected.');
    }
  }

  showStatusHistoryDialog() {
    if (this.editAuditId) {
      this.showStatusHistory = true;
    } else {
      toastr.error('No audit selected.');
    }
  }

  showSearchAuditClaimsDialog() {
    if (this.editAuditId) {
      this.showSearchAuditClaimsHistory = true;
    } else {
      toastr.error('No audit selected.');
    }
  }

  showImagesDialog() {
    if (this.editAuditId) {
      this.showAddImages = true;
    } else {
      toastr.error('No audit selected.');
    }
  }

  hideDialog() {
    this.showAddClaims = false;
    this.refreshGrid();
  }

  hideSearchAuditClaimDialog(event: string[]) {
    this.showSearchAuditClaimsHistory = false;
    if (event) {
      this.rxNumbersFilter = event;
      this.rxFilterString = this.rxNumbersFilter.join(', ');
    }
    if (!this.associatedClaimsDataLoading) {
      this.associatedClaimsDataLoading = true;
    }
    this.refreshGrid();
  }

  hideImagesDialog() {
    this.associatedClaimsDataLoading = false;
    this.showAddImages = false;
  }

  hideFaxDialog() {
    this.showSendFax = false;
  }

  hideStatusHistoryDialog() {
    this.showStatusHistory = false;
  }

  hideClaimDetailDialog() {
    this.associatedClaimsDataLoading = false;
    this.claimDetailAuditClaimId = undefined;
    this.showClaimDetail = false;
  }

  setSort(event: LazyLoadEvent) {
    if (typeof event.sortField === 'boolean') {
      event.sortField = this.prevSort;
      event.sortOrder = this.prevSortOrder;
    } else {
      this.prevSort = event.sortField;
      this.prevSortOrder = event.sortOrder;
    }

    return event;
  }

  selectText(event: any, flag: boolean) {
    event.target.focus();
    event.target.select();
    event.stopPropagation();
  }

  // context menu action
  itemsClicked(action: ModalAction) {
    switch (action.id) {
      case 'AssociateClaims':
        this.showDialog();
      break;
      case 'NewAuditClaims':
        this.showFileUploadDialog(true);
      break;
      case 'UploadDocuments':
        this.showImagesDialog();
      break;
      case 'SendFax':
        this.showFaxDialog();
      break;
      case 'StatusHistory':
        this.showStatusHistoryDialog();
      break;
      case 'SearchAuditClaims':
        this.showSearchAuditClaimsDialog();
      break;
    }
  }

  // FILE UPLOAD FUNCTIONS
  uploadComplete(event: any): void {
    this.uploadDisabled = false;
    this.refreshGrid();
  }

  showFileUploadDialog(event: boolean): void {
    this.uploadDisabled = true;
    if (event) {
      this.showFileDialog = true;
    }
  }

  setIsHideFromWorkflow(isHideFromWorkflow: boolean): boolean {
    if (isHideFromWorkflow !== true) {
      isHideFromWorkflow = false;
    }
    return isHideFromWorkflow;
  }

  setIsPending(isPending: boolean): boolean {
    if (isPending !== true) {
      isPending = false;
    }
    return isPending;
  }

  clickClaimDetail(rowData: AssociatedClaim): void {
    this.claimDetailAuditClaimId = rowData.AuditClaimId;
    this.claimDetailSource = rowData.Source;
    this.showClaimDetail = true;
  }

  getTemplate(value): void {
    this.showTemplate = true;
    if (value) {
      const templateDetails = this.auditClassTemplateListItems.filter(item => item.label === value);
      templateDetails.forEach((template) => {
        this.templateName = template.value.TemplateName;
        this.imageId = template.value.ImageId;
      });
      if (this.imageId) {
        this.isTemplateAvailable = true;
      } else {
        this.isTemplateAvailable = false;
      }
    } else {
      this.showTemplate = false;
    }
  }

  resetTemplateField(): void {
      this.getTemplate(this.initialStateAuditModel.AuditClassId);
  }

  onPhiToggleChange(): void {
    this.phiToggleEnabled = this.phiService.PhiToggle;
  }

  clickTemplate(event) {
    this.imageRepoService.getImageMetadata(this.imageId).subscribe((imageSearchResult) => {
      const mimeType = imageSearchResult.MimeType;
      this.imageRepoService.downloadImage(this.imageId).subscribe((data) => {

        const blob = new Blob([data], {type: mimeType});

        const downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.templateName;

        // this is a get around to download a blob.
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, this.templateName);
        } else {
          link.click();
          link = null;
        }
      }, err => {
        toastr.error('There was an issue getting the image from the server.');
      });
    });

    }
}
