import { Component, OnInit, ViewChild } from '@angular/core';
import { VendorTableHelper } from './helpers/vendor-table-helper';
import { GlobalFilterService,
         AuthorizationService,
         AuditVendorService } from 'src/app/services';
import { Table } from 'primeng/table';
import { dropRight } from 'lodash';
import { Pagination,
         AuditVendor } from 'src/app/models';
import { LazyLoadEvent } from 'primeng/api';
import { VendorToolbarHelper } from './helpers/vendor-toolbar.helper';
import { ToolbarAction } from 'src/app/shared/models/toolbarAction';
import { ModalAction } from 'src/app/shared/models/modalAction';
import * as toastr from 'toastr';
import { AdminModalAction } from '../shared/models/adminModalAction';
import { BusinessResponseService } from 'src/app/services/business-response.service';

@Component({
  selector: 'app-audit-vendor',
  templateUrl: './audit-vendor.component.html',
  styleUrls: ['./audit-vendor.component.css']
})
export class AuditVendorComponent implements OnInit {
  public readonly tableHelper: VendorTableHelper;
  public readonly toolbarHelper: VendorToolbarHelper;
  public readonly max_rows = 30;
  public readonly viewFeatures = [{ feature: 'UI::VendorDelete', name: 'delete', accessable: false},
                                  { feature: 'UI::VendorEdit', name: 'edit', accessable: false }];
  public deleteText = '';
  public readonly deleteTitle = 'Delete Confirmation';
  public tableLoading: boolean;
  public confirmDelete: boolean;
  public vendors: AuditVendor[];
  public totalItemCount: number;
  public deleteAllowed: boolean;
  public showAddEditDialog: boolean;
  public editDialogTitle: string;
  public editId: string;
  public vendorName: string;
  public vendorId: number;
  constructor(private auditVendorService: AuditVendorService,
              private authService: AuthorizationService,
              private globalFilterService: GlobalFilterService,
              private businessRespService: BusinessResponseService
              ) {
    this.tableHelper = new VendorTableHelper();
    this.toolbarHelper = new VendorToolbarHelper(this.authService);
    this.globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
  }

  @ViewChild('dt', { static: true }) dTable: Table;

  ngOnInit() {
    this.tableLoading = true;
    this.checkFeatures();
  }

  private checkFeatures(): void {
    for ( let i = 0; i < this.viewFeatures.length; i++) {
      if (this.authService.hasFeature(this.viewFeatures[i].feature)) {
        this.viewFeatures[i].accessable = true;
      }
    }
    if ( !this.viewFeatures[0].accessable) {
      this.tableHelper.tableColumns = dropRight(this.tableHelper.tableColumns);
    }
  }

  loadDataLazy(event: LazyLoadEvent): void {
    if (!this.tableLoading) {
      this.tableLoading = true;
    }

    this.auditVendorService.getAuditVendorsLimitedResult
      (new Pagination(this.max_rows, event.first, event.sortField, event.sortOrder))
      .subscribe( (data) => {
        this.vendors = data.Items;
        this.totalItemCount = data.TotalItemCount;
        this.tableLoading = false;
      }, (error) => {
        this.tableLoading = false;
      });
  }

  onClientsChanged(): void {
    if (this.dTable) {
      this.dTable.reset();
      this.tableLoading = true;
    }
  }

  toolbarBtnClick(event: ToolbarAction) {
    switch (event.index) {
      case 0: {
        this.dTable.reset();
        break;
      } case 1: {
        this.handleDialogs('add');
      }
    }
  }

  clickName(name: string, id: number) {
    this.vendorName = name;
    this.vendorId = id;
    this.handleDialogs('edit');
  }

  deleteClicked(rowData: AuditVendor): void {
    this.vendorId = rowData.VendorId;
    this.deleteText = `Are you sure that you want to delete <strong>${rowData.Name}</strong>?`;
    this.handleDialogs('delete');
  }

  handleDialogs(name: string) {
    if (name === 'edit') {
      this.editDialogTitle = 'Edit Vendor';
      this.editId = 'edit';
      this.showAddEditDialog = true;
    }

    if (name === 'add') {
      this.vendorName = null;
      this.editDialogTitle = 'Add New Vendor';
      this.editId = 'add';
      this.showAddEditDialog = true;
    }

    if ( name === 'delete') {
      this.confirmDelete = true;
    }
  }

  deleteCallback(event: ModalAction): void {
    if (event.action === 'cancel') {
      this.confirmDelete = false;
      return;
    }
    this.auditVendorService.deleteAuditVendor(this.vendorId)
      .subscribe( (response) => {
        toastr.success('Vendor was deleted successfully!');
        this.resetVendor();
        this.dTable.reset();
        this.confirmDelete = false;
      }, () => {
        toastr.error('We are not able to delete the vendor at this time.');
      });
  }

  editCallback(event: AdminModalAction): void {
    if (event.action === 'cancel') {
      this.showAddEditDialog = false;
      return;
    }
    if (event.action === 'update') {
      this.auditVendorService.updateAuditVendor(this.vendorId, event.nameValue)
        .subscribe( (response) => {
          if (this.businessRespService.handleBusinessResponse('Update vendor name successful!', response)) {
            this.resetVendor();
            this.dTable.reset();
            this.showAddEditDialog = false;
          }
        }, () => {
          toastr.error('We are not able to update the vendor name at this time.');
        });
    }

    if (event.action === 'add') {
      this.auditVendorService.addAuditVendor(event.nameValue)
        .subscribe( (response) => {
          if ( this.businessRespService.handleBusinessResponse('Add vendor successful!', response)) {
            this.resetVendor();
            this.dTable.reset();
            this.showAddEditDialog = false;
          }
        }, () => {
          toastr.error('We are not able to add the vendor at this time.');
        });
    }
  }

  resetVendor(): void {
    this.vendorId = null;
    this.vendorName = '';
  }
}
