import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditSpecialistTableHelper } from './helpers/audit-specialist-table-helper';
import { AuditSpecialistService, GlobalFilterService, AuthorizationService } from 'src/app/services';
import { Table } from 'primeng/table';
import { dropRight } from 'lodash';
import { AuditSpecialist, Pagination } from 'src/app/models';
import { LazyLoadEvent } from 'primeng/api';
import { SpecialistToolbarHelper } from './helpers/specialist-toolbar.helper';
import { ToolbarAction } from 'src/app/shared/models/toolbarAction';
import { ModalAction } from 'src/app/shared/models/modalAction';
import * as toastr from 'toastr';
import { AdminModalAction } from 'src/app/components/admin/shared/models/adminModalAction';
import { BusinessResponseService } from 'src/app/services/business-response.service';

@Component({
  selector: 'app-audit-specialist',
  templateUrl: './audit-specialist.component.html',
  styleUrls: ['./audit-specialist.component.css']
})
export class AuditSpecialistComponent implements OnInit {
  public readonly tableHelper: AuditSpecialistTableHelper;
  public readonly toolbarHelper: SpecialistToolbarHelper;
  public readonly max_rows = 30;
  public readonly viewFeatures = [{ feature: 'UI::SpecialistDelete', name: 'delete', accessable: false},
                                  { feature: 'UI::SpecialistEdit', name: 'edit', accessable: false }];
  public deleteText = '';
  public readonly deleteTitle = 'Delete Confirmation';
  public tableLoading: boolean;
  public confirmDelete: boolean;
  public specialists: AuditSpecialist[];
  public totalItemCount: number;
  public deleteAllowed: boolean;
  public showAddEditDialog: boolean;
  public editDialogTitle: string;
  public editId: string;
  public specialistName: string;
  public specialistId: number;

  constructor(private auditSpecialistService: AuditSpecialistService,
              private authService: AuthorizationService,
              private globalFilterService: GlobalFilterService,
              private businessRespService: BusinessResponseService) {
    this.tableHelper = new AuditSpecialistTableHelper();
    this.toolbarHelper = new SpecialistToolbarHelper(this.authService);
    this.globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
  }

  @ViewChild('dt', { static: true }) dTable: Table;

  ngOnInit() {
    this.tableLoading = true;
    this.checkFeatures();
  }

  private checkFeatures(): void {
    for ( let i = 0; i < this.viewFeatures.length; i++) {
      if (this.authService.hasFeature(this.viewFeatures[i].feature)) {
        this.viewFeatures[i].accessable = true;
      }
    }
    if ( !this.viewFeatures[0].accessable) {
      this.tableHelper.tableColumns = dropRight(this.tableHelper.tableColumns);
    }
  }

  loadDataLazy(event: LazyLoadEvent): void {
    if (!this.tableLoading) {
      this.tableLoading = true;
    }

    this.auditSpecialistService.getAuditSpecialistsLimitedResult
      (new Pagination(this.max_rows, event.first, event.sortField, event.sortOrder))
      .subscribe( (data) => {
        this.specialists = data.Items;
        this.totalItemCount = data.TotalItemCount;
        this.tableLoading = false;
      }, (error) => {
        this.tableLoading = false;
      });
  }

  onClientsChanged(): void {
    if (this.dTable) {
      this.dTable.reset();
      this.tableLoading = true;
    }
  }

  toolbarBtnClick(event: ToolbarAction) {
    switch (event.index) {
      case 0: {
        this.dTable.reset();
        break;
      } case 1: {
        this.handleDialogs('add');
      }
    }
  }

  clickName(name: string, id: number) {
    this.specialistName = name;
    this.specialistId = id;
    this.handleDialogs('edit');
  }

  deleteClicked(rowData: AuditSpecialist): void {
    this.specialistId = rowData.SpecialistId;
    this.deleteText = `Are you sure that you want to delete <strong>${rowData.Name}</strong>?`;
    this.handleDialogs('delete');
  }

  handleDialogs(name: string) {
    if (name === 'edit') {
      this.editDialogTitle = 'Edit Specialist';
      this.editId = 'edit';
      this.showAddEditDialog = true;
    }

    if (name === 'add') {
      this.specialistName = null;
      this.editDialogTitle = 'Add New Specialist';
      this.editId = 'add';
      this.showAddEditDialog = true;
    }

    if ( name === 'delete') {
      this.confirmDelete = true;
    }
  }

  deleteCallback(event: ModalAction): void {
    if (event.action === 'cancel') {
      this.confirmDelete = false;
      return;
    }
    this.auditSpecialistService.deleteAuditSpecialist(this.specialistId)
      .subscribe( (response) => {
        toastr.success('Specialist was deleted successfully!');
        this.resetSpecialist();
        this.dTable.reset();
        this.confirmDelete = false;
      }, () => {
        toastr.error('We are not able to delete the specialist at this time.');
      });
  }

  editCallback(event: AdminModalAction): void {
    if (event.action === 'cancel') {
      this.showAddEditDialog = false;
      return;
    }
    if (event.action === 'update') {
      this.auditSpecialistService.updateAuditSpecialist(this.specialistId, event.nameValue)
        .subscribe( (response) => {
          if ( this.businessRespService.handleBusinessResponse('Update specialist name successful!', response)) {
            this.resetSpecialist();
            this.dTable.reset();
            this.showAddEditDialog = false;
          }
        }, () => {
          toastr.error('We are not able to update the specialist name at this time.');
        });
    }

    if (event.action === 'add') {
      this.auditSpecialistService.addAuditSpecialist(event.nameValue)
        .subscribe( (response) => {
          if (this.businessRespService.handleBusinessResponse('Add specialist successful!', response)) {
            this.resetSpecialist();
            this.dTable.reset();
            this.showAddEditDialog = false;
          }
        }, () => {
          toastr.error('We are not able to add the specialist at this time.');
        });
    }
  }

  resetSpecialist(): void {
    this.specialistId = null;
    this.specialistName = '';
  }
}
