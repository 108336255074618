<p-dialog header="{{titleText}}" [(visible)]="show" [modal]="true" [style]="{'margin': '5px'}" [closable]="false"
 [style]="{'width':'600px'}" [contentStyle]="{'min-height':'200px'}" (onShow)="onShow()" (onHide)="onHide()">
  <div>
    <label>
      <div class="inm-label--required">Name:</div>
      <input #name class="inm-input" style="width: 420px" [(ngModel)]="nameValueInternal" (input)="limit('nameChange')"
        (keydown.enter)="submitClick('save')">
    </label>
    <div class="inm-spaced">
      <div>
        <div class="inm-label">Template:</div>
        <div class='inm-spaced'>
          <section *ngIf="showTable">
           <p-table #dTable [columns]="tableHelper.auditTemplateColumns"
             [responsive]="true" [scrollable]="true" [value]="editClassModelArray">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [ngClass]="col.hdrClass ? col.hdrClass : 'default'"
                  [pSortableColumn]="col.sort === false ? false : col.field">
                  {{col.header}}
                  <p-sortIcon *ngIf="col.sort !== false" [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns" [ngClass]="col.fldClass ? col.fldClass : 'default'">
                  <div *ngIf="col.field === 'TemplateName'" style="cursor:default;text-align: center;">
                      {{rowData[col.field]}}
                  </div>
                  <div *ngIf="col.field === 'Delete'">
                    <a (click)="confirmDelete(rowData)"
                      style="cursor: pointer; width: 20px;">
                      <svg class="inm-icon" aria-label="Delete">
                        <use xlink:href="#inm-icon__trash" class="danger" /></svg>
                    </a>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table> 
        </section>
        <section *ngIf="!showTable">
          <tr>
            <td colspan="1" style=" text-align: center">
              No Template uploaded for this audit class
            </td>
          </tr>
        </section>
        </div>
      </div>
    </div>
  <div class="btn-container-dialog" style="padding-top: 14px">
    <button #save type="button" class="inm-button btn-padding" [disabled]="submitDisabled"
      (click)="submitClick('save')">Save</button>
    <button type="button" class="inm-button btn-padding" (click)="submitClick('cancel')">Cancel</button>
    <button type="button" class="inm-button btn-padding" (click)="uploadClicked()" [disabled]="uploadDisabled">Upload Template</button>
  </div>
  </div>
</p-dialog>
<app-delete-dialog [id]="'imageId'" [show]="showDeleteDialog" [titleText]="'Confirm Delete Template'"
  [message]="deleteMessage" (modalAction)="delete($event)">
</app-delete-dialog>
<app-template-upload-component [(show)]="showImagesDialog" [key]="id" [keyName]="nameValueInternal"
  (uploadImageComplete)="uploadImageComplete($event)">
</app-template-upload-component>