import { CustomCurrencyPipe } from './custom-currency-pipe';
import { NgModule } from '@angular/core';
import { CustomPhonePipe } from './custom-phone-pipe';

@NgModule({
  declarations: [
    CustomCurrencyPipe,
    CustomPhonePipe
  ],
  exports: [
    CustomCurrencyPipe,
    CustomPhonePipe
  ]}
)

export class CustomPipeModule {}
