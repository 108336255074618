import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Pagination, ILimitedResult, AuditCarrier } from '../../../models';
import {
  AuditCarrierService,
  AuthorizationService,
  GlobalFilterService,
} from '../../../services';

import { AuditCarrierTableHelper } from './helpers/audit-carrier-table-helper';

import * as toastr from 'toastr';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { AdminModalAction } from 'src/app/components/admin/shared/models/adminModalAction';
import { ToolbarAction } from 'src/app/shared/models/toolbarAction';
import { CarrierToolbarHelper } from './helpers/carrier-toolbar.helper';
import { ModalAction } from 'src/app/shared/models/modalAction';
import { dropRight } from 'lodash';
import { BusinessResponseService } from 'src/app/services/business-response.service';

@Component({
  selector: 'app-audit-carriers',
  templateUrl: './audit-carriers.component.html',
  styleUrls: ['./audit-carriers.component.css']
})
export class AuditCarriersComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: true }) dTable: Table;
  public readonly maxRows = 30;
  public gridLoading = true;
  public totalItemCount = 0;
  public readonly default_page: Pagination;
  public auditCarrierItems: AuditCarrier[];
  public readonly auditCarrierTableHelper: AuditCarrierTableHelper;
  public readonly toolbarHelper: CarrierToolbarHelper;
  public readonly featureAuditCarrierAdd = 'UI::AuditCarrierAdd';       // feature user must have to enable Edit links in grid
  public readonly featureAuditCarrierDelete = 'UI::AuditCarrierDelete';  // feature user must have to enable Delete links in grid
  public readonly featureAuditCarrierEdit = 'UI::AuditCarrierEdit';       // feature user must have to enable Edit links in grid
  public readonly deleteFailMessage = 'We are not able to delete the carrier at this time.';
  public readonly deleteSuccessMessage = 'Audit carrier was deleted successfully!';
  public readonly updateFailMessage = 'We are not able to update the audit carrier name at this time.';
  public readonly updateSuccessMessage = 'Update audit carrier name successful!';
  public readonly addFailMessage = 'We are not able to add the audit carrier at this time.';
  public readonly addSuccessMessage = 'Add audit carrier successful!';
  public readonly addTitle = 'Add New Audit Carrier';
  public readonly editTitle = 'Edit Audit Carrier';
  public clientChangedSubscription: any;
  // add/edit variables
  public canEdit: boolean;
  public showAuditCarrierDialog = false;
  public showDeleteDialog = false;
  public addEditTitle: string;
  public carrierId: number;
  public carrierName: string;
  public deleteMessage: string;

  constructor(
    private auditCarrierService: AuditCarrierService,
    private authorizationService: AuthorizationService,
    private globalFilterService: GlobalFilterService,
    private businessResponseService: BusinessResponseService
  ) {
    // subscribe to global so we can reset form if global client is changed
    this.clientChangedSubscription = this.globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
    this.default_page = new Pagination(this.maxRows, 0, '');
    this.auditCarrierTableHelper = new AuditCarrierTableHelper();
    this.toolbarHelper = new CarrierToolbarHelper(this.authorizationService);
  }

  onClientsChanged(): void {
    this.initialPageSetup();
  }

  ngOnInit() {
    this.initialPageSetup();
  }

  ngOnDestroy(): void {
    if (this.clientChangedSubscription) {
      this.clientChangedSubscription.unsubscribe();
    }
  }

  initialPageSetup() {
    if (this.dTable) {
      this.dTable.reset();
    }
    this.checkFeatures();
  }

  private checkFeatures(): void {
    this.canEdit = this.hasEditFeature();

    if (!this.hasDeleteFeature()) {
      this.auditCarrierTableHelper.carrierColumns = dropRight(this.auditCarrierTableHelper.carrierColumns);
    }
  }

  toolbarBtnClick(event: ToolbarAction) {
    console.log(event);
    switch (event.name) {
      case 'refresh': {
        this.dTable.reset();
        break;
      } case 'addCarrier': {
        this.openEditDialog();
        break;
      }
    }
  }

  loadDataLazy(event: LazyLoadEvent) {
    if (!this.gridLoading) {
      this.gridLoading = true;
    }
    this.getAuditCarriers(new Pagination(this.maxRows, event.first, event.sortField, event.sortOrder));
  }

  getAuditCarriers(pagination: Pagination) {
    this.auditCarrierService.getAuditCarriers(pagination)
      .subscribe(
        (data: ILimitedResult<AuditCarrier>) => {
          this.auditCarrierItems = data.Items;
          this.gridLoading = false;
          this.totalItemCount = data.TotalItemCount;
        }, err => {
          this.gridLoading = false;
          toastr.error('Error loading data.');
        }
      );
  }

  confirmDelete(rowData) {
    this.deleteMessage =
      `Are you sure that you want to delete <strong>${rowData.Name}</strong>?`;
    this.carrierId = rowData.AuditCarrierId;
    this.showDeleteDialog = true;
  }

  deleteModalAction(modalAction: ModalAction, callback?: () => void) {
    if (modalAction.action === 'cancel') {
      this.showDeleteDialog = false;
      return;
    }
    if (modalAction.action === 'save') {
      this.auditCarrierService.deleteAuditCarrier(<number><any>modalAction.id)
      .subscribe((success) => {
        toastr.success(this.deleteSuccessMessage);
        this.dTable.reset();
        this.showDeleteDialog = false;
       }, () => {
          toastr.error(this.deleteFailMessage);
       });
    }
  }

  refresh() {
    this.dTable.reset();
  }

  hasAddFeature(): boolean {
    return this.authorizationService.hasFeature(this.featureAuditCarrierAdd);
  }

  hasDeleteFeature(): boolean {
    return this.authorizationService.hasFeature(this.featureAuditCarrierDelete);
  }

  hasEditFeature(): boolean {
    return this.authorizationService.hasFeature(this.featureAuditCarrierEdit);
  }

  openEditDialog(rowData?: any) {
    if (rowData) {
      // editing
      this.addEditTitle = this.editTitle;
      this.carrierId = rowData['AuditCarrierId'];
      this.carrierName = rowData['Name'];
      this.showAuditCarrierDialog = true;
    } else {
      // adding
      this.addEditTitle = this.addTitle;
      this.carrierId = null;
      this.carrierName = '';
      this.showAuditCarrierDialog = true;
    }
  }

  modalAction(modalAction: AdminModalAction, callback?: () => void) {
    if (modalAction.action === 'cancel') {
      this.showAuditCarrierDialog = false;
      return;
    }
    const editedCarrier: AuditCarrier = new AuditCarrier();
    editedCarrier.AuditCarrierId = modalAction.id;
    editedCarrier.Name = modalAction.nameValue;
    editedCarrier.CreateDate = new Date();
    editedCarrier.CreatedBy = "";
    editedCarrier.InUse = false;
    editedCarrier.ModifiedBy = "";
    editedCarrier.ModifiedDate = new Date();

    if (modalAction.action === 'update') {
      this.auditCarrierService.updateAuditCarrier(editedCarrier).subscribe( (response) => {
        if (this.businessResponseService.handleBusinessResponse(this.updateSuccessMessage, response)) {
          this.refresh();
          this.showAuditCarrierDialog = false;
        }
      }, () => {
        toastr.error(this.updateFailMessage);
      });
    } else if (modalAction.action === 'add') {
      this.auditCarrierService.addAuditCarrier(editedCarrier).subscribe( (response) => {
        if ( this.businessResponseService.handleBusinessResponse(this.addSuccessMessage, response)) {
          this.refresh();
          this.showAuditCarrierDialog = false;
        }
      }, () => {
        toastr.error(this.addFailMessage);
      });
    }
  }
}
