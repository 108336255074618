import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { PrimeNgListItem} from '../models';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DeliveryMethodService {

  private deliveryMethods: PrimeNgListItem[];

  constructor(
  ) {}

  public getDeliverMethods(): Observable<PrimeNgListItem[]> {
   const deliveryMethods = [
      new PrimeNgListItem('Email', 'email'),
      new PrimeNgListItem('Fax', 'fax'),
      new PrimeNgListItem('Mail', 'mail'),
      new PrimeNgListItem('Telephone', 'telephone'),
      new PrimeNgListItem('Website', 'website')
    ];
    return of(deliveryMethods);
  }
}
