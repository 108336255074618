export class AuditAddressBook {
    AuditAddressBookId: number;
    ClientId: number;
    ClientTenantId: number;
    CompanyName: string;
    FaxNumber: string;
    PhoneNumber: string;
    Notes: string;
    ContactName: string;
    CreatedBy: string;
    CreateDate: Date;
    ModifiedBy: string;
    ModifiedDate: Date;
}
