<p style="font-style: italic;color:orangered;padding:2px; ;">
  <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
  PHI toggle must be on to open documents.
</p>
<p-progressBar [ngStyle]="{ 'height': '1px' }" mode="indeterminate" *ngIf="loadingImgGrid"></p-progressBar>
<app-audit-toolbar [toolbarItemList]="toolbarHelper.currentToolbar" (OnClick)="toolbarBtnClick($event)"></app-audit-toolbar>
<div class='inm-spaced'>
<p-table #dTable
    [columns]="tableHelper.auditImageColumns"
    [value]="imageResults" 
    [loading]="loadingImgGrid" 
    [lazy]="true" 
    [responsive]="true" 
    [totalRecords]="totalItemCount" 
    [scrollable]="true" 
    scrollHeight="350px"
    (onLazyLoad)="loadImagesLazy($event)">
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr class="imageSearchCols">
          <th *ngFor="let col of columns" 
            [ngClass]="col.hdrClass ? col.hdrClass : 'default'" 
            [pSortableColumn]="col.sort === false ? false : col.field">
            {{col.header}} 
            <p-sortIcon *ngIf="col.sort !== false" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">      
                <tr>
          <td *ngFor="let col of columns" [ngClass]="col.fldClass ? col.fldClass : 'default'">
            <div *ngIf="col.field === 'Name'">
              <div *ngIf="getPhiToggle()">
              <a class="table-link" (click)="downloadFile(rowData)">
                {{rowData[col.field]}}</a></div>
              <div *ngIf="!getPhiToggle()" style="cursor:default;text-align: center;">
                {{rowData[col.field]}}
              </div>
            </div> 
            <div *ngIf="col.field === 'Delete'">
              <a *ngIf="rowData.InUse !== true && canDelete" (click)="confirmDelete(rowData)" style="cursor: pointer; width: 20px;">
                  <svg class="inm-icon" aria-label="Delete">
                    <use xlink:href="#inm-icon__trash" class="danger" /></svg>
              </a>
              <a *ngIf="!canDelete" style="cursor: default;width: 30px;">
                <svg class="inm-icon" aria-label="Delete">
                  <use xlink:href="#inm-icon__trash" class="disabled" /></svg>
              </a>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan ="7" style=" text-align: center">
              No records found
            </td>
          </tr>
        </ng-template>
      <ng-template pTemplate="summary">
        Total Record Count: {{ totalItemCount }}
      </ng-template>
  </p-table>
</div>
<app-delete-dialog 
[id]="'imageId'" 
[show]="showDeleteDialog" 
[titleText]="'Confirm Delete Document'"
[message]="deleteMessage"
(modalAction)="delete($event)">
</app-delete-dialog>
<app-image-upload-component
  [(show)]="showImagesDialog"
  [key]="auditId"
  (uploadImageComplete)="uploadImageComplete($event)">
</app-image-upload-component>

