import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RxAuditSummaryComponent } from 'src/app/components/rx-audit/rx-audit-summary/rx-audit-summary.component';
import { RxAuditViewComponent } from 'src/app/components/rx-audit/rx-audit-view/rx-audit-view.component';
import { AuthGuard, FeatureGuard } from 'src/app/guards';
import { ClientTenantGuard } from 'src/app/guards/clientTenant.guard';

const routes: Routes = [{
  path: 'metrics',
  component: RxAuditSummaryComponent,
  canActivate: [AuthGuard, ClientTenantGuard],
  children: [
    { path: '', redirectTo: 'analytics/home', pathMatch: 'prefix'},
    { path: 'analytics', redirectTo: 'analytics/:slugid', pathMatch:'full'},
    {
      path: 'analytics/:slugid',
      component: RxAuditViewComponent,
      canActivate: [AuthGuard, FeatureGuard],
      data: { feature: 'UI::MenuItemMetrics', shouldDetach: true },
    }
  ],
  runGuardsAndResolvers: 'always'
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RxAuditRoutingModule { }
