import { Component, OnInit } from '@angular/core';
import { AuditCarrierTableHelper } from './helpers/audit-carrier-table-helper';
import { AuditSummary, ILimitedResult, Pagination } from 'src/app/models';
import { AuditService, GlobalFilterService } from 'src/app/services';
import { ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
const MAX_ROWS = 30;

@Component({
  selector: 'app-audit-carrier-summary',
  templateUrl: './audit-carrier-summary.component.html',
  styleUrls: ['./audit-carrier-summary.component.css']
})

export class AuditCarrierSummaryComponent implements OnInit {

  @ViewChild('dt', { static: true }) dTable: Table;

  readonly auditCarrierTableHelper: AuditCarrierTableHelper;
  carrierSummary: AuditSummary[];
  summaryAttribute = 'AuditCarrier';
  carrierDataLoading: boolean;
  totalItemCount = MAX_ROWS;

  constructor(private auditService: AuditService,
              private globalFilterService: GlobalFilterService) {
    this.auditCarrierTableHelper = new AuditCarrierTableHelper();
    globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
  }

  onClientsChanged(): void {
    if (this.dTable) {
      this.dTable.reset();
      this.carrierDataLoading = true;
    }
  }

  ngOnInit() {
    this.carrierDataLoading = true;
  }

  getCarrierSummary(pagination: Pagination, searchAttribute: string) {
    this.auditService.getAuditSummary(pagination, searchAttribute)
      .subscribe(
        (data: ILimitedResult<AuditSummary>) => {
          this.carrierSummary = data.Items;
          this.carrierDataLoading = false;
          this.totalItemCount = data.TotalItemCount;
        }
    );
  }

  loadDataLazy(event: LazyLoadEvent) {
    if (!this.carrierDataLoading) {
      this.carrierDataLoading = true;
    }
    this.getCarrierSummary(new Pagination(MAX_ROWS, event.first, event.sortField, event.sortOrder), this.summaryAttribute);
  }

  refresh() {
    this.dTable.reset();
  }
}
