import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { TemplateModalAction } from './models/template-model-action';
import { AuditClass } from 'src/app/models/audit-class';
import { AuditTemplateTableHelper } from './helpers/audit-template-table.helper';
import { ToolbarAction } from 'src/app/shared/models/toolbarAction';
import { Table } from 'primeng/table';
import { Guid } from 'guid-typescript';
import { ModalAction } from 'src/app/shared/models/modalAction';
import { TemplateUploadAction } from '../../template-upload/models/template-upload-action';

@Component({
  selector: 'audit-class-template-add-edit-dialog',
  templateUrl: './audit-class-template-add-edit-dialog.component.html',
  styleUrls: ['./audit-class-template-add-edit-dialog.component.css']
})
export class AuditClassTemplateAddEditDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('name', { static: true }) name: ElementRef;
  @ViewChild('save', { static: true }) save: ElementRef;
  @Input() show: boolean;
  @Input() id: number;
  @Input() titleText: string;
  @Input() nameValue: string;
  @Input() editClassModel: AuditClass;
  @ViewChild('dTable', { static: false }) dTable: Table;
  @Output() modalAction: EventEmitter<TemplateModalAction> = new EventEmitter<TemplateModalAction>();

  submitDisabled: boolean;
  showTable: boolean;
  fileToBeUploaded: File;
  nameValueInternal: string;
  editClassModelArray: AuditClass[] = [];
  editClassModelInternal: AuditClass;

  tableHelper : AuditTemplateTableHelper;
  public uploadDisabled: boolean;
  public showImagesDialog: boolean;
  public deleteMessage: string;
  public showDeleteDialog = false;


  constructor() { 
    this.tableHelper = new AuditTemplateTableHelper();
  }

  ngOnInit() {
    this.checkSubmitEnabled();
  }

  ngAfterViewInit() {
    this.save.nativeElement.blur();
  }

  onHide() {
    this.name.nativeElement.blur();
    this.id = null;
    this.editClassModelArray = [];
  }

  onShow(): void {
    this.nameValueInternal = this.nameValue;
    this.initializeData();
    this.checkShowTable();
    this.name.nativeElement.focus();
    this.checkSubmitEnabled();
  }

  initializeData(): void{
    if(this.editClassModel){
      this.editClassModelInternal = Object.assign({},this.editClassModel);
      this.editClassModelArray.push(this.editClassModelInternal);
    }else{
      this.editClassModelInternal = new AuditClass();
    }
  }

  checkShowTable(): void{
      this.showTable = this.editClassModelInternal.TemplateName ? true : false;
  }

  limit(controlName: string) {
    switch (controlName) {
      case 'nameChange': {
        if (this.nameValueInternal && this.nameValueInternal.length > 50) {
          this.nameValueInternal = this.nameValueInternal.substring(0, 50);
          this.name.nativeElement.value = this.name.nativeElement.value.substring(0, 50);
        }
        break;
      }
        break;
    }
    this.checkSubmitEnabled();
  }

  submitClick(action: string): void {
    if (action === 'cancel') {
      this.modalAction.emit({
        action: action,
        auditClassId: null,
        nameValue: null,
        imageId: null,
        FormFiles: null
      });
      this.nameValueInternal = null;
    } else {
      if (this.id) {
        this.modalAction.emit({
          action: 'update',
          auditClassId: this.id,
          nameValue: this.nameValueInternal,
          imageId: this.editClassModelInternal.ImageId,
          FormFiles: this.fileToBeUploaded
        });
        this.fileToBeUploaded = null;
      } else {
        this.modalAction.emit({
          action: 'add',
          auditClassId: null,
          nameValue: this.nameValueInternal,
          imageId: null,
          FormFiles: this.fileToBeUploaded
        });
        this.fileToBeUploaded = null;
      }
    }
    this.name.nativeElement.focus();
  }

  checkSubmitEnabled(): void {
      this.submitDisabled = this.nameValueInternal == null || this.nameValueInternal.length < 1 || (this.nameValueInternal === this.nameValue);
      this.uploadDisabled = this.nameValueInternal == null || this.nameValueInternal.length < 1;
  }

  uploadClicked(): void {
    this.showImageUploadDialog(true);
  }

  showImageUploadDialog(event: boolean): void {
    if (event) {
      this.showImagesDialog = true;
    }
  }

  confirmDelete(rowData) {
    this.deleteMessage =
      `Are you sure that you want to remove <strong>${rowData.TemplateName}</strong>?`;
    this.showDeleteDialog = true;
  }

  delete(event: ModalAction) {
    if (event.action === 'save') {
      this.showDeleteDialog = false;
      this.editClassModelInternal.ImageId = null;
      this.editClassModelInternal.TemplateName = null;
      this.fileToBeUploaded = null;
      this.updateModelArray(this.editClassModelInternal);
      this.checkShowTable();
      this.submitDisabled = false;
    }
    this.showDeleteDialog = false;
  }

  uploadImageComplete(event: TemplateUploadAction): void {
    this.showImagesDialog = false;
    if(event.uploadComplete) {
      this.submitDisabled = false;
      this.editClassModelInternal.TemplateName = event.File.name;
      this.editClassModelInternal.ImageId = null;
      this.fileToBeUploaded = event.File;
      this.updateModelArray(this.editClassModelInternal);
      this.checkShowTable();
    }else{
        if(this.editClassModelInternal.ImageId){
          this.fileToBeUploaded = null;
        }
    }
  }

  updateModelArray(model: AuditClass): void{
    this.editClassModelArray = [];
    this.editClassModelArray.push(model);    
  }

}
