import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class HttpUtilityService {

  constructor(private cookieService: CookieService) {
  }
  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders(
        {'Content-Type': 'application/json'
    });
    return headers;
  }
  // getPhiToggle(): boolean {
  //   if( this.cookieService.get("phi_toggle") === ""){
  //     return false;
  //   } else {
  //     if(this.cookieService.get("phi_toggle") === "true"){
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // }
}
