export const environment = {
  production: false,
  envName: 'dev',
  applicationName: 'Inmar.Healthcare.AuditPlus',
  applicationDisplayName: 'RX AUDIT MANAGEMENT',
  rootUrl: 'http://dev-web-auditplus.healthcare.inmar.com',
  authZApiRoot: 'https://dev-api-authz.healthcare.inmar.com/v1.0',
  healthcareTopBar: 'https://dev-web-auth.healthcare.inmar.com/Scripts/healthcare-topbar.js',
  healthcareSignInAddress: 'https://dev-web-auth.healthcare.inmar.com/SignIn/SignIn',

  auditApiRoot: 'https://dev-api-auditplus.healthcare.inmar.com/v1.0/api',
  imageApiRoot: 'https://dev-api-imagerepository.healthcare.inmar.com/v1.0/api',
  claimApiRoot: 'https://dev-api-claim.healthcare.inmar.com/v1.0/api',
  faxApiRoot: 'https://dev-api-fax.healthcare.inmar.com/v1.0/api',
  helpDocName: 'ManualClaimHelpV5.pdf',
  defaultListLimit: 9999,   // API has logic on several Get-List endpoint that assumes 9999 is getting all for drop list
  appInsights: {
    instrumentationKey: '',
  },
  googleAnalytics: {
    key: ''
  },
  lookerAnalytics: {
    url: 'https://devpfmanalytics.cloud.looker.com'
  },
  lookerAdminRoot: 'https://dev-api-lookeradmin.healthcare.inmar.com'
};
