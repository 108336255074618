import { Component, OnInit, ViewChild } from '@angular/core';
import { ClassTableHelper } from './helpers/class-table-helper';
import { GlobalFilterService,
         AuthorizationService} from 'src/app/services';
import { Table } from 'primeng/table';
import { dropRight } from 'lodash';
import { Pagination} from 'src/app/models';
import { LazyLoadEvent } from 'primeng/api';
import { ClassToolbarHelper } from './helpers/class-toolbar.helper';
import { ToolbarAction } from 'src/app/shared/models/toolbarAction';
import { ModalAction } from 'src/app/shared/models/modalAction';
import * as toastr from 'toastr';
import { BusinessResponseService } from 'src/app/services/business-response.service';
import { AuditClass } from 'src/app/models/audit-class';
import { AuditClassService } from 'src/app/services/audit-class.service';
import { PhiService } from 'src/app/services/phi.service';
import { ImageRepoService } from 'src/app/services/image-repo.service';
import { ImageSearchResult } from 'src/app/models/image-search-result';
import { TemplateModalAction } from '../audit-class-template-add-edit-dialog/models/template-model-action';

@Component({
  selector: 'app-audit-class',
  templateUrl: './audit-class.component.html'
})
export class AuditClassComponent implements OnInit {
  public readonly tableHelper: ClassTableHelper;
  public readonly toolbarHelper: ClassToolbarHelper;
  public readonly max_rows = 30;
  public readonly viewFeatures = [{ feature: 'UI::AuditClassDelete', name: 'delete', accessable: false},
                                  { feature: 'UI::AuditClassEdit', name: 'edit', accessable: false }];
  public deleteText = '';
  public readonly deleteTitle = 'Delete Confirmation';
  public tableLoading: boolean;
  public confirmDelete: boolean;
  public auditClasses: AuditClass[];
  public totalItemCount: number;
  public deleteAllowed: boolean;
  public showAddEditDialog: boolean;
  public editDialogTitle: string;
  public editId: string;
  public auditClassName: string;
  public auditClassId: number;
  public phiToggleEnabled: boolean;
  public imageSearchResults: ImageSearchResult;
  public editAuditClass: AuditClass;
  constructor(private auditClassService: AuditClassService,
              private authService: AuthorizationService,
              private globalFilterService: GlobalFilterService,
              private businessRespService: BusinessResponseService,
              private phiService: PhiService,
              private imageRepoService: ImageRepoService) {
    this.tableHelper = new ClassTableHelper();
    this.toolbarHelper = new ClassToolbarHelper(this.authService);
    this.globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
    this.phiService.PhiToggleChanged.subscribe(() => this.onPhiToggleChange());
  }

  @ViewChild('dt', { static: true }) dTable: Table;

  ngOnInit() {
    this.tableLoading = true;
    this.checkFeatures();
    this.onPhiToggleChange();
  }

  private checkFeatures(): void {
    for ( let i = 0; i < this.viewFeatures.length; i++) {
      if (this.authService.hasFeature(this.viewFeatures[i].feature)) {
        this.viewFeatures[i].accessable = true;
      }
    }
    if ( !this.viewFeatures[0].accessable) {
      this.tableHelper.tableColumns = dropRight(this.tableHelper.tableColumns);
    }
  }

  loadDataLazy(event: LazyLoadEvent): void {
    if (!this.tableLoading) {
      this.tableLoading = true;
    }

    this.auditClassService.getAuditClassesLimitedResult
      (new Pagination(this.max_rows, event.first, event.sortField, event.sortOrder))
      .subscribe( (data) => {
        this.auditClasses = data.Items;
        this.totalItemCount = data.TotalItemCount;
        this.tableLoading = false;
      }, (error) => {
        this.tableLoading = false;
      });
  }

  onClientsChanged(): void {
    if (this.dTable) {
      this.dTable.reset();
      this.tableLoading = true;
    }
  }

  onPhiToggleChange(): void {
    this.phiToggleEnabled = this.phiService.PhiToggle;
  }

  toolbarBtnClick(event: ToolbarAction) {
    switch (event.index) {
      case 0: {
        this.dTable.reset();
        break;
      } case 1: {
        this.handleDialogs('add');
      }
    }
  }

  clickName(rowData: AuditClass) {
    this.auditClassName = rowData.Name;
    this.auditClassId = rowData.AuditClassId;
    this.editAuditClass = rowData;
    this.handleDialogs('edit');
  }

  clickTemplate(rowData: AuditClass ){
  this.imageRepoService.getImageMetadata(rowData.ImageId).subscribe((imageSearchResult) => {

    let mimeType = imageSearchResult.MimeType;
    this.imageRepoService.downloadImage(rowData.ImageId).subscribe((data) => {

      const blob = new Blob([data], {type: mimeType});
    
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = rowData.TemplateName;
      
      // this is a get around to download a blob. 
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, rowData.TemplateName);
      } else {
        link.click();
        link = null;
      }
    }, err => {
      toastr.error('There was an issue getting the image from the server.');
    });;
  });
    
  }

  deleteClicked(rowData: AuditClass): void {
    this.auditClassId = rowData.AuditClassId;
    this.deleteText = `Are you sure that you want to delete <strong>${rowData.Name}</strong>?`;
    this.handleDialogs('delete');
  }

  handleDialogs(name: string) {
    if (name === 'edit') {
      this.editDialogTitle = 'Edit Audit Class';
      this.editId = 'edit';
      this.showAddEditDialog = true;
    }

    if (name === 'add') {
      this.auditClassName = null;
      this.editDialogTitle = 'Add New Audit Class';
      this.editId = 'add';
      this.auditClassId = null;
      this.editAuditClass = null;
      this.showAddEditDialog = true;
    }

    if ( name === 'delete') {
      this.confirmDelete = true;
    }
  }

  deleteCallback(event: ModalAction): void {
    if (event.action === 'cancel') {
      this.confirmDelete = false;
      return;
    }
    this.auditClassService.deleteAuditClass(this.auditClassId)
      .subscribe( (response) => {
        toastr.success('Class was deleted successfully!');
        this.resetAuditClass();
        this.dTable.reset();
        this.confirmDelete = false;
      }, () => {
        toastr.error('We are not able to delete the class at this time.');
      });
    }

  editCallback(event: TemplateModalAction): void {
    if (event.action === 'cancel') {
      this.showAddEditDialog = false;
      this.auditClassId = null;
      return;
    }
    if (event.action === 'update') {
      this.auditClassService.updateAuditClass(this.auditClassId, event.nameValue, event.imageId, event.FormFiles).subscribe( (response) => {
        if (this.businessRespService.handleBusinessResponse('Update class name successful!', response)) {
          this.resetAuditClass();
          this.dTable.reset();
          this.showAddEditDialog = false;
        }
      }, () => {
        toastr.error('We are not able to update the class name at this time.');
      });
    }

    if (event.action === 'add') {
      this.auditClassService.addAuditClass(event.nameValue, event.FormFiles)
        .subscribe( (response) => {
          if (this.businessRespService.handleBusinessResponse('Add class successful!', response)) {
            this.resetAuditClass();
            this.dTable.reset();
            this.showAddEditDialog = false;
          }
        }, () => {
          toastr.error('We are not able to add the class at this time.');
        });
    }
  }

  resetAuditClass(): void {
    this.auditClassId = null;
    this.auditClassName = '';
  }
}
