import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { AuditImageTableHelper } from './helpers/audit-image-table.helper';
import { AuditImageService} from '../../services/audit-image.service';
import { ImageSearchResult} from '../../models/image-search-result';
import { Table } from 'primeng/table';
import { ToolbarAction } from 'src/app/shared/models/toolbarAction';
import * as detectie from 'detectie';
import { LazyLoadEvent } from 'primeng/api';
import { Pagination, ILimitedResult } from 'src/app/models';
import * as toastr from 'toastr';
import { Guid } from 'guid-typescript';
import { ModalAction } from 'src/app/shared/models/modalAction';
import { AuditImageToolbarHelper } from './helpers/audit-image-toolbar.helper';
import { AuditService, AuthorizationService } from 'src/app/services';
import { PhiService } from '../../services/phi.service';

const MAX_ROWS = 5;

@Component({
  selector: 'app-audit-images',
  templateUrl: './audit-images.component.html',
  styleUrls: ['./audit-images.component.css']
})
export class AuditImagesComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() auditId: number;
  @Input() key: any;
  @Input() showDialog: boolean;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @ViewChild('dTable', { static: true }) dTable: Table;
  public loadingImgGrid = true;

  public readonly tableHelper: AuditImageTableHelper;
  public readonly toolbarHelper: AuditImageToolbarHelper;
  public readonly isIE: any;
  public multiselect = 1;
  public imageResults: ImageSearchResult[] = [];
  public disableUI: boolean;
  public showPagination: boolean;
  public uploadDisabled: boolean;
  public showImagesDialog: boolean;
  public readonly rowsByBrowser: number;
  public totalItemCount = 0;
  public rows: number;
  public showGrid = false;
  public deleteMessage: string;
  public imageId: Guid;
  public showDeleteDialog = false;
  readonly _deleteFeature = 'UI::AuditImagesDelete';
  public canDelete = false;
  readonly deleteToastrMessage = 'There was an issue with deleting the image you selected. The item was not deleted.';
  private phiToggleChangedSubscription: any;

  constructor(private imageService: AuditImageService, private auditService: AuditService, private authService: AuthorizationService, private phiService: PhiService) {
      this.rowsByBrowser = 9999;
      this.tableHelper = new AuditImageTableHelper();
      this.toolbarHelper = new AuditImageToolbarHelper(authService);
      this.phiToggleChangedSubscription = this.phiService.PhiToggleChanged.subscribe(() => {
        this.loadImagesLazy;
      });

  }

  hasDeleteFeature(): boolean {
    return this.authService.hasFeature(this._deleteFeature);
  }

  ngAfterViewInit() {
    if (this.auditId) {
      this.canDelete = this.hasDeleteFeature();
    }
  }

  ngOnDestroy() {
    this.auditId = undefined;
    if (this.phiToggleChangedSubscription) {
      this.phiToggleChangedSubscription.unsubscribe();
    }
}

  ngOnChanges(changes): void {
    if (changes.showDialog &&
        changes.showDialog.currentValue === false) {
          this.closed.emit();
    }

    if (changes.showDialog &&
      changes.showDialog.currentValue === true && this.auditId) {
        this.dTable.reset();
      }
  }

  toolbarBtnClick(event: ToolbarAction) {
    switch (event.name) {
      case 'refresh': {
        this.dTable.reset();
        break;
      } case 'uploadImage': {
        this.showImageUploadDialog(true);
        break;
      }
      case 'generateClaimsTextFile': {
        this.generateClaimsTextFile();
        break;
      }
    }
  }

  loadImagesLazy(event: LazyLoadEvent) {
   if (this.auditId) {
    this.loadImagesGrid(new Pagination(this.rowsByBrowser, 0, event.sortField, event.sortOrder));
   }
  }

  loadImagesGrid(pagination: Pagination) {
  this.loadingImgGrid = true;
  this.showGrid = true;
  this.imageResults = [];

  this.imageService.getAssociatedImages(this.auditId, pagination)
    .subscribe( (result: ILimitedResult<ImageSearchResult>) => {
      this.imageResults = result.Items;
      this.totalItemCount = result.TotalItemCount;
      this.loadingImgGrid = false;

      if (!this.isIE) {
        this.rows = result.TotalItemCount;
      }
    }, err => {
      toastr.error('There was an issue getting images from the server.');
      this.imageResults = [];
      this.loadingImgGrid = false;
    });
  }

  downloadFile(rowData: ImageSearchResult) {
    this.imageService.downloadImage(this.auditId, rowData.ImageId)
    .subscribe((data) => {

      const blob = new Blob([data], {type: rowData.MimeType});

      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = rowData.Name;

      // this is a hack to support ie download of blob. 
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, rowData.Name);
      } else {
        link.click();
        link = null;
      }
    }, err => {
      toastr.error('There was an issue getting the image from the server.');
    });
  }

  confirmDelete(rowData) {
    this.deleteMessage =
      `Are you sure that you want to delete <strong>${rowData.Name}</strong>?`;
    this.imageId = rowData.ImageId;
    this.showDeleteDialog = true;
  }

  delete(event: ModalAction) {
    if (event.action === 'save') {
      let success: boolean;
      this.showDeleteDialog = false;
      this.imageService.deleteImages(this.auditId, this.imageId)
        .subscribe((value) => {
          success = value;
          console.log(value);
          !success ? toastr.error(this.deleteToastrMessage) : toastr.success('Delete successful!');
          this.loadImagesGrid(new Pagination(this.rowsByBrowser, 0, 'Name'));
        });
    }
    this.showDeleteDialog = false;
  }

  resetTable(): void {
    if (this.dTable) {
      this.dTable.reset();
    }
  }

  uploadImageComplete(event: boolean): void {
    if (event) {
      this.dTable.reset();
    }
    this.uploadDisabled = false;
  }

  showImageUploadDialog(event: boolean): void {
    this.uploadDisabled = true;
    if (event) {
      this.showImagesDialog = true;
    }
  }

  getPhiToggle(): boolean {
    return this.phiService.PhiToggle;
  }

  generateClaimsTextFile() {
    toastr.info('Claim file has been successfully queued.');
    this.toolbarHelper.updateGenerateClaimFileButton(true);
    this.auditService.generateClaimsTextFile(this.auditId)
    .subscribe((result) => {
      this.loadImagesGrid(new Pagination(this.rowsByBrowser, 0, 'Name'));
      this.toolbarHelper.updateGenerateClaimFileButton(false);
    }, err => {
      toastr.error('There was an issue generating the claims file.');
      this.toolbarHelper.updateGenerateClaimFileButton(false);
    });
  }

}
