import { ITableColumn } from 'src/app/models/tColumn';

export class AssociateClaimsTableHelper {
  public assocClaimColumns: ITableColumn[];
  constructor() {
    this.buildColumns();
  }

  private buildColumns(): void {
    this.assocClaimColumns = [
      { field: 'Selected', header: '', hdrClass: 'grid-checkbox', fldClass: 'grid-checkbox', sort: false },
      { field: 'Prescription_PrescriptionNumber', header: 'Rx Number' },
      { field: 'Prescription_SplitBillCode', header: 'COB #'},
      { field: 'Prescription_FillDate', header: 'Fill Date' },
      { field: 'Payer_ProcessorIdentifier', header: 'BIN', hdrClass: 'yesno-hdr', fldClass: 'yesno-hdr' },
      { field: 'Payer_ShardPayerName', header: 'Carrier Name' },
      { field: 'Location_CorporateLocationNumber', header: 'Store #' },
      { field: 'Drug_NationalDrugCode', header: 'NDC', fldClass: 'overflow' },
      { field: 'Drug_Label', header: 'Drug Name', fldClass: 'overflow' },
      { field: 'Money_PayerReceivableAmountClaim', header: 'Receivable Amt', fldClass: 'money' },
      { field: 'Source', header: 'Source', sort: false},
      { field: 'InUse', header: 'In Use', hdrClass: 'yesno-hdr', fldClass: 'yesno-hdr', show: false},
      { field: 'hide', header: '', hdrClass: 'ie-scroll', fldClass: 'ie-scroll', sort: false}
    ];
  }
}
