export class ClaimSearchResult {
    public AuditClaimId: number;
    public RawDataImport_ShardClaimIdentifier: number;       // ClaimId: number;
    public Prescription_PrescriptionNumber: string;          // ScriptNumber: string;
    public Payer_ProcessorIdentifier: string;                // 'BIN' (carrier)
    public Payer_ProcessorControlNumber: string;
    public Location_CorporateLocationNumber: string;         // StoreId
    public Location_NameOrDescription: string;               // StoreName
    public Drug_NationalDrugCode: string;                    // PrimaryNdc
    public Money_PayerReceivableAmountClaim: number;         // ActualReceivableAmount
    public Prescription_SoldDate: Date;                      // SoldDate
    public Prescription_FillDate: Date;                      // FillDate
    public Payer_PlanCode: string;                           // PlanCode
    public Source: string;
    public InUse: boolean;
    public Prescription_SplitBillCode: number;
}
