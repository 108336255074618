import { Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ViewChild,
  ElementRef} from '@angular/core';
import { ModalAction } from '../../models/modalAction';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-add-notes-dialog',
  templateUrl: './add-notes-dialog.component.html',
  styleUrls: ['./add-notes-dialog.component.css']
})
export class AddNotesDialogComponent implements OnInit {
  @ViewChild('notes', { static: true }) notes: ElementRef;
  @Input() show: boolean;
  @Input() titleText: string;
  @Input() displayTextBoxLabel: string;
  @Input() id: string;
  @Input() picklistLabel: string;
  @Output() modalAction: EventEmitter<ModalAction> = new EventEmitter<ModalAction>();

  public noteText = '';
  private noteValue;

  constructor() { }

  ngOnInit() {
  }

  onShow(): void {
    this.noteText = '';
    this.notes.nativeElement.focus();
  }

  trimText(length: number): void {
    if (length > 500) {
      this.noteText = this.noteText.substring(0, 500);
      this.notes.nativeElement.value = this.notes.nativeElement.value.substring(0, 500);
    }
  }

  submitClick(action: string): void {
    this.modalAction.emit({id: this.id, title: this.titleText, action: action, value: this.noteText });
  }

}


