import * as _ from 'lodash';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { HttpUtilityService } from './http-utility.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';

@Injectable()
export class AuthorizationService {
  private healthcareTopBar: any;
  private CACHE_INTERVAL = 1000;
  private _topBarLoaded = false;
  private features: any;
  private timer: any;

  private healthcareLoadedSubject = new Subject<void>();
  public healthcareLoadedSubject$ = this.healthcareLoadedSubject.asObservable();

  constructor(private router: Router, private _http: HttpClient, private _httpUtility: HttpUtilityService,
     private _cookieService: CookieService, private location: Location) {
    this.timer = setInterval(() => {
      this.updateCache();
    }, this.CACHE_INTERVAL);
  }

  public setHealthcareTopBar(topBar: any) {
    this.healthcareTopBar = topBar;
  }

  public get HealthcareTopBar(): any { return this.healthcareTopBar ? this.healthcareTopBar : null; }

  public get topBarLoaded() {
    return this._topBarLoaded;
  }

  private updateCache() {
    if (!this.HealthcareTopBar || !this.HealthcareTopBar.currentUserDetails) {
      return false;
    }

    // it is fair to say that we have the topbar loaded at this point. - kmb
    this._topBarLoaded = true;
    this.healthcareLoadedSubject.next();
    this.features = _.filter(this.HealthcareTopBar.currentUserDetails.Features, { App: environment.applicationName });
    clearTimeout(this.timer);
  }

  getSecured(routepath: string) {
    const externalUrl = this.location.prepareExternalUrl(routepath);
    const prepLink = `${environment.healthcareSignInAddress}?returnUrl=${environment.rootUrl}/${encodeURIComponent(externalUrl)}`;
    window.location.href = prepLink;
  }

  // Do not bind this without using cache to the DOM
  hasFeature(featureName: string, useCache: boolean = false): boolean {
    let collection: any[];
    if (!this.HealthcareTopBar || !this.HealthcareTopBar.currentUserDetails) {
      return false;
    }

    if (useCache) {
      collection = this.features;
    } else {
      collection = this.HealthcareTopBar.currentUserDetails.Features;
    }

    let found = false;
    found = _.find(collection, (features) => {
      if (featureName === features.Name && features.App === environment.applicationName) {
        return true;
      }
    });

    return found;
  }

  async CurrentUser(): Promise<User> {
    return await this._http.get<User>(
      environment.authZApiRoot + '/CurrentUser')
      .toPromise<User>();
  }

  GetLookerUrl(clientId: string, slugId: string): Observable<any> {
    const authheaders = new HttpHeaders();
    const jwt = this._cookieService.get('id_token');
    if (jwt) {
      authheaders.append('Authorization', 'Bearer ' + jwt);
      authheaders.append('Content-Type', 'application/json');
      authheaders.append('Access-Control-Allow-Origin', '*');
      authheaders.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    }

    return this._http.get<any>(`${environment.authZApiRoot}/LookerUrl/Clients/${clientId}/slugIds/${slugId}`, { headers: authheaders });
  }
}
