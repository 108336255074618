import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'customCurrencyPipe'})

export class CustomCurrencyPipe implements PipeTransform {
    transform (value: any, args?: any): any {

        if (value) {
            return value.charAt(0) === '-' ?
            '(' + value.substring(1, value.length) + ')' :
                value;
        } else {
            return value;
        }
    }
}
