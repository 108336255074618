<h2>
  Workflow
</h2>
<p-progressBar mode="indeterminate" *ngIf="loadingMasterData || loadingStatus"></p-progressBar>
<form #workflowForm="ngForm" [formGroup]="workflowFormGroup">
  <section class='container form'> 
    <div class="container-spacing">
      <div class="inm-label">Audit Specialist:</div>
      <p-dropdown [options]="auditSpecialistListItems" autoWidth="true" [disabled]="loadingMasterData"
        formControlName="specialistId" [showClear]="true"></p-dropdown>
    </div> 
    <div style="width:300px">
      <div class="inm-label" >Audit Status:</div>
      <p-dropdown [options]="auditStatusListItems" autoWidth="true" [disabled]="loadingMasterData"
        formControlName="auditStatusId" [showClear]="true"></p-dropdown>
    </div> 
    <div class="container-spacing">
        <div class="inm-label">Status Due Start Date:</div>
        <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true" 
          [monthNavigator]="true" [showIcon]="true" formControlName="statusStartDate"
          placeholder="MM/DD/YYYY">
        </p-calendar>
        <div class="inm-spaced" *ngIf="invalidStatusDates()"> 
          <div style="flex: 1;">
            <p class="inm-message--danger">{{dateRangeError}}</p>
          </div>
        </div>
    </div> 
    <div class="container-spacing">
        <div class="inm-label">Status Due End Date:</div>
        <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true" 
          [monthNavigator]="true" [showIcon]="true" formControlName="statusEndDate"
          placeholder="MM/DD/YYYY">
        </p-calendar>
    </div>   
    <div class="container-spacing">
      <div class="inm-label">Audit Due Start Date:</div>
      <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true" 
        [monthNavigator]="true" [showIcon]="true" formControlName="dueStartDate"
        placeholder="MM/DD/YYYY">
      </p-calendar>
      <div class="inm-spaced" *ngIf="invalidDueDates()"> 
        <div style="flex: 1;">
          <p class="inm-message--danger">{{dateRangeError}}</p>
        </div>
      </div>
    </div>
    <div>
      <div class="inm-label">Audit Due End Date:</div>
      <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true" 
        [monthNavigator]="true" [showIcon]="true" formControlName="dueEndDate"
        placeholder="MM/DD/YYYY">
      </p-calendar>
    </div> 
  </section>
  <div class='inm-actions--plain reduce-padding' style="margin-right: 70px; margin-top: 12px;"> 
    <button class="inm-button" [disabled]="loadingMasterData || workflowForm.errors" (click)="searchByStatus()">Search</button>
    <div class = "space-it"></div>
    <button class="inm-button" (click)="resetPageSetup()">Reset</button>
  </div>
</form>
  <section *ngIf="showStatuses">
    <!-- <span *ngIf="false">Show <p-dropdown [style]="{'width':'50px'}" [options]="pageSizeOptions" [(ngModel)]="pageSize"></p-dropdown> per audit status result page.</span>  -->
    <app-workflow-status #workflow
      *ngFor="let item of workflowStatusResults; let i = index" 
      [status]="item" 
      [searchFilter]="workflowStatusFilter"
      [autoShowTable]="autoShowTable || item.Expanded"
      [tablePageSize]="pageSize"
      [canEdit]="hasEdit"
      (click)="!showHere"
      [(expanded)]="item.Expanded">
    </app-workflow-status>
  </section>

