import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { map } from 'lodash';
import { Pagination, ILimitedResult, StatusModel, AuditStatusOrder } from '../../../models';
import {
  AuditStatusService,
  AuthorizationService,
  GlobalFilterService,
} from '../../../services';

import { AuditStatusTableHelper, IChecked } from './helpers/audit-status-table-helper';

import * as toastr from 'toastr';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { AdminModalAction } from 'src/app/components/admin/shared/models/adminModalAction';
import { ToolbarAction } from 'src/app/shared/models/toolbarAction';
import { StatusToolbarHelper } from './helpers/status-toolbar.helper';
import { ModalAction } from 'src/app/shared/models/modalAction';
import { dropRight } from 'lodash';
import { BusinessResponseService } from 'src/app/services/business-response.service';
import { NgxSortableComponent } from 'src/app/shared/components/ngx-sortable/ngx-sortable.component';

@Component({
  selector: 'app-audit-status',
  templateUrl: './audit-status.component.html',
  styleUrls: ['./audit-status.component.css']
})
export class AuditStatusComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: true }) dTable: Table;
  @ViewChild('sortable', { static: true }) sortable: NgxSortableComponent;
  public readonly maxRows = 30;
  public gridLoading = true;
  public totalItemCount = 0;
  public readonly default_page: Pagination;
  public auditStatusItems: StatusModel[];
  public newStatusOrder: StatusModel[];
  public readonly auditStatusTableHelper: AuditStatusTableHelper;
  public readonly toolbarHelper: StatusToolbarHelper;
  public readonly featureAuditStatusAdd = 'UI::AuditStatusAdd';       // feature user must have to enable Edit links in grid
  public readonly featureAuditStatusDelete = 'UI::AuditStatusDelete';  // feature user must have to enable Delete links in grid
  public readonly featureAuditStatusEdit = 'UI::AuditStatusEdit';       // feature user must have to enable Edit links in grid
  public readonly deleteFailMessage = 'We are not able to delete the status at this time.';
  public readonly deleteSuccessMessage = 'Status was deleted successfully!';
  public readonly updateFailMessage = 'We are not able to update the status name at this time.';
  public readonly updateSuccessMessage = 'Update status name successful!';
  public readonly addFailMessage = 'We are not able to add the status at this time.';
  public readonly addSuccessMessage = 'Add Audit Status successful!';
  public readonly addTitle = 'Add New Audit Status';
  public readonly editTitle = 'Edit Audit Status';
  public clientChangedSubscription: any;
  // add/edit variables
  public canEdit: boolean;
  public showAuditStatusDialog = false;
  public showDeleteDialog = false;
  public addEditTitle: string;
  public statusId: number;
  public statusName: string;
  public displayOnWorkflow: number;
  public deleteMessage: string;
  public workFlowInUse: boolean;
  public displayOrderDialog: boolean;
  public readonly orderWorkflowStatus: Pagination;
  
  constructor(
    private auditStatusService: AuditStatusService,
    private authorizationService: AuthorizationService,
    private globalFilterService: GlobalFilterService,
    private businessRespService: BusinessResponseService
  ) {
    // subscribe to global so we can reset form if global client is changed
    this.clientChangedSubscription = this.globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
    this.default_page = new Pagination(this.maxRows, 0, '');
    this.auditStatusTableHelper = new AuditStatusTableHelper();
    this.toolbarHelper = new StatusToolbarHelper(this.authorizationService);
    this.orderWorkflowStatus = new Pagination(9999, 0, 'WorkflowOrder,Name');
  }

  onClientsChanged(): void {
    this.initialPageSetup();
  }

  ngOnInit() {
    this.initialPageSetup();
  }

  ngOnDestroy(): void {
    if (this.clientChangedSubscription) {
      this.clientChangedSubscription.unsubscribe();
    }
  }

  initialPageSetup() {
    if (this.dTable) {
      this.dTable.reset();
    }
    this.checkFeatures();
  }

  private checkFeatures(): void {
    this.canEdit = this.hasEditFeature();

    if (!this.hasDeleteFeature()) {
      this.auditStatusTableHelper.statusColumns = dropRight(this.auditStatusTableHelper.statusColumns);
    }
  }

  toolbarBtnClick(event: ToolbarAction) {
    console.log(event);
    switch (event.name) {
      case 'refresh': {
        this.dTable.reset();
        break;
      } case 'addStatus': {
        this.openEditDialog();
        break;
      }
      case 'orderStatus': {
        this.sortable.disableButtons = true;
        this.auditStatusService.getAuditStatuses(this.orderWorkflowStatus, false, true)
          .subscribe( data => {
            this.newStatusOrder = data.Items;
            this.displayOrderDialog = true;
        })
        break;
      }
    }
  }

  loadDataLazy(event: LazyLoadEvent) {
    if (!this.gridLoading) {
      this.gridLoading = true;
    }
    this.getAuditStatuses(new Pagination(this.maxRows, event.first, event.sortField, event.sortOrder));
  }

  getAuditStatuses(pagination: Pagination) {
    this.auditStatusService.getAuditStatuses(pagination)
      .subscribe(
        (data: ILimitedResult<StatusModel>) => {
          this.auditStatusItems = data.Items;
          this.gridLoading = false;
          this.totalItemCount = data.TotalItemCount;
        }, err => {
          this.gridLoading = false;
          toastr.error('Error loading data.');
        }
      );
  }

  confirmDelete(rowData) {
    this.deleteMessage =
      `Are you sure that you want to delete <strong>${rowData.Name}</strong>?`;
    this.statusId = rowData.StatusId;
    this.showDeleteDialog = true;
  }

  deleteModalAction(modalAction: ModalAction, callback?: () => void) {
    if (modalAction.action === 'cancel') {
      this.showDeleteDialog = false;
      return;
    }
    if (modalAction.action === 'save') {
      this.auditStatusService.deleteAuditStatus(<number><any>modalAction.id)
      .subscribe((success) => {
        !success ? toastr.error(this.deleteFailMessage) : toastr.success(this.deleteSuccessMessage);
        this.dTable.reset();
        this.showDeleteDialog = false;
       });
    }
  }

  refresh() {
    this.dTable.reset();
  }

  hasAddFeature(): boolean {
    return this.authorizationService.hasFeature(this.featureAuditStatusAdd);
  }

  hasDeleteFeature(): boolean {
    return this.authorizationService.hasFeature(this.featureAuditStatusDelete);
  }

  hasEditFeature(): boolean {
    return this.authorizationService.hasFeature(this.featureAuditStatusEdit);
  }

  openEditDialog(rowData?: any) {
    if (rowData) {
      // editing
      this.addEditTitle = this.editTitle;
      this.statusId = rowData['StatusId'];
      this.statusName = rowData['Name'];
      this.displayOnWorkflow = rowData['DisplayOnWorkflow'];
      this.showAuditStatusDialog = true;
    } else {
      // adding
      this.addEditTitle = this.addTitle;
      this.statusId = null;
      this.statusName = '';
      this.displayOnWorkflow = 0;
      this.showAuditStatusDialog = true;
      this.workFlowInUse = false;
    }
  }

  orderDialogClick(event: string) {
    if (event === 'cancel') {
      this.displayOrderDialog = false;
      return;
    }

    if (event === 'save') {
      let orderStatuses: AuditStatusOrder[] =[];
      this.newStatusOrder.forEach( (element: StatusModel, index) => {
        orderStatuses.push({StatusId: element.StatusId, WorkflowOrder: index })
      });

      this.auditStatusService.updateAuditStatusOrder(orderStatuses).subscribe( resp => {
        this.dTable.reset();
        toastr.success('Workflow Order Applied!');
        this.displayOrderDialog = false;
      }, err => {
        toastr.error('We are unable to update the workflow order at this time.');
      })
    }
  }

  modalAction(modalAction: AdminModalAction, callback?: () => void) {
    if (modalAction.action === 'cancel') {
      this.showAuditStatusDialog = false;
      return;
    }
    const editedStatus: StatusModel = new StatusModel();
    editedStatus.StatusId = modalAction.id;
    editedStatus.Name = modalAction.nameValue;
    editedStatus.DisplayOnWorkflow = modalAction.workflowValue;
    if (modalAction.action === 'update') {
      this.auditStatusService.updateAuditStatus(editedStatus)
        .subscribe( (response) => {
        if ( this.businessRespService.handleBusinessResponse(this.updateSuccessMessage, response)) {
         this.refresh();
         this.showAuditStatusDialog = false;
        }
      }, () => {
        toastr.error(this.updateFailMessage);
      });
    } else if (modalAction.action === 'add') {
      this.auditStatusService.addAuditStatus(editedStatus)
        .subscribe( (response) => {
        if (this.businessRespService.handleBusinessResponse(this.addSuccessMessage, response)) {
          this.refresh();
          this.showAuditStatusDialog = false;
        }
      }, () => {
        toastr.error(this.addFailMessage);
      });
    }
  }
}
