<p-dialog header="{{titleText}}" [(visible)]="show"
  [modal]="true" [closable]="false" (onShow)="onShow()" 
  [blockScroll]="true" [style]="{minWidth:'450px'}" [contentStyle]="{height:'350px'}">
  <form [formGroup]="form" *ngIf="show" style="height: 200px">
    <div>{{displayTextBoxLabel}}</div>
    <p-listbox id="checkboxDialog" [options]="listItems" [(ngModel)]="selectedValue" [ngModelOptions]="{standalone: true}" [showToggleAll]="false" [checkbox]="true" 
    [multiple]="true" [showToggleAll]="false" [filter]="true" optionLabel="{{filterProperty}}" (onChange)="validateInput()"
     [listStyle]="{'max-height':'170px', 'width':'400px', 'border':'5px'}">
    </p-listbox>
  </form>
  <div style="margin-top: 35px" class="btn-container-dialog">
    <button type="button" class="inm-button btn-padding" [disabled]="disableSaveBtn" (click)="submitClick('save')">Save</button>
    <button type="button" class="inm-button btn-padding" (click)="submitClick('cancel')">Cancel</button>
  </div>
</p-dialog>
