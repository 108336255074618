
import { FormGroup } from '@angular/forms';

// Form Validators are static, not injectable, so this service is not injectable.

export class ValidateDates {

  // If invalid then both controls get error 'invalidDateOrder'.
  static StartDateNotGreaterThanEndDate( startDate: string, endDate: string) {
    return (group: FormGroup): { [key: string]: any } => {

      let date1 = new Date(group.controls[startDate].value);
      let date2 = new Date(group.controls[endDate].value);
      const minDate = new Date(1970, 1, 1);

      if (date1 < minDate) {
        date1 = null;
      }
      if ( date2 < minDate) {
        date2 = null;
      }
      if (date1 && date2 && (date1 > date2)) {
        group.controls[startDate].setErrors({invalidDateOrder: true});
        group.controls[endDate].setErrors({invalidDateOrder: true});
        return {
          invalidDateOrder: true
        };
     } else {
      group.controls[startDate].setErrors(null);
      group.controls[endDate].setErrors(null);
      return null;
     }
    };
  }
}

