<p-dialog header="{{titleText}}" [(visible)]="show"
  [modal]="true" [closable]="false" (onShow)="onShow()" [style]="{minWidth:'650px'}" [contentStyle]="{minHeight:'150px'}">
  <div class="inm-label--required">{{displayTextBoxLabel}}</div>
  <textarea 
    autocomplete="off" 
    autocorrect="off" 
    autocapitalize="off" 
    spellcheck="false" 
    #notes
    style="height: 200px;width: 100%"
    [(ngModel)]="noteText"
    ng-model-options="{ updateOn: 'blur' }"
    (input)="trimText(noteText.length)">
  </textarea>
  <p>Characters: {{noteText.length}}\500</p>
  <div class="btn-container-dialog">
    <button type="button" class="inm-button btn-padding" [disabled]="noteText.length > 500 || noteText.length < 1" (click)="submitClick('save')">Save</button>
    <button type="button" class="inm-button btn-padding" (click)="submitClick('cancel')">Cancel</button>
  </div>
</p-dialog>
