<p-panel #content [showHeader]="false" styleClass="panel-fix">
  <div id="main">
    <div style="flex-grow: 5;justify-content: start;align-content: flex-start;">
      <form class="container" [formGroup]="formGroup">
        <div style="flex-grow: 3;">
          <div class="inm-label--required space-it">Rx Numbers:</div>
          <textarea #area [(ngModel)]="rxNums" class="text-area inm-input" autoResize="true" formControlName="rxNumbers"
            (input)="rxNumbersChanged($event)" spellcheck="false" placeholder="{{placeholderText}}">
            </textarea>
        </div>
        <div class="space-it"></div>
        <div>
          <section class="inm-actions--plain" style="padding-top:23px;flex-grow: 2;margin-left:10px;">
            <button class="inm-button" (click)="search()" [disabled]="searchDisabled()">Search</button>
            <div class="space-it"></div>
            <button class="inm-button" (click)="resetRxNumbers()">Reset</button>
          </section>
        </div>
      </form>
    </div>
  </div>
  <div class="hspacer"></div>
  <span style="padding-bottom: 6px;" *ngIf="showMaxRxNumberError">
    <p style="font-style: italic;color:orangered;padding:2px;display:inline-block;">
      <svg class="inm-icon" aria-label="Warning">
        <use xlink:href="#inm-icon__warning" />
      </svg>
      Exceeded maximum of 20.
    </p>
  </span>
  <p-dialog header="Invalid Rx Numbers" [(visible)]="showInvalidRxNumberCount" [minWidth]="465">
    <textarea [ngModel]="badRxNumbersString" class="text-area inm-input scroll-area" readonly>
      </textarea>
  </p-dialog>
</p-panel>