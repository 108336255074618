import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { ILimitedResult, Pagination } from 'src/app/models';
import { AuditStatusHistoryResult } from 'src/app/models/audit-status-history-result';
import { AuditService, AuthorizationService } from 'src/app/services';
import { ToolbarAction } from 'src/app/shared/models/toolbarAction';
import { AuditStatusHistoryTableHelper } from './helpers/audit-status-history-table.helper';
import { AuditStatusHistoryToolbarHelper } from './helpers/audit-status-history-toolbar.helper';
import * as toastr from 'toastr';


const MAX_ROWS = 30;

@Component({
  selector: 'app-audit-status-history',
  templateUrl: './audit-status-history.component.html',
  styleUrls: ['./audit-status-history.component.css']
})
export class AuditStatusHistoryComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() auditId: number;
    @Input() key: any;
    @Input() showDialog: boolean;
    @Output() closed: EventEmitter<any> = new EventEmitter();
    @ViewChild('dTable', { static: true }) dTable: Table;
    public loadingHistGrid = true;
    public readonly tableHelper: AuditStatusHistoryTableHelper;
    public readonly toolbarHelper: AuditStatusHistoryToolbarHelper;
    public readonly isIE: any;
    public statusHistoryResults: AuditStatusHistoryResult[] = [];
    public disableUI: boolean;
    public showPagination: boolean;
    public readonly rowsByBrowser: number;
    public totalItemCount = 0;
    public rows: number;
    public showGrid = false;
    private phiToggleChangedSubscription: any;
  
    constructor(private auditService: AuditService, private authService: AuthorizationService) {
        this.tableHelper = new AuditStatusHistoryTableHelper();
        this.toolbarHelper = new AuditStatusHistoryToolbarHelper(authService);
    }
  
    ngAfterViewInit() {
      if(this.auditId){
        this.loadHistGrid(new Pagination(MAX_ROWS, 0, '-CreateDate'), true);
      } 
    }
  
    ngOnDestroy() {
      this.auditId = undefined;
      if (this.phiToggleChangedSubscription) {
        this.phiToggleChangedSubscription.unsubscribe();
      }
  }
  
    ngOnChanges(changes): void {
      if (changes.showDialog &&
          changes.showDialog.currentValue === false) {
            this.closed.emit();
      }
  
      if(changes.showDialog &&
        changes.showDialog.currentValue === true && this.auditId) {
          this.dTable.reset();
        }
    }
  
    toolbarBtnClick(event: ToolbarAction) {
      switch (event.name) {
        case 'refresh': {
          this.dTable.reset();
          break;
        } 
      }
    }
  
    loadAuditStatusHistoryLazy(event: LazyLoadEvent) {
     if(this.auditId){
      this.loadHistGrid(new Pagination(MAX_ROWS, event.first, event.sortField, event.sortOrder), false);
     }
    }
  
    loadHistGrid(pagination: Pagination, resetTable: boolean) {
    this.loadingHistGrid = true;
    this.showGrid = true;
    this.statusHistoryResults = [];

    if (resetTable) {
      this.resetTable();
    }

    this.auditService.getAuditStatusHistory(this.auditId, pagination)
      .subscribe( (result: ILimitedResult<AuditStatusHistoryResult>) => {
        this.statusHistoryResults = result.Items;
        this.totalItemCount = result.TotalItemCount;
        this.loadingHistGrid = false;
  
        if (!this.isIE) {
          this.rows = result.TotalItemCount;
        }
      }, err => {
        toastr.error('There was an issue getting images from the server.');
        this.statusHistoryResults = [];
        this.loadingHistGrid = false;
      });
    }
  
    resetTable(): void {
      if (this.dTable) {
        this.dTable.reset();
      }
    }
  
  }
  