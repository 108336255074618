<p-panel #content [showHeader]="false" styleClass="panel-fix">
  <p-progressBar [ngStyle]="{ 'height': '1px' }" mode="indeterminate" *ngIf="loadingFaxFormDetails"></p-progressBar>
  <div>
    <form [formGroup]="auditFormGroup" id="auditForm" class="form">
      <div class="single-line">
        <p style="font-style: italic;color:orangered;padding:2px; ;">
          <svg class="inm-icon" aria-label="Warning">
            <use xlink:href="#inm-icon__warning" /></svg>
          Up to 10 files may be faxed at a time and total fax size cannot exceed 50 MB.
        </p>
      </div>
      <div class="single-line">
        <div class="left-side">
          <p style="font-style: italic;color:orangered;padding:2px; ;">
            <svg class="inm-icon" aria-label="Warning">
              <use xlink:href="#inm-icon__warning" /></svg>
            PHI toggle must be on to open templates.
          </p>
        </div>
      </div>
      <section class="sub-section">
        <div class='inm-wrapper'>
          <div class="single-line">
            <div class="left-side">
              <div class="inm-label--required">To: </div>
              <p-autoComplete class="inm-input autocomplete" #autoCompAddress [suggestions]="filteredResults"
                formControlName="recipientName" [size]="41" placeholder="Recipient Name" [minlength]="1" [maxlength]="50"
                (completeMethod)="filterAddress($event)" appendTo="body" (onSelect)="displayOtherFields()">
              </p-autoComplete>
            </div>
            <div class="right-side">
              <div class="inm-label--required">From: </div>
              <input class="inm-input" formControlName="senderName" placeholder="Sender Name" (input)="limit($event)">
            </div>
          </div>
          <div class="single-line">
            <div class="left-side">
              <div class="inm-label--required">Fax: </div>
              <input class="inm-input" formControlName="recipientFax" [value]="auditFormGroup.get('recipientFax').value | customPhonePipe" placeholder="Recipient Fax"
                (input)="limit($event)">
            </div>
            <div class="right-side">
              <div class="inm-label--required">Fax: </div>
              <input class="inm-input" formControlName="senderFax" [value]="auditFormGroup.get('senderFax').value | customPhonePipe" placeholder="Sender Fax" (input)="limit($event)">
            </div>
          </div>
          <div class="single-line">
            <p *ngIf="faxIsInvalid()"
              class="inm-message--danger">
              {{faxInvalidErrorMessage}}
            </p>
          </div>
          <div class="single-line">
            <div class="left-side">
              <div class="inm-label">Phone: </div>
              <input class="inm-input" formControlName="recipientPhone"
                [value]="auditFormGroup.get('recipientPhone').value | customPhonePipe" placeholder="Recipient Phone"
                (input)="limit($event)">
            </div>
            <div class="right-side">
              <div class="inm-label">Phone: </div>
              <input class="inm-input" formControlName="senderPhone"
                [value]="auditFormGroup.get('senderPhone').value | customPhonePipe" placeholder="Sender Phone"
                (input)="limit($event)">
            </div>
          </div>
          <div class="single-line">
            <p *ngIf="phoneIsInvalid()"
              class="inm-message--danger">
              {{phoneInvalidErrorMessage}}
            </p>  
          </div>
          <div class="single-line">
            <div class="left-side">
              <div class="inm-label">Company: </div>
              <input class="inm-input" formControlName="recipientCompany" placeholder="Recipient Company" (input)="limit($event)">
            </div>
            <div class="right-side">
              <div class="inm-label">Date: </div>
              <input class="inm-input" type="text" formControlName="todayDate" readonly>
            </div>
          </div>
          <div class="single-line">
            <div class="inm-label">Subject: </div>
            <input class="inm-input" formControlName="subject" placeholder="Enter Subject here" (input)="limit($event)">
          </div>
          <div class="single-line">
            <div class="inm-label space-it">Comments: </div>
            <textarea class="text-area inm-input" autoResize="true" formControlName="comments"
              spellcheck="false" placeholder="{{placeholderText}}" (input)="limit($event)">
          </textarea>
          </div>

          <div class="single-line" class="check-box">
            <p-checkbox label="   Use as coversheet" binary="true" [(ngModel)]="isIncludeHeader"
              formControlName="headerCheckBox">
            </p-checkbox>
          </div>
        </div>
      </section>
      <section id="auditClass" *ngIf="showAuditClassSection" class='second-form sub-section'>
        <div class="single-line">
          <div class="left-side">
            <div class="inm-label">Audit Class: </div>
            <label class="inm-input">{{auditClassName}}</label>
          </div>
        </div>
        <div class="single-line">
          <div class="left-side">
            <div class="inm-label">Template: </div>
            <p *ngIf="!isTemplateAvailable" class="template">Template is not available for this Audit Class</p>
            <div *ngIf="isTemplateAvailable">
              <p-checkbox binary="true" [(ngModel)]="isIncludeAuditClass" formControlName="auditCheckbox"
                (onChange)="toggleAuditClassCheckbox($event)" [disabled]="!isIncludeAuditClass && maxFileCountLimitReached">
              </p-checkbox>
              <a class="templateLink" *ngIf="phiToggleEnabled" (click)="clickAuditClassTemplate($event)">
                {{auditClassTemplateName}}
              </a>
              <span style="color: black;" *ngIf="!phiToggleEnabled">
                {{auditClassTemplateName}}
              </span>
            </div>
          </div>
        </div>
      </section>
      <section id="documents" *ngIf="showDocumentsSection" class='second-form sub-section'>
        <div>
          <p-table #dTable [columns]="tableHelper.documentsColumns" [value]="documentResults"
            [loading]="loadingDocumentGrid" [lazy]="true" [responsive]="true" [totalRecords]="totalItemCount"
            [scrollable]="true" scrollHeight="350px" [(selection)]="selectedDocuments"
            (onRowSelect)="onDocumentSelect($event)" (onRowUnselect)="onDocumentUnselect($event)">
            <ng-template pTemplate="header" let-columns>
              <tr class="imageSearchCols">
                <th *ngFor="let col of columns" [ngClass]="col.hdrClass ? col.hdrClass : 'default'">
                  {{col.header}} 
                  <p-tableHeaderCheckbox #chkBoxAll *ngIf="col.field === 'Selected'"
                    [disabled]="(!chkBoxAll.checked && maxFileCountLimitReached) || documentResults.length > (totalFilesAllowed - 1) || documentResultsFileSize > totalFileSizeAllowed"
                    (click)="checkAllClick($event)">
                  </p-tableHeaderCheckbox>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
              <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                <td *ngFor="let col of columns" [ngClass]="col.fldClass ? col.fldClass : 'default'">
                  <div *ngIf="col.field === 'Selected'" style="padding:0.25em;">
                    <p-tableCheckbox [value]="rowData" #row>
                    </p-tableCheckbox>
                  </div>
                  <div *ngIf="col.field === 'Name'">
                    <div *ngIf="phiToggleEnabled">
                      <a class="table-link" (click)="downloadDocument(rowData)">
                        {{rowData[col.field]}}</a></div>
                    <div *ngIf="!phiToggleEnabled" style="cursor:default;text-align: center;">
                      {{rowData[col.field]}}
                    </div>
                  </div>
                  <div *ngIf="col.field === 'Size'">
                        {{rowData[col.field]}} MB
                  </div>
                </td>
              </tr>
            </ng-template>
            
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2" style=" text-align: center">
                  No records found
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              Total Record Count: {{ totalItemCount }}
            </ng-template>
          </p-table>
        </div>
      </section>
      <section class='inm-actions--plain reduce-padding' style="margin-top: 12px">
        <button class="inm-button" [disabled]="formIsInvalid()" (click)="sendFax()">Send</button>
        <div class="space-it"></div>
        <button class="inm-button" (click)="resetPageSetup()">Reset</button>
      </section>
    </form>
  </div>
</p-panel>