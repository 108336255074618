import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationService } from '../services/authorization.service';
import { ClaimService, GlobalFilterService } from '../services';
import { Tag } from '../models/tag';
import { Subject, Observable } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmTagComponent } from '../components/confirm-tag/confirm-tag.component';

type SimpleCallback = () => void;

@Injectable()
export class AuditTagGuard implements CanActivateChild {

    public result: boolean;
    bsModalRef: BsModalRef;

    constructor(private router: Router, private claimService: ClaimService,
        private modalService: BsModalService, private cookieService: CookieService,
        private globalFilterService: GlobalFilterService) { }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const self = this;
        self.getTopBar(() => {
            // Increase the version of the key, if more tags are required
            const tagKey = `${this.globalFilterService.selectedClientId}-tagpresent-v1`;

            if (!this.getCookie(tagKey)) {
                this.claimService.getAllTags().subscribe((data) => {
                    this.result = data.Items.map(item => item.TagCode).includes('AUDIT')
                        && data.Items.map(item => item.TagCode).includes('RECOUP');

                    if (this.result) {
                        this.setCookie(tagKey);
                        return true;
                    }
                    this.bsModalRef = this.modalService.show(ConfirmTagComponent);
                    this.router.navigate(['/']);
                    return this.result;
                },
                    err => {
                        console.log('error calling claim api');
                        return false;
                    });
            }
        });
        return true;
    }

    getCookie(key: string) {
        return this.cookieService.get(key);
    }

    setCookie(key: string) {
        const today = new Date();
        const exp = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
        this.cookieService.set(key, 'true', exp);
    }

    getTopBar(cb: SimpleCallback): void {
        const self = this;
        if (this.globalFilterService.selectedClientId === undefined) {
            setTimeout(() => {
                self.getTopBar(cb);
            }, 50);
        } else {
            cb();
        }
    }

}
