export class AuditSearchRequest {

    errorMessages: string[];
    AuditReferenceNumber: string;
    AuditNumber: string;
    StartNotificationDate: Date;
    EndNotificationDate: Date;
    StartReceiveDate: Date;
    EndReceiveDate: Date;
    StartAuditDueDate: Date;
    EndAuditDueDate: Date;
    AuditTypeId: number;
    AuditStatusId: number;

    /***** The next 3 will all pass the 'this.Store' value to API *********/
    Location_NCPDPProviderIdentifier: string;
    Location_CorporateLocationNumber: string;
    Location_NationalProviderIdentifier: number;

    /***** The next 3 can all be set by 'Store' setter *********/
    /***** Use 'Store' in your component ***********************/
    /***** and these properties will be passed to API call. ***/
    private _store: string;
    set Store(value: string) {
        this._store = value;
        this.Location_NCPDPProviderIdentifier = value;
        this.Location_CorporateLocationNumber = value;

        // This one is a number set if we can, else set it to null
        if (!isNaN(Number(value)) && value) {
            this.Location_NationalProviderIdentifier = Number(value);
        } else {
            this.Location_NationalProviderIdentifier = null;
        }
    }
    get Store(): string {
        return this._store;
    }
    /*********************************************************/

    /***** The next 3 can all be set by 'Drug' setter *********/
    /***** Use 'Drug' in your component ***********************/
    /***** and these properties will be passed to API call. ***/
    Drug_NationalDrugCode: string;
    Drug_GCN: string;
    Drug_GPI: string;

    private _drug: string;
    set Drug(value: string) {
        this._drug = value;
        this.Drug_NationalDrugCode = value;
        this.Drug_GCN = value;
        this.Drug_GPI = value;
    }
    get Drug(): string {
        return this._drug;
    }
    /*********************************************************/

    // rx
    Prescription_PrescriptionNumber: string;
    Prescription_FromFillDate?: Date;
    Prescription_ToFillDate?: Date;
    // bin
    Payer_ProcessorIdentifier: string;
    Tag: string; // only one 5 digit tag allowed

    SpecialistId: number;
    AuditCarrierId: number;
    VendorId: number;
    DeliveryMethod: string;
    AuditClassId: string;
    Patient_ClaimControlNumber: string;
}
