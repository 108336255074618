import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { PrimeNgListItem,
         StatusModel,
         Pagination,
         ILimitedResult,
         AuditStatusOrder
        } from '../models';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessResponse } from 'src/app/models/businessResponse';
import { AuditStatusHistoryResult } from '../models/audit-status-history-result';

@Injectable({
  providedIn: 'root'
})
export class AuditStatusService {
  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) { }
  public getAuditStatuses(pagination: Pagination, showAdmin: boolean = true, workflowFilter: boolean = false): Observable<ILimitedResult<StatusModel>> {
    let params = pagination.buildSearchParams();
    if (showAdmin && !workflowFilter) {
      params = params.append('showAdmin', String(true));
    }

    if(!showAdmin && workflowFilter) {
      params = params.append('workflowFilter', String(true));
    }
    
    return this.http.get<ILimitedResult<StatusModel>>(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditStatuses`,
      { params }
    );
  }

  public getAuditStatusListItems(workflowFilter: boolean = false): Observable<PrimeNgListItem[]> {
    const limit = 9999;
    let params = new HttpParams();
    params = params.append('limit', String(limit));
    if (workflowFilter) {
      params = params.append('workflowFilter', String(workflowFilter));
    }
    return this.http.get(
      `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditStatuses`,
      { headers: this.httpUtility.getHeaders(), params }
    )
    .pipe(
      map(res => res['Items'].map(item => new PrimeNgListItem(item.Name, item.StatusId))));
  }
  // returns 'true' if successful (false or error if it didn't succeed)
  public deleteAuditStatus(statusId: number): Observable<boolean> {
    const url = `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditStatuses/${statusId}`;
    return this.http.delete<boolean>(url);
  }

  public updateAuditStatus(auditStatus: StatusModel): Observable<BusinessResponse> {
    const headers = this.httpUtility.getHeaders();
    const url = `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditStatuses/`;
    return this.http.put<BusinessResponse>(url, auditStatus, { headers });
  }

  public addAuditStatus(auditStatus: StatusModel): Observable<BusinessResponse> {
    const headers = this.httpUtility.getHeaders();
    const url = `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditStatuses/`;
    return this.http.post<BusinessResponse>(url, auditStatus, { headers });
  }

  public updateAuditStatusOrder(auditStatusOrder: AuditStatusOrder[]): Observable<Response> {
    const headers = this.httpUtility.getHeaders();
    const url = `${environment.auditApiRoot}/clients/${this.globalFilterService.selectedClientId}/OrderAuditStatuses/`;
    return this.http.put<Response>(url, auditStatusOrder, { headers });
  }

}
