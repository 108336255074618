import { Component, OnInit, Input } from '@angular/core';
import { Section } from '../../models';
import { AuthorizationService, GlobalFilterService } from '../../services';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  public sectionsObj: Section;
  private _lastModule: string;
  @Input() sections: any[];
  @Input() parent: string;
  constructor(private router: Router, private authorizationService: AuthorizationService) { }

  ngOnInit(): void {
    // this.redirectToFirstLink();
  }
  checkValidLink(feature: string): boolean {
    if (!feature) {
      return false;
    }
    if (!this.authorizationService.hasFeature(feature)) {
      return false;
    }
    return true;
  }

  // this function dynamically checks for access to and redirects
  // the user to the first router linking within the specified
  // parent.
  redirectToFirstLink() {
    let run = true;

    if (
      this.parent !== this._lastModule &&
      this.sections.length > 0) {
      this.sections.forEach((item: any) => {
        if (run && this.authorizationService.hasFeature(item.feature)) {
          run = false;
          this._lastModule = this.parent;
          if (this.parent == 'audit' && this.authorizationService.hasFeature('UI::Workflow')) {
            this.router.navigate([`/${this.parent}/workflow`]);
          } else {
            this.router.navigate([`/${this.parent}/${item.path}`]);
          }
        }
      });
    }
  }
}
