import { ITableColumn } from 'src/app/models/tColumn';

export class AuditSearchTableHelper {
  public auditSearchColumns: ITableColumn[];
  constructor() {
    this.buildColumns();
  }

  buildColumns(): void {
    this.auditSearchColumns = [
      { field: 'AuditNumber', header: 'Audit Number' },
      { field: 'AuditReferenceNumber', header: 'Ref Number' },
      { field: 'Carrier', header: 'Audit Carrier' },
      { field: 'AuditType', header: 'Type' },
      { field: 'AuditSpecialist', header: 'Audit Specialist' },
      { field: 'AuditStatus', header: 'Audit Status' },
      { field: 'ClaimCount', header: 'Claim Count' },
      { field: 'TotalInitialRiskAmount', header: 'Initial Risk Amt', hdrClass: 'money', fldClass: 'money' },
      { field: 'TotalApprovedRecoupmentAmount', header: 'Approved Recoupment Amt', hdrClass: 'money', fldClass: 'money' },
      { field: 'Delete', header: '', hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn', sort: false }
    ];
  }
}
