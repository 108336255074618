export interface ITableColumn {
  field: string;
  header: string;
  hdrClass?: string;
  fldClass?: string;
  show: boolean;
  disabled?: boolean;
  sortable?: boolean;
}

export class VendorTableHelper {
  public tableColumns: ITableColumn[];

  constructor() {
    this.generateColumns();
  }

  private generateColumns(): void {
    this.tableColumns = [
      { field: 'Name', header: 'Name',
        show: true, disabled: false, sortable: true},
      { field: 'InUse', header: 'In Use',
        hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn',
        show: true, disabled: false, sortable: false},
      { field: 'ModifiedBy', header: 'Author',
        show: true, disabled: false, sortable: true},
      { field: 'ModifiedDate', header: 'Date Modified',
        show: true, disabled: false, sortable: true},
      { field: 'delete', header: '',
        hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn',
        show: false, disabled: false, sortable: false},
    ];
  }
}
