import { Component,
  EventEmitter,
  OnInit,
  Output,
  Input } from '@angular/core';
import { ModalAction } from '../../models/modalAction';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {
  @Input() show: boolean;
  @Input() message: string;
  @Input() id: string;
  @Input() titleText: string;
  @Output() modalAction: EventEmitter<ModalAction> = new EventEmitter<ModalAction>();
  constructor() { }

  ngOnInit() {
  }

  submitClick(action: string): void {
    this.modalAction.emit({id: this.id, title: this.titleText, action: action});
  }

}
