import { Input } from '@angular/core';
import { AuthorizationService } from 'src/app/services';

export interface IToolbarItem {
  label?: string;
  name: string;
  tip: string;
  icon: string;
  disabled?: boolean;
  show: boolean;
  exclude?: boolean;
}

export class AuditCarrierDocumentSearchToolbarHelper {
  public showAction: string;
  public actionText: string;
  public currentToolbar = [];
  private readonly TOOLBAR_ITEMS = [
    { feature: '',
    item: {label: 'Refresh', name: 'refresh', tip: 'Refresh Grid', icon: '#inm-icon__refresh',
    disabled: false, show: true, exclude: true }},
    { feature: 'UI::AuditCarrierBulkDownload',
      item: {label: 'Download Documents', name: 'bulkDownloadDocuments', tip: 'Download a .zip file of selected documents.', icon: '#inm-icon__download',
      disabled: false, show: true}}];
  constructor(private authService: AuthorizationService) {
    this.generateToolbarData();
  }

  generateToolbarData(): void {
    for (let i = 0; i < this.TOOLBAR_ITEMS.length; i++) {
      if (!this.TOOLBAR_ITEMS[i].feature || this.authService.hasFeature(this.TOOLBAR_ITEMS[i].feature)) {
        this.currentToolbar.push(this.TOOLBAR_ITEMS[i].item);
        continue;
      }
    }
  }
  
  //Enable/Disable Generate Claim File Button 
   public updateBulkDownloadDocumentsButton(isEnabled: boolean): void {
    for (const item of this.currentToolbar) {
      if(item.name === 'bulkDownloadDocuments') {
          item.disabled = isEnabled;
      }
    }
  }
}
