import * as toastr from 'toastr';
import { BusinessResponse } from '../models/businessResponse';
import { Injectable } from '@angular/core';

const DEF_TIMEOUT = { timeOut: 5000 };

@Injectable()
export class BusinessResponseService {
  private readonly Success_code = 200;
  constructor() {}

  public handleBusinessResponse(
    successMessage: string, res: BusinessResponse, options: any = DEF_TIMEOUT, toastAll: boolean = false): boolean {
    let index: number;
    if (res && res.Messages && res.ResponseCode !== 200) {
      index = toastAll ? res.Messages.length : 1;
      if (res.Messages && res.Messages.length > 0) {
        res.Messages.forEach( msg => {
          toastr.error(msg, '', options);
        });
      }
      return false;
    } else {
      if (successMessage !== '') {
        toastr.success(successMessage, '', options);
      }
      return true;
    }
  }
}
