<h2 style="margin-bottom: 1em;">Audit Address Book</h2>
<app-audit-toolbar [toolbarItemList]="toolbarHelper.currentToolbar" (OnClick)="toolbarBtnClick($event)">
</app-audit-toolbar>
<p-table #dt [columns]="tableHelper.tableColumns" [value]="addressBooks" (onLazyLoad)="loadDataLazy($event)"
  [loading]="tableLoading" loading="fa-spinner" [scrollable]="true" [lazy]="true" [paginator]="true" [responsive]="true"
  [rows]="30" [totalRecords]="totalItemCount" [(selection)]="selectedRows">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [ngClass]="col.hdrClass ? col.hdrClass : 'default'"
        [pSortableColumn]="col.sortable ? col.field : ''" [ngStyle]="!col.sortable? {'cursor': 'default'} : ''">
        {{col.header}}
        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" [ngClass]="col.fldClass ? col.fldClass : 'default'">
        <div *ngIf="col.field === 'ContactName'" style='word-wrap: break-word;'>
          <a style="cursor: pointer;" *ngIf="viewFeatures[1].accessable" [routerLink]="" (click)="clickName(rowData)">
            {{rowData[col.field]}}
          </a>
          <span *ngIf="!viewFeatures[1].accessable">
            {{rowData[col.field]}}
          </span>
        </div>
        <div *ngIf="col.field === 'CompanyName'" style='word-wrap: break-word;'>
          {{rowData[col.field]}}
        </div>
        <div *ngIf="col.field === 'FaxNumber'">
          {{rowData[col.field]|customPhonePipe}}
        </div>
        <div *ngIf="col.field === 'PhoneNumber'">
          {{rowData[col.field]|customPhonePipe}}
        </div>
        <div *ngIf="col.field === 'Notes'"
          style='white-space: nowrap;overflow:hidden;text-overflow: ellipsis;'
          pTooltip="{{rowData[col.field]}}">
          {{rowData[col.field]}}
        </div>
        <div *ngIf="col.field === 'ModifiedBy'">
          {{rowData[col.field]}}
        </div>
        <div *ngIf="col.field === 'ModifiedDate'">
          {{this.datePipe.transform(rowData[col.field], 'MM/dd/yyyy')}}
        </div>
        <div *ngIf="col.field === 'delete'" [ngStyle]="!rowData[col.InUse] ? {'cursor': 'pointer'} : ''">
          <a (click)="deleteClicked(rowData)" style="cursor: pointer; width: 30px;">
            <svg class="inm-icon" aria-label="Delete">
              <use xlink:href="#inm-icon__trash" class="danger" /></svg>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    Total Record Count: {{ totalItemCount }}
  </ng-template>
</p-table>
<app-delete-dialog style="width: 300px; height: auto" [show]="confirmDelete" [titleText]="deleteTitle"
  [message]="deleteText" (modalAction)="deleteCallback($event)">
</app-delete-dialog>
<p-dialog id="addEditAddressBook" 
  header="{{editDialogTitle}}" 
  [(visible)]="addEditAddressBookVisible" 
  [modal]="true" 
  [style]="{'margin': '5px'}"
  [closable]="false"
  [minHeight]="300"
  (onShow)="showAddEditAddressBook()"
  (onHide)="hideAddEditAddressBook()"
  [width]="500" 
  [positionTop]="75" 
  [contentStyle]="{'max-height':'800px'}">
  <div class="scr-fix">
    <div [formGroup]="addressBookFormGroup" id="addressBookForm" class="form">
      <label>
        <div class="inm-label--required">Name:</div>
        <input #contactName class="inm-input" style="width: 420px" formControlName="contactName" [value]="this.contactName" maxlength=50 (input)="checkSubmitEnabled()">
        <div class="single-line">
          <p *ngIf="contactNameValid()"
            class="inm-message--danger">
            {{contactNameRequiredMessage}}
          </p>
        </div>
        </label>
      <label>
        <div class="inm-label">Company:</div>
        <input #companyName class="inm-input" style="width: 420px" formControlName="companyName" [value]="this.companyName" maxlength=100 (input)="checkSubmitEnabled()">
      </label>
      <label>
        <div class="inm-label--required">Fax Number:</div>
        <input #faxNumber class="inm-input" style="width: 420px" formControlName="faxNumber" [value]="addressBookFormGroup.get('faxNumber').value | customPhonePipe" maxlength=14 (input)="checkSubmitEnabled()">
      </label>
      <div class="single-line">
        <p *ngIf="faxIsInvalid()"
          class="inm-message--danger">
          {{faxInvalidErrorMessage}}
        </p>
        <p *ngIf="faxIsEmpty()"
          class="inm-message--danger">
          {{faxRequiredErrorMessage}}
        </p>
      </div>
      <label>
        <div class="inm-label">Phone Number:</div>
        <input #phoneNumber class="inm-input" style="width: 420px" formControlName="phoneNumber" [value]="addressBookFormGroup.get('phoneNumber').value | customPhonePipe" maxlength=14 (input)="checkSubmitEnabled()">
      </label>
      <div class="single-line">
        <p *ngIf="phoneIsInvalid()"
          class="inm-message--danger">
          {{phoneInvalidErrorMessage}}
        </p>
      </div>
      <label>
        <div class="inm-label">Notes:</div>
        <textarea #notes class="inm-input" style="width: 420px;height: 200px;" formControlName="notes" [value]="this.notes" maxlength=2000 (input)="checkSubmitEnabled()"></textarea>
      </label>
      </div>  
      <div class="btn-container-dialog" style="padding-top: 14px">
        <button #save type="button" class="inm-button btn-padding" [disabled]="submitDisabled"
          (click)="submitClick('save')">Save</button>
        <button type="button" class="inm-button btn-padding" (click)="submitClick('cancel')">Cancel</button>
      </div>
      </div>
</p-dialog>
