import { ITableColumn } from 'src/app/models/tColumn';

export class AuditFaxDocumentsTableHelper {
  public documentsColumns: ITableColumn[];
  constructor() {
    this.buildColumns();
  }

  buildColumns(): void {
    this.documentsColumns = [
      {  field: 'Selected', header: '',
      hdrClass: 'grid-checkbox', fldClass: 'grid-checkbox', show: true, disabled: false },
      { field: 'Name', header: 'File Name', fldClass: 'col-link',
      show: true, disabled: false },
      { field: 'Size', header: 'File Size',
      show: true, disabled: false },
    ];
  }
}
