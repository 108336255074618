import { Directive, ElementRef, Input, OnInit } from '@angular/core';
const LOCAL_NAME = 'BUTTON';

@Directive({
  selector: '[appSvgIcon]',
})
export class SvgIconDirective implements OnInit {
  private element: HTMLElement;
  // tslint:disable-next-line:no-input-rename
  @Input('appSvgIcon') svgIcon: string;

  constructor(private elRef: ElementRef) {
    if ( elRef.nativeElement.nodeName !== LOCAL_NAME) {
      throw new Error(`SvgIcon can only be used on a ${LOCAL_NAME.toLowerCase()} element.`);
    }
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
   const svg = this.buildSVGElement();
   this.element.appendChild(svg);
  }

  private buildSVGElement(): HTMLElement {
    const svg = document.createElement('svg');
    const use = document.createElement('use');
    svg.setAttribute('class', 'inm-icon');
    svg.setAttribute('aria-label', 'inmar icon');
    use.setAttribute('attr.xlink:href', `{{#inm-icon__${this.svgIcon}}}`);
    svg.appendChild(use);
    return svg;
  }
}
