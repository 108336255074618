import { Component,
         EventEmitter,
         OnInit,
         Output,
         Input,
         ViewChild,
         ElementRef} from '@angular/core';
import { ModalAction } from '../../models/modalAction';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { find, map } from 'lodash';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.css']
})

export class SelectDialogComponent implements OnInit {
  @ViewChild('autoCompSelector', { static: false }) autoSelectorElement: AutoComplete;
  @Input() listItems: any[];
  @Input() show: boolean;
  @Input() id: string;
  @Input() displayTextBoxLabel: string;
  @Input() displayLabel: string;
  @Input() titleText: string;
  @Input() filterProperty: string;
  @Output() modalAction: EventEmitter<ModalAction> = new EventEmitter<ModalAction>();

  public selectedValue: any;
  public filteredResults: any;
  public form: FormGroup;
  public disableSaveBtn: boolean;
  public unfilteredResults: any;

  constructor() {
    this.disableSaveBtn = true;
  }

  ngOnInit() {
    this.form = new FormGroup({
      selection: new FormControl(null, [
      ])
    });
  }

  onShow() {
    this.autoSelectorElement.focusInput();
    this.autoSelectorElement.dropdown = true;
    this.autoSelectorElement.dropdownMode = 'current';
    this.reset();
    this.unfilteredResults = map(this.listItems, this.filterProperty);
  }

  validateInput(): void {
    const exists = find(this.unfilteredResults, (o) => {
      return o === this.selectedValue;
    });

    this.disableSaveBtn = exists ? false : true;
  }

  search(event) {
    if (event.query) {
      const results = this.unfilteredResults.filter(s => s
        .toLowerCase().startsWith(event.query.toLowerCase()));
          this.filteredResults = results;
    }
  }

  getListData(): void {
    this.filteredResults = [];
    this.filteredResults = this.unfilteredResults;
    this.autoSelectorElement.suggestions = this.filteredResults;
    this.autoSelectorElement.show();
  }

  reset(): void {
    this.form.reset();
    this.filteredResults = [];
  }

  submitClick(action: string): void {
    const value = find(this.listItems, (o) => o[this.filterProperty] === this.selectedValue);
    this.autoSelectorElement.dropdown = false;
    this.filteredResults = [];
    this.modalAction.emit({
      id: this.id,
      title: this.titleText,
      action: action,
      value: value });
      this.selectedValue = '';
      this.autoSelectorElement.dropdown = false;
      this.disableSaveBtn = true;
  }

}
