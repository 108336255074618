import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { UiHelperService, ClaimService } from 'src/app/services';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

const MAX_RXNUMBERS = 20;

@Component({
  selector: 'app-search-audit-claims',
  templateUrl: './search-audit-claims.component.html',
  styleUrls: ['./search-audit-claims.component.css']
})

export class SearchAuditClaimsComponent implements OnInit, OnChanges {

  @Input() auditId: number;
  @Input() showDialog: boolean;
  @Output() closed: EventEmitter<string[]> = new EventEmitter();
  @ViewChild('area', { static: false }) textArea: ElementRef;
  public rxNums: string;
  public rxNumbersLength: number;
  public badRxNumbers: string[];
  public rxNumbersList: string[] = [];
  public badRxNumbersString: string;
  public formGroup: FormGroup;
  public readonly isIE: any;
  public disableUI: boolean;
  public showInvalidRxNumberCount: boolean;
  public showMaxRxNumberError = false;
  public placeholderText: string = ['Enter or paste one or more (max 20) comma-separated values.\n \n',
    'Example: 55555551234567, 123456876, \u2026'].join('');

  constructor(private uiHelperService: UiHelperService,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.initFormGroup();
  }

  ngOnChanges(changes): void {
    if (changes.showDialog &&
      changes.showDialog.currentValue === false) {
      this.closed.emit();
    }
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      rxNumbers: new FormControl(null, []),
    });
  }

  rxNumbersChanged(event: any) {
    this.rxNumbersLength = 0;
    if (this.rxNums) {
      let dataIn: string[] = [];
      if (this.rxNums.length > 0) {
        dataIn = this.rxNums.split(/\r|\n/);
        if (dataIn && dataIn.length <= 0) {
          dataIn = this.rxNums.split(',');
        }
      }

      if (dataIn.length >= 0 && dataIn.length < MAX_RXNUMBERS + 1) {
        this.rxNums = dataIn.join(', ');
        this.rxNumbersLength = dataIn.length;
      } else {
        this.rxNums = '';
        for (let i = 0; i < MAX_RXNUMBERS; i++) {
          if (i !== MAX_RXNUMBERS - 1) {
            this.rxNums += dataIn[i] + ', ';
          }

          if (i === MAX_RXNUMBERS - 1) {
            this.rxNums += dataIn[i];
          }
        }
        this.rxNumbersLength = this.rxNums.split(',').length;
      }
    }
  }

  searchDisabled() {
    return !(this.rxNumbersLength > 0);
  }

  search() {
    this.loadClaimsGrid();
  }

  loadClaimsGrid() {
    this.badRxNumbers = [];
    const items = this.rxNums.split(',');
    this.rxNumbersList = items.map((item) => item.trim());
    this.rxNumbersList = this.rxNumbersList.filter(Boolean); // to remove trailing commas
    if (items && items.length > MAX_RXNUMBERS) {
      this.showMaxRxNumberError = true;
      return;
    } else {
      this.showMaxRxNumberError = false;
    }
    // Validate Rx numbers against the regex rules
    this.badRxNumbers = this.validateRxNumbers(this.rxNums.trim().split(','));
    this.showInvalidRxNumberCount = this.badRxNumbers.length > 0;
    if (this.showInvalidRxNumberCount) {
      this.badRxNumbersString = this.badRxNumbers.join(', ');
      return;
    }

    this.closed.emit(this.rxNumbersList);
  }

  validateRxNumbers(seids: string[]): string[] {
    let badIds: string[] = [];
    const regexPattern = '^[a-zA-Z0-9]{1,14}$';

    seids.forEach((id: string) => {
      if (!id && !(id.length > 0)) {
        return;
      }
      let cleanId = id.replace(',', '').trim();
      let valid: boolean;
      valid = RegExp(regexPattern).test(cleanId);
      if (valid === false) {
        badIds.push(cleanId);
      }
    });
    return badIds;
  }

  resetRxNumbers() {
    this.rxNums = '';
    this.rxNumbersList = [];
    this.rxNumbersLength = 0;
    this.showInvalidRxNumberCount = false;
    this.showMaxRxNumberError = false;
    if (this.formGroup) {
      this.formGroup.reset();
    }
  }

  close() {
    this.resetRxNumbers();
    this.closed.emit();
  }
}
