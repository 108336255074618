import { ITableColumn } from 'src/app/models/tColumn';

export class AuditTemplateTableHelper {
  public auditTemplateColumns: ITableColumn[];
  constructor() {
    this.buildColumns();
  }

  buildColumns(): void {
    this.auditTemplateColumns = [
      { field: 'TemplateName', header: 'Template Name', hdrClass:'col-link', fldClass: 'col-link', sort: false},
      { field: 'Delete', header: 'Delete', hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn', sort: false}
    ];
  }
}
