import { ITableColumn } from 'src/app/models/tColumn';

export class AuditCarrierDocumentSearchTableHelper {
  public auditCarrierDocumentSearchColumns: ITableColumn[];
  constructor() {
    this.buildColumns();
  }

  buildColumns(): void {
    this.auditCarrierDocumentSearchColumns = [
      { field: 'Selected', header: '',
      hdrClass: 'grid-checkbox', fldClass: 'grid-checkbox', sort: false },
      { field: 'ImageName', header: 'File Name' },
      { field: 'ImageSize', header: 'File Size' },
      { field: 'AuditSpecialistName', header: 'Audit Specialist' },
      { field: 'AuditClassName', header: 'Audit Class' },
      { field: 'ImageCreateDate', header: 'Date' },
    ];
  }
}
