<p-toolbar>
  <div class="ui-toolbar-group-left" *ngFor="let item of toolbarItemList;let i = index">
    <button class="inm-button" *ngIf="item.show" 
      [disabled]="item.disabled" type="button" 
      pTooltip="{{item.tip}}" (click)="toolbarBtnClick(item.name, i)">
      <svg class="inm-icon" aria-label="item.tip">
        <use [attr.xlink:href]="item.icon">{{item.label}}</use>
      </svg>
    </button>
  </div>
</p-toolbar>
