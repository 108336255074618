import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { AuditCarrier,
         PrimeNgListItem,
         ILimitedResult,
         Pagination,
         AuditSummary,
         LimitedResult} from '../models';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessResponse } from 'src/app/models/businessResponse';
import { AuditCarrierDocumentSearch } from '../models/audit-carrier-document-search';

@Injectable({
  providedIn: 'root'
})
export class AuditCarrierService {
  apiRoot: string;

  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) {
    this.apiRoot = environment.auditApiRoot;
  }

  public getAuditCarrierListItems(): Observable<PrimeNgListItem[]> {
    return this.http.get(
    `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditCarriers/?limit=${environment.defaultListLimit}`,
      { headers: this.httpUtility.getHeaders() }
    )
    .pipe(
      map(res => res['Items'].map(item => new PrimeNgListItem(item.Name, item.AuditCarrierId))));
  }

  public getAuditCarriers(pagination: Pagination): Observable<ILimitedResult<AuditCarrier>> {
    const params = pagination.buildSearchParams();
    return this.http.get<ILimitedResult<AuditCarrier>>(
      `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditCarriers`,
      { params }
    );
  }
  // returns 'true' if successful (false or error if it didn't succeed)
  public deleteAuditCarrier(auditCarrierId: number): Observable<boolean> {
    const url = `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditCarriers/${auditCarrierId}`;
    return this.http.delete<boolean>(url);
  }

  public updateAuditCarrier(auditCarrier: AuditCarrier): Observable<BusinessResponse> {
    const headers = this.httpUtility.getHeaders();
    const url = `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditCarriers/`;
    return this.http.put<BusinessResponse>(url, auditCarrier, { headers });
  }

  public addAuditCarrier(auditCarrier: AuditCarrier): Observable<BusinessResponse> {
    const headers = this.httpUtility.getHeaders();
    const url = `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditCarriers/`;
    return this.http.post<BusinessResponse>(url, auditCarrier, { headers });
  }

  public performAuditCarrierDocumentSearch(auditCarrierId: number, pagination: Pagination): 
  Observable<ILimitedResult<AuditCarrierDocumentSearch>> {
    const params = pagination.buildSearchParams();
    const headers = this.httpUtility.getHeaders();
    return this.http.get<LimitedResult<AuditCarrierDocumentSearch>>(
      `${this.apiRoot}/clients/${this.globalFilterService.selectedClientId}/AuditCarriers/${auditCarrierId}/SearchAuditCarrierImageMetadatas`,
      { headers, params }
    );
  }

}

