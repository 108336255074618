import { Component,OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './confirm-tag.component.html',
  styleUrls: ['./confirm-tag.component.css']
})
export class ConfirmTagComponent implements OnInit {

  message: string;
  titleText: string;
  show: boolean;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.message = 'Client Configuration Incomplete (AUDIT and/or RECOUP tag needed). Please contact your client representative.';
    this.titleText = 'Client Configuration Error';
    this.show = true;
  }

  actionCall(): void {
    this.bsModalRef.hide();
  }

}
