import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { BusinessResponseService } from 'src/app/services/business-response.service';
import { SharedClientTenantGuardModule } from '../SharedModules/SharedClientTenantGuardModule';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedClientTenantGuardModule
  ],
  declarations: [],
  providers: [
  ]
})
export class AdminModule { }
