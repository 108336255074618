export class StatusModel {
    StatusId: number;
    Name: string;
    WorkflowOrder: number;
    InUse: boolean;
    DisplayOnWorkflow: boolean;
    ModifiedBy: string;
    ModifiedDate: Date;
    CreatedBy: string;
    CreatedDate: Date;
}
