export interface IAuditStatusColumn {
    field: string;
    header: string;
    hdrClass?: string;
    fldClass?: string;
    show: boolean;
    disabled?: boolean;
    editable?: boolean;
    width?: string;
    disableSort?: boolean;
}

export interface IChecked<T> {
    checked: boolean;
}

export class AuditStatusTableHelper {
    public statusColumns: IAuditStatusColumn[];

    constructor() {
        this.generateStatusSummaryColumns();
    }

    private generateStatusSummaryColumns(): void {
        this.statusColumns = [
            {
                field: 'Name', header: 'Name',
                show: true, disabled: false
            },
            {
                field: 'DisplayOnWorkflow', header: 'Display On Workflow',
                show: true, disabled: false
            },
            {
                field: 'InUse', header: 'In Use',
                hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn',
                show: true, disabled: false, disableSort: true
            },
            {
                field: 'ModifiedBy', header: 'Author',
                show: true, disabled: false
            },
            {
                field: 'ModifiedDate', header: 'Date Modified',
                show: true, disabled: false
            },
            {
                field: 'Delete', header: '',
                hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn',
                show: true, disabled: false,
                width: '50px', disableSort: true
            },
        ];
    }
}


