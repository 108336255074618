import { Component, OnInit, Injectable } from '@angular/core';
import {Router } from '@angular/router';
import * as _ from 'lodash';
import { AuthorizationService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-section',
  templateUrl: './admin-section.component.html'
})
export class AdminSectionComponent implements OnInit {
  sectionRoot = '/admin/';
  sections = [{
    name: 'AUDIT ADDRESS BOOK',
    path: 'auditaddressbook',
    feature: 'UI::AuditAddressBook',
    subSection: null,
  }, {
    name: 'AUDIT CARRIERS',
    path: 'auditcarriers',
    feature: 'UI::AuditCarriers',
    subSection: null,
  }, {
    name: 'AUDIT CLASSES',
    path: 'auditclass',
    feature: 'UI::AuditClass',
    subSection: null,
  }, {
    name: 'AUDIT DISCREPANCY CODES',
    path: 'auditdiscrepancycodes',
    feature: 'UI::AuditDiscrepancyCodes',
    subSection: null,
  }, {
    name: 'AUDIT SPECIALISTS',
    path: 'auditspecialist',
    feature: 'UI::AuditSpecialist',
    subSection: null,
  }, {
    name: 'AUDIT STATUSES',
    path: 'auditstatus',
    feature: 'UI::AuditStatus',
    subSection: null,
  }, {
    name: 'AUDIT TYPES',
    path: 'audittype',
    feature: 'UI::AuditType',
    subSection: null,
  }, {
    name: 'AUDIT VENDORS',
    path: 'auditvendor',
    feature: 'UI::AuditVendor',
    subSection: null,
  }];

  constructor(private router: Router,
    private authorizationService: AuthorizationService) {
  }

  ngOnInit() {
  }
  routeChanged() {
    if (this.authorizationService.HealthcareTopBar) {
      this.authorizationService.HealthcareTopBar.notifyActivity();
    }
  }
}
