import { AuthorizationService } from "src/app/services";
import { ContextMenuItem } from "src/app/shared/models/contextMenuItem";


export class MenuHelper {
  public readonly _menuItems: ContextMenuItem[] = []; 
  private readonly MENU_ITEMS = [
    {'feature': null,
     'item': {
      Name: 'AssociateClaims',
      Label: 'Associate Inmar Claims',
     }},
    {'feature': 'UI::AuditManualClaimUpload',
     'item': { 
      Name: 'NewAuditClaims',
      Label: 'Upload New Audit Claims',
    }},
    {'feature': 'UI::AuditImagesView',
     'item': {
      Name: 'UploadDocuments',
      Label: 'Work With Documents',
    }},
    {'feature': 'UI::AuditFaxDocuments',
     'item': {
      Name: 'SendFax',
      Label: 'Fax Documents',
    }},
    {'feature': 'UI::AuditStatusHistory',
    'item': {
     Name: 'StatusHistory',
     Label: 'Status History',
   }},
   {'feature': 'UI::SearchAuditClaims',
    'item': {
     Name: 'SearchAuditClaims',
     Label: 'Search Audit Claims',
   }}
  ];

  constructor(private authService: AuthorizationService){
    this.generateMenuData();
  }

  generateMenuData(): void {
    for (let i = 0; i < this.MENU_ITEMS.length; i++) {
      if (!this.MENU_ITEMS[i].feature || this.authService.hasFeature(this.MENU_ITEMS[i].feature)) {
          this._menuItems.push(this.MENU_ITEMS[i].item);
      }
    }
  }

  get MenuItems(): ContextMenuItem[] {
    return this._menuItems;
  }
}