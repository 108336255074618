import { Component, EventEmitter, AfterViewInit, OnDestroy, ViewChild, Input, Output, OnChanges, OnInit } from '@angular/core';
import * as toastr from 'toastr';
import { AuthorizationService, AuditService, ImageRepoService, UiHelperService, AuditAddressBookService, GlobalFilterService } from 'src/app/services';
import {  Pagination, ILimitedResult} from 'src/app/models';
import { AuditFaxDocumentsTableHelper } from './helpers/audit-fax-table.helper';
import { TableHeaderCheckbox, TableCheckbox } from 'primeng/table';
import { map, find, forEach } from 'lodash';
import { PhiService } from '../../services/phi.service';
import { FormGroup, FormBuilder, Validators, ValidationErrors, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { AuditImageService } from 'src/app/services/audit-image.service';
import { AuditClassService } from 'src/app/services/audit-class.service';
import { ImageSearchResult } from 'src/app/models/image-search-result';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { AuditAddressBook } from 'src/app/models/audit-address-book';
import { AutoComplete } from 'primeng/autocomplete';
import { CustomPhonePipe } from 'src/app/pipes/custom-phone-pipe';
import { FaxService } from 'src/app/services/fax-service';
import { FaxSend } from 'src/app/models/fax-send';
import { FaxParty } from 'src/app/models/fax-party';


@Component({
  selector: 'app-audit-fax',
  templateUrl: './audit-fax.component.html',
  styleUrls: ['./audit-fax.component.css']
})

export class AuditFaxComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('autoCompAddress', { static: true }) autoSelectorElement: AutoComplete;
  @ViewChild('chkBoxAll', { static: false }) selectAll: TableHeaderCheckbox;
  @ViewChild('row', { static: false }) checkBoxRow: TableCheckbox;
  @Input() auditId: number;
  @Input() showDialog: boolean;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  public auditFormGroup: FormGroup;
  public faxToSend: FaxSend;
  public loadingFaxFormDetails = false;
  public phiToggleEnabled: boolean;
  public faxInProgress = false;
  public showAuditClassSection = false;
  public showDocumentsSection = false;
  public isTemplateAvailable = false;
  public auditClassId: string;
  public auditClassName: string;
  public auditClassImageId: string;
  public auditClassTemplateName: string;
  public auditClassImageMetadata: ImageSearchResult;
  private phiToggleChangedSubscription: any;
  public placeholderText = 'Enter or paste your comments here.';
  public loadingDocumentGrid = true;
  public isIncludeHeader = true;
  public isIncludeAuditClass = false;
  public maxFileCountLimitReached = false;
  public maxFileSizeLimitReached = false;
  public readonly isIE: any;
  public documentResults: ImageSearchResult[] = [];
  public selectedDocuments: ImageSearchResult[] = [];
  public listAddressBook: AuditAddressBook[] = [];
  public filteredResults: any[] = [];
  public unfilteredResults: any[] = [];
  public filterProperty = 'ContactName';
  public totalItemCount = 0;
  public rows: number;
  public headerCheckboxCookieKey = 'fax_include_header_checkbox';
  public senderNameCookieKey = 'fax_sender_name';
  public senderFaxCookieKey = 'fax_sender_fax';
  public senderPhoneCookieKey = 'fax_sender_phone';
  public name: any;
  public totalFilesAllowed = 10;
  public totalFileSizeAllowed = 50;
  public filesAttached = 0;
  public selectedDocumentsFileSize = 0;
  public documentResultsFileSize = 0;
  public readonly tableHelper: AuditFaxDocumentsTableHelper;
  public readonly rowsByBrowser: number;

  // error messages
  readonly faxInvalidErrorMessage = 'Fax should be 10 digit number.';
  readonly phoneInvalidErrorMessage = 'Phone should 10 digit number.';
  readonly validPhoneRegexPattern = '^\\(?[0-9]{3}\\)?[\\s]?[0-9]{3}[-]?[0-9]{4}$';

  constructor(private fb: FormBuilder,
    private imageRepoService: ImageRepoService,
    private auditImageService: AuditImageService,
    private auditClassService: AuditClassService,
    private globalFilterService: GlobalFilterService,
    private faxService: FaxService,
    private auditService: AuditService,
    private uiHelperService: UiHelperService,
    private datePipe: DatePipe,
    private cookieService: CookieService,
    private phiService: PhiService,
    private auditAddressBookService: AuditAddressBookService,
    private customPhonePipe: CustomPhonePipe) {
    this.rowsByBrowser = 9999;
    this.tableHelper = new AuditFaxDocumentsTableHelper();
    this.phiService.PhiToggleChanged.subscribe(() => this.onPhiToggleChange());
    this.initFormGroup();
  }

  ngOnInit() {
    this.onPhiToggleChange();
  }

  ngOnDestroy() {
    this.auditId = undefined;
    if (this.phiToggleChangedSubscription) {
      this.phiToggleChangedSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes): void {
    if (changes.showDialog && changes.showDialog.currentValue === false) {
      this.resetForm();
      this.closed.emit();
    }
    if (changes.showDialog &&
      changes.showDialog.currentValue === true && this.auditId) {
        this.initFormGroup();
        this.loadData();
        this.selectedDocuments = [];
        this.selectedDocumentsFileSize = 0;
        this.documentResultsFileSize = 0;
      }
  }

  initFormGroup() {
    this.auditFormGroup = this.fb.group(
      {
        recipientName: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
        senderName: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
        recipientFax: ['', Validators.compose([Validators.required, Validators.maxLength(14), Validators.pattern(this.validPhoneRegexPattern)])],
        senderFax: ['', Validators.compose([Validators.required, Validators.maxLength(14), Validators.pattern(this.validPhoneRegexPattern)])],
        recipientPhone: ['', Validators.compose([Validators.maxLength(14), Validators.pattern(this.validPhoneRegexPattern)])],
        senderPhone: ['', Validators.compose([Validators.maxLength(14), Validators.pattern(this.validPhoneRegexPattern)])],
        todayDate: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
        recipientCompany: ['', Validators.compose([Validators.maxLength(100)])],
        subject: ['', Validators.compose([Validators.maxLength(100)])],
        comments: ['', Validators.compose([Validators.maxLength(2000)])],
        headerCheckBox: true,
        auditCheckbox: false
      });
  }

  loadData() {
    this.loadDocumentsSection();
    this.loadAuditClassSection();
    this.getDataFromCookies();
    this.getAddressBook();
  }

  loadAuditClassSection() {
    this.resetAuditClass();
    if (this.auditId) {
      this.auditService.getAudit(this.auditId)
        .subscribe(
          (result) => {
            this.auditClassId = result.AuditClassId;
            if (this.auditClassId) {
              this.showAuditClassSection = true;
              this.auditClassService.getAuditClass(Number(this.auditClassId)).subscribe((auditClass) => {
                this.auditClassImageId = auditClass.ImageId;
                this.auditClassTemplateName = auditClass.TemplateName;
                this.auditClassName = auditClass.Name;
                this.auditClassImageMetadata = new ImageSearchResult;
                if (this.auditClassImageId) {
                  this.isTemplateAvailable = true;
                  this.isIncludeAuditClass = true;
                  this.filesAttached += 1;
                  this.imageRepoService.getImageMetadata(this.auditClassImageId).subscribe((imageSearchResult) => {
                    this.auditClassImageMetadata.ImageId = imageSearchResult.ImageId;
                    this.auditClassImageMetadata.Name = imageSearchResult.Name;
                    this.auditClassImageMetadata.MimeType = imageSearchResult.MimeType;
                    this.auditClassImageMetadata.Size = this.convertAuditClassImageSizeToMB(imageSearchResult.Size);
                    this.auditClassImageMetadata.ClientId = imageSearchResult.ClientId;
                    this.auditClassImageMetadata.CreateUserId = imageSearchResult.CreateUserId;
                    this.auditClassImageMetadata.CreateDate = imageSearchResult.CreateDate;

                    this.documentResultsFileSize += this.auditClassImageMetadata.Size;

                    this.selectedDocuments.splice(0, 0, this.auditClassImageMetadata);

                  }, err => {
                    toastr.error('There was an issue getting the audit class image from the server.');
                  });

                }

              });
            }
          },
          err => {
            toastr.error('Error loading audit');
          });
    }
  }

  loadDocumentsSection() {
    if (this.auditId) {
      this.loadImagesGrid(new Pagination(this.rowsByBrowser, 0));
    }
  }

  loadImagesGrid(pagination: Pagination) {
    this.loadingDocumentGrid = true;
    this.documentResults = [];
    this.auditImageService.getAssociatedImages(this.auditId, pagination)
      .subscribe((result: ILimitedResult<ImageSearchResult>) => {
        this.documentResults = result.Items;
        this.totalItemCount = result.TotalItemCount;
        this.documentResults = this.documentResults.map(this.convertImageSizeToMB);
        const tempDocumentResults = this.documentResults.map(item => item.Size);
        this.documentResultsFileSize = tempDocumentResults.map(Number).reduce((a, b) => a + b, 0);
        if (this.totalItemCount !== 0) {
          this.showDocumentsSection = true;
        }
        this.loadingDocumentGrid = false;
        if (!this.isIE) {
          this.rows = result.TotalItemCount;
        }

      }, err => {
        toastr.error('There was an issue getting images from the server.');
        this.documentResults = [];
        this.loadingDocumentGrid = false;
      });
  }

  getAddressBook() {
    if (this.auditId) {
    this.auditAddressBookService.getAuditAddressBookLimitedResult(new Pagination(this.rowsByBrowser, 0))
      .subscribe((result: ILimitedResult<AuditAddressBook>) => {
        this.listAddressBook = result.Items;
        this.unfilteredResults = map(this.listAddressBook, this.filterProperty);
      }, err => {
        toastr.error('There was an issue getting Addresses from the server.');
        this.listAddressBook = [];
      });
    }
  }

  getDataFromCookies() {
    if (this.cookieService.check(this.senderNameCookieKey)) {
      this.auditFormGroup.controls['senderName'].setValue(this.getCookie(this.senderNameCookieKey));
    }
    if (this.cookieService.check(this.senderFaxCookieKey)) {
      const faxValue = this.getCookie(this.senderFaxCookieKey);
      this.auditFormGroup.controls['senderFax'].setValue(this.customPhonePipe.transform(faxValue));
    }
    if (this.cookieService.check(this.senderPhoneCookieKey)) {
      const phoneValue = this.getCookie(this.senderPhoneCookieKey);
      this.auditFormGroup.controls['senderPhone'].setValue(this.customPhonePipe.transform(phoneValue));
    }
    if (this.cookieService.check(this.headerCheckboxCookieKey)) {
      const boolValue = JSON.parse(this.getCookie(this.headerCheckboxCookieKey));
      this.auditFormGroup.controls['headerCheckBox'].setValue(boolValue);
      this.isIncludeHeader = boolValue;
    }
  }

  displayOtherFields() {
    const value = find(this.listAddressBook, (o) => o[this.filterProperty] === this.auditFormGroup.controls['recipientName'].value);
    this.auditFormGroup.controls['recipientFax'].setValue(value.FaxNumber);
    this.auditFormGroup.controls['recipientPhone'].setValue(value.PhoneNumber);
    this.auditFormGroup.controls['recipientCompany'].setValue(value.CompanyName);
    this.getFormValidationErrors();
  }

  sendFax() {
    // set cookie
    this.faxInProgress = true;
    const phoneValue = this.auditFormGroup.controls['senderPhone'].value;
    this.saveDataInCookies();
    this.setFaxValues();
    this.faxService.sendFax(this.faxToSend).subscribe(
      (response: Response) => {
        this.resetForm();
        toastr.success('Fax Successfully Queued.');
        this.faxInProgress = false;
        this.closed.emit(true);
      }, (err) => {
        err.error.Content.forEach( (message) => {
          toastr.error('Fax Unsuccessfully Queued: \n' + message.PropertyError);
        });
        this.faxInProgress = false;
        this.closed.emit(false);
      });

  }

  resetForm() {
    this.resetAuditClass();
    this.selectedDocuments = [];
    this.documentResults = [];
    this.filesAttached = 0;
    this.selectedDocumentsFileSize = 0;
    this.isIncludeHeader = true;
    this.maxFileCountLimitReached = false;
    this.maxFileSizeLimitReached = false;
    if (this.auditFormGroup) {
      this.auditFormGroup.reset();
    }
  }

  checkAllClick(event): void {
    if (!this.selectAll.checked) {
      if (this.isIncludeAuditClass) {
        this.filesAttached = 1;
        this.selectedDocuments.splice(0, 0, this.auditClassImageMetadata);
      } else {
        this.filesAttached = 0;
        this.selectedDocuments = this.selectedDocuments.filter(document => document.ImageId !== this.auditClassImageId);
      }
    } else {
      this.filesAttached += this.selectedDocuments.length;
      this.calculateSelectedDocumentsFileSize();
      this.isMaxFileLimitReached();
    }
  }

  resetPageSetup() {
    if (this.auditFormGroup) {
      this.auditFormGroup.reset();
    }
    this.filesAttached = 0;
    this.selectedDocumentsFileSize = 0;
    this.selectedDocuments = [];
    this.initFormGroup();
    this.getDataFromCookies();
    if (this.isTemplateAvailable) {
      this.isIncludeAuditClass = true;
      this.auditFormGroup.controls['auditCheckbox'].setValue(true);
      this.filesAttached++;
      this.selectedDocumentsFileSize += this.auditClassImageMetadata.Size;
      this.selectedDocuments.splice(0, 0, this.auditClassImageMetadata);
    }
    this.maxFileCountLimitReached = false;
    this.maxFileSizeLimitReached = false;
  }

  resetAuditClass() {
    this.auditClassId = null;
    this.showAuditClassSection = false;
    this.auditClassName = null;
    this.isTemplateAvailable = false;
    this.filesAttached = 0;
    this.selectedDocumentsFileSize = 0;
  }

  toggleAuditClassCheckbox(isChecked: boolean) {
    if (isChecked) {
      this.filesAttached++;
      this.selectedDocuments.splice(0, 0, this.auditClassImageMetadata);
      this.calculateSelectedDocumentsFileSize();
      this.isMaxFileLimitReached();
    } else {
      this.filesAttached--;
      if (this.filesAttached <= this.totalFilesAllowed) {
        this.maxFileCountLimitReached = false;
      }
      this.selectedDocuments = this.selectedDocuments.filter(document => document.ImageId !== this.auditClassImageId);
      this.calculateSelectedDocumentsFileSize();
      // this.isMaxFileLimitReached();
      if (this.selectedDocumentsFileSize <= this.totalFileSizeAllowed) {
        this.maxFileSizeLimitReached = false;
      }

    }

  }

  filterAddress(event) {
    if (event.query) {
      const results = this.unfilteredResults.filter(s => s
        .toLowerCase().includes(event.query.toLowerCase()));
      this.filteredResults = results;
    }
  }

  saveDataInCookies() {
    if (this.auditFormGroup.controls['senderName'].value != null) {
      this.setCookie(this.senderNameCookieKey, this.auditFormGroup.controls['senderName'].value);
    }
    if (this.auditFormGroup.controls['senderFax'].value != null) {
      const unmaskedFaxValue = this.unmaskPhone(this.auditFormGroup.controls['senderFax'].value);
      this.setCookie(this.senderFaxCookieKey, unmaskedFaxValue);
    }
    if (this.auditFormGroup.controls['senderPhone'].value != null) {
      const unmaskedPhoneValue = this.unmaskPhone(this.auditFormGroup.controls['senderPhone'].value);
      this.setCookie(this.senderPhoneCookieKey, unmaskedPhoneValue);
    }
    this.setCookie(this.headerCheckboxCookieKey, this.isIncludeHeader);
  }

  setCookie(key: string, value: any) {
    const today = new Date();
    const exp = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
    this.cookieService.set(key, value, exp);
  }

  getCookie(key: string) {
    return this.cookieService.get(key);
  }

  undoSelect(event): void {
    if (this.selectedDocuments.length > 0) {
      this.selectedDocuments = [];
    }
  }

  onDocumentSelect(event) {
    this.filesAttached++;
    this.calculateSelectedDocumentsFileSize();
    this.isMaxFileLimitReached();
  }

  onDocumentUnselect(event) {
    this.filesAttached--;
    if (this.filesAttached <= this.totalFilesAllowed) {
      this.maxFileCountLimitReached = false;
    }
    this.calculateSelectedDocumentsFileSize();
    if (this.selectedDocumentsFileSize <= this.totalFileSizeAllowed) {
      this.maxFileSizeLimitReached = false;
    }
  }

  isMaxFileLimitReached() {
    if (this.filesAttached > this.totalFilesAllowed) {
      this.maxFileCountLimitReached = true;
      toastr.error('Exceeded max file limitation of ' + this.totalFilesAllowed + ' documents.');
    }
    if (this.selectedDocumentsFileSize > this.totalFileSizeAllowed) {
      this.maxFileSizeLimitReached = true;
      toastr.error('Exceeded max file limitation of ' + this.totalFileSizeAllowed + ' MB.');
    }
  }

  convertImageSizeToMB(image): ImageSearchResult {
    image.Size = parseFloat((image.Size / Math.pow(10, 6)).toFixed(2));
    if (image.Size === 0) {
      image.Size = 0.01;
    }
    return image;
  }

  convertAuditClassImageSizeToMB(imageSize: number): number {
    let auditClassImageSize = parseFloat((imageSize / Math.pow(10, 6)).toFixed(2));
    if (auditClassImageSize === 0) {
      auditClassImageSize = 0.01;
    }
    return auditClassImageSize;
  }

  calculateSelectedDocumentsFileSize() {
    const tempSelectedDocuments = this.selectedDocuments.map(item => item.Size);
    this.selectedDocumentsFileSize = tempSelectedDocuments.map(Number).reduce((a, b) => a + b, 0);
  }

  limit(event) {
    const controlName = event.target.attributes['formcontrolname'].value;
    this.uiHelperService.limitToMaxLength(this.auditFormGroup, controlName);
  }

  faxIsInvalid() {
    return ((!this.auditFormGroup.controls['recipientFax'].valid
      && this.auditFormGroup.controls['recipientFax'].touched
      && this.auditFormGroup.controls['recipientFax'].errors['pattern']) ||
      (!this.auditFormGroup.controls['senderFax'].valid
        && this.auditFormGroup.controls['senderFax'].touched
        && this.auditFormGroup.controls['senderFax'].errors['pattern']));
  }

  phoneIsInvalid() {
    return ((!this.auditFormGroup.controls['senderPhone'].valid
      && this.auditFormGroup.controls['senderPhone'].touched
      && this.auditFormGroup.controls['senderPhone'].errors['pattern']) ||
      (!this.auditFormGroup.controls['recipientPhone'].valid
        && this.auditFormGroup.controls['recipientPhone'].touched
        && this.auditFormGroup.controls['recipientPhone'].errors['pattern']));
  }

  onPhiToggleChange(): void {
    this.phiToggleEnabled = this.phiService.PhiToggle;
  }

  hide(): void {
    this.resetForm();
    this.closed.emit(false);
  }

  unmaskPhone(tel: string) {
    return tel.replace(/\D+/g, '');
  }

  getFormValidationErrors() {
      Object.keys(this.auditFormGroup.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.auditFormGroup.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
          });
        }
      });
  }

  validSectionSelected(): boolean {
    return (this.isIncludeAuditClass || this.selectedDocuments.length > 0 );
  }

  formIsInvalid(): boolean {
    return (!this.validSectionSelected() || this.auditFormGroup.invalid ||
      this.maxFileSizeLimitReached || this.maxFileCountLimitReached || this.faxInProgress);
  }

  clickAuditClassTemplate(event) {

      this.imageRepoService.downloadImage(this.auditClassImageId).subscribe((data) => {

        const blob = new Blob([data], { type: this.auditClassImageMetadata.MimeType });

        const downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.auditClassTemplateName;

        // this is a get around to download a blob.
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, this.auditClassTemplateName);
        } else {
          link.click();
          link = null;
        }
      }, err => {
        toastr.error('There was an issue getting the image from the server.');
      });


  }

  downloadDocument(rowData: ImageSearchResult) {
    this.auditImageService.downloadImage(this.auditId, rowData.ImageId)
      .subscribe((data) => {

        const blob = new Blob([data], { type: rowData.MimeType });

        const downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = rowData.Name;

        // this is a hack to support ie download of blob.
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, rowData.Name);
        } else {
          link.click();
          link = null;
        }
      }, err => {
        toastr.error('There was an issue getting the image from the server.');
      });
  }

  getPhiToggle(): boolean {
    return this.phiService.PhiToggle;
  }

  setFaxValues() {
    this.faxToSend = new FaxSend();
    this.faxToSend.Recipients = new Array<FaxParty>();
    const recipient = new FaxParty();
    recipient.Name = this.auditFormGroup.controls['recipientName'].value;
    recipient.PhoneNumber = '';
    if (this.auditFormGroup.controls['recipientPhone'].value) {
      recipient.PhoneNumber = '1' + this.unmaskPhone(this.auditFormGroup.controls['recipientPhone'].value);
    }
    recipient.FaxNumber = '1' + this.unmaskPhone(this.auditFormGroup.controls['recipientFax'].value);
    recipient.Company = this.auditFormGroup.controls['recipientCompany'].value;
    this.faxToSend.Recipients.push(recipient);

    this.faxToSend.Sender = new FaxParty();
    this.faxToSend.Sender.Name = this.auditFormGroup.controls['senderName'].value;
    this.faxToSend.Sender.FaxNumber = '1' + this.unmaskPhone(this.auditFormGroup.controls['senderFax'].value);
    this.faxToSend.Sender.PhoneNumber = '';
    if (this.auditFormGroup.controls['recipientPhone'].value) {
      this.faxToSend.Sender.PhoneNumber = '1' + this.unmaskPhone(this.auditFormGroup.controls['recipientPhone'].value);
    }
    this.faxToSend.SendDate = this.auditFormGroup.controls['todayDate'].value;
    this.faxToSend.IncludeVendorCoverPage = this.auditFormGroup.controls['headerCheckBox'].value;
    this.faxToSend.Subject = this.auditFormGroup.controls['subject'].value;
    this.faxToSend.Comments = this.auditFormGroup.controls['comments'].value;

    this.faxToSend.SelectedDocumentMetadata = this.selectedDocuments;

  }

}
