import { AuthorizationService } from 'src/app/services';
import { generate } from 'rxjs';

export class VendorToolbarHelper {
  public showAction: string;
  public actionText: string;
  public currentToolbar = [];
  private readonly TOOLBAR_ITEMS = [
    { feature: '',
      item: {label: 'Refresh', name: 'refresh', tip: 'Refresh Grid', icon: '#inm-icon__refresh',
      disabled: false, show: true, exclude: true }},
    { feature: 'UI::VendorAdd',
      item: {label: 'Add Vendor', name: 'addVendor', tip: 'Add New Vendor',
      icon: '#inm-icon__plus-square', disabled: false, show: true }}];
  constructor(private authService: AuthorizationService) {
    this.generateToolbarData();
  }

  generateToolbarData(): void {
    for (let i = 0; i < this.TOOLBAR_ITEMS.length; i++) {
      if (!this.TOOLBAR_ITEMS[i].feature) {
        this.currentToolbar.push(this.TOOLBAR_ITEMS[i].item);
        continue;
      }
      if (this.TOOLBAR_ITEMS[i].feature &&
            this.authService.hasFeature(this.TOOLBAR_ITEMS[i].feature)) {
          this.currentToolbar.push(this.TOOLBAR_ITEMS[i].item);
      }
    }
  }

  toolbarBtnClick(name: string): void {
    this.showAction = name;
    switch (name) {
      case 'refresh': {
        break;
      } case 'deleteVendor': {
        this.actionText = 'Delete Selected Vendor';
        break;
      }
    }
  }
}
