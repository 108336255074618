
<p-progressBar mode="indeterminate" *ngIf="loadingHistGrid"></p-progressBar>
<app-audit-toolbar [toolbarItemList]="toolbarHelper.currentToolbar" (OnClick)="toolbarBtnClick($event)"></app-audit-toolbar>
<div class='inm-spaced'>
<p-table #dTable
    [columns]="tableHelper.auditStatusHistoryColumns"
    [value]="statusHistoryResults" 
    [loading]="loadingHistGrid" 
    [lazy]="true" 
    [paginator]="true" 
    [rows]="30" 
    [responsive]="true" 
    [totalRecords]="totalItemCount" 
    [scrollable]="true" 
    scrollHeight="350px"
    (onLazyLoad)="loadAuditStatusHistoryLazy($event)">
      <ng-template pTemplate="header" let-columns>
        <tr class="statusHistoryCols">
          <th *ngFor="let col of columns" 
            [ngClass]="col.hdrClass ? col.hdrClass : 'default'" 
            [pSortableColumn]="col.sort === false ? false : col.field">
            {{col.header}} 
            <p-sortIcon *ngIf="col.sort !== false" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">      
                <tr>
          <td *ngFor="let col of columns" [ngClass]="col.fldClass ? col.fldClass : 'default'">
            <div *ngIf="col.field === 'Name'">
              {{rowData[col.field]}}
            </div>
            <div *ngIf="col.field === 'DueDate'">
              {{rowData[col.field] | date: 'MM/dd/yyyy'}}
            </div>
            <div *ngIf="col.field === 'CreatedBy'">
                {{rowData[col.field]}}
              </div>
            <div *ngIf="col.field === 'CreateDate'">
              {{rowData[col.field] | date: 'MM/dd/yyyy'}}
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan ="3" style=" text-align: center">
              No records found
            </td>
          </tr>
        </ng-template>
      <ng-template pTemplate="summary">
        Total Record Count: {{ totalItemCount }}
      </ng-template>
  </p-table>
</div>

