import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuditTypeService, GlobalFilterService } from '../services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmClientTenantComponent } from '../components/confirm-client-tenant/confirm-client-tenant.component';
import { Observable } from 'rxjs';

type SimpleCallback = () => void;

@Injectable()
export class ClientTenantGuard implements CanActivateChild, CanActivate {
  currentRoute: ActivatedRouteSnapshot;
  currentRouterState: RouterStateSnapshot;

  bsModalRef: BsModalRef;

  constructor(private router: Router, private auditTypeService: AuditTypeService,
    private modalService: BsModalService, private cookieService: CookieService,
    private globalFilterService: GlobalFilterService) { }

    

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      const self = this;
      
      const promise = new Promise((resolve, reject) => {
        self.getTopBar(async () => {
          const clientTenantKey = `${this.globalFilterService.selectedClientId}-tenantpresent`;
          if (this.globalFilterService.selectedClientId !== undefined) {
            var result = await this.auditTypeService.getAuditTypeListItems()
              .toPromise()
              .catch(error => {
                // take out the second condition when we allow more than one tenant.
                if ((error.error.Error === 'User does not have access to any client tenants.' &&
                  error.status === 401) || (error.error.Error === 'client tenant id is required for this request.' && error.status === 404)) {
                  this.bsModalRef = this.modalService.show(ConfirmClientTenantComponent);
                  // this.clientChangedSubscription.unsubscribe();
                  this.router.navigate(['/']);
                  return false;
                }
              });
            if(result !== undefined) {
              this.setCookie(clientTenantKey);
              resolve(true);
              return;
            }
          }
          else {
            resolve(false);
            return;
          }
        });
      });
      
      return promise.then(
        (res: boolean) => {
          if (res) {
            return true;
          }
          else {
            self.router.navigate(['/']);
            return false;
          }
        },
        (error) => {
          self.router.navigate(['/']);
          return false;
        }
      )
    }

  public clientChangedSubscription = this.globalFilterService.ClientChanged
    .subscribe(() => this.canActivateChild(this.currentRoute, this.currentRouterState));

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const self = this;
    self.getTopBar(() => {
      const clientTenantKey = `${this.globalFilterService.selectedClientId}-tenantpresent`;

      if (!this.getCookie(clientTenantKey)) {
        if (this.globalFilterService.selectedClientId !== undefined) {
          this.currentRoute = route;
          this.currentRouterState = this.currentRouterState;
          this.auditTypeService.getAuditTypeListItems()
            .toPromise()
            .then(result => {
              // if all is well we do nothing
              this.setCookie(clientTenantKey);
              return true;
            })
            .catch(error => {
              // take out the second condition when we allow more than one tenant.
              if ((error.error.Error === 'User does not have access to any client tenants.' &&
                error.status === 401) || (error.error.Error === 'client tenant id is required for this request.' && error.status === 404)) {
                this.bsModalRef = this.modalService.show(ConfirmClientTenantComponent);
                this.clientChangedSubscription.unsubscribe();
                this.router.navigate(['/']);
                return false;
              }
            });
        } else {
          return false;
        }
      }
    });
    return true;
  }

  getCookie(key: string) {
    return this.cookieService.get(key);
  }

  setCookie(key: string) {
    const today = new Date();
    const exp = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
    this.cookieService.set(key, 'true', exp);
  }

  removeCookie(key: string) {
    return this.cookieService.delete(key);
  }

  getTopBar(cb: SimpleCallback): void {
    const self = this;
    if (this.globalFilterService.selectedClientId === undefined) {
      setTimeout(() => {
        self.getTopBar(cb);
      }, 50);
    } else {
      cb();
    }
  }
}
