import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ILimitedResult, Pagination, AuditClaimNote, ClaimWithSourceAndNoteIds
} from '../models';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';

const BASE_URL = `${environment.auditApiRoot}/clients`;

@Injectable({
  providedIn: 'root'
})

export class AuditClaimNoteService {
  private readonly Timeout: number = 30000;
  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) {
  }

  public getAuditClaimNotes(rawDataImport_ShardClaimIdentifier: number,
                            source: string,
                            pagination: Pagination): Observable<ILimitedResult<AuditClaimNote>> {
    const url = `${BASE_URL}/${this.globalFilterService.selectedClientId}/Claims/${rawDataImport_ShardClaimIdentifier}/AuditClaimNotes/Source/${source}`;
    const params = pagination.buildSearchParams();
    const headers = this.httpUtility.getHeaders();
    return this.http.get<ILimitedResult<AuditClaimNote>>(url, { headers, params });
  }

  public deleteAuditClaimNotes(claimWithSourceAndNotes: ClaimWithSourceAndNoteIds): Observable<boolean> {
    const url = `${BASE_URL}/${this.globalFilterService.selectedClientId}/DeleteAuditClaimNotes`;
    const headers = this.httpUtility.getHeaders();
    return this.http.post<boolean>(url, claimWithSourceAndNotes, { headers });
  }
}
