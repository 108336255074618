<section class="inm-layout-sidebar">
    <nav class="inm-expandable--sidebar inm-layout-sidebar__nav" style="margin-top: 10px">
      <app-side-nav parent="admin" [sections]="sections"></app-side-nav> 
    </nav>
    <div class="inm-layout-sidebar__content-area">
      <!-- main site content goes here -->
      <section class="inm-layout-main__content">
          <router-outlet (activate)="routeChanged()"></router-outlet>
      </section> 
    </div>
  </section>

