import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'customPhonePipe'
    })

export class CustomPhonePipe implements PipeTransform {
    transform (phone: any, args?: any): any {

        if (phone) {
            // console.log('Telephone number coming:: ' + tel);
            // return value.charAt(0) === '-' ?
            // '(' + value.substring(1, value.length) + ')' :
            //     value;

                const value = phone.toString().trim().replace(/[)( -\+]/g, '');

                if (value.match(/[^0-9]/)) {
                    // console.log('Inside first if condition and returning:' + tel);
                    return phone;
                }

                let country, city, number;

                switch (value.length) {
                    case 10: // +1PPP####### -> C (PPP) ###-####
                        country = 1;
                        city = value.slice(0, 3);
                        number = value.slice(3);
                        break;

                    case 11: // +CPPP####### -> CCC (PP) ###-####
                        country = value[0];
                        city = value.slice(1, 4);
                        number = value.slice(4);
                        break;

                    case 12: // +CCCPP####### -> CCC (PP) ###-####
                        country = value.slice(0, 3);
                        city = value.slice(3, 5);
                        number = value.slice(5);
                        break;

                    default:
                        // console.log('Return default case:' + tel);
                        return phone;
                }

                if (country === 1) {
                    country = '';
                }

                number = number.slice(0, 3) + '-' + number.slice(3);
                const finalResp = (' (' + city + ') ' + number).trim();
                // console.log('Returning proper response' + finalResp);
                return finalResp;
        } else {
            // console.log('Returning in final else condition:' + tel);
            return phone;
        }
    }
}
