import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    private lastNotifyActivityCalled = null;

    constructor(
        private cookieService: CookieService,
        private authorizationService: AuthorizationService,
    ) { 
        this.lastNotifyActivityCalled = new Date();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.cookieService.check('id_token')) {
            // Important: this is done to avoid calling notifyActivity multiple times and stacking up ActiveTokens api calls.
            let currentTime = new Date();
            let difference = (currentTime.getTime() - this.lastNotifyActivityCalled.getTime()) / 1000;

            if(difference > 10){
                if (this.authorizationService.HealthcareTopBar ) {
                    this.authorizationService.HealthcareTopBar.notifyActivity();
                    this.lastNotifyActivityCalled = new Date();
                }
            }
            
            req = req.clone({
                setHeaders: { Authorization: `Bearer ${this.cookieService.get('id_token')}` },
            });
        }
        return next.handle(req);
    }

    getCookie(key: string) {
        return this.cookieService.get(key);
    }
  
    setCookie(key: string){
        let today = new Date();
        const exp = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        this.cookieService.set(key, 'true', exp);
    }
}
