<h2 style="margin-bottom: 1em;">Audit Vendors</h2>
<app-audit-toolbar [toolbarItemList]="toolbarHelper.currentToolbar" (OnClick)="toolbarBtnClick($event)"></app-audit-toolbar>
<p-table #dt [columns]="tableHelper.tableColumns" 
  [value]="vendors" (onLazyLoad)="loadDataLazy($event)"
  [loading]="tableLoading" loading="fa-spinner" [scrollable]="true" 
  [lazy]="true" [paginator]="true" [responsive]="true" [rows]="30"
  [totalRecords]="totalItemCount">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" 
        [ngClass]="col.hdrClass ? col.hdrClass : 'default'"
        [ngStyle]="!col.sortable? {'cursor': 'default'} : ''"
        [pSortableColumn]="col.sortable? col.field : ''">
          {{col.header}}
      <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
    </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" [ngClass]="col.fldClass ? col.fldClass : 'default'">
        <div *ngIf="col.field === 'Name'">
          <a style="cursor: pointer;" *ngIf="viewFeatures[1].accessable" [routerLink]=""
          (click)="clickName(rowData[col.field], rowData['VendorId'])">
            {{rowData[col.field]}}
          </a>
          <span *ngIf="!viewFeatures[1].accessable">
            {{rowData[col.field]}}
          </span>
        </div>
        <div *ngIf="col.field === 'InUse'">
          {{rowData[col.field] ? 'Yes' : 'No'}}
        </div>
        <div *ngIf="col.field === 'ModifiedBy'">
          {{ rowData[col.field] }}
        </div>
        <div *ngIf="col.field === 'ModifiedDate'">
          {{ rowData[col.field] | date:'MM/dd/yyyy'}}
        </div>
        <div *ngIf="col.field === 'delete'"  [ngStyle]="!rowData[col.InUse] ? {'cursor': 'pointer'} : ''">
          <a *ngIf="rowData.InUse !== true" (click)="deleteClicked(rowData)" style="cursor: pointer; width: 30px;">
            <svg class="inm-icon" aria-label="Delete">
              <use xlink:href="#inm-icon__trash" class="danger" /></svg>
          </a>
          <a *ngIf="rowData.InUse === true" style="cursor: default;width: 30px;">
            <svg class="inm-icon" aria-label="Delete">
              <use xlink:href="#inm-icon__trash" class="disabled" /></svg>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary"> 
    Total Record Count: {{ totalItemCount }}
  </ng-template>
</p-table>
<app-delete-dialog style="width: 300px; height: auto" 
  [show]="confirmDelete" 
  [titleText]="deleteTitle"
  [message]="deleteText"
  (modalAction)="deleteCallback($event)">
</app-delete-dialog>
<app-admin-add-edit-dialog 
  [titleText]="editDialogTitle" 
  [(show)]="showAddEditDialog" 
  [id]="vendorId"
  [nameValue]="vendorName" 
  (modalAction)="editCallback($event)">
</app-admin-add-edit-dialog>
