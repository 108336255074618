<p-progressBar mode="indeterminate" *ngIf="loadingMasterData"></p-progressBar>
<h2 *ngIf="editAuditId">
  {{auditNumberText}}
</h2>
<h2 *ngIf="!editAuditId">
  Add Audit
</h2>
<div *ngIf="auditFormGroup">
  <form [formGroup]="auditFormGroup" id="auditForm" class="form">
    <section class='inm-wrapper'>
      <div class="inm-spaced">
        <label class="evenwidth">
          <div class="inm-label--required">Audit Reference Number:</div>
          <input class="inm-input" formControlName="auditReferenceNumber" (input)="limit($event)">
        </label>
        <div class="evenwidth">
          <div class="inm-label--required">Audit Type:</div>
          <p-dropdown [options]="auditTypesListItems" autoWidth="true" [disabled]="loadingMasterData" formControlName="auditTypeId"></p-dropdown>
        </div>
        <div class="evenwidth">
          <label class="inm-label--required" for="notificationDate">Audit Letter Date:</label>
          <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true" [monthNavigator]="true" [showIcon]="true"
            formControlName="notificationDate" placeholder="MM/DD/YYYY">
          </p-calendar>
        </div>
      </div>
      <div class="inm-spaced">
        <div class="evenwidth">
          <div class="inm-label--required">Audit Status:</div>
          <p-dropdown [options]="auditStatusListItems" autoWidth="true" [disabled]="loadingMasterData" formControlName="statusId"></p-dropdown>
        </div>
        <div class="evenwidth">
          <div class="inm-label">Audit Vendor:</div>
          <p-dropdown [options]="auditVendorListItems"
          autoWidth="true" [disabled]="loadingMasterData"
          [showClear]="true" placeholder=" "
          formControlName="vendorId"></p-dropdown>
        </div>
        <div class="evenwidth">
          <label class="inm-label--required">Received Date:</label>
          <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true" [monthNavigator]="true" [showIcon]="true"
            formControlName="receivedDate" placeholder="MM/DD/YYYY">
          </p-calendar>
        </div>
      </div>
      <div class="inm-spaced">
        <div class="evenwidth">
          <div class="inm-label">Audit Specialist:</div>
          <p-dropdown
          [options]="auditSpecialistListItems"
          autoWidth="true"
          [showClear]="true" placeholder=" "
          [disabled]="loadingMasterData"
          formControlName="specialistId"></p-dropdown>
        </div>
        <label class="evenwidth">
          <div class="inm-label">Note:</div>
          <textarea class="inm-input" formControlName="noteText" (input)="limit($event)" style="height:100px;"></textarea>
        </label>
        <div class="evenwidth">
          <label class="inm-label--required">Audit Due Date:</label>
          <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true" [monthNavigator]="true" [showIcon]="true"
            formControlName="auditDueDate" placeholder="MM/DD/YYYY">
          </p-calendar>
        </div>
      </div>
      <div class="inm-spaced">
        <div class="evenwidth" style="margin-top: -69px">
          <div class="inm-label">Delivery Method:</div>
          <p-dropdown [options]="deliveryMethodListItems" autoWidth="true" [disabled]="loadingMasterData"
            formControlName="deliveryMethod"></p-dropdown>
        </div>
        <div class="evenwidth"></div>
        <div class="evenwidth" style="margin-top: -69px">
          <label class="inm-label--required">Status Due Date:</label>
          <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true" [monthNavigator]="true" [showIcon]="true"
            formControlName="statusDueDate" placeholder="MM/DD/YYYY">
          </p-calendar>
        </div>
      </div>
      <div class="inm-spaced">
        <div class="evenwidth">
          <div class="inm-label--required">Audit Carrier:</div>
          <p-dropdown [options]="auditCarrierListItems" autoWidth="true" [disabled]="loadingMasterData"
          formControlName="auditCarrierId"></p-dropdown>
        </div>
        <div class="evenwidth">
          <div class="inm-label">Audit Class:</div>
          <p-dropdown
          [options]="auditClassListItems"
          [showClear]="true" placeholder=" "
          [disabled]="loadingMasterData"
          formControlName="auditClassId" (onChange)="getTemplate($event.value)"></p-dropdown>
        </div>
        <div class="evenwidth" *ngIf="showTemplate">
          <p style="font-style: italic;color:orangered;padding:2px; ;">
            <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
            PHI toggle must be on to open templates.
        </p>
        <p *ngIf="!isTemplateAvailable" class="template">Template is not available for this Audit Class</p>
        <div *ngIf="isTemplateAvailable">
        <a class="templateLink" *ngIf="phiToggleEnabled"
          (click)="clickTemplate($event)">
            {{templateName}}
          </a>
          <span style="color: black;" *ngIf="!phiToggleEnabled">
            {{templateName}}
          </span>
        </div>
        </div>
        <div class="evenwidth" *ngIf="!showTemplate"></div>
      </div>
      <div class="inm-spaced" *ngIf="editAuditId">
        <div class="evenwidth">
          <!-- <div>
          <div class="inm-label--hidden hidden">HiddenDiv</div>
        </div> -->
        <div class="evenwidth" class="check-box">
            <p-checkbox name="groupname"  label="  Remove From Workflow"
            [disabled]="loadingMasterData"
            binary="true"
            formControlName="isHideFromWorkflow">
            </p-checkbox>
        </div>
      </div>
        <div class="evenwidth"></div>
        <div class="evenwidth"></div>
      </div>
      <div class="inm-spaced" *ngIf="editAuditId">
        <div class="evenwidth">
          <div class="evenwidth" class="check-box">
              <p-checkbox name="groupname"  label="  Pending"
              [disabled]="loadingMasterData"
              binary="true"
              formControlName="isPending">
              </p-checkbox>
          </div>
      </div>
        <div class="evenwidth"></div>
        <div class="evenwidth"></div>
      </div>


      <section class='inm-actions--plain reduce-padding'>
        <button type="button" class="inm-button" (click)="saveAudit()" [disabled]="disableSave || (!auditFormGroup.valid || !auditFormGroup.dirty)">Save</button>
        <div class="space-it"></div>
        <button type="button" class="inm-button" (click)="reset()">Reset</button>
        <div class="space-it"></div>
        <app-context-menu *ngIf="editAuditId" [menuItems]="menuHelper.MenuItems" [buttonText]="'&#8942;'" (modalAction)="itemsClicked($event)"></app-context-menu>
      </section>
      <div class="hspacer"></div>
    </section>
  </form>
</div>
<div *ngIf="editAuditId && this.rxNumbersFilter.length > 0">
  <p-chip><strong>Filtering on Rx Numbers:</strong> {{rxFilterString}} </p-chip>
  <i class="pi pi-times" style="cursor: pointer;" (click)="removeFilter()"></i>
</div>
<section *ngIf="editAuditId && auditFormGroup" class='inm-wrapper reduce-padding' style="height: 48%">
  <p style="font-style: italic;color:orangered;padding:2px; ;">
    <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
    Refresh, paging and sorting will clear claim selections.
  </p>
  <p-header >
    <p-toolbar >
      <div class="ui-toolbar-group-left" *ngFor="let item of toolbarHelper.currentToolbar">
        <button class="inm-button" *ngIf="item.show" 
          [disabled]="item.exclude ? false : ((selectedClaims && selectedClaims.length > 0 || item.toggle) ? item.disabled : true)"
          type="button" pTooltip="{{item.tip}}" (click)="toolbarBtnClick(item.name)">
          <svg class="inm-icon" aria-label="item.tip">
            <use [attr.xlink:href]="item.icon"></use>
          </svg>
        </button>
      </div>
      <div class="ui-toolbar-group-right">
        <label class="labelTotals">Claim Count:</label><label id="totalClaimCount">{{totalClaimCount}}</label>
        <label class="labelTotals">Approved Recoupment Amt:</label><label>{{totalApprovedRecoupmentAmount|currency}}</label>
      </div>
    </p-toolbar>
  </p-header>
  <p-table #dt [columns]="auditFormTableHelper.associatedClaimsColumns"
  [value]="associatedClaims" (onLazyLoad)="loadDataLazy($event)"
  [loading]="associatedClaimsDataLoading" loading="fa-spinner"
  [lazy]="true" [paginator]="true" [responsive]="true" [rows]="maxRowsBinding"
  [totalRecords]="totalItemCount" [(selection)]="selectedClaims"
  (onRowSelect)="updateRowEdit($event)" (onRowUnselect)="undoRowEdit($event)"
  (onPage)="undoSelect($event)">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns"
          [ngClass]="col.hdrClass? col.hdrClass : 'default'"
          [pSortableColumn]="col.sortable ? col.field : false">
            {{col.header}}
          <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
          <p-tableHeaderCheckbox #chkBoxAll *ngIf="col.field === 'Selected'" (click)="checkAllClick($event)"></p-tableHeaderCheckbox>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
      <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
        <td *ngFor="let col of columns"
        [ngClass]="col.fldClass? col.fldClass : 'default'" pEditableColumn>
            <div *ngIf="col.field === 'Selected'" >
              <p-tableCheckbox [value]="rowData" ></p-tableCheckbox>
            </div>
            <div *ngIf="col.field === 'Prescription_PrescriptionNumber'">
              <a style="cursor: pointer;" *ngIf="hasLinkToClaimDetail"
              (click)="clickClaimDetail(rowData)">
                {{rowData[col.field]}}
              </a>
              <span *ngIf="!hasLinkToClaimDetail">
                  {{rowData[col.field]}}
              </span>
            </div>
            <div *ngIf="col.field === 'Prescription_SplitBillCode'">
              {{rowData[col.field]}}
            </div>
            <div *ngIf="col.field === 'Prescription_FillDate'">
              {{ rowData[col.field]  | date:'MM/dd/yyyy' }}
            </div>
            <div *ngIf="col.field === 'DiscrepancyCode'">
              {{ rowData[col.field] }}
            </div>
            <div *ngIf="col.field === 'TagCode'">
              {{ rowData[col.field] }}
            </div>
            <div *ngIf="col.field === 'Payer_ProcessorIdentifier'">
                {{ rowData[col.field]}}
            </div>
            <div *ngIf="col.field === 'Payer_ShardPayerName'">
              {{rowData[col.field]}}
            </div>
            <div *ngIf="col.field === 'Location_CorporateLocationNumber'">
                {{ rowData[col.field]}}
            </div>
            <div *ngIf="col.field === 'Drug_NationalDrugCode'">
                {{ rowData[col.field]}}
            </div>
            <div *ngIf="col.field === 'Drug_Label'">
              {{rowData[col.field]}}
            </div>
            <div *ngIf="col.field === 'InitialRisk'">
              {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' | customCurrencyPipe }}
            </div>
            <div *ngIf="col.field === 'Money_PayerReceivableAmountClaim'">
              {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' | customCurrencyPipe }}
            </div>
            <div *ngIf="col.field === 'ApprovedRecoupmentAmount'">
              <div *ngIf="!toolbarHelper.isRecoupment">
                {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' | customCurrencyPipe }}
              </div>
              <div class="ui-cell-flex">
                <p-cellEditor [style]="{'align-content': 'center'}" *ngIf="toolbarHelper.isRecoupment">
                  <ng-template pTemplate="input">
                    <input class="inm-input ui-editing-custom" type="text" [disabled]="!checkRowSelected(rowData.RawDataImport_ShardClaimIdentifier)"
                    (click)="selectText($event)"
                    [(ngModel)]="rowData.EditApprovedRecoupment" currencyMask>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <input class="inm-input ui-editing-custom" [disabled]="!checkRowSelected(rowData.RawDataImport_ShardClaimIdentifier)"
                    (click)="selectText($event)"
                    [(ngModel)]="rowData.EditApprovedRecoupment" currencyMask>
                  </ng-template>
                </p-cellEditor>
              </div>
            </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      Total Record Count: {{ totalItemCount }}
    </ng-template>
  </p-table>
</section>
<app-add-notes-dialog id="notesDialog" [id]="toolbarHelper.showAction" [show]="toolbarHelper.showAction === 'applyNote'"
  [picklistLabel]="'Enter Notes'" [titleText]="toolbarHelper.actionText" [displayTextBoxLabel]="toolbarHelper.labelText"
  (modalAction)="toolbarHelper.toolbarDialogModalAction($event, selectedClaims)"></app-add-notes-dialog>
<app-select-dialog id="tagDialog" [id]="toolbarHelper.showAction" [show]="toolbarHelper.showAction === 'applyTag'" [listItems]="toolbarHelper.tags"
  [titleText]="toolbarHelper.actionText" [filterProperty]="toolbarHelper.optionLabel" [displayTextBoxLabel]="toolbarHelper.labelText"
  (modalAction)="toolbarHelper.toolbarDialogModalAction($event, selectedClaims)"></app-select-dialog>
<app-checkbox-dialog id="discrepancyCodesDialog" [id]="toolbarHelper.showAction" [show]="toolbarHelper.showAction === 'applyDiscrepancy'"
  [listItems]="toolbarHelper.discrepancyCodes"
  [titleText]="toolbarHelper.actionText" [filterProperty]="toolbarHelper.optionLabel" [displayTextBoxLabel]="toolbarHelper.labelText"
  (modalAction)="toolbarHelper.toolbarDialogModalAction($event, selectedClaims)"></app-checkbox-dialog>
<app-delete-dialog id="deleteDialog" [id]="toolbarHelper.showAction" [show]="toolbarHelper.showAction === 'deleteClaim'" [titleText]="toolbarHelper.actionText"
 [message]="toolbarHelper.deleteMsg"
 (modalAction)="deleteCallback($event)"></app-delete-dialog>
<p-dialog id="addClaimsDialog" header="Associate Inmar Claims" [(visible)]="showAddClaims"
[closable]="true" [modal]="true" [responsive]="true" [style]="{'width':'1100px'}"  [positionTop]="10" (onShow)="showAddClaims"
[contentStyle]="{'min-height':'500px', 'padding': '0', 'overflow': 'hidden'}" [baseZIndex]="10000">
  <div class="scr-fix">
    <app-audit-claims [auditId]="editAuditId" (closed)="hideDialog()" [showDialog]="showAddClaims"></app-audit-claims>
  </div>
</p-dialog>

<p-dialog id="searchAuditClaimsDialog" header="Search Audit Claims" [(visible)]="showSearchAuditClaimsHistory"
[closable]="true" [modal]="true" [responsive]="true" [style]="{'width':'1100px'}"  [positionTop]="10" (onShow)="showSearchAuditClaimsHistory"
[contentStyle]="{'min-height':'500px', 'padding': '0', 'overflow': 'hidden'}" [baseZIndex]="10000">
  <div class="scr-fix">
    <app-search-audit-claims [auditId]="editAuditId" (closed)="hideSearchAuditClaimDialog($event)" [showDialog]="showSearchAuditClaimsHistory"></app-search-audit-claims>
  </div>
</p-dialog>

<p-dialog id="viewDocumentsDialog" *ngIf="hasAssociatedDocs"
  header="Work With Documents"
  [(visible)]="showAddImages"
  [closable]="true"
  [modal]="true"
  [responsive]="true"
  [positionTop]="30"
  (onShow)="showAddImages"
  [style]="{'width':'545px'}"
  [contentStyle]="{'min-height':'300px'}">
<div class="scr-fix">
  <app-audit-images [auditId]="editAuditId" (closed)="hideImagesDialog()" [showDialog]="showAddImages"></app-audit-images>
</div>
</p-dialog>

<p-dialog id="viewSendFaxDialog"
  header="Send Fax"
  [(visible)]="showSendFax"
  [closable]="true"
  [modal]="true"
  [positionTop]="75"
  (onShow)="showSendFax"
  [style]="{'width':'1000px'}"
  [contentStyle]="{'max-height':'800px','min-height':'300px'}">
  <div class="scr-fix">
    <app-audit-fax [auditId]="editAuditId" (closed)="hideFaxDialog()" [showDialog]="showSendFax"></app-audit-fax>
  </div>
</p-dialog>

<p-dialog id="viewStatusHistoryDialog"
  header="Status History"
  [(visible)]="showStatusHistory"
  [closable]="true"
  [modal]="true"
  [responsive]="true"
  [positionTop]="30"
  (onShow)="showStatusHistory"
  [style]="{'width':'1000px'}"
  [contentStyle]="{'min-height':'300px'}">
<div class="scr-fix">
 <app-audit-status-history [auditId]="editAuditId" (closed)="hideStatusHistoryDialog()" [showDialog]="showStatusHistory"></app-audit-status-history>
</div>
</p-dialog>

<p-dialog id="viewClaimDetailDialog"
  header="Audit Claim Detail"
  [(visible)]="showClaimDetail"
  [closable]="true"
  [modal]="true"
  [positionTop]="75"
  (onShow)="showClaimDetail"
  [style]="{'width':'1000px'}"
  [contentStyle]="{'max-height':'800px', 'min-height':'300px'}">
  <div class="scr-fix">
    <app-claim-detail [auditClaimId]="claimDetailAuditClaimId"
      [source]="claimDetailSource"
      (closed)="hideClaimDetailDialog()"
      [showDialog]="showClaimDetail"></app-claim-detail>
  </div>
</p-dialog>

<app-file-upload-dialog id="associateDocumentsDialog"
  [key]="{auditId: editAuditId}"
  [(show)]="showFileDialog"
  (uploadComplete)="uploadComplete($event)">
</app-file-upload-dialog>

