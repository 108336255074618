import {
    AuditCarrierSummaryComponent,
    AuditFormComponent,
    AuditSearchComponent,
    AuditSectionComponent,
    AuditTypeSummaryComponent
} from '../../components';
import { AuthGuard, FeatureGuard } from '../../guards';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule, Routes } from '@angular/router';

import { AuditCarrierDocumentSearchComponent } from 'src/app/components/audit-carrier-document-search/audit-carrier-document-search.component';
import { AuditTagGuard } from 'src/app/guards/auditTag.guard';
import { ClientTenantGuard } from 'src/app/guards/clientTenant.guard';
import { ConfirmTagComponent } from 'src/app/components/confirm-tag/confirm-tag.component';
import { DialogModule } from 'primeng/dialog';
import { FaxStatusComponent } from 'src/app/components/fax-status/fax-status.component';
import { NgModule } from '@angular/core';
import { UploadClaimsComponent } from 'src/app/components/upload-claims/upload-claims.component';
import { WorkflowSummaryComponent } from 'src/app/components/workflow/workflow-summary/workflow-summary.component';

/*********************************************/
/*
The AuditModule defines routes for the Audit related pages of the site (see routes below).

The default route will load the AuditSectionCompents.  Child routes load components within
the AuditSectionComponent's router-outlet.

AuditSectionComponent (components/audit-section) is responsible for determining which child to
load if you navigate to the default path of the module.
*/
/********************************************/


const routes: Routes = [
    {
        path: 'audit',
        component: AuditSectionComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuditTagGuard, ClientTenantGuard],
        children: [
            {
                path: 'workflow', component: WorkflowSummaryComponent, canActivate: [FeatureGuard],
                data: { feature: 'UI::Workflow' }
            },
            {
                path: 'faxstatus', component: FaxStatusComponent, canActivate: [FeatureGuard],
                data: { feature: 'UI::FaxStatus' }
            },
            {
                path: 'auditsearch', component: AuditSearchComponent, canActivate: [FeatureGuard],
                data: { feature: 'UI::AuditSearch' }
            },
            {
                path: 'auditsearch/:carrierName', component: AuditSearchComponent, canActivate: [FeatureGuard],
                data: { feature: 'UI::AuditSearch' }
            },
            {
                path: 'auditsearch/:typeName', component: AuditSearchComponent, canActivate: [FeatureGuard],
                data: { feature: 'UI::AuditSearch' }
            },
            {
                path: 'auditcarrierdocumentsearch', component: AuditCarrierDocumentSearchComponent, canActivate: [FeatureGuard],
                data: { feature: 'UI::AuditCarrierDocumentSearch' }
            },
            {
                path: 'auditcarriersummary', component: AuditCarrierSummaryComponent, canActivate: [FeatureGuard],
                data: { feature: 'UI::AuditCarrierSummary' }
            },
            {
                path: 'audittypesummary', component: AuditTypeSummaryComponent, canActivate: [FeatureGuard],
                data: { feature: 'UI::AuditTypeSummary' }
            },
            {
                path: 'uploadclaims', component: UploadClaimsComponent, canActivate: [FeatureGuard],
                data: { feature: 'UI::UploadClaims' }
            },
            {
                path: 'audit-form', component: AuditFormComponent,
                canActivate: [FeatureGuard],
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                data: { feature: 'UI::AuditEdit' }
            },
            {
                path: 'audit-form/:id', component: AuditFormComponent,
                canActivate: [FeatureGuard],
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                data: { feature: 'UI::AuditEdit' }
            },
            { path: '', redirectTo: 'workflow', pathMatch: 'full' },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes), DialogModule, ModalModule.forRoot()],
    exports: [RouterModule],
    declarations: [ConfirmTagComponent],
    // entryComponents: [ConfirmTagComponent],
    providers: [BsModalService]
})
export class AuditRoutingModule { }
