import { Route, Routes } from '@angular/router';
import * as _ from 'lodash';

import { AuthGuard } from './guards/auth.guard';

import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HealthcheckComponent } from './components/healthcheck/healthcheck.component';
export const appRoutes: Routes = [
    { path: 'healthcheck', component: HealthcheckComponent },
    {
        path: 'audit',
        loadChildren: () => import('./modules/audit/audit.module').then(m => m.AuditModule)
    },
    {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
    },
    // {
    //     path: 'metrics',
    //     loadChildren: () => import('./modules/metrics/metrics.module').then(m => m.MetricsModule)
    // },
    {
      path: 'metrics',
      loadChildren: () => import('./modules/rx-audit/rx-audit.module').then(m => m.RxAuditModule)
    },
    { path: '', component: LandingPageComponent, canActivate: [AuthGuard] },
    { path: '**', component: PageNotFoundComponent }];

export function componentFromRoute(route: string): Route {
    const myRoute = _.find(appRoutes, { path: route });
    return myRoute;
}
