<h2>
  Document Search
</h2>
<p-progressBar mode="indeterminate" *ngIf="loadingMasterData"></p-progressBar>
<form [formGroup]="auditCarrierDocumentSearchFormGroup">
  <section class='container form'>
    <div class="inm-spaced">
      <div class="container-spacing">
        <div class="inm-label--required">Audit Carrier:</div>
        <p-dropdown [options]="auditCarrierListItems" autoWidth="true" [disabled]="loadingMasterData"
        formControlName="auditCarrierId"></p-dropdown>
      </div>
      <section class='inm-actions--plain reduce-padding' style="margin-right: 70px; margin-top: 12px;">
        <button class="inm-button" [disabled]="loadingMasterData || formIsInvalid()"
          (click)="search(default_page, true)">Search</button>
        <div class="space-it"></div>
        <button class="inm-button" (click)="resetPageSetup()">Reset</button>
      </section>
    </div>
  </section>
  <div class="container-spacing">
    <section *ngIf="showSearchResults" style="margin-top: 12px;">
      <p style="font-style: italic;color:orangered;padding:2px;">
         <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
         PHI toggle must be on to open and download documents. Max download size 100MB.
       </p>

    <div class="ui-toolbar-group-right"> 
      <label [ngClass]="{'labelTextOverFileSizeLimit': selectedDocumentsFileSize > totalFileSizeAllowed, 
        'labelTextUnderFileSizeLimit': selectedDocumentsFileSize < totalFileSizeAllowed}">Selected Documents File Size:</label>
        <label [ngClass]="{'fileSizeTextOverFileSizeLimit': selectedDocumentsFileSize > totalFileSizeAllowed, 
          'fileSizeTextUnderFileSizeLimit': selectedDocumentsFileSize < totalFileSizeAllowed}">{{selectedDocumentsFileSize | number : '1.2-2'}} MB</label>
    </div>  

    <app-audit-toolbar [toolbarItemList]="toolbarHelper.currentToolbar" (OnClick)="toolbarBtnClick($event)">
    </app-audit-toolbar>
      <p-table #dTable [columns]="tableHelper.auditCarrierDocumentSearchColumns" [value]="auditCarrierDocumentSearchResults"
        [loading]="loadingSearchResults" loading="fa-spinner" [lazy]="true"
        (onLazyLoad)="loadAuditCarrierDocumentSearchLazy($event)" [paginator]="true" 
        [responsive]="true" [rows]="30"
        [totalRecords]="totalItemCount" [(selection)]="selectedDocuments"
        (onPage)="undoSelect($event)"  (onRowSelect)="onDocumentSelect($event)" 
        (onRowUnselect)="onDocumentUnselect($event)" resizableColumns="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [ngClass]="col.hdrClass? col.hdrClass : 'default'"
              [pSortableColumn]="col.field !== 'Selected' ? col.field : false">
              {{col.header}}
              <p-sortIcon *ngIf="col.field !== 'Selected'" [field]="col.field"></p-sortIcon>
              <p-tableHeaderCheckbox #chkBoxAll *ngIf="col.field === 'Selected'"
              [disabled]="documentResultsFileSize > totalFileSizeAllowed" 
              (click)="checkAllClick($event)">
            </p-tableHeaderCheckbox>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
            <td *ngFor="let col of columns" [ngClass]="col.fldClass ? col.fldClass : 'default'">
              <div *ngIf="col.field === 'Selected'" style="padding:0.25em;">
                <p-tableCheckbox [value]="rowData" #row>
                </p-tableCheckbox>
              </div>
              <div *ngIf="col.field === 'ImageName'">
                <div *ngIf="phiToggleEnabled">
                  <a class="table-link" (click)="downloadDocument(rowData)">
                    {{rowData[col.field]}}</a>
                </div>
                <div *ngIf="!phiToggleEnabled" style="cursor:default;text-align: center;">
                  {{rowData[col.field]}}
                </div>
              </div>
              <div *ngIf="col.field === 'ImageSize'">
                {{rowData[col.field]}} MB
              </div>
              <div *ngIf="col.field === 'AuditSpecialistName'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'AuditClassName'">
                {{rowData[col.field]}}
              </div>
              <div *ngIf="col.field === 'ImageCreateDate'">
                {{rowData[col.field] | date: 'MM/dd/yyyy'}}
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan="6" style=" text-align: center">
              No records found
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          Total Record Count: {{ totalItemCount }}
        </ng-template>
      </p-table>
    </section>
  </div>
</form>