export class AuditSearchResult {
    AuditId: number;
    AuditReferenceNumber: string;
    AuditNumber: string;
    Carrier: string;
    AuditTypeId: number;
    AuditType: string;
    ClaimCount: number;
    TotalInitialRiskAmount: number;
    TotalApprovedRecoupmentAmount: number;
    AuditStatus: string;
    AuditSpecialist: string;
    CreatedBy: string;
    CreateDate: Date;
}
