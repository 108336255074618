import * as routes from './routes';

import {ActivatedRoute, UrlSegment} from '@angular/router';
import {
  AdminSectionComponent,
  AuditAddressBookComponent,
  AuditCarrierSummaryComponent,
  AuditCarriersComponent,
  AuditClaimsComponent,
  AuditDiscrepancyCodeComponent,
  AuditFormComponent,
  AuditSearchComponent,
  AuditSectionComponent,
  AuditTypeComponent,
  AuditTypeSummaryComponent,
  AuditVendorComponent,
  FooterComponent,
  SideNavComponent,
  WorkflowStatusComponent,
  WorkflowSummaryComponent
} from './components';
import {
        AppInsightsService,
        AuditAddressBookService,
        AuditCarrierService,
        AuditTypeService,
        AuthorizationService,
        GlobalFilterService
} from './services';
import { AuthGuard, FeatureGuard } from './guards';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AccordionModule } from 'primeng/accordion';
import { AdminAddEditDialogComponent } from './components/admin/shared/components/admin-add-edit-dialog/admin-add-edit-dialog.component';
import { AdminModule } from './modules/admin/admin.module';
import { AppComponent } from './app.component';
import { AuditAddressBook } from './models/audit-address-book';
import { AuditCarrierDocumentSearchComponent } from './components/audit-carrier-document-search/audit-carrier-document-search.component';
import { AuditClassComponent } from './components/admin/audit-class/audit-class.component';
import { AuditClassTemplateAddEditDialogComponent } from './components/admin/audit-class-template-add-edit-dialog/audit-class-template-add-edit-dialog.component';
import { AuditFaxComponent } from './components/audit-fax/audit-fax.component';
import { AuditImagesComponent } from './components/audit-images/audit-images.component';
import { AuditModule } from './modules/audit/audit.module';
import { AuditSpecialistComponent } from './components/admin/audit-specialist/audit-specialist.component';
import { AuditStatusComponent } from './components/admin/audit-status/audit-status.component';
import { AuditStatusHistoryComponent } from './components/audit-status-history/audit-status-history.component';
import { AuditTagGuard } from './guards/auditTag.guard';
import { BlockUIModule } from 'primeng/blockui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BusinessResponseService } from './services/business-response.service';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ClaimDetailComponent } from './components/claim-detail/claim-detail.component';
import { ClientTenantGuard } from './guards/clientTenant.guard';
import { ConfirmClientTenantComponent } from './components/confirm-client-tenant/confirm-client-tenant.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CustomCurrencyPipe } from './pipes/custom-currency-pipe';
import { CustomPhonePipe } from './pipes/custom-phone-pipe';
import { CustomPipeModule } from './pipes/custom-pipe.module';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FaxStatusComponent } from './components/fax-status/fax-status.component';
import { FileUploadModule } from 'primeng/fileupload';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { HeaderComponent } from './components/header/header.component';
import { HealthcheckComponent } from './components/healthcheck/healthcheck.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { InputMaskModule } from 'primeng/inputmask';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import {MessageModule} from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { RouterModule } from '@angular/router';
import { SEIDValidator } from './validators/seid-validator';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SharedModule } from './shared/shared.module';
import { SidebarModule } from 'primeng/sidebar';
import { SvgIconDirective } from './directives/svg-icon.directive';
import { TableModule } from 'primeng/table';
import { TemplateUploadComponent } from './components/template-upload/template-upload.component';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { UploadClaimsComponent } from './components/upload-claims/upload-claims.component';
import { UploadManualClaimDialogComponent } from './components/upload-manual-claim-dialog/upload-manual-claim-dialog.component';
import { environment } from '../environments/environment';
import { fromEventPattern } from 'rxjs';
import { LookerAnalyticsService } from './services/looker-analytics.service';
import { SearchAuditClaimsComponent } from './components/search-audit-claims/search-audit-claims.component';
import { ChipsModule } from 'primeng';
import { RxAuditSummaryComponent } from './components/rx-audit/rx-audit-summary/rx-audit-summary.component';
import { RxAuditViewComponent } from './components/rx-audit/rx-audit-view/rx-audit-view.component';
import { SlugMenuSideNavComponent } from './components/slug-menu-side-nav/slug-menu-side-nav.component';
import { RxAuditModule } from './modules/rx-audit/rx-audit.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FlexLayoutModule } from '@angular/flex-layout';

// tslint:disable-next-line: max-line-length

@NgModule({
  imports: [
    OrderListModule,
    CustomPipeModule,
    AccordionModule,
    BrowserAnimationsModule,
    BrowserModule,
    ConfirmDialogModule,
    CurrencyMaskModule,
    FormsModule,
    HttpClientModule,
    MessageModule,
    MessagesModule,
    PanelModule,
    PanelMenuModule,
    ProgressBarModule,
    ButtonModule,
    TooltipModule,
    FileUploadModule,
    BlockUIModule,
    RouterModule.forRoot(routes.appRoutes,
      { enableTracing: !environment.production,
    onSameUrlNavigation: 'reload',
    useHash: true, relativeLinkResolution: 'legacy' }),
    ReactiveFormsModule,
    CurrencyMaskModule,
    CommonModule,
    FlexLayoutModule,

    // -- PrimeNg Modules
    BlockUIModule,
    CalendarModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    OverlayPanelModule,
    PanelModule,
    RadioButtonModule,
    TableModule,
    ToolbarModule,
    SharedModule,
    EditorModule,
    InputMaskModule,
    RadioButtonModule,
    ProgressBarModule,
    SidebarModule,
    AdminModule,
    AuditModule,
    RxAuditModule,
    ChipsModule,
    ProgressSpinnerModule
  ],
  declarations: [
    AuditSectionComponent,
    AdminSectionComponent,
    AppComponent,
    HeaderComponent,
    LandingPageComponent,
    PageNotFoundComponent,
    FooterComponent,
    AuditFormComponent,
    AuditSearchComponent,
    SideNavComponent,
    AuditCarriersComponent,
    AuditClaimsComponent,
    AuditVendorComponent,
    AuditStatusComponent,
    AuditCarriersComponent,
    AuditCarrierSummaryComponent,
    AuditTypeSummaryComponent,
    SvgIconDirective,
    AuditSpecialistComponent,
    AdminAddEditDialogComponent,
    AuditClassTemplateAddEditDialogComponent,
    AuditTypeComponent,
    AuditClassComponent,
    AuditAddressBookComponent,
    AuditDiscrepancyCodeComponent,
    WorkflowSummaryComponent,
    WorkflowStatusComponent,
    AuditImagesComponent,
    ImageUploadComponent,
    TemplateUploadComponent,
    UploadManualClaimDialogComponent,
    UploadClaimsComponent,
    ClaimDetailComponent,
    AuditFaxComponent,
    HealthcheckComponent,
    FaxStatusComponent,
    AuditCarrierDocumentSearchComponent,
    AuditStatusHistoryComponent,
    SearchAuditClaimsComponent,
    RxAuditSummaryComponent,
    RxAuditViewComponent,
    SlugMenuSideNavComponent
  ],
  providers: [
    AuthorizationService,
    GoogleAnalyticsService,
    AuthGuard,
    AuditTagGuard,
    BusinessResponseService,
    ClientTenantGuard,
    FeatureGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    SEIDValidator,
    AuditCarrierService,
    CookieService,
    CurrencyMaskModule,
    GlobalFilterService,
    ConfirmationService,
    DatePipe,
    CustomPhonePipe,
    AuditTypeService,
    AuditAddressBookService,
    LookerAnalyticsService
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
