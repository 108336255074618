import { Component, EventEmitter, OnInit, ViewChild, Input, Output, OnChanges } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { cloneDeep, remove } from 'lodash';
import * as toastr from 'toastr';
import { BusinessResponseService } from 'src/app/services/business-response.service';
import { AuditClassService } from 'src/app/services/audit-class.service';
import { TemplateUploadAction } from './models/template-upload-action';

const TOASTR_OPTIONS: any = {
  closeButton: true,
  timeOut: '0',
  extendedTimeOut: '0'
};


@Component({
  selector: 'app-template-upload-component',
  templateUrl: './template-upload.component.html',
  styleUrls: ['./template-upload.component.css']
})

export class TemplateUploadComponent implements OnInit {
  @ViewChild('imageUpload', { static: true }) fileUploadElem: FileUpload;
  @Input() show: boolean;
  @Input() keyName: string;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() key: any;
  @Output() uploadImageComplete: EventEmitter<TemplateUploadAction> = new EventEmitter<TemplateUploadAction>();

  public uploading: boolean;
  public readonly fileLimitMsg = 'Only 1 file may be uploaded.';
  public invalidFileCount = false;
  public readonly fileErrorMessage = 'Only 1 file may be uploaded.';
  private filesCache: File[] = [];
  constructor() { }

  ngOnInit() {
  }

  filesSelected(event: any) {
    const maxFiles = 1;
    if (this.fileUploadElem.files) {
      const filesCount = this.fileUploadElem.files.length;
      if (filesCount > 0 && filesCount <= 1) {
        this.filesCache = cloneDeep(this.fileUploadElem.files);
      }
      if (filesCount > maxFiles) {
        this.invalidFileCount = true;

        if (this.filesCache.length > 0) {
          this.fileUploadElem.files = this.filesCache;
        } else {
          this.fileUploadElem.clear();
        }
        setInterval(() => {
          this.invalidFileCount = false;
        }, 6000);
      }
    }
  }

  uploadImages(event: any, key: any): void {
    this.uploading = true;
    this.uploadImageComplete.emit({
      uploadComplete: true,
      auditClassId: null,
      File: event.files[0]
    });
    this.show = false;
    this.fileUploadElem.clear();
  }

  removeImage(file: any) {
    remove(this.fileUploadElem.files, (f) => {
      return f === file;
    });
  }

  onShow(event): void {
    this.fileUploadElem.files = [];
    this.filesCache = [];
    this.invalidFileCount = false;
  }

  hide(): void {
    this.showChange.emit(false);
    this.uploadImageComplete.emit({
      uploadComplete: false,
      auditClassId: null,
      File: null
    });
  }
}
