<div class="sortable-container" [style.width]='listStyle.width'>
  <div class="sortable-header">
    <div class="sortable-buttons">
      <button pTooltip="Move Item Up" class="inm-button" (click)="moveUp()" [disabled]="disableButtons" title="Move Up">
        <svg class="inm-icon" aria-label="item.tip">
          <use xlink:href="#inm-icon__chevron-up-square"></use>
        </svg>
      </button>
      <button pTooltip="Move Item Down"  class="inm-button" (click)="moveDown()" [disabled]="disableButtons" title="Move Down">
        <svg class="inm-icon" aria-label="item.tip">
          <use xlink:href="#inm-icon__chevron-down-square"></use>
        </svg>
      </button>
    </div>
  </div>
  <ul class="sortable-list" [style.height]='listStyle.height'>
    <li class="li-curse" draggable="true" (click)="selectItem(item)" *ngFor="let item of items; let i = index;" [ngClass]="{'active': item == selectedItem}"
      (drop)="onDrop($event, i)" (dragover)="allowDrop($event,i)" (dragstart)="onDragStart($event, i)">
      <ng-template ngFor [ngForOf]="[item]" [ngForTemplate]="itemTemplate">
        <div class="drop-zone" *ngIf="onDragOverIndex == i || inDragOverIndex.length == i + 1">
        </div>
        {{onDragOverIndex}}{{i}}
      </ng-template>
    </li>
  </ul>
</div>