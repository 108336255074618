export interface ITableColumn {
    field: string;
    header: string;
    hdrClass?: string;
    fldClass?: string;
    show: boolean;
    width?: string;
    disabled?: boolean;
    sortable?: boolean;
}

export class AddressBookTableHelper {
    public tableColumns: ITableColumn[];

    constructor() {
        this.generateColumns();
    }

    private generateColumns(): void {
        this.tableColumns = [
            {
                field: 'ContactName', header: 'Name',
                show: true, disabled: false, sortable: true
            },
            {
                field: 'CompanyName', header: 'Company',
                show: true, disabled: false, sortable: true
            },
            {
                field: 'FaxNumber', header: 'Fax Number',
                show: true, disabled: false, sortable: true
            },
            {
                field: 'PhoneNumber', header: 'Phone Number',
                show: true, disabled: false, sortable: true
            },
            {
                field: 'Notes', header: 'Notes',
                show: true, disabled: false, sortable: true
            },
            {
                field: 'ModifiedBy', header: 'Author',
                show: true, disabled: false, sortable: true
            },
            {
                field: 'ModifiedDate', header: 'Date Modified',
                show: true, disabled: false, sortable: true
            },
            {
                field: 'delete', header: '',
                hdrClass: 'column-icon-btn', fldClass: 'column-icon-btn',
                show: false, disabled: false, sortable: false
            },
        ];
    }
}