<p-progressBar mode="indeterminate" *ngIf="loadingMasterData || loadingSearchResults"></p-progressBar>
<h2>Audit Search</h2>
<p-confirmDialog header="Delete Confirmation" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>
<form [formGroup]="auditSearchFormGroup">
  <section class='inm-wrapper form'>
    <div class="inm-spaced">
      <label class="evenwidth">
        <div class="inm-label">Audit Reference Number:</div>
        <input class="inm-input" formControlName="auditReferenceNumber" >
      </label>
      <div class="evenwidth">
        <div class="inm-label">Audit Letter Start Date:</div>
        <p-calendar
          [yearRange]="'2000:2040'" [yearNavigator]="true" [monthNavigator]="true" [showIcon]="true" formControlName="notificationStartDate"
          placeholder="MM/DD/YYYY">
        </p-calendar>
      </div>
      <div class="evenwidth">
        <div class="inm-label">Audit Letter End Date:</div>
        <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true" [monthNavigator]="true" [showIcon]="true" formControlName="notificationEndDate"
          placeholder="MM/DD/YYYY">
        </p-calendar>
      </div>
    </div>
    <div class="inm-spaced" *ngIf="invalidNotificationDates()">
      <div style="flex: 1;"></div>
      <div style="flex: 2;" class="secondColumnError">
        <p class="inm-message--danger">{{notificationDateErrorMessage}}</p>
      </div>
    </div>
    <div class="inm-spaced">
      <label class="evenwidth">
        <div class="inm-label">Audit Number:</div>
        <input class="inm-input" formControlName="auditNumber" type="number" >
      </label>
      <div class="evenwidth">
        <div class="inm-label">Received Start Date: </div>
          <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true"
            [monthNavigator]="true" [showIcon]="true" formControlName="receivedStartDate"
            placeholder="MM/DD/YYYY">
        </p-calendar>
      </div>
      <div class="evenwidth">
        <div class="inm-label">Received End Date: </div>
        <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true"
          [monthNavigator]="true" [showIcon]="true" formControlName="receivedEndDate"
          placeholder="MM/DD/YYYY">
        </p-calendar>
      </div>
    </div>
    <div class="inm-spaced" *ngIf="invalidReceivedDates()" >
        <div style="flex: 1;"></div>
        <div style="flex: 2;" class="secondColumnError">
          <p class="inm-message--danger">{{receivedDateErrorMessage}}</p>
      </div>
    </div>
    <div class="inm-spaced">
      <div class="evenwidth">
        <div class="inm-label">Audit Status:</div>
        <p-dropdown [options]="auditStatusListItems" autoWidth="false" [disabled]="loadingMasterData"
        formControlName="auditStatusId" [showClear]="true"></p-dropdown>
      </div>
      <div class="evenwidth">
        <div class="inm-label">Audit Due Start Date:</div>
        <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true"
          [monthNavigator]="true" [showIcon]="true" formControlName="dueStartDate"
          placeholder="MM/DD/YYYY">
        </p-calendar>
      </div>
      <div class="evenwidth">
        <div class="inm-label">Audit Due End Date:</div>
        <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true"
          [monthNavigator]="true" [showIcon]="true" formControlName="dueEndDate"
          placeholder="MM/DD/YYYY">
        </p-calendar>
      </div>
    </div>
    <div class="inm-spaced" *ngIf="invalidDueDates()">
      <div style="flex: 1;"></div>
      <div style="flex: 2;" class="secondColumnError">
        <p class="inm-message--danger">{{dueDateErrorMessage}}</p>
      </div>
    </div>
    <div class="inm-spaced">
      <div class="evenwidth">
        <div class="inm-label">Audit Specialist:</div>
        <p-dropdown [options]="auditSpecialistListItems" autoWidth="true" [disabled]="loadingMasterData"
          formControlName="specialistId" [showClear]="true"></p-dropdown>
      </div>
      <div class="evenwidth">
        <div class="inm-label">Audit Carrier: </div>
        <p-dropdown [options]="auditCarrierListItems" autoWidth="true" [disabled]="loadingMasterData"
        formControlName="auditCarrierId" [showClear]="true"></p-dropdown>
      </div>
      <div class="evenwidth">
        <div class="inm-label">Audit Vendor: </div>
        <p-dropdown [options]="auditVendorListItems" autoWidth="true" [disabled]="loadingMasterData"
        formControlName="vendorId" [showClear]="true"></p-dropdown>
      </div>
    </div>
    <div class="inm-spaced">
      <div class="evenwidth">
        <div class="inm-label">Audit Type: </div>
        <p-dropdown [options]="auditTypesListItems" autoWidth="true" [disabled]="loadingMasterData"
          formControlName="auditTypeId" [showClear]="true"></p-dropdown>
      </div>
      <div class="evenwidth">
        <div class="inm-label">Delivery Method: </div>
        <p-dropdown [options]="deliveryMethodListItems" autoWidth="true" [disabled]="loadingMasterData"
          formControlName="deliveryMethod" [showClear]="true"></p-dropdown>
      </div>
      <div class="evenwidth">
          <div class="inm-label">Audit Class: </div>
          <p-dropdown [options]="auditClassListItems" autoWidth="true" [disabled]="loadingMasterData"
          formControlName="auditClassId" [showClear]="true"></p-dropdown>
      </div>
    </div>
    <div class="inm-spaced">
      <label class="evenwidth">
        <div class="inm-label">Tag:</div>
        <input class="inm-input" formControlName="tag">
        <p *ngIf="tagLengthInvalid()" class="inm-message--danger">
          {{tagInvalidErrorMessage}}
        </p>
      </label>
      <label class="evenwidth">
        <div class="inm-label">Drug (NDC, GCN, or GPI):</div>
        <input class="inm-input" formControlName="drug">
      </label>
      <label class="evenwidth">
          <div class="inm-label">BIN:</div>
          <input class="inm-input" formControlName="bin">
        </label>
    </div>
    <div class="inm-spaced">
      <div class="evenwidth">
        <div class="inm-label">Location (Store #, NCPDP, or NPI):</div>
        <input class="inm-input" formControlName="store">
      </div>
      <label class="evenwidth">
        <div class="inm-label">Rx Number:</div>
        <input class="inm-input" formControlName="rxNumber">
      </label>
      <div class="evenwidth" id="fillDates">
        <div>
          <div>
            <div class="inm-label">From Fill Date Range:</div>
            <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true" [monthNavigator]="true" [showIcon]="true"
              formControlName="rxFromFillDate" placeholder="MM/DD/YYYY">
            </p-calendar>
            <div style="padding-left:47px; display: inline-block;">
              <p-calendar [yearRange]="'2000:2040'" [yearNavigator]="true" [monthNavigator]="true" [showIcon]="true"
                formControlName="rxToFillDate" placeholder="MM/DD/YYYY">
              </p-calendar>
            </div>
          </div>
          <div class="inm-spaced" *ngIf="invalidFillDates()" style="width: 400px;margin-left: 5px">
            <div style="flex: 2;" class="secondColumnError">
            <p class="inm-message--danger">{{fillDateErrorMessage}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inm-spaced">
      <label class="evenwidth">
        <div class="inm-label">Claim Control Number:</div>
        <input class="inm-input" formControlName="claimControlNumber">
        <p *ngIf="claimControlNumberLengthInvalid()" class="inm-message--danger">
          {{claimControlNumberInvalidErrorMessage}}
        </p>
      </label>
      <div class="evenwidth">
      </div>
      <div class="evenwidth">
      </div>
    </div>
    <section class='inm-actions--plain reduce-padding' style="margin-top: 12px">
      <button class="inm-button" [disabled]="loadingMasterData || formIsInvalid()" (click)="search(default_page, true)">Search</button>
      <div class = "space-it"></div>
      <button class="inm-button" (click)="resetPageSetup()">Reset</button>
    </section>
  </section>
  <section *ngIf="showSearchResults">
    <p-table #dTable
      [columns]="tableHelper.auditSearchColumns"
      [value]="auditSearchResults"
      [loading]="loadingSearchResults"
      [lazy]="true"
      (onLazyLoad)="onLazyLoad($event)"
      [paginator]="true"
      [rows]="30"
      [scrollable]="false"
      resizableColumns="true"
      [totalRecords]="totalItemCount">
      <ng-template pTemplate="header" let-columns>
        <tr class="cols">
          <th *ngFor="let col of columns"
            [ngClass]="col.hdrClass ? col.hdrClass : 'default'"
            [pSortableColumn]="col.sort === false ? false : col.field"
            [style.cursor]="col.sort !== false ? 'default' : 'pointer'">
            {{col.header}}
            <p-sortIcon *ngIf="col.sort !== false" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-audit let-columns="columns">
        <tr>
          <td *ngFor="let col of columns"
            style="text-align: center;"
            [ngClass]="col.fldClass ? col.fldClass : 'default'">
            <div *ngIf="col.field === 'AuditNumber'">
              <a *ngIf="hasEditFeature()" [routerLink]="['/audit/audit-form', {'id': audit['AuditId']}]">
                {{audit[col.field]}}
              </a>
              <span *ngIf="!hasEditFeature()">{{audit[col.field]}}</span>
            </div>
            <div *ngIf="col.field === 'AuditReferenceNumber'">
              {{audit[col.field]}}
            </div>
            <div *ngIf="col.field === 'Carrier'">
                {{audit[col.field]}}
              </div>
            <div *ngIf="col.field === 'AuditType'">
              {{audit[col.field]}}
            </div>
            <div *ngIf="col.field == 'AuditSpecialist'">
              {{audit[col.field]}}
            </div>
            <div *ngIf="col.field == 'AuditStatus'">
              {{audit[col.field]}}
            </div>
            <div *ngIf="col.field === 'ClaimCount'">
              {{audit[col.field]}}
            </div>
            <div *ngIf="col.field === 'TotalInitialRiskAmount'" style="text-align: right;">
              <span>{{audit[col.field] | currency:'USD':'symbol':'1.2-2'| customCurrencyPipe  }}</span>
            </div>
            <div *ngIf="col.field === 'TotalApprovedRecoupmentAmount'" style="text-align: right;">
              <span>{{audit[col.field] | currency:'USD':'symbol':'1.2-2'| customCurrencyPipe  }}</span>
            </div>
            <div *ngIf="col.field === 'Delete'" style="cursor: pointer">
              <a (click)="confirmDelete(audit)" >
                <svg class="inm-icon" aria-label="Delete"><use
                  xlink:href="#inm-icon__trash" class="danger"/></svg>
              </a>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan ="10" style=" text-align: center">
              No records found
            </td>
          </tr>
        </ng-template>
      <ng-template pTemplate="summary">
          Total count: {{totalItemCount}} audits
      </ng-template>
    </p-table>
  </section>
</form>
<app-delete-dialog
 [id]="'deleteAudit'"
 [show]="showDeleteDialog"
 [titleText]="'Confirm Delete Audit'"
 [message]="deleteConfirmationMessage"
 (modalAction)="delete($event)">
</app-delete-dialog>

