import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AuditClaimDetail, ILimitedResult, Pagination
} from '../models';

import { Observable } from 'rxjs';
import * as toastr from 'toastr';
import { environment } from '../../environments/environment';
import { HttpUtilityService } from './http-utility.service';
import { GlobalFilterService } from './global-filter.service';
import { AuditClaimDiscrepancyCode } from '../models/audit-claim-discrepancy-code';

const BASE_URL = `${environment.auditApiRoot}/clients`;

@Injectable({
  providedIn: 'root'
})

export class AuditClaimService {
  private readonly Timeout: number = 30000;
  constructor(
    private http: HttpClient,
    private httpUtility: HttpUtilityService,
    private globalFilterService: GlobalFilterService,
  ) {
  }

  public getAuditClaimDetailAsync(auditClaimId: number, source: string, showPhi: boolean): Observable<AuditClaimDetail> {
    const url = `${BASE_URL}/${this.globalFilterService.selectedClientId}/AuditClaims/${auditClaimId}/Sources/${source}?showPhi=${showPhi}`;
    return this.http.get<AuditClaimDetail>(url, { headers: this.httpUtility.getHeaders() });
  }

  public getAuditClaimDiscrepancyCodes(auditClaimId: number,
                                       pagination: Pagination): Observable<ILimitedResult<AuditClaimDiscrepancyCode>> {
    const url = `${BASE_URL}/${this.globalFilterService.selectedClientId}/AuditClaims/${auditClaimId}/DiscrepancyCodes/`;
    const params = pagination.buildSearchParams();
    const headers = this.httpUtility.getHeaders();
    return this.http.get<ILimitedResult<AuditClaimDiscrepancyCode>>(url, { headers, params });
  }

  public deleteAuditClaimDiscrepancyCode(auditClaimId: number, discrepancyCodeId: number): Observable<boolean> {
    const url =
      `${BASE_URL}/${this.globalFilterService.selectedClientId}/AuditClaims/${auditClaimId}/DiscrepancyCodes/${discrepancyCodeId}`;
    return this.http.delete<boolean>(url);
  }
}
